import { SharedStateService } from './shared.state-service';
import axiosLibrary from 'axios';
import { config } from '../../auth/components/auth-provider/config';
import { getToken } from './msal';

/** Axios service
 * USE THIS INSTEAD OF NORMAL AXIOS
 * config each API Client call with headers and baseURL
 */
const http = axiosLibrary.create({
    baseURL: `${config.apiUrl}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

http.interceptors.request.use(async config => {
    const token = await getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token.accessToken}`;
    }

    return config;
}, err => {
    Promise.reject(err);
});

// Use next for checking if the user is logged in
http.interceptors.response.use(response => {
    // SharedStateService.setBackendNotWorking(false);

    return Promise.resolve(response);
}, error => {
    if (error.response.status === 401) {
        // console.error('Unauthorized');
        // authProvider.logout();
        // localStorage.clear();
        // sessionStorage.clear();

        // SharedStateService.setFoundUnauthorizedCall(true);
        // window.location.href = '/login';
    }

    if (error.response.status === 500) {
        SharedStateService.setBackendNotWorking(true);
    }

    return Promise.reject(error);
});

export const axios = http;
