import * as React from 'react';
import * as div from './icon.style';

interface Props {
    color: string;
    d: string;
    viewBox?: string;
    width?: number;
    height?: number;
}

export const Icon: React.FunctionComponent<Props> = (props: Props) => {
    const { color, d, viewBox, width, height} = props;

    return (
        <div.Svg viewBox={viewBox ? viewBox : '0 0 24 24'}
            width={width ? width : 20}
            height={height ? height : 20}>
            <path fill={color} d={d} />
        </div.Svg>
    );
};
