import * as React from 'react';

import { CustomersStateService, _deleteCustomer, _getCustomersByCompanyId, _setCurrentCustomer, _toggleDeleteCustomerModal, _toggleSaveCustomerModal } from '../services/customers.state-service';
import { ICustomer, IParser } from '../interfaces/customer';
import { SharedStateService, _getPoTypes } from '../../shared/services/shared.state-service';
import { useHistory, useParams } from 'react-router';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ColumnsType } from 'antd/lib/table';
import { CompaniesStateService } from '../../companies/services/companies.state-service';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { IPoType } from '../../shared/interfaces/po-type';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { ISiteId } from '../../shared/interfaces/site-id';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SaveCustomerModal } from '../components/save-customer-modal/save-customer-modal';
import { UsersService } from '../../users/services/users.service';
import { UsersStateService } from '../../users/services/users-state.service';
import { colors } from '../../shared/styles/colors';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

interface IUrlParams {
    companyId: string;
}
interface Props extends IAzureADFunctionProps { }

export const CustomersPage: React.FC<Props> = (props: Props) => {
    // store
    const customersLoading = useSelector<AppState, boolean>(state => state.customers.customersLoading);
    const customers = useSelector<AppState, ICustomer[]>(state => state.customers.customers);
    const deleteCustomerModal = useSelector<AppState, boolean>(state => state.customers.modals.delete);
    const saveCustomerModal = useSelector<AppState, boolean>(state => state.customers.modals.save);
    const customer = useSelector<AppState, ICustomer>(state => state.customers.currentCustomer);
    const poTypes = useSelector<AppState, IPoType[]>(state => state.shared.poTypes);
    const parsers = useSelector<AppState, IParser[]>(state => state.customers.parsers);
    const siteIds = useSelector<AppState, ISiteId[]>(state => state.shared.siteIds);

    const urlParams: IUrlParams = useParams();
    const history = useHistory();

    /**
     * @todo Add "add" functionality
     */

    const render = () => {
        if (customersLoading) {
            return (
                <ActivityIndicator />
            );
        }

        return (
            <PageWrapper>
                <PageTitle text={'Customer IDs'} />

                {/* <CustomersTable customers={customers} poTypes={poTypes} /> */}

                <GeckoTable dataSource={{ data: customers }}
                    columns={columns()}
                    tableId={'customers'}
                    filters={[]}
                    actions={{ add: true }}
                    onAddClick={() => onAddCustomerClick()}
                    showRowMenu={true}
                    onRow={onRow}
                    rowMenu={customer => rowMenu(customer)} />

                {
                    deleteCustomerModal &&
                    <ConfirmationModal onClose={() => _toggleDeleteCustomerModal(false)}
                        onConfirm={() => _deleteCustomer(customer)} />
                }

                {
                    saveCustomerModal &&
                    <SaveCustomerModal customer={customer}
                        companyId={urlParams.companyId}
                        parsers={parsers}
                        error={''}
                        siteIDs={siteIds}
                        poTypes={poTypes} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    React.useEffect(() => {
        AuthService.checkAuth(history);

        CustomersStateService.getParsers();
        SharedStateService.getSiteIds();
        _getPoTypes();
        UsersStateService.getRoles();
    }, []);

    React.useEffect(() => {
        _getCustomersByCompanyId(urlParams.companyId);

        CompaniesStateService.getBreadcrumbCompany(urlParams.companyId);
    }, [urlParams.companyId]);

    const onAddCustomerClick = () => {
        _toggleSaveCustomerModal(true);
        _setCurrentCustomer({} as ICustomer);
    };

    const rowMenu = (customer: ICustomer): IRowMenu[] => {
        return [{
            label: 'Edit Customer',
            onClick: () => onEditClick(customer),
        }, {
            label: 'Delete Customer',
            onClick: () => onDeleteClick(customer),
            disabled: !UsersService.authorizeDisplay([ROLES.Admin])
        }, {
            label: 'View PDF Rules',
            onClick: () => window.open(`https://app.docparser.com/stack/${customer.parserId}/fields`, '_blank'),
            disabled: !customer.parserId,
        }];
    };

    const onDeleteClick = (customer: ICustomer) => {
        _setCurrentCustomer(customer);
        _toggleDeleteCustomerModal(true);
    };

    const onEditClick = (customer: ICustomer) => {
        _setCurrentCustomer(customer);
        _toggleSaveCustomerModal(true);
    };

    const getIcon = (flag: boolean | null) => {
        if (flag) {
            return (
                <FontAwesomeIcon icon={faCheck}
                    color={colors.$success}
                    size={'lg'} />
            );
        }
    };

    const getPoTypeName = (id: string | number): string | undefined => {
        return poTypes.find(type => type.id === id) ?.name;
    };

    const onBodyRowClick = (customer: ICustomer) => {
        const { pathname } = history.location;
        const path = `${pathname}/${customer.id}/purchaseorder-list?customerId=${customer.id}&pageNumber=1&pageSize=20`;

        CustomersStateService.setBreadcrumbCustomer(customer);

        history.push(path);
    };

    const onRow = (customer: ICustomer, _rowIndex?: number) => {
        return {
            onClick: () => onBodyRowClick(customer),
        };
    };

    const columns = (): ColumnsType<ICustomer> => {
        return [{
            title: 'External Customer ID',
            dataIndex: 'externalCustomerId',
        }, {
            title: 'PO Type',
            render: (_text: string, customer: ICustomer) => getPoTypeName(customer.poTypeId),
        }, {
            title: 'Site ID',
            dataIndex: 'siteId',
        }, {
            title: 'Email',
            dataIndex: 'email',
        }, {
            title: 'Email Notifications',
            render: (_text: string, customer: ICustomer) => getIcon(customer.emailNotifications),
        }, {
            title: 'Automatic Approval',
            render: (_text: string, customer: ICustomer) => getIcon(customer.automaticApproval),
        }, {
            title: 'Currency',
            dataIndex: 'currency',
        }, {
            title: 'Ship To Country',
            dataIndex: 'shipToCountryCode',
        }];
    }

    return render();
};
