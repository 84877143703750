import moment from 'moment';

export class DateService {
    public static formatDate(date: string | null) {
        if (date) {
            return moment(date).format('MM/DD/YYYY');
        }

        return '';
    }
}