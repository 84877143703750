import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initOrderDateFrom: string;
    initOrderDateTo: string;
}

const { Filters } = Theme;

export const OrderDateFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initOrderDateFrom, initOrderDateTo } = props;

    const getInitFrom = () => {
        if (initOrderDateFrom) {
            return moment(initOrderDateFrom);
        }

        return null;
    };

    const getInitTo = () => {
        if (initOrderDateTo) {
            return moment(initOrderDateTo);
        }

        return null;
    };

    const [orderDateFrom, setOrderDateFrom] = useState(getInitFrom());
    const [orderDateTo, setOrderDateTo] = useState(getInitTo());

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Order Date</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <DateSelector value={orderDateFrom}
                            label={'From'}
                            onChange={date => onChange(date, 'orderDateFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <DateSelector value={orderDateTo}
                            label={'To'}
                            onChange={date => onChange(date, 'orderDateTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initOrderDateFrom && !initOrderDateTo) {
            setOrderDateFrom(null);
            setOrderDateTo(null);
        }
    }, [initOrderDateTo, initOrderDateFrom]);

    const onChange = (date: Moment, keyValue: string) => {
        if (keyValue === 'orderDateFrom') {
            setOrderDateFrom(date);
        }

        if (keyValue === 'orderDateTo') {
            setOrderDateTo(date);
        }

        if (date) {
            onInputChange(keyValue, moment(date).format('MM/DD/YYYY'));
        } else {
            onInputChange(keyValue, '');
        }

    };

    return render();
};