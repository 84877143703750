import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initShippedDateFrom: string;
    initShippedDateTo: string;
}

const { Filters } = Theme;

export const ErpShippedDateFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initShippedDateFrom, initShippedDateTo } = props;

    const getInitFrom = () => {
        if (initShippedDateFrom) {
            return moment(initShippedDateFrom);
        }

        return null;
    };

    const getInitTo = () => {
        if (initShippedDateTo) {
            return moment(initShippedDateTo);
        }

        return null;
    };

    const [shippedDateFrom, setShippedDateFrom] = useState(getInitFrom());
    const [shippedDateTo, setShippedDateTo] = useState(getInitTo());

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Shipped Date</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <DateSelector value={shippedDateFrom}
                            label={'From'}
                            onChange={date => onChange(date, 'erpShippedDateFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <DateSelector value={shippedDateTo}
                            label={'To'}
                            onChange={date => onChange(date, 'erpShippedDateTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initShippedDateFrom && !initShippedDateTo) {
            setShippedDateFrom(null);
            setShippedDateTo(null);
        }
    }, [initShippedDateTo, initShippedDateFrom]);

    const onChange = (date: Moment, keyValue: string) => {
        if (keyValue === 'erpShippedDateFrom') {
            setShippedDateFrom(date);
        }

        if (keyValue === 'erpShippedDateTo') {
            setShippedDateTo(date);
        }

        if (date) {
            onInputChange(keyValue, moment(date).format('MM/DD/YYYY'));
        } else {
            onInputChange(keyValue, '');
        }

    };

    return render();
};