import * as companiesActions from './companies.actions';

import { Action } from "../../shared/interfaces/shared";
import { ICompany } from "../interfaces/company";
import { ICompanyState } from "../interfaces/companies-state";
import { companiesInitState } from './companies.init-state';

export function companiesReducer(state: ICompanyState = companiesInitState, action: Action<any>): ICompanyState {
    switch (action.type) {
        case companiesActions.SAVE_COMPANY_OK: {
            return {
                ...state,
                saveCompany: {
                    ...state.saveCompany,
                    error: null,
                    response: action.payload,
                },
            };
        }

        case companiesActions.SAVE_COMPANY_FAIL: {
            return {
                ...state,
                saveCompany: {
                    ...state.saveCompany,
                    error: action.payload,
                    response: {} as ICompany,
                },
            };
        }

        case companiesActions.GET_COMPANY_BY_ID_OK: {
            return {
                ...state,
                company: action.payload,
            };
        }

        case companiesActions.RESET_COMPANY: {
            return {
                ...state,
                company: {} as ICompany,
                saveCompany: {
                    ...state.saveCompany,
                    error: null,
                    response: {} as ICompany,
                },
            };
        }

        case companiesActions.GET_COMPANIES_OK: {
            return {
                ...state,
                companies: action.payload.data,
                pagination: action.payload,
            };
        }

        case companiesActions.TOGGLE_COMPANIES_LOADING: {
            return {
                ...state,
                companiesLoading: action.payload,
            };
        }

        case companiesActions.DELETE_COMPANY_OK: {
            return {
                ...state,
                deleteCompany: {
                    ...state.deleteCompany,
                    response: action.payload,
                    error: null,
                },
            };
        }

        case companiesActions.DELETE_COMPANY_FAIL: {
            return {
                ...state,
                deleteCompany: {
                    ...state.deleteCompany,
                    response: {} as ICompany,
                    error: action.payload,
                },
            };
        }

        case companiesActions.GET_FILTER_COMPANIES_OK: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    companies: action.payload,
                },
            };
        }

        case companiesActions.TOGGLE_DELETE_COMPANY_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: action.payload,
                },
            };
        }

        case companiesActions.SET_COMPANY: {
            return {
                ...state,
                company: action.payload,
            };
        }

        case companiesActions.TOGGLE_SAVE_COMPANY_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    save: action.payload,
                },
            };
        }

        case companiesActions.RESET_COMPANIES_MODULE: {
            return {
                ...state,
                ...companiesInitState,
            };
        }

        case companiesActions.GET_ERP_COMPANIES_OK: {
            return {
                ...state,
                erpCompanies: action.payload,
            };
        }

        default:
            return state;
    }
}
