import * as React from 'react';

import {
    _createSoa,
    _deleteSoa,
    _editSoa,
    _getSoas,
    _resetCurrentSoa,
    _resetSoas,
    _toggleCreatetSoaModal,
    _toggleDeleteSoaModal,
} from '../services/soa-state.service';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { CreateSoaModal } from '../components/create-soa-modal/create-soa-modal';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ISoa } from '../interfaces/soa';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SoaPageUtils } from './soa.page.utils';
import { UsersService } from '../../users/services/users.service';
import { _resetAoeBreadcrumbs } from '../../aoe/services/aoe-state.service';
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps { }

const _SOAPage: React.FC<Props> = (_props: Props) => {
    const deletedSoa = useSelector<AppState, ISoa>(state => state.soa.deleteSoa.deletedSoa);
    const deleteSoaModal = useSelector<AppState>(state => state.soa.deleteSoaModal);
    const createSoaModal = useSelector<AppState>(state => state.soa.createSoaModal);
    const currentSOA = useSelector<AppState, ISoa>(state => state.soa.editSoa.currentSoa);
    const soas = useSelector<AppState, ISoa[]>(state => state.soa.soas);
    const getSoasLoading = useSelector<AppState, boolean>(state => state.soa.getSoasLoading);
    const createSoaLoading = useSelector<AppState, boolean>(state => state.soa.createSoa.loading);
    const deleteSoaLoading = useSelector<AppState, boolean>(state => state.soa.deleteSoa.loading);
    const editSoaLoading = useSelector<AppState, boolean>(state => state.soa.editSoa.loading);

    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

    const history = useHistory();

    const displayAddButton = UsersService.authorizeDisplay([ROLES.Admin]);

    const onPageInit = React.useCallback(() => {
        AuthService.checkAuth(history);

        _resetSoas();
        _resetAoeBreadcrumbs();

        _getSoas();
    }, [history]);

    React.useEffect(() => {
        onPageInit();

        return () => {
            _resetSoas();
        };
    }, [onPageInit]);

    React.useEffect(() => {
        if (!isEmpty(currentSOA)) {
            setIsEditMode(true)
        } else {
            setIsEditMode(false)
        }
    }, [currentSOA]);

    const render = () => {
        if (getSoasLoading) {
            return <ActivityIndicator />;
        }

        return (
            <PageWrapper>
                <PageTitle text={'SOA/CSR'} />

                <GeckoTable dataSource={{ data: soas }}
                    columns={SoaPageUtils.tableColumns}
                    showRowMenu={true}
                    rowMenu={soa => SoaPageUtils.rowMenu(soa)}
                    tableId={'soas'}
                    onAddClick={() => _toggleCreatetSoaModal(true)}
                    filters={[]}
                    actions={{ add: displayAddButton }} />

                {
                    deleteSoaModal &&
                    <ConfirmationModal
                        onClose={() => _toggleDeleteSoaModal(false)}
                        onConfirm={() => _deleteSoa(deletedSoa.id)}
                        loading={deleteSoaLoading}
                    />
                }

                {
                    createSoaModal &&
                    <CreateSoaModal
                        currentSOA={currentSOA && currentSOA}
                        isEditMode={isEditMode}
                        onSubmit={_createSoa}
                        onSubmitEdit={_editSoa}
                        onClose={() => _resetCurrentSoa()}
                        loading={editSoaLoading ? editSoaLoading : createSoaLoading}
                    />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    return render();
};

export const SOAPage = _SOAPage;
