import 'react-datepicker/dist/react-datepicker.css'

import * as React from "react";
import * as styled from './date-selector.style'

import { DatePicker } from 'antd';
import { Moment } from 'moment';

interface Props {
    value: Moment | null;

    onChange: (text: any) => void;

    placeholder?: string;

    label?: string;

    disabled?: boolean;

    error?: string;

    required?: boolean;

    format?: string;
}

export const DateSelector: React.FC<Props> = (props: Props) => {
    const { value, label, placeholder, error, onChange, disabled, required, format } = props;

    return (
        <styled.Wrapper hasError={!!error}>
            {/** Label */}

            {
                label &&
                <styled.Label>
                    {label}

                    {
                        required && <span>*</span>
                    }
                </styled.Label>
            }

            {/**date picker */}
            {/* <DatePicker
                hasError={!!error}
                placeholderText={placeholder}
                dateFormat="MM/dd/yyyy"
                showMonthDropdown={true}
                dropdownMode="select"
                disabled={disabled ? disabled: false}
                selected={handleEmptyValue(value)}
                onChange={onChange}
            /> */}
            <DatePicker onChange={date => onChange(date)}
                format={format || 'MM/DD/YYYY'}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                disabled={disabled}
                placeholder={placeholder}
                value={value} />

            {/** Error */}
            {
                error &&
                <styled.Error>
                    {error}
                </styled.Error>
            }
        </styled.Wrapper>
    );
};
