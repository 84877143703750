import * as actions from '../state/order-list.actions';

import { IOrderList, IOrderListFilters, IOrderListSaveReq } from '../interfaces/order-list'

import { IApiTableRequest } from '../../shared/interfaces/shared';
import { IShippingFee } from '../interfaces/shipping-fee';
import { store } from '../../shared/services/state.service';

export class OrderListStateService {
    public static getBreadcrumbOrderList(id: string): void {
        store.dispatch(
            actions.getBreadcrumbOrderListReq(id),
        );
    }

    public static setBreadcrumbOrderList(orderList: IOrderList): void {
        store.dispatch(
            actions.setBreadcrumbOrderList(orderList),
        );
    }

    public static confirmCustomerOrder(id: number): void {
        store.dispatch(
            actions.OrderListActions.confirmCustomerOrderReq(id),
        );
    }

    public static getAllOrderLists(request: IApiTableRequest): void {
        store.dispatch(
            actions.getAllOrderListsReq(request),
        );
    };

    public static validateOrderList(orderListId: string): void {
        store.dispatch(
            actions.OrderListActions.validateOrderListReq(orderListId),
        );
    }

    public static toggleValidationLoading(flag: boolean): void {
        store.dispatch(
            actions.OrderListActions.toggleValidationLoading(flag),
        );
    }

    public static toggleFilters(flag: boolean): void {
        store.dispatch(
            actions.OrderListActions.toggleFilters(flag),
        );
    }

    public static setFilteredOrderList(orderLists: IOrderList[]): void {
        store.dispatch(
            actions.OrderListActions.setFilteredOrderList(orderLists),
        );
    }

    public static setFilters(filters: IOrderListFilters): void {
        store.dispatch(
            actions.OrderListActions.setFilters(filters),
        );
    }

    public static setViewMode(flag: boolean): void {
        store.dispatch(
            actions.OrderListActions.setViewMode(flag),
        );
    }

    public static getFilterPoNumber(text: string): void {
        store.dispatch(
            actions.OrderListActions.getFilterPoNumbersReq(text),
        );
    }

    public static getOrderBlob(orderList: IOrderList): void {
        store.dispatch(
            actions.OrderListActions.getOrderBlobReq(orderList),
        );
    }

    public static archivePo(id: number): void {
        store.dispatch(
            actions.OrderListActions.archivePoReq(id),
        );
    }

    public static toggleShippingFeesModal(flag: boolean): void {
        store.dispatch(
            actions.OrderListActions.toggleShippingFeedModal(flag),
        );
    }

    public static getShippingFees(id: number): void {
        store.dispatch(
            actions.OrderListActions.getShippingFeedByOrderIdReq(id),
        );
    }

    public static setSelectedShippingFee(fee: IShippingFee): void {
        store.dispatch(
            actions.OrderListActions.setSelectedShippingFee(fee),
        );
    }

    public static toggleLoadingShippingFees(flag: boolean): void {
        store.dispatch(
            actions.OrderListActions.toggleLoadingShippingFees(flag),
        );
    }

}

/** GET Original Order */
export const _getOriginalOrder = (orderList: IOrderList): void => {
    store.dispatch(actions.getOriginalOrderReq(orderList));
};

export const _toggleGetOriginalOrderLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleGetOriginalLoading(flag));
};

export const _toggleGetOrderListsLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleGetOrderListsLoading(flag));
};

/** CREATE Order List */


export const _toggleCreateOrderListModal = (flag: boolean): void => {
    store.dispatch(actions.toggleCreateOrderListModal(flag));
};


export function _createOrderList(orderList: IOrderListSaveReq): void {
    store.dispatch(actions.createOrderListReq(orderList));
}

export const _toggleCreateOrderListLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleCreateOrderListLoading(flag));
};


/** EDIT Order List */


export function _setCurrentOrderList(orderList: IOrderList): void {
    store.dispatch(actions.setCurrentOrderList(orderList));
}

export function _resetCurrentOrderList(): void {
    store.dispatch(actions.resetCurrentOrderList());
}

export function _editOrderList(orderList: IOrderListSaveReq): void {
    store.dispatch(actions.editOrderListReq(orderList));
}

export const _toggleEditOrderListLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleEditOrderListLoading(flag));
};

/** DELETE Order List */
export function _toggleDeleteOrderListModal(flag: boolean): void {
    store.dispatch(actions.toggleDeleteOrderListModal(flag));
}
export function _setDeletedOrderList(orderList: IOrderList): void {
    store.dispatch(actions.setDeletedOrderList(orderList));
}

export const _deleteOrderList = (orderList: IOrderList): void => {
    store.dispatch(actions.deleteOrderListByIdReq(orderList));
}

export const _toggleDeleteOrderListLoading = (flag: boolean): void => {
    store.dispatch(actions.toggleDeleteOrderListLoading(flag));
};
