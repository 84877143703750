import { FiltersService } from "../../shared/services/filters.service";
import { IApiTableRequest } from "../../shared/interfaces/shared";
import { IAttachRoleReq } from "../interfaces/roles";
import { ISetting } from "../interfaces/settings";
import { axios } from "../../shared/services/axios.service";
import { from } from "rxjs";

export class UsersWebApi {
    public static getRoles() {
        return from(
            axios.get('/UserPermission/roles')
                .then(res => res.data),
        );
    }

    public static attachRole(req: IAttachRoleReq) {
        return from(
            axios.put(`/UserPermission/${req.id}`, { ...req })
                .then(res => res.data),
        );
    }

    public static getUserRoles(params: IApiTableRequest) {
        const qs = FiltersService.getApiQuerySearch(params);

        return from(
            axios.get(`/UserPermission?${qs}`)
                .then(res => res.data),
        );
    }

    public static getUserRole() {
        return from(
            axios.get('/User/role')
                .then(res => res.data),
        );
    }

    public static getUserRoleById(id: number) {
        return from(
            axios.get(`/UserPermission/${id}`)
                .then(res => res.data),
        );
    }

    public static getAdminSettings() {
        return from(
            axios.get('/GeneralSetting')
                .then(res => res.data),
        );
    }

    public static saveAdminSettings(settings: ISetting[]) {
        return from(
            axios.put('/GeneralSetting', [ ...settings ])
                .then(res => res.data),
        );
    }

}
