import { DatePicker } from 'antd';
import { colors } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import styled from 'styled-components';

interface DatePickerProps {
    hasError: boolean;
    disabled: boolean;
}

interface WrapperProps {
    hasError: boolean;
}

export const MyDatePicker = styled(DatePicker) <DatePickerProps>`
    cursor: pointer;
    height: 48px;
    border-radius: 4px;
    font-family: ${fonts.arial};
    font-size: 14px;
    color: ${colors.$darkBlue};
    background-color: ${props => getBackgroundColor(props)};
    outline: none;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 1.14;
    border: 1px solid ${props => getInputBorderColor(props)};
    transition: 0.4s;

    &:hover, &:focus {
       outline: none !important;
        background-color: ${colors.$white};
        box-shadow: 0 3px 20px 0 rgba(101, 152, 184, 0.3);
    }
`;

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;

    ${props => props.hasError && `
            .ant-picker {
                border-color: ${colors.$alert} !important;
            }
        `
    }
`;


export const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$darkBlue};
    line-height: 1.17;
    margin-bottom: 5px;
`;

export const Error = styled.div`
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$alert};
    line-height: 1.17;
`;

function getInputBorderColor(props: DatePickerProps) {
    if (props.hasError) {
        return colors.$alert;
    }

    return colors.$blue;
}

function getBackgroundColor(props: DatePickerProps) {
    if (props.disabled) {
        return colors.$disabled;
    }

    return 'inherit';
}
