import React, { useState } from 'react';

import { Input } from '../../../shared/components/input/input';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initQuantityMin: string;
    initQuantityMax: string;
}

const { Filters } = Theme;

export const ShippedQtyFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initQuantityMin, initQuantityMax } = props;

    const [quantityMin, setQuantityMin] = useState(initQuantityMin || '');
    const [quantityMax, setQuantityMax] = useState(initQuantityMax || '');

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Shipped Quantity</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <Input label={'Min'}
                            value={quantityMin}
                            onChange={quantity => onChange(quantity, 'shippedQtyFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <Input label={'Max'}
                            value={quantityMax}
                            onChange={quantity => onChange(quantity, 'shippedQtyTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    const onChange = (q: string, key: string) => {
        if (key === 'shippedQtyFrom') {
            setQuantityMin(q);
        }

        if (key === 'shippedQtyTo') {
            setQuantityMax(q);
        }

        onInputChange(key, q);
    };

    return render();
}