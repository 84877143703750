import { IGetOrderRequest } from "../interfaces/order";

export class OrdersService {
    public static getSearchStringFromRequest(request: IGetOrderRequest) {
        let searchString = '?';
        // let filterCounter = 0;
        const { sortColumn, sortOrder, pageNumber, pageSize } = request;

        if (sortColumn) {
            searchString += `sortColumn=${sortColumn}&`;
        }

        if (sortOrder) {
            searchString += `sortOrder=${sortOrder}&`;
        }

        searchString += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

        return searchString;
    }

    public static ERP_STATUSES: string[] = ['Released', 'On Hold', 'Closed'];

}