import activCartsLogo from './shared/assets/activ-carts-logo.png'
import geckoLogo from "./shared/assets/gecko-logo.png";

export const Config = {
    gecko: {
        logo: geckoLogo,
        links: {
            documentations: "https://gdlearning.squarespace.com/docs",
            clips: "https://gdlearning.squarespace.com/all-clips",
            events: "https://gdlearning.squarespace.com/all-clips-2",
            seminars: "https://gdlearning.squarespace.com/seminars",
            inTouch2: "https://gdlearning.squarespace.com/connectedintouch2",
            cases: "https://gdlearning.squarespace.com/my-cases#my-customer-portal/"
        }
    },
    activCarts: {
        logo: activCartsLogo
    },
    API_KEY_PARSER: '18500618aeaf2a8472782124a90f45322a3a4307',
};
