export interface IRole {
    id: number;
    name: string;
}

export interface IAttachRoleReq {
    id: number;
    userRoleId: number;
    externalCustomerIdsList: string;
    userEmail: string;
    rejected: boolean;
    companyName: string;
}

export interface IUserRole {
    id: number;
    userEmail: string;
    externalCustomerIdsList: string;
    companyNameClientInput: string;
    userRoleId: number;
    rejected: boolean;
    rejectedDate: string;
    requestDate: string;
    companyName: string;
}

export enum ROLES {
    Admin = 'Admin',
    SOA = 'SoaUser',
    Client = 'ClientUser',
    ClientFinance = 'ClientFinanceUser'
}
