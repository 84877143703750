import * as CompaniesActions from '../../companies/state/companies.actions';

import { GET_CUSTOMERS_FROM_ERP_COMPANY_OK, GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK } from "../../customers/state/customers.actions";

import { Action } from "../../shared/interfaces/shared";
import { FiltersActions } from './filters.actions';
import { GET_FILTER_COMPANIES_OK } from "../../companies/state/companies.actions";
import { GET_FILTER_SOAS_OK } from '../../soa/state/soa.actions';
import { IFiltersState } from "../interfaces/filters-state";
import { OrderListActions } from "../../order-list/state/order-list.actions";
import { OrdersActions } from '../../orders/state/orders.actions';
import { filtersInitState } from "./filters.init-state";

export function filtersReducer(state: IFiltersState = filtersInitState, action: Action<any>): IFiltersState {
    switch (action.type) {
        case GET_FILTER_SOAS_OK: {
            return {
                ...state,
                soas: action.payload,
            };
        }

        case GET_FILTER_COMPANIES_OK: {
            return {
                ...state,
                companies: action.payload,
            };
        }

        case GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK: {
            return {
                ...state,
                customerIds: action.payload,
            };
        }

        case OrderListActions.GET_FILTER_PO_NUMBERS_OK: {
            return {
                ...state,
                poNumbers: action.payload,
            };
        }

        case CompaniesActions.GET_ERP_COMPANIES_OK: {
            return {
                ...state,
                erpCompanies: action.payload,
            };
        }

        case FiltersActions.SET_ORDER_COMPANY: {
            return {
                ...state,
                orders: {
                    ...state.orders,
                    company: action.payload,
                },
            };
        }

        case GET_CUSTOMERS_FROM_ERP_COMPANY_OK: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case FiltersActions.SET_ERP_CUSTOMER_IDS: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case OrdersActions.GET_ERP_PONUMBERS_OK: {
            return {
                ...state,
                erpPoNumbers: action.payload,
            };
        }

        case OrdersActions.GET_ERP_ORDER_NUMBERS_OK: {
            return {
                ...state,
                erpOrderNumbers: action.payload,
            };
        }

        default:
            return state;
    }
}
