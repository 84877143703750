import { colors } from '../../../shared/styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.$black};
    position: relative;
`;

export const Description = styled.div`
    background-color: ${colors.$white};
    padding: 10px;
    position: absolute;
    top: -21px;
    left: 57px;
`;

export const Value = styled.div`
    font-size: 30px;
    font-weight: 600;
`;
