import { Selector, createSelector } from "reselect";

import { AppState } from "../interfaces/app.state";
import { IPoType } from "../interfaces/po-type";
import { SharedState } from "../interfaces/shared.state";

const SHARED_MODULE: Selector<AppState, SharedState> = state => state.shared;

export const PO_TYPES = createSelector<AppState, SharedState, IPoType[]>(
    SHARED_MODULE,
    state => state.poTypes,
);
