import * as React from 'react';
import * as styles from './search.style';

import { Select } from 'antd';

const { Option } = Select;

interface Props {
    value: string;
    onSearch: (value: string) => void;
    onChange: (value: string) => void;
    placeholder?: string;
    label?: string;
    data: any[];
    showKey?: string;

    /** minimum number of letters to call onSearch function  */
    searchAfter?: number;
}

export const Search: React.FC<Props> = (props: Props) => {
    const { value, onSearch, placeholder, onChange, label, data, searchAfter } = props;

    let timeout: any = null;

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.Label>
                    {label}
                </styles.Label>

                <Select showSearch={true}
                    placeholder={placeholder}
                    value={value}
                    allowClear={true}
                    style={{ width: '100%' }}
                    filterOption={false}
                    onChange={onChange}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    onSearch={(value: string) => onSearchEnd(value)}>

                    {
                        (data && data.length) &&
                        data.map((obj, index) =>
                            <Option key={`${obj.value}${index}`} value={obj.value}>
                                {obj.value}
                            </Option>
                        )
                    }

                </Select>
            </styles.Wrapper>
        );
    };

    const onSearchEnd = (value: string) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        let minimum = searchAfter ? searchAfter : 3;

        if (value && value.length >= minimum) {
            if (onSearch) {
                timeout = setTimeout(() => onSearch(value), 500);
            }
        }
    };

    return render();
};
