import { NoAccessPage } from "../pages/no-access.page";
import React from 'react';
import { Route } from "react-router";

export const usersRoutes = () => {
    return [
        <Route exact={true}
            path={'/no-access'}
            key={'no-access'}
            render={props => <NoAccessPage {...props} /> }/>,
    ];
};
