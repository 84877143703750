import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initRevisedDateFrom: string;
    initRevisedDateTo: string;
}

const { Filters } = Theme;

export const RevisedDateFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initRevisedDateFrom, initRevisedDateTo } = props;

    const getInitFrom = () => {
        if (initRevisedDateFrom) {
            return moment(initRevisedDateFrom);
        }

        return null;
    };

    const getInitTo = () => {
        if (initRevisedDateTo) {
            return moment(initRevisedDateTo);
        }

        return null;
    };

    const [revisedDateFrom, setRevisedDateFrom] = useState(getInitFrom());
    const [revisedDateTo, setRevisedDateTo] = useState(getInitTo());

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Revised Date</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <DateSelector value={revisedDateFrom}
                            label={'From'}
                            onChange={date => onChange(date, 'revisedDateFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <DateSelector value={revisedDateTo}
                            label={'To'}
                            onChange={date => onChange(date, 'revisedDateTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initRevisedDateFrom && !initRevisedDateTo) {
            setRevisedDateFrom(null);
            setRevisedDateTo(null);
        }
    }, [initRevisedDateTo, initRevisedDateFrom]);

    const onChange = (date: Moment, keyValue: string) => {
        if (keyValue === 'revisedDateFrom') {
            setRevisedDateFrom(date);
        }

        if (keyValue === 'revisedDateTo') {
            setRevisedDateTo(date);
        }

        if (date) {
            onInputChange(keyValue, moment(date).format('MM/DD/YYYY'));
        } else {
            onInputChange(keyValue, '');
        }

    };

    return render();
};