import { Button, Modal } from 'antd';
import { IEditErpOrderLineReq, IErpOrderLine } from '../../interfaces/order';
import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { DateService } from '../../../shared/services/date.service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ILateCode } from '../../../shared/interfaces/late-code';
import { Input } from '../../../shared/components/input/input';
import { LATE_CODES } from '../../../shared/services/utils.service';
import { OrdersStateService } from '../../services/orders-state.service';
import { Theme } from '../../../shared/styles/theme';
import { _getPartIds } from '../../../order-line/services/order-line.state-service';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface Props {
    line: IErpOrderLine;
    customerId: string;
    orderId: string;
    handleSubmit: (req: IEditErpOrderLineReq) => void;
}

export const EditErpOrderLineModal: React.FC<Props> = (props: Props) => {
    const { line, handleSubmit, customerId, orderId } = props;

    const partIds = useSelector<AppState, string[]>(state => state.orderLine.partIds);

    const [inputs, setInputs] = useState({
        confirmedDate: null,
        customerRequestedDate: null,
        shippedDate: null,
        partId: '',
        quantity: '',
        lateDescription: '',
    });

    const [lateError, setLateError] = useState('');
    const [selectedLateCode, setSelectedLateCode] = useState({} as ILateCode);

    const render = () => {
        return (
            <Modal visible={true}
                onCancel={() => OrdersStateService.toggleEditErpOrderLine(false)}
                footer={[
                    <Button key='cancel'
                        onClick={() => OrdersStateService.toggleEditErpOrderLine(false)}>
                        Cancel
                    </Button>,
                    <Button key='submit'
                        type='primary'
                        onClick={() => onSubmitClick()}
                        disabled={isButtonDisabled()}>
                        Submit
                    </Button>
                ]}
                title={`Updating order line ${line.lineNo}`}
                okButtonProps={{ disabled: isButtonDisabled() }}>

                <DateSelector value={inputs.confirmedDate}
                    label={'Confirmed Date'}
                    onChange={date => setInputs({ ...inputs, confirmedDate: date })} />

                <DateSelector value={inputs.customerRequestedDate}
                    label={'Expected Date'}
                    onChange={date => setInputs({ ...inputs, customerRequestedDate: date })} />

                <DateSelector value={inputs.shippedDate}
                    label={'Shipped Date'}
                    onChange={date => setInputs({ ...inputs, shippedDate: date })} />

                <Dropdown value={inputs.partId}
                    keyToShow={''}
                    options={partIds}
                    label={'Part ID'}
                    onChange={partId => setInputs({ ...inputs, partId })} />

                <Input value={inputs.quantity}
                    label={'Quantity'}
                    onChange={q => setInputs({ ...inputs, quantity: q })} />

                <Dropdown value={selectedLateCode}
                    keyToShow={'key'}
                    options={LATE_CODES}
                    label={'Late Code'}
                    allowClear={true}
                    onChange={code => onLateCodeChange(code)} />

                <Input value={inputs.lateDescription}
                    onChange={lateDescription => setInputs({ ...inputs, lateDescription })}
                    disabled={true}
                    label={'Late Description'} />

                {
                    lateError &&
                    <Theme.ErrorMessage>
                        {lateError}
                    </Theme.ErrorMessage>
                }
            </Modal>
        );
    };

    useEffect(() => {
        _getPartIds(customerId);
    }, [customerId]);

    // useEffect(() => {
    //     if ((inputs.lateCode && !inputs.lateDescription) || (inputs.lateDescription && !inputs.lateCode)) {
    //         setSubmitDisabled(true);
    //         setLateError('Late Code or Late Description is empty');
    //     } else {
    //         setLateError('');
    //         setSubmitDisabled(false);
    //     }
    // }, [inputs.lateDescription, inputs.lateCode]);

    useEffect(() => {
        if (isEmpty(selectedLateCode)) {
            setInputs({
                ...inputs,
                lateDescription: '',
            });
        }
    }, [selectedLateCode]);

    const isButtonDisabled = () => {
        if (!inputs.confirmedDate && !inputs.customerRequestedDate && !inputs.shippedDate && !inputs.partId && !inputs.quantity && isEmpty(selectedLateCode)) {
            return true;
        }

        return false;
    };

    const onLateCodeChange = (code: ILateCode) => {
        setInputs({
            ...inputs,
            lateDescription: code.description
        });
        setSelectedLateCode(code);
    };

    const onSubmitClick = () => {
        let req: IEditErpOrderLineReq = {
            orderId: orderId,
            lineNo: line.lineNo,
            orderLineId: line.id,
            confirmedDate: inputs.confirmedDate ? DateService.formatDate(inputs.confirmedDate) : null,
            customerRequestedDate: inputs.customerRequestedDate ? DateService.formatDate(inputs.customerRequestedDate) : null,
            shippedDate: inputs.shippedDate ? DateService.formatDate(inputs.shippedDate) : null,
            partId: inputs.partId ? inputs.partId : null,
            quantity: inputs.quantity ? inputs.quantity : null,
            lateCode: selectedLateCode.key ? selectedLateCode.key : null,
            lateDescription: inputs.lateDescription ? inputs.lateDescription : null,
        };

        handleSubmit(req);
    };

    return render();
};
