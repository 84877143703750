import * as React from 'react';
import * as styles from './modal-header.style';

interface Props {
    text: string;
}

export const ModalHeader: React.FC<Props> = (props: Props) => {
    const { text } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                {text}

                {/* <styles.BottomBar /> */}
            </styles.Wrapper>
        );
    };

    return render();
};
