import { ISetOrderConfirmation } from "../interfaces/order-confirmation";
import { OrdersConfirmationActions } from "../state/orders-confirmation.actions";
import { store } from "../../shared/services/state.service";

export class OrdersConfirmationStateService {
    public static getOrderConfirmation(id: string) {
        store.dispatch(
            OrdersConfirmationActions.getOrderConfirmationReq(id),
        );
    }

    public static setOrderConfirmation(request: ISetOrderConfirmation) {
        store.dispatch(
            OrdersConfirmationActions.setOrderConfirmationReq(request),
        );
    }

    public static toggleDisplayOrderConfirmationPage(flag: boolean | null) {
        store.dispatch(
            OrdersConfirmationActions.toggleDisplayOrderConfirmationPage(flag),
        );
    }

}
