import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IFilterSoa } from '../../../soa/interfaces/soa';
import { SoaStateService } from '../../../soa/services/soa-state.service';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const SoaFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    // Store
    const soas = useSelector<AppState, IFilterSoa[]>(state => state.filters.soas);

    // Getters
    const getInitialSoa = (soaId: string) => {
        return soas.filter(soa => soa.id.toString() === soaId)[0];
    };

    // State
    const [soa, setSoa] = useState(getInitialSoa(initValue));

    const render = () => {
        return (
            <Dropdown label={'SOA'}
                options={soas}
                keyToShow={'value'}
                value={soa}
                disabled={false}
                allowClear={true}
                onChange={soa => onChange(soa)} />
        );
    };

    useEffect(() => {
        SoaStateService.getFilterSoas();
    }, []);

    useEffect(() => {
        if (!initValue) {
            setSoa({} as IFilterSoa);
        }
    }, [initValue]);

    const onChange = (soa: IFilterSoa) => {
        onInputChange('soaId', soa.id && soa.id.toString() || '');
        setSoa(soa);
    };

    return render();
};
