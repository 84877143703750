import { colors } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

export const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$darkBlue};
    line-height: 1.17;
`;
