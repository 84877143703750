import * as React from "react";

import { IAzureADFunctionProps } from "react-aad-msal";
import { SUBHEADER_TILES } from "../constants/orders.constants";
import { Subheader } from "../../shared/components/subheader/subheader";
import { ordersRoutes } from "./orders.routes";

interface Props extends IAzureADFunctionProps {}

export const OrdersRouter: React.FC<Props> = (azureAd: Props) => {
    return (
        <React.Fragment>
            {/* Subheader */}
            <Subheader tiles={SUBHEADER_TILES()} />

            {/* Orders Routes */}
            {ordersRoutes(azureAd)}
        </React.Fragment>
    );
};
