import { IOrderStatus } from '../../interfaces/order-list';
import { colors } from '../../../shared/styles/colors';
import styled from 'styled-components';

interface IStatusTextProps {
    type: IOrderStatus;
}

export const StatusText = styled.div<IStatusTextProps>`
    font-weight: 700;
    width: 150px;
    color: ${props => getColor(props)};
`;

const getColor = (props: IStatusTextProps) => {
    if (props.type) {
        switch (props.type.id) {
            case 1: {
                return colors.$grey;
            }

            case 2: {
                return colors.$warning;
            }

            case 3: {
                return colors.$blue;
            }

            case 4: {
                return colors.$success;
            }

            case 5: {
                return colors.$alert;
            }

            case 6: {
                return colors.$darkBlue;
            }

            default:
                return colors.$grey;
        }
    }

    return '';
};
