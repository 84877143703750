import * as styled from './faq.page.style';

import { Document, Page, pdfjs } from 'react-pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageWrapper } from '../../../shared/styles/page-wrapper';
import React from 'react';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { colors } from '../../../shared/styles/colors';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const defaultExcelTemplate = require('../../../shared/files/Purchase_order_template.xlsx');
const customerPortalExplanation = require('../../../shared/files/Customer_Portal_explanation.pdf');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {}

export const FAQPage: React.FC<Props> = (props: Props) => {
    const render = () => {
        return (
            <PageWrapper>
                <styled.FaqSection>
                    <SectionTitle text={'Default Excel Template'} />

                    <a href={defaultExcelTemplate} download={'Purchase Order Template.xlsx'}>
                        Download from here &nbsp;
                        <FontAwesomeIcon icon={faFileExcel}
                            size={'1x'}
                            color={colors.$excel} />
                    </a>
                </styled.FaqSection>

                <styled.FaqSection>
                    <SectionTitle text={'Validation rules'} />

                    <div>Initial validation </div>

                    <ul>
                        <li><b>PO Number</b> - check if the "PO Number" is already present in DataHub</li>
                        <li><b>PO Date</b> - check date format and fill in the revised "PO Date"</li>
                        <li><b>Expected Date</b> - check date format (if "Expected Date" was received in the PO and fill in "Revised Expected Delivery Date" by default with "PO Received Date" + 4 weeks)</li>
                        <li><b>Ship Via</b> - check if ship via was received in the PO and set the "Revised Ship Via" field to empty by default</li>
                        <li><b>FOB</b> - check if the "FOB" received in the "PO" is valid and set the "Revised FOB" field to empty by default</li>
                        <li><b>Part ID</b> - check if "Part ID" is valid for that Customer</li>
                        <li><b>Quantity</b> - check if the quantity received in the "PO" is below the "MOQ" value. Fill in the "Revised Quantity" field as follows:
                            <ol>
                                <li>If the quantity is below the "MOQ" value, fill in the "MOQ" value by default</li>
                                <li>If the quantity is equal/greater than "MOQ" value, fill in the the same quantity as received in the "PO"</li>
                            </ol>
                        </li>
                        <li><b>Unit price</b> - check if the unit price received in the "PO" is correct and fill in the "Received Unit Price" field with the correct value</li>
                    </ul>

                    <div>Confirm PO Validation </div>

                    <ul>
                        <li><b>PO Number</b> - check if the "PO Number" is already present in DataHub</li>
                        <li><b>Revised PO Date</b> - check if it has the correct format</li>
                        <li><b>Revised Expected Delivery Date</b> - check if it has the correct format</li>
                        <li><b>Part ID</b> - check if it's valid for that Customer</li>
                        <li><b>Revised Quantity</b> - check if it's below the "MOQ" value</li>
                        <li><b>Revised Unit Price</b> - check if it's correct for that "Part ID"</li>
                    </ul>

                </styled.FaqSection>

                <styled.FaqSection>
                    <SectionTitle text={'Description of functioning'} />

                    <Document file={customerPortalExplanation}>
                        <Page pageNumber={1} />
                    </Document>
                </styled.FaqSection>

            </PageWrapper>
        );
    };

    return render();
};
