import React, { useState } from 'react';

import { Input } from '../../../shared/components/input/input';

interface Props {
    initPartDescription: string;
    onInputChange: (key: string, value: string) => void;
}

export const PartDescriptionFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initPartDescription } = props;

    const [partDesc, setPartDesc] = useState(initPartDescription || '');

    const render = () => {
        return (
            <Input value={partDesc}
                onChange={desc => onChange(desc, 'partDescription')}
                label={'Part Description'} />
        );
    };

    const onChange = (description: string, key: string) => {
        setPartDesc(description);

        onInputChange(key, description);
    };

    return render();
};
