import { IOrderLine, ISaveOrderLineReq } from "../interfaces/order-line";

export const GET_ORDER_LINES_REQ = 'GET_ORDER_LINES_REQ';;
export const getOrderLinesReq = (headerId: string) => ({
    type: GET_ORDER_LINES_REQ,
    payload: headerId,
});

export const GET_ORDER_LINES_OK = 'GET_ORDER_LINES_OK';
export const getOrderLinesOk = (orderLines: IOrderLine[]) => ({
    type: GET_ORDER_LINES_OK,
    payload: orderLines,
});

export const GET_ORDER_LINES_FAIL = 'GET_ORDER_LINES_FAIL';
export const getOrderLinesFail = (error: string) => ({
    type: GET_ORDER_LINES_FAIL,
    payload: error,
});

export const GET_BREADCRUMB_ORDER_LINE_REQ = 'GET_BREADCRUMB_ORDER_LINE_REQ';
export const getBreadcrumbOrderLineReq = (id: string) => ({
    type: GET_BREADCRUMB_ORDER_LINE_REQ,
    payload: id,
});

export const GET_BREADCRUMB_ORDER_LINE_OK = 'GET_BREADCRUMB_ORDER_LINE_OK';
export const getBreadcrumbOrderLineOk = (orderLine: IOrderLine) => ({
    type: GET_BREADCRUMB_ORDER_LINE_OK,
    payload: orderLine,
});

export const GET_BREADCRUMB_ORDER_LINE_FAIL = 'GET_BREADCRUMB_ORDER_LINE_FAIL';
export const getBreadcrumbOrderLineFail = (error: string) => ({
    type: GET_BREADCRUMB_ORDER_LINE_FAIL,
    payload: error,
});

export const SET_BREADCRUMB_ORDER_LINE = 'SET_BREADCRUMB_ORDER_LINE';
export const setBreadcrumbOrderLine = (orderLine: IOrderLine) => ({
    type: SET_BREADCRUMB_ORDER_LINE,
    payload: orderLine,
});

export const TOGGLE_ORDER_LINES_LOADING = 'TOGGLE_ORDER_LINES_LOADING';
export const toggleOrderLinesLoading = (flag: boolean) => ({
    type: TOGGLE_ORDER_LINES_LOADING,
    payload: flag,
});

export const DELETE_ORDER_LINE_REQ = 'DELETE_ORDER_LINE_REQ';
export const deleteOrderLineReq = (orderLine: IOrderLine) => ({
    type: DELETE_ORDER_LINE_REQ,
    payload: orderLine,
});

export const DELETE_ORDER_LINE_OK = 'DELETE_ORDER_LINE_OK';
export const deleteOrderLineOk = (orderLine: IOrderLine) => ({
    type: DELETE_ORDER_LINE_OK,
    payload: orderLine,
});

export const DELETE_ORDER_LINE_FAIL = 'DELETE_ORDER_LINE_FAIL';
export const deleteOrderLineFail = (error: string) => ({
    type: DELETE_ORDER_LINE_FAIL,
    payload: error,
});

export const TOGGLE_DELETE_ORDER_LINE_MODAL = 'TOGGLE_DELETE_ORDER_LINE_MODAL';
export const toggleDeleteOrderLineModal = (flag: boolean) => ({
    type: TOGGLE_DELETE_ORDER_LINE_MODAL,
    payload: flag,
});

export const TOGGLE_SAVE_ORDER_LINE_MODAL = 'TOGGLE_SAVE_ORDER_LINE_MODAL';
export const toggleSaveOrderLineModal = (flag: boolean) => ({
    type: TOGGLE_SAVE_ORDER_LINE_MODAL,
    payload: flag,
});

export const SET_ORDER_LINE = 'SET_ORDER_LINE';
export const setOrderLine = (orderLine: IOrderLine) => ({
    type: SET_ORDER_LINE,
    payload: orderLine,
});


export const RESET_ORDER_LINE = 'RESET_ORDER_LINE';
export const resetOrderLine = () => ({
    type: RESET_ORDER_LINE,
    payload: null,
});

export const SAVE_ORDER_LINE_REQ = 'SAVE_ORDER_LINE_REQ';
export const saveOrderLineReq = (orderLine: ISaveOrderLineReq) => ({
    type: SAVE_ORDER_LINE_REQ,
    payload: orderLine,
});


export const SAVE_ORDER_LINE_OK = 'SAVE_ORDER_LINE_OK';
export const saveOrderLineOk = (orderLine: IOrderLine) => ({
    type: SAVE_ORDER_LINE_OK,
    payload: orderLine
});

export const SAVE_ORDER_LINE_FAIL = 'SAVE_ORDER_LINE_FAIL';
export const saveOrderLineFail = (error: string) => ({
    type: SAVE_ORDER_LINE_FAIL,
    payload: error
});

export const TOGGLE_SAVE_ORDER_LINE_LOADING = 'TOGGLE_SAVE_ORDER_LINE_LOADING';
export const toggleSaveOrderLineLoading = (flag: boolean) => ({
    type: TOGGLE_SAVE_ORDER_LINE_LOADING,
    payload: flag
});


export const EDIT_ORDER_LINE_REQ = 'EDIT_ORDER_LINE_REQ';
export const editOrderLineReq = (orderLine: IOrderLine) => ({
    type: EDIT_ORDER_LINE_REQ,
    payload: orderLine,
});

export const EDIT_ORDER_LINE_OK = 'EDIT_ORDER_LINE_OK';
export const editOrderLineOk = (orderLine: IOrderLine) => ({
    type: EDIT_ORDER_LINE_OK,
    payload: orderLine,
});

export const EDIT_ORDER_LINE_FAIL = 'EDIT_ORDER_LINE_FAIL';
export const editOrderLineFail = (error: string) => ({
    type: EDIT_ORDER_LINE_FAIL,
    payload: error,
});


export const TOGGLE_EDIT_ORDER_LINE_LOADING = 'TOGGLE_EDIT_ORDER_LINE_LOADING';
export const toggleEditOrderLineLoading = (flag: boolean) => ({
    type: TOGGLE_EDIT_ORDER_LINE_LOADING,
    payload: flag
});

export const GET_PART_IDS_REQ = 'GET_PART_IDS_REQ';
export const getPartIdsReq = (externalCustomerId: string) => ({
    type: GET_PART_IDS_REQ,
    payload: externalCustomerId,
});

export const GET_PART_IDS_OK = 'GET_PART_IDS_OK';
export const getPartIdsOk = (partIds: string[]) => ({
    type: GET_PART_IDS_OK,
    payload: partIds,
});

export const GET_PART_IDS_FAIL = 'GET_PART_IDS_FAIL';
export const getPartIdsFail = (error: string) => ({
    type: GET_PART_IDS_FAIL,
    payload: error,
});

export const GET_PART_DESCRIPTIONS_REQ = 'GET_PART_DESCRIPTIONS_REQ';
export const getPartDescriptionsReq = (externalCustomerId: string) => ({
    type: GET_PART_DESCRIPTIONS_REQ,
    payload: externalCustomerId,
});

export const GET_PART_DESCRIPTIONS_OK = 'GET_PART_DESCRIPTIONS_OK';
export const getPartDescriptionsOk = (partDescriptions: string[]) => ({
    type: GET_PART_DESCRIPTIONS_OK,
    payload: partDescriptions,
});

export const GET_PART_DESCRIPTIONS_FAIL = 'GETPART_DESCRIPTIONS_FAIL';
export const getPartDescriptionsFail = (error: string) => ({
    type: GET_PART_DESCRIPTIONS_FAIL,
    payload: error,
});

export const TOGGLE_ARCHIVE_ORDER_MODAL = 'TOGGLE_ARCHIVE_ORDER_MODAL';
export const toggleArchiveOrderModal = (flag: boolean) => ({
    type: TOGGLE_ARCHIVE_ORDER_MODAL,
    payload: flag,
});


export const TOGGLE_VIEW_MODE = 'TOGGLE_VIEW_MODE';
export const toggleViewMode = (flag: boolean) => ({
    type: TOGGLE_VIEW_MODE,
    payload: flag,
});
