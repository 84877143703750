import { IHeaderTile } from "../header-tiles/header-tile.interface";

export enum UserNameEmailTypes {
    GECKO = 'geckoal',
};

export const HEADER_TILES: IHeaderTile[] = [
    {
        label: "DASHBOARD",
        path: "/",
        exact: true,
        urlPath: '',
    },
    {
        label: "ORDERS",
        path: "/orders/orders",
        urlPath: 'orders',
    },
    {
        label: 'NOTIFICATIONS',
        path: "/notifications/preferences",
        urlPath: 'notifications',
    },
    {
        label: "AUTOMATED ORDER ENTRY",
        path: "/automated-order-entry/purchaseorder-list",
        urlPath: 'automated-order-entry',
    },
    {
        label: 'ADMIN',
        path: '/admin/soa',
        urlPath: 'admin',
    },
];

export const USER_HEADER_TILES: IHeaderTile[] = [
    {
        label: "DASHBOARD",
        path: "/",
        exact: true,
        urlPath: '',
    },
    {
        label: "ORDERS",
        path: "/orders/orders",
        urlPath: 'orders',
    },
    {
        label: 'NOTIFICATIONS',
        path: "/notifications/preferences",
        urlPath: 'notifications',
    },
];

export const INACTIVE_USER_HEADER_TILES: IHeaderTile[] = [
    {
        label: "DASHBOARD",
        path: "/",
        exact: true,
        urlPath: '',
    }
];
