import { Action } from "../../shared/interfaces/shared";
import { INotificationsState } from "../interfaces/notifications-state";
import { NotificationsActions } from "./notifications.actions";
import { notificationsInitState } from "./notifications.init-state";

export function notificationsReducer(state: INotificationsState = notificationsInitState, action: Action<any>): INotificationsState {
    switch (action.type) {
        case NotificationsActions.GET_NOTIFICATION_EVENTS_OK: {
            return {
                ...state,
                events: action.payload,
            };
        }

        case NotificationsActions.GET_NOTIFICATION_CHANNELS_OK: {
            return {
                ...state,
                channels: action.payload,
            };
        }

        case NotificationsActions.GET_PREFERENCES_BY_CUSTOMER_ID_OK: {
            return {
                ...state,
                currentPreference: action.payload,
            };
        }

        case NotificationsActions.SET_CURRENT_PREFERENCE: {
            return {
                ...state,
                currentPreference: action.payload,
            };
        }

        case NotificationsActions.TOGGLE_CURRENT_PREFERENCE_LOADING: {
            return {
                ...state,
                currentPreferenceLoading: action.payload,
            };
        }

        default:
            return state;
    }
}
