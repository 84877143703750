import * as styles from './orders-confirmation.page.style';

import { Collapse, Spin } from 'antd';
import { IAllocationInfo, IOrderConfirmation, OrderConfirmationChoice } from '../interfaces/order-confirmation';
import React, { useEffect } from 'react';
import { faArrowsAltH, faCheck } from '@fortawesome/free-solid-svg-icons';

import { AllocationInfoTable } from '../components/allocation-info-table/allocation-info-table';
import { AppState } from '../../shared/interfaces/app.state';
import { Button } from '../../shared/components/button/button';
import { ButtonTypes } from '../../shared/components/button/button.utils';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header } from '../../shared/components/header/header';
import { IHeaderTile } from '../../shared/components/header-tiles/header-tile.interface';
import { OrdersConfirmationStateService } from '../services/orders-confirmation-state.service';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { colors } from '../../shared/styles/colors';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

interface Props { }

export const OrdersConfirmationPage: React.FC<Props> = (props: Props) => {
    // store
    const order = useSelector<AppState, IOrderConfirmation>(state => state.ordersConfirmation.order);
    const isLoading = useSelector<AppState, boolean>(state => state.ordersConfirmation.isLoading);
    const token = useSelector<AppState, string>(state => state.users.currentUser.token);
    const getError = useSelector<AppState, string>(state => state.ordersConfirmation.error);

    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const render = () => {
        if (isLoading) {
            return (
                <styles.Wrapper>
                    <Spin />
                </styles.Wrapper>
            );
        }

        return (
            <styles.Wrapper>
                {
                    !token &&
                    <Header
                        tiles={HeaderTiles(queryParams as any)}
                        activeTileIndex={HeaderTiles.length} />
                }

                <styles.Content>
                    {
                        (isEmpty(order) && !getError) &&
                        <SectionTitle text={`No data found for this client to confirm`} />
                    }

                    {
                        (getError && isEmpty(order)) &&
                        <SectionTitle text={getError} />
                    }

                    {
                        !isEmpty(order) &&
                        <>
                            <SectionTitle text={`${order.customerName}`} />

                            <Collapse accordion={true}>
                                {
                                    !!order.allocationInfo.length &&
                                    order.allocationInfo.map(allocation =>
                                        <CollapsePanel
                                            header={renderCollapsePanelHeader(allocation)}
                                            key={allocation.id}>

                                            <AllocationInfoTable allocationInfo={allocation} />

                                        </CollapsePanel>
                                    )
                                }
                            </Collapse>

                            <styles.ButtonsWrapper>
                                <Button type={ButtonTypes.PRIMARY}
                                    label={'CONFIRM ALLOCATION'}
                                    onClick={() => onActionClick(OrderConfirmationChoice.CONFIRMED)}
                                    icon={<FontAwesomeIcon color={colors.$white} icon={faCheck} />} />

                                <div>
                                    <Button type={ButtonTypes.PRIMARY}
                                        label={'DECREASE ALLOCATION'}
                                        onClick={() => onActionClick(OrderConfirmationChoice.DECREASE)}
                                        icon={<FontAwesomeIcon color={colors.$alert} icon={faArrowsAltH} />} />

                                    <styles.Description>
                                        If you need to decrease your allocation,
                                        your dedicated Sales Operations Agent
                                        will contact you shortly
                                    </styles.Description>
                                </div>
                            </styles.ButtonsWrapper>
                        </>
                    }
                </styles.Content>
            </styles.Wrapper>
        );
    };

    useEffect(() => {
        OrdersConfirmationStateService.toggleDisplayOrderConfirmationPage(true);

        if (!isEmpty(queryParams) && queryParams.id) {
            OrdersConfirmationStateService.getOrderConfirmation(queryParams.id as unknown as string);
        }

        return () => {
            OrdersConfirmationStateService.toggleDisplayOrderConfirmationPage(null);
        };
    }, []);

    const onActionClick = (status: OrderConfirmationChoice) => {
        let id = !isEmpty(queryParams) ? queryParams.id as unknown as string : '';

        if (id) {
            OrdersConfirmationStateService.setOrderConfirmation({ id, choice: status });
        }
    };

    const renderCollapsePanelHeader = (allocation: IAllocationInfo) => {
        let averageText = '';

        if (!allocation.allocationAverage) {
            averageText = 'No allocation for the next 4 weeks';
        } else {
            averageText = `AVG ${allocation.allocationAverage} per week`;
        }

        return (
            <div>
                <strong>{allocation.categoryName}</strong>
                <span> - {averageText}</span>
            </div>
        );
    };

    return render();
};

const HeaderTiles = (queryParams: any): IHeaderTile[] => {
    let id = '';

    if (!isEmpty(queryParams) && queryParams.id) {
        id = queryParams.id;
    }

    return [{
        path: '/',
        disabled: true,
        label: 'DASHBOARD',
        urlPath: '',
    }, {
        label: 'ORDERS',
        path: '/orders/orders',
        disabled: true,
        urlPath: 'orders',
    }, {
        label: 'NOTIFICATIONS',
        path: '/notifications',
        urlPath: 'notifications',
        disabled: true,
    }, {
        label: 'ORDERS CONFIRMATION',
        path: `/orders-confirmation/customer?id=${id}`,
        urlPath: 'orders-confirmation',
        disabled: false,
    }]
};
