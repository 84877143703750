import { Action } from "../../shared/interfaces/shared";
import { IPagination } from "../../shared/interfaces/pagination";
import { IUserRole } from "../interfaces/roles";
import { IUsersState } from "../interfaces/users-state";
import { UsersActions } from "./users.actions";
import { usersInitState } from "./users.init-state";

export function usersReducer(state: IUsersState = usersInitState, action: Action<any>): IUsersState {
    switch (action.type) {
        case UsersActions.SET_CURRENT_USER: {
            return {
                ...state,
                currentUser: action.payload,
            };
        }

        case UsersActions.SET_TOKEN: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    token: action.payload,
                },
            };
        }

        case UsersActions.GET_ROLES_OK: {
            return {
                ...state,
                roles: action.payload,
            };
        }

        case UsersActions.GET_USER_PERMISSION_REQ: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case UsersActions.GET_USER_PERMISSION_OK: {
            return {
                ...state,
                userRoles: action.payload,
                isLoading: false,
            };
        }

        case UsersActions.GET_USER_PERMISSION_FAIL: {
            return {
                ...state,
                userRoles: {} as IPagination<IUserRole>,
                isLoading: false,
            };
        }

        case UsersActions.SET_CURRENT_USER_ROLE: {
            return {
                ...state,
                currentUserRole: action.payload,
            };
        }

        case UsersActions.TOGGLE_CHANGE_USER_ROLE_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    changeRole: action.payload,
                },
            };
        }

        case UsersActions.GET_USER_ROLE_OK: {
            return {
                ...state,
                tokenUserRole: action.payload,
            };
        }

        case UsersActions.GET_USER_ROLE_FAIL: {
            return {
                ...state,
                tokenUserRole: null,
            };
        }

        case UsersActions.GET_USER_ROLE_BY_ID_OK: {
            return {
                ...state,
                currentUserRole: action.payload,
            };
        }

        case UsersActions.GET_ADMIN_SETTINGS_OK: {
            return {
                ...state,
                settings: action.payload,
            };
        }

        default:
            return state;
    }
}
