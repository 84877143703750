import React, { useEffect } from 'react';

import { AppState } from '../../interfaces/app.state';
import { SharedStateService } from '../../services/shared.state-service';
import { Snackbar } from '../snackbar/snackbar';
import { SnackbarTypes } from '../snackbar/snackback.utils';
import { useSelector } from 'react-redux';

interface Props {}

export const BackendError: React.FC<Props> = (_props: Props) => {
    const backendNotWorking = useSelector<AppState, boolean>(state => state.shared.backendNotWorking);

    const render = () => {
        if (backendNotWorking) {
            return (
                <Snackbar type={SnackbarTypes.ERROR} message={'Something went wrong. Please try again later'} />
            );
        }

        return <></>;
    };

    useEffect(() => {
        return () => {
            SharedStateService.setBackendNotWorking(false);
        };
    }, []);

    return render();
};
