import { FilterOptions } from "../../shared/interfaces/filters";
import { GeckoTableService } from "../../shared/components/gecko-table/gecko-table-service";
import { IHeaderTile } from "../../shared/components/header-tiles/header-tile.interface";
import { SortingOrder } from "../../shared/interfaces/sorting";

const orderListQuerySearch = GeckoTableService.getUrlParamsFromTableRequestByTableId({
    pageSize: 20,
    pageNumber: 1,
    sortOrder: SortingOrder.Desc,
    sortColumn: 'OrderDate',
    filters: [{
        key: 'status',
        option: FilterOptions.IsNotEqualTo,
        value: '6',
    }],
}, 'ordersAoe');

export const SUBHEADER_TILES_AOE: IHeaderTile[] = [{
    path: `/automated-order-entry/purchaseorder-list?${orderListQuerySearch}`,
    label: "Purchase Order List",
}, {
    path: '/automated-order-entry/faq',
    label: 'Frequently Asked Questions',
}
//  {
//     path: "/automated-order-entry/customer-config",
//     label: "Client Configuration"
// },
// {
//     path: '/automated-order-entry/soa',
//     label: 'SOA',
// }, {
//     path: '/automated-order-entry/companies',
//     label: 'Companies',
// },
    //  {
    //     path: '/automated-order-entry/customers',
    //     label: 'Customers',
    // }
];
