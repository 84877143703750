import * as React from "react";
import * as div from "./header.style";

import { HEADER_TILES, INACTIVE_USER_HEADER_TILES, USER_HEADER_TILES } from "./header.constants";

import { AppState } from "../../interfaces/app.state";
import { AuthService } from "../../../auth/services/auth.service";
import { Config } from "../../../config";
import { HeaderTiles } from "../header-tiles/header-tiles";
import { IHeaderTile } from "../header-tiles/header-tile.interface";
import { ITokenUserRole } from "../../../users/interfaces/token-user-role";
import { ROLES } from "../../../users/interfaces/roles";
import { UserHeaderActions } from "../../../users/components/user-header-actions/user-header-actions";
import { config } from "../../../auth/components/auth-provider/config";
import { getAccountName } from "../../services/msal";
import { isEmpty } from "lodash";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

interface Props {
    tiles?: IHeaderTile[];
    activeTileIndex?: number;
}

const Logo = (userName: string | undefined, isGeckoLogo?: boolean) => {
    const favicon: any = document.getElementById('favicon');

    if (!userName) {
        return;
    }

    if (isGeckoLogo) {
        favicon.href = Config.gecko.logo;

        return (
            <div.Logo src={Config.gecko.logo} alt="logo" />
        );
    }

    favicon.href = Config.activCarts.logo;

    return (
        <div.Logo width={'70px'} height={'50px'} src={Config.activCarts.logo} alt="logo" />
    );
}

export const Header: React.FunctionComponent<Props> = (props: Props) => {
    const { activeTileIndex, tiles } = props;

    const currentTokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const getInitTiles = () => {
        if (tiles) {
            return tiles;
        }

        if (!isEmpty(currentTokenUserRole)) {
            if (currentTokenUserRole!.role === ROLES.ClientFinance || currentTokenUserRole!.role === ROLES.Client || !currentTokenUserRole!.role) {
                if (currentTokenUserRole!.status === 0) {
                    return USER_HEADER_TILES;
                } else {
                    return INACTIVE_USER_HEADER_TILES;
                }
            }
        }

        return HEADER_TILES;
    };

    const location = useLocation();
    const account = getAccountName();
    const isAC = config.isAC;

    // state
    const [currentTiles, setCurrentTiles] = React.useState(getInitTiles());
    const [activeTile, setActiveTile] = React.useState(currentTiles[activeTileIndex ? activeTileIndex : 0]);
    const [renderTiles, setRenderTiles] = React.useState(true);

    const render = () => {
        return (
            <div.Wrapper>
                {/** Logo */}
                {
                    (account && (account.username || account.name)) &&
                    Logo(account.username || account.name, !isAC)
                }

                {/** Tile List */}
                {
                    <HeaderTiles tiles={currentTiles} activeTile={activeTile} />
                }

                {/** Actions */}
                <UserHeaderActions />
            </div.Wrapper>
        );
    };

    React.useEffect(() => {
        let modulePath = location.pathname.split('/')[1] || '';

        let currentTile = currentTiles.filter(ht => ht.urlPath === modulePath)[0];

        if (currentTile) {
            setActiveTile(currentTile);
        }

    }, [location.pathname, tiles]);

    React.useEffect(() => {
        async function check() {
            const isAuth = await AuthService.isAuth();
            // const isAccountActive = UsersService.isExternalAccountActive(currentTokenUserRole);

            // if (isAuth && !isAccountActive) {
            //     setRenderTiles(false);
            // }

            if (isAuth) {
                setRenderTiles(true);
            }

            setRenderTiles(false);
        }

        check();
    }, [])

    return render();
};
