import { colors } from '../../../shared/styles/colors';
import styled from 'styled-components';

interface WrapperProps {
    isSelected: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    border: 2px solid ${props => props.isSelected ? colors.$success : colors.$darkBlue};
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    cursor: pointer;
`;

export const Row = styled.div`
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
`;

export const Description = styled.div`
    font-weight: 300;
    word-wrap: break-word;
`;
