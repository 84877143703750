import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { CustomersStateService } from '../../../customers/services/customers.state-service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IFilterObjectData } from '../../../order-list/interfaces/order-list';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const CustomerFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const customers = useSelector<AppState, IFilterObjectData[]>(state => state.filters.customerIds);

    const getInitialCustomer = (currCustomer: string) => {
        if (!currCustomer) {
            return null;
        }

        let currentCustomer = null;

        for (let customer of customers) {
            if (customer.id.toString() === currCustomer) {
                currentCustomer = customer;
                break;
            }
        }

        return currentCustomer;
    };

    // State
    const [customer, setCustomer] = useState(getInitialCustomer(initValue));

    const render = () => {
        return (
            <Dropdown label={'Customer ID'}
                keyToShow={'value'}
                allowClear={true}
                options={customers}
                onChange={customers => onChange(customers)}
                value={customer} />
        );
    };

    useEffect(() => {
        CustomersStateService.getFilterExternalCustomerIds();
    }, []);

    useEffect(() => {
        if (!initValue) {
            setCustomer({} as IFilterObjectData);
        }
    }, [initValue]);

    const onChange = (customer: IFilterObjectData) => {
        setCustomer(customer);
        onInputChange('customerId', (customer.id && customer.id.toString()) || '');
    };

    return render();
};
