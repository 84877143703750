import { colors } from "../../styles/colors";
import { fontSizes } from "../../styles/font-sizes";
import { fonts } from "../../styles/fonts";
import styled from "styled-components";

export const Wrapper = styled.div`
    margin-bottom: 1em;
`;

export const Text = styled.div`
    font-family: ${fonts.proxima};
    font-size: ${fontSizes.sectionTitle};
    font-weight: bold;
    line-height: 1.21;
    color: ${colors.$darkBlue};
`;
