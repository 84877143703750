import { AppState } from "../interfaces/app.state";
import { aoeInitState } from "../../aoe/state/aoe.init-state";
import { companiesInitState } from "../../companies/state/companies.init-state";
import { customersInitState } from "../../customers/state/customers.init-state";
import { filtersInitState } from "../../filters/state/filters.init-state";
import { notificationsInitState } from "../../notifications/state/notifications.init-state";
import { orderLineInitState } from "../../order-line/state/order-line.init-state";
import { orderListInitState } from '../../order-list/state/order-list.init-state'
import { ordersConfirmationInitState } from "../../orders-confirmation/state/orders-confirmation.init-state";
import { ordersInitState } from "../../orders/state/orders.init-state";
import { sharedInitState } from "./shared.init-state";
import { soaInitState } from "../../soa/state/soa.init-state";
import { usersInitState } from "../../users/state/users.init-state";

export const appInitState: AppState = {
    aoe: aoeInitState,
    companies: companiesInitState,
    soa: soaInitState,
    filters: filtersInitState,
    customers: customersInitState,
    notifications: notificationsInitState,
    orderList: orderListInitState,
    orderLine: orderLineInitState,
    orders: ordersInitState,
    ordersConfirmation: ordersConfirmationInitState,
    shared: sharedInitState,
    users: usersInitState,
};
