import * as styles from './order-details.page.style';

import { ICompleteOrder, IErpOrderInvoice, IErpOrderLine } from '../interfaces/order';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { Button } from '../../shared/components/button/button';
import { ButtonTypes } from '../../shared/components/button/button.utils';
import { EditErpOrderHeaderModal } from '../components/edit-erp-order-header-modal/edit-erp-order-header-modal';
import { EditErpOrderLineModal } from '../components/edit-erp-order-line-modal/edit-erp-order-line-modal';
import { ErpOrderInvoiceTable } from '../components/erp-order-invoice-table/erp-order-invoice-table';
import { ErpOrderLineTable } from '../components/erp-order-line-table/erp-order-line-table';
import { ErpShippingInfoTable } from '../components/erp-shipping-info-table/erp-shipping-info-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ITokenUserRole } from '../../users/interfaces/token-user-role';
import { OrderAdditionalInfo } from '../components/additional-info/additional-info';
import { OrderMainInfo } from '../components/main-info/main-info';
import { OrdersStateService } from '../services/orders-state.service';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { UsersService } from '../../users/services/users.service';
import { UtilityButton } from '../../shared/components/utility-button/utility-button';
import { colors } from '../../shared/styles/colors';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps { }

export const OrderDetailsPage: React.FC<Props> = (props: Props) => {
    const order = useSelector<AppState, ICompleteOrder>(state => state.orders.currentCompleteOrder);
    const isLoading = useSelector<AppState, boolean>(state => state.orders.loading);
    const invoices = useSelector<AppState, IErpOrderInvoice[]>(state => state.orders.invoices);
    const editErpOrderLineModal = useSelector<AppState, boolean>(state => state.orders.modals.editErpOrderLine);
    const currentErpOrderLine = useSelector<AppState, IErpOrderLine>(state => state.orders.currentErpOrderLine);
    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);
    const editOrderHeaderModal = useSelector<AppState, boolean>(state => state.orders.modals.editOrderHeader);

    const history = useHistory();
    const { id } = useParams();

    const render = () => {
        if (isLoading || isEmpty(order)) {
            return <ActivityIndicator />;
        }

        return (
            <PageWrapper>
                <UtilityButton onClick={() => history.goBack()}
                    iconOnLeft={true}
                    icon={<FontAwesomeIcon icon={faArrowLeft} color={colors.$darkBlue} size='lg' />}
                    label='Back' />

                <styles.Header>
                    <PageTitle text={`Order number ${order.orderId}`} />

                    <Button type={ButtonTypes.PRIMARY}
                        label={'Download Packing Slip'}
                        onClick={() => { }}
                        disabled={true} />
                </styles.Header>

                <styles.CustomerName>
                    {order.customerId}
                </styles.CustomerName>

                {/** Order informations */}
                <styles.Section>
                    {
                        UsersService.authorizeDisplay([ROLES.Admin, ROLES.SOA]) &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Update header'}
                            onClick={() => onEditHeaderClick()} />
                    }

                    <styles.Info>
                        <OrderMainInfo order={order} />
                        {/* <OrderDetailsInfo values={getMainInformations(order)} title={'Main Information'} width={850} /> */}

                        <OrderAdditionalInfo order={order} />
                        {/* <OrderDetailsInfo values={getAdditionalInformations(order)} title={'Additional Information'} /> */}
                    </styles.Info>
                </styles.Section>

                {/** Order Line List */}
                <styles.Section>
                    <SectionTitle text={'Order line list'} />

                    <ErpOrderLineTable orderLines={order.erpOrderLines} />
                </styles.Section>

                {/** Shipping informations */}
                <styles.Section>
                    <SectionTitle text={'Shipping information'} />

                    <ErpShippingInfoTable erpShippers={order.erpShippers}
                        showExport={true}
                        showExpandedView={true}
                        showFilters={false} />
                </styles.Section>

                {/** Invoice informations */}
                {
                    (tokenUserRole && tokenUserRole.role !== ROLES.Client) &&
                    <styles.Section>
                        <SectionTitle text={'Invoice information'} />

                        <ErpOrderInvoiceTable showFilters={false}
                            showExport={true}
                            invoices={invoices} />
                    </styles.Section>
                }

                {
                    editErpOrderLineModal && <EditErpOrderLineModal line={currentErpOrderLine}
                        customerId={order.customerId}
                        orderId={order.orderId}
                        handleSubmit={OrdersStateService.editErpOrderLine} />
                }

                {
                    editOrderHeaderModal &&
                    <EditErpOrderHeaderModal order={order} />
                }
            </PageWrapper>
        );
    };

    useEffect(() => {
        AuthService.checkAuth(history);

        return () => {
            OrdersStateService.resetCurrentCompleteOrder();
        };
    }, []);

    useEffect(() => {
        OrdersStateService.getOrderDetails(id);

        if (tokenUserRole && tokenUserRole.role !== ROLES.Client) {
            OrdersStateService.getErpOrderInvoices(id);
        }
    }, [id]);

    const onEditHeaderClick = () => {
        OrdersStateService.toggleEditErpOrderHeaderModal(true);
    };

    return render();
};
