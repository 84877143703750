import { IFiltersState } from "../interfaces/filters-state";

export const filtersInitState: IFiltersState = {
    soas: [],
    companies: [],
    erpCompanies: [],
    customerIds: [],
    erpCustomerIds: [],
    poNumbers: [],
    erpPoNumbers: [],
    erpOrderNumbers: [],

    orders: {
        company: '',
    },
};
