import { IOrderLine } from "../interfaces/order-line";
import { IOrderLineState } from "../interfaces/order-line-state";

export const orderLineInitState: IOrderLineState = {
    orderLines: [],
    orderLine: {} as IOrderLine,
    orderLineLoading: false,

    saveOrderLine: {
        loading: false,
        response: {} as IOrderLine,
        error: null,
    },


    editOrderLine: {
        loading: false,
        response: {} as IOrderLine,
        error: null,
    },


    deleteOrderLine: {
        response: {} as IOrderLine,
        error: null,
    },

    modals: {
        delete: false,
        save: false,
        archiveOrder: false,
    },

    partIds: [],
    partDescriptions: [],
    viewMode: false,
};
