import { INotificationPreference, INotificationPreferenceReq } from "../interfaces/notification-preference";

import { INotificationChannel } from "../interfaces/notification-channel";
import { INotificationEvent } from "../interfaces/notification-event";

export class NotificationsActions {
    public static readonly GET_NOTIFICATION_CHANNELS_REQ = 'GET_NOTIFICATION_CHANNLES_REQ';
    public static getNotificationsChannelsReq() {
        return {
            type: this.GET_NOTIFICATION_CHANNELS_REQ,
            payload: null,
        };
    }

    public static readonly GET_NOTIFICATION_CHANNELS_OK = 'GET_NOTIFICATION_CHANNELS_OK';
    public static getNotificationChannelsOk(channels: INotificationChannel[]) {
        return {
            type: this.GET_NOTIFICATION_CHANNELS_OK,
            payload: channels,
        };
    }

    public static readonly GET_NOTIFICATION_CHANNELS_FAIL = 'GET_NOTIFICATIONS_CHANNELS_FAIL';
    public static getNotificationChannelsFail(error: string) {
        return {
            type: this.GET_NOTIFICATION_CHANNELS_FAIL,
            payload: error,
        };
    }

    public static readonly GET_NOTIFICATION_EVENTS_REQ = 'GET_NOTIFICATION_EVENTS_REQ';
    public static getNotificationEventsReq() {
        return {
            type: this.GET_NOTIFICATION_EVENTS_REQ,
            payload: null,
        };
    }

    public static readonly GET_NOTIFICATION_EVENTS_OK = 'GET_NOTIFICATION_EVENTS_OK';
    public static getNotificationEventsOk(events: INotificationEvent[]) {
        return {
            type: this.GET_NOTIFICATION_EVENTS_OK,
            payload: events,
        };
    }

    public static readonly GET_NOTIFICATION_EVENTS_FAIL = 'GET_NOTIFICATION_EVENTS_FAIL';
    public static getNotificationEventsFail(error: string) {
        return {
            type: this.GET_NOTIFICATION_EVENTS_FAIL,
            payload: error,
        };
    }

    public static readonly GET_PREFERENCES_BY_CUSTOMER_ID_REQ = 'GET_PREFERENCES_BY_CUSTOMER_ID_REQ';
    public static getPreferencesByCustomerIdReq(id: string) {
        return {
            type: this.GET_PREFERENCES_BY_CUSTOMER_ID_REQ,
            payload: id,
        };
    }

    public static readonly SET_CURRENT_PREFERENCE = 'SET_CURRENT_PREFERENCE';
    public static setCurrentPreference(preference: INotificationPreference) {
        return {
            type: this.SET_CURRENT_PREFERENCE,
            payload: preference,
        };
    }

    public static readonly GET_PREFERENCES_BY_CUSTOMER_ID_OK = 'GET_PREFERENCES_BY_CUSTOMER_ID_OK';
    public static getPreferencesByCustomerIdOk(preference: INotificationPreference) {
        return {
            type: this.GET_PREFERENCES_BY_CUSTOMER_ID_OK,
            payload: preference,
        };
    }

    public static readonly GET_PREFERENCES_BY_CUSTOMER_ID_FAIL = 'GET_PREFERENCES_BY_CUSTOMER_ID_FAIL';
    public static getPreferencesByCustomerIdFail(error: string) {
        return {
            type: this.GET_PREFERENCES_BY_CUSTOMER_ID_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_CURRENT_PREFERENCE_LOADING = 'TOGGLE_CURRENT_PREFERENCE_LOADING';
    public static toggleCurrentPreferenceLoading(flag: boolean) {
        return {
            type: this.TOGGLE_CURRENT_PREFERENCE_LOADING,
            payload: flag,
        };
    }

    public static readonly SAVE_NOTIFICATION_PREFERENCE_REQ = 'SAVE_NOTIFICATION_PREFERENCE_REQ';
    public static saveNotificationPreferenceReq(req: INotificationPreferenceReq) {
        return {
            type: this.SAVE_NOTIFICATION_PREFERENCE_REQ,
            payload: req,
        };
    }

    public static readonly SAVE_NOTIFICATION_PREFERENCE_OK = 'SAVE_NOTIFICATION_PREFERENCE_OK';
    public static saveNotificationPreferenceOk(res: string) {
        return {
            type: this.SAVE_NOTIFICATION_PREFERENCE_OK,
            payload: res,
        };
    }

    public static readonly SAVE_NOTIFICATION_PREFERENCE_FAIL = 'SAVE_NOTIFICAITON_PREFERENCE_FAIL';
    public static saveNotificationPreferenceFail(error: string) {
        return {
            type: this.SAVE_NOTIFICATION_PREFERENCE_FAIL,
            payload: error,
        };
    }

}
