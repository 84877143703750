export const fonts = {
    brandonGrotesque: `"brandon-grotesque", sans-serif`,
    proxima: '"proxima-nova", sans-serif',
    arial: 'Arial',
};

export const fontSizes = {
    pageTitle: '40px',
    sectionTitle: '24px',
    snackbarMessage: '14px',
    deleteConfirmationTitle: '20px',

    tableHeader: '14px',
    tableRow: '14px',
};
