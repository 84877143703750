import { fontSizes, fonts } from '../../styles/fonts';

import { SnackbarTypes } from './snackback.utils';
import { boxShadows } from '../../styles/box-shadows';
import { colors } from '../../styles/colors';
import styled from 'styled-components';

interface IWrapperProps {
    type: SnackbarTypes | null;
}



export const Wrapper = styled.div<IWrapperProps>`
    position: fixed;
    bottom: 50px;
    right: 50px;
    border-radius: 4px;
    padding: 10px 24px;
    border: 1px solid ${props => getWrapperBorderColor(props)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${colors.$white};
    box-shadow: ${boxShadows.snackbar};
    z-index: 100000;
`;

export const Message = styled.div`
    font-family: ${fonts.proxima};
    font-size: ${fontSizes.snackbarMessage};
    color: ${colors.$darkBlue};
    line-height: 1.21;
    margin-left: 10px;
`;

// ===== UTILS ======

function getWrapperBorderColor(props: IWrapperProps) {
    switch (props.type) {
        case SnackbarTypes.SUCCESS:
            return colors.$success;

        case SnackbarTypes.ERROR:
            return colors.$alert;

        case SnackbarTypes.WARNING:
            return colors.$warning;

        default:
            return colors.$success;
    }
}
