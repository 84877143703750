import { Selector, createSelector } from "reselect";

import { AppState } from "../../shared/interfaces/app.state";
import { ICompany } from "../interfaces/company";
import { ICompanyState } from "../interfaces/companies-state";
import { IServerError } from "../../shared/interfaces/shared";

const COMPANIES_MODULE: Selector<AppState, ICompanyState> = state => state.companies;

export const SAVE_COMPANY_RESPONSE = createSelector<AppState, ICompanyState, ICompany>(
    COMPANIES_MODULE,
    state => state.saveCompany.response,
);

export const SAVE_COMPANY_ERROR = createSelector<AppState, ICompanyState, IServerError | null>(
    COMPANIES_MODULE,
    state => state.saveCompany.error,
);

export const COMPANIES = createSelector<AppState, ICompanyState, ICompany[]>(
    COMPANIES_MODULE,
    state => state.companies,
);

export const COMPANY = createSelector<AppState, ICompanyState, ICompany>(
    COMPANIES_MODULE,
    state => state.company,
);

export const COMPANIES_LOADING = createSelector<AppState, ICompanyState, boolean>(
    COMPANIES_MODULE,
    state => state.companiesLoading,
);

export const DELETE_COMPANY_RESPONSE = createSelector<AppState, ICompanyState, ICompany>(
    COMPANIES_MODULE,
    state => state.deleteCompany.response,
);

export const DELETE_COMPANY_ERROR = createSelector<AppState, ICompanyState, IServerError | null>(
    COMPANIES_MODULE,
    state => state.deleteCompany.error,
);
