import { catchError, concatMap, map, tap } from "rxjs/operators";

import { Action } from "../../shared/interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { INotificationPreferenceReq } from "../interfaces/notification-preference";
import { NotificationsActions } from "./notifications.actions";
import { NotificationsStateService } from "../services/notifications-state.service";
import { NotificationsWebApi } from "../webapi/notifications.webapi";
import { SnackbarTypes } from "../../shared/components/snackbar/snackback.utils";
import { _setSnackbar } from "../../shared/services/shared.state-service";
import { of } from "rxjs";

export const getNotificationChannels$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(NotificationsActions.GET_NOTIFICATION_CHANNELS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => NotificationsWebApi.getNotificationChannels().pipe(
            map(channels => NotificationsActions.getNotificationChannelsOk(channels)),
            catchError(err => of(NotificationsActions.getNotificationChannelsFail(err.response && err.response.data))),
        )),
    );

export const getNotificationEvents$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(NotificationsActions.GET_NOTIFICATION_EVENTS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => NotificationsWebApi.getNotificationEvents().pipe(
            map(events => NotificationsActions.getNotificationEventsOk(events)),
            catchError(err => of(NotificationsActions.getNotificationEventsFail(err.response && err.response.data))),
        )),
    );

export const getNotificationPreferenceByCustomerId$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(NotificationsActions.GET_PREFERENCES_BY_CUSTOMER_ID_REQ).pipe(
        map(action => action.payload!),
        tap(() => NotificationsStateService.toggleCurrentPreferenceLoading(true)),
        concatMap(id => NotificationsWebApi.getPreferencesByCustomerId(id).pipe(
            tap(() => NotificationsStateService.toggleCurrentPreferenceLoading(false)),
            map(preference => NotificationsActions.getPreferencesByCustomerIdOk(preference)),
            catchError(err => of(NotificationsActions.getPreferencesByCustomerIdFail(err.response && err.response.data))),
        )),
    );

export const saveNotificationPreference$ = (action$: ActionsObservable<Action<INotificationPreferenceReq>>) =>
    action$.ofType(NotificationsActions.SAVE_NOTIFICATION_PREFERENCE_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => {
            if (!req.id) {
                return NotificationsWebApi.createPreference(req).pipe(
                    map(preference => {
                        NotificationsStateService.setCurrentPreference(preference);

                        _setSnackbar({
                            type: SnackbarTypes.SUCCESS,
                            message: 'Successfully updated',
                            isOpen: true,
                        });

                        return NotificationsActions.saveNotificationPreferenceOk('Ok');
                    }),
                    catchError(err => {
                        _setSnackbar({
                            type: SnackbarTypes.ERROR,
                            message: 'Could not update',
                            isOpen: true,
                        });

                        return of(NotificationsActions.saveNotificationPreferenceFail(err.response && err.response.data));
                    }),
                );
            } else {
                return NotificationsWebApi.updatePreference(req).pipe(
                    map(() => {
                        _setSnackbar({
                            type: SnackbarTypes.SUCCESS,
                            message: 'Successfully updated',
                            isOpen: true,
                        });

                        return NotificationsActions.saveNotificationPreferenceOk('Ok');
                    }),
                    catchError(err => {
                        _setSnackbar({
                            type: SnackbarTypes.ERROR,
                            message: 'Could not update',
                            isOpen: true,
                        });

                        return of(NotificationsActions.saveNotificationPreferenceFail(err.response && err.response.data));
                    }),
                );
            }
        })
    );
