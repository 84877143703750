import { colors } from "../../styles/colors";
import { fontSizes } from "../../styles/font-sizes";
import { fonts } from "../../styles/fonts";
import styled from "styled-components";
import tinycolor from 'tinycolor2';

interface WrapperProps extends Override {
    disabled: boolean | undefined;
    color: string;
}

interface IIconWrapperProps {
    hasLabel: boolean;
}

interface Override {
    override: string | undefined;
    hasIcon?: boolean;
}

export const Wrapper = styled.button<WrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    display: inline-block;
    padding: 14px 25px;
    border-radius: 4px;
    transition: 0.4s;
    outline: none;
    border: none;

    &:disabled {
        opacity: 0.3 !important;
        cursor: inherit;
    }

    ${props => props.override};
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
    margin-right: ${props => props.hasLabel ? 5 : 0}px;
    display: inline-block;
`;

export const Label = styled.span<Override>`
    font-size: ${fontSizes.paragraph};
    line-height: 24px;
    font-weight: 500;
    font-family: ${fonts.proxima};

    ${props => props.override};
`;

// ===== PRIMARY =====

export const PrimaryWrapper = styled(Wrapper)`
    box-shadow: 0 6px 24px 0 ${colors.$darkBlueRGBA(0.4)};
    background-color: ${props => props.color};

    &:hover {
        background-color: ${props => tinycolor(props.color).darken(15).toString()};
        box-shadow: 0 6px 24px 0 ${colors.$blueRGBA(0.3)};
    }

    &:active {
        background-color: ${props => tinycolor(props.color).lighten(15).toString()};
    }
`;

export const PrimaryLabel = styled(Label)`
    color: ${colors.$white};
`;

// ===== SECONDARY =====

export const SecondaryWrapper = styled(Wrapper)`
    border: solid 2px ${colors.$buttonSecondaryBorder};
    padding: 12px 23px;

    :active {
        opacity: 0.6;
    }
`;

export const SecondaryLabel = styled(Label)`
    color: ${colors.$darkBlue};
    margin-left: ${props => props.hasIcon ? 10 : 0}px;
`;

// ===== SLIM BUTTON =====

export const SlimButtonWrapper = styled.button<WrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 4px;
    transition: 0.4s;
    outline: none;
    border: none;
    box-shadow: 0 6px 24px 0 ${colors.$darkBlueRGBA(0.4)};
    background-color: ${props => props.color};
    margin-bottom: 5px;

    &:hover {
        background-color: ${props => tinycolor(props.color).darken(15).toString()};
        box-shadow: 0 6px 24px 0 ${colors.$blueRGBA(0.3)};
    }

    &:active {
        background-color: ${props => tinycolor(props.color).lighten(15).toString()};
    }

    &:disabled {
        opacity: 0.3 !important;
        cursor: inherit;
    }

    ${props => props.override};
`;
