import * as orderLineActions from './order-line.actions';

import { Action } from "../../shared/interfaces/shared";
import { IOrderLineState } from "../interfaces/order-line-state";
import { orderLineInitState } from "./order-line.init-state";

export const orderLineReducer = (state: IOrderLineState = orderLineInitState, action: Action<any>): IOrderLineState => {
    switch (action.type) {
        case orderLineActions.GET_ORDER_LINES_OK: {
            return {
                ...state,
                orderLines: action.payload,
            };
        }

        case orderLineActions.TOGGLE_ORDER_LINES_LOADING: {
            return {
                ...state,
                orderLineLoading: action.payload,
            };
        }

        case orderLineActions.TOGGLE_DELETE_ORDER_LINE_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: action.payload,
                },
            };
        }

        case orderLineActions.TOGGLE_SAVE_ORDER_LINE_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    save: action.payload,
                },
            };
        }

        case orderLineActions.SET_ORDER_LINE: {
            return {
                ...state,
                orderLine: action.payload,
            };
        }

        case orderLineActions.RESET_ORDER_LINE: {
            return {
                ...state,
                orderLine: orderLineInitState.orderLine,
            };
        }


        case orderLineActions.SAVE_ORDER_LINE_OK: {
            return {
                ...state,
                saveOrderLine: {
                    ...state.saveOrderLine,
                    response: action.payload,
                },

            };
        }

        case orderLineActions.SAVE_ORDER_LINE_FAIL: {
            return {
                ...state,
                saveOrderLine: {
                    ...state.saveOrderLine,
                    error: action.payload,
                    loading: false
                },
                modals: {
                    ...state.modals,
                    save: false
                }

            };
        }

        case orderLineActions.TOGGLE_SAVE_ORDER_LINE_LOADING: {
            return {
                ...state,
                saveOrderLine: {
                    ...state.saveOrderLine,
                    loading: action.payload
                }
            };
        }


        case orderLineActions.EDIT_ORDER_LINE_OK: {
            return {
                ...state,
                editOrderLine: {
                    ...state.editOrderLine,
                    response: action.payload,
                },

            };
        }


        case orderLineActions.EDIT_ORDER_LINE_FAIL: {
            return {
                ...state,
                editOrderLine: {
                    ...state.editOrderLine,
                    error: action.payload,
                    loading: false
                },
                modals: {
                    ...state.modals,
                    save: false
                }
            };
        }

        case orderLineActions.TOGGLE_EDIT_ORDER_LINE_LOADING: {
            return {
                ...state,
                editOrderLine: {
                    ...state.editOrderLine,
                    loading: action.payload
                }
            };
        }

        case orderLineActions.TOGGLE_ARCHIVE_ORDER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    archiveOrder: action.payload,
                },
            };
        }

        case orderLineActions.GET_PART_IDS_OK: {
            return {
                ...state,
                partIds: action.payload,
            };
        }

        case orderLineActions.GET_PART_DESCRIPTIONS_OK: {
            return {
                ...state,
                partDescriptions: action.payload,
            };
        }

        case orderLineActions.TOGGLE_VIEW_MODE: {
            return {
                ...state,
                viewMode: action.payload,
            };
        }

        default:
            return state;
    }
};
