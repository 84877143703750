import * as styles from './order-details.style';

import { ErpOrderLineTable } from '../erp-order-line-table/erp-order-line-table';
import { ErpShippingInfoTable } from '../erp-shipping-info-table/erp-shipping-info-table';
import { ICompleteOrder } from '../../interfaces/order';
import React from 'react';
import { isEmpty } from 'lodash';

interface Props {
    order: ICompleteOrder;
}

export const OrderDetails: React.FC<Props> = (props: Props) => {
    const { order } = props;

    const render = () => {
        if (isEmpty(order)) {
            return <div>No order found</div>;
        }

        return (
            <styles.Wrapper>

                <styles.OrderDetailsTitle>
                    Order Line
                </styles.OrderDetailsTitle>

                {/** Order Lines */}
                <ErpOrderLineTable orderLines={order.erpOrderLines} />

                <styles.OrderDetailsTitle>
                    Shipping Info
                </styles.OrderDetailsTitle>

                {/** Shipping Info */}
                <ErpShippingInfoTable erpShippers={order.erpShippers} />
            </styles.Wrapper>
        );
    };

    return render();
};
