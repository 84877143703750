import * as React from 'react';
import * as div from './gecko-logo.style';

import logo from '../../assets/gecko-logo.png';
import logoAc from '../../assets/activ-carts-logo.png';

interface Props {
    width: number;
    height: number;
    isAc: boolean;
}

export const GeckoLogo: React.FunctionComponent<Props> = (props: Props) => {
    const { width, height, isAc } = props;

    return (
        <div.Wrapper width={width} height={height} src={isAc ? logoAc : logo} />
    );
};

