import * as actions from './soa.actions';

import { Action } from '../../shared/interfaces/shared';
import { ISOAState } from '../interfaces/soa-state';
import { ISoa } from '../interfaces/soa'
import { soaInitState } from './soa.init-state';

export function soaReducer(state: ISOAState = soaInitState, action: Action<any>): ISOAState {
    switch (action.type) {

        case actions.RESET_SOAS: {
            return {
                deleteSoaModal: false,
                createSoaModal: false,
                soas: [],
                getSoasLoading: false,
                createSoa: {
                    loading: false,
                    response: '',
                    error: ''
                },
                deleteSoa: {
                    deletedSoa: {} as ISoa,
                    loading: false,
                    response: '',
                    error: ''
                },
                editSoa: {
                    currentSoa: {} as ISoa,
                    loading: false,
                    response: '',
                    error: '',
                }

            };
        }

        case actions.GET_SOAS_OK: {
            return {
                ...state,
                soas: action.payload
            };
        }

        case actions.TOGGLE_GET_SOAS_LOADING: {
            return {
                ...state,
                getSoasLoading: action.payload
            };
        }

        case actions.TOGGLE_DELETE_MODAL: {
            return {
                ...state,
                deleteSoaModal: action.payload
            };
        }


        case actions.SET_DELETED_SOA: {
            return {
                ...state,
                deleteSoa: {
                    ...state.deleteSoa,
                    deletedSoa: action.payload
                }
            };
        }

        case actions.DELETE_SOA_BY_ID_OK: {
            return {
                ...state,
                deleteSoa: {
                    ...state.deleteSoa,
                    response: action.payload,
                    error: ''
                },
                editSoa: {
                    ...soaInitState.editSoa
                },
                createSoa: {
                    ...soaInitState.createSoa
                }

            };
        }

        case actions.DELETE_SOA_BY_ID_FAIL: {
            return {
                ...state,
                deleteSoaModal: false,
                deleteSoa: {
                    ...state.deleteSoa,
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                editSoa: {
                    ...soaInitState.editSoa
                },
                createSoa: {
                    ...soaInitState.createSoa
                }
            };
        }

        case actions.TOGGLE_DELETE_SOA_LOADING: {
            return {
                ...state,
                deleteSoa: {
                    ...state.deleteSoa,
                    loading: action.payload
                }
            };
        }

        case actions.TOGGLE_CREATE_SOA_MODAL: {
            return {
                ...state,
                createSoaModal: action.payload
            };
        }

        case actions.CREATE_SOA_OK: {
            return {
                ...state,
                createSoa: {
                    ...state.createSoa,
                    response: action.payload,
                    error: ''
                },
                deleteSoa: {
                    ...soaInitState.deleteSoa
                },
                editSoa: {
                    ...soaInitState.editSoa
                }
            };
        }

        case actions.CREATE_SOA_FAIL: {
            return {
                ...state,
                createSoaModal: false,
                createSoa: {
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                deleteSoa: {
                    ...soaInitState.deleteSoa
                },
                editSoa: {
                    ...soaInitState.editSoa
                }
            };
        }

        case actions.TOGGLE_CREATE_SOA_LOADING: {
            return {
                ...state,
                createSoa: {
                    ...state.createSoa,
                    loading: action.payload
                }
            };
        }

        case actions.SET_CURRENT_SOA: {
            return {
                ...state,
                editSoa: {
                    ...state.editSoa,
                    currentSoa: action.payload
                },
            };
        }

        case actions.RESET_CURRENT_SOA: {
            return {
                ...state,
                editSoa: {
                    ...state.editSoa,
                    currentSoa: soaInitState.editSoa.currentSoa
                },
                deleteSoaModal: false,
                createSoaModal: false
            };
        }

        case actions.EDIT_SOA_OK: {
            return {
                ...state,
                editSoa: {
                    ...state.editSoa,
                    response: action.payload,
                    error: ''
                },
                deleteSoa: {
                    ...soaInitState.deleteSoa
                },
                createSoa: {
                    ...soaInitState.createSoa
                }
            };
        }

        case actions.EDIT_SOA_FAIL: {
            return {
                ...state,
                createSoaModal: false,
                editSoa: {
                    ...state.editSoa,
                    loading: false,
                    error: action.payload,
                    response: ''
                },
                deleteSoa: {
                    ...soaInitState.deleteSoa
                },
                createSoa: {
                    ...soaInitState.createSoa
                }
            };
        }

        case actions.TOGGLE_EDIT_SOA_LOADING: {
            return {
                ...state,
                editSoa: {
                    ...state.editSoa,
                    loading: action.payload
                }
            };
        }



        default:
            return state;
    }
}
