import * as styles from './shipping-fee-radio.style';

import { IShippingFee } from '../../interfaces/shipping-fee';
import React from 'react';

interface Props {
    fee: IShippingFee;
    isSelected: boolean;
    onSelect: (fee: IShippingFee) => void;
}

export const ShippingFeeRadio: React.FC<Props> = (props: Props) => {
    const { fee, onSelect, isSelected } = props;

    const render = () => {
        return (
            <styles.Wrapper onClick={() => onSelect(fee)}
                isSelected={isSelected}>
                <styles.Row>
                    <styles.Title>Carrier Code</styles.Title>
                    <styles.Description>{fee.carrierCode}</styles.Description>
                </styles.Row>

                <styles.Row>
                    <styles.Title>Code</styles.Title>
                    <styles.Description>{fee.code}</styles.Description>
                </styles.Row>

                <styles.Row>
                    <styles.Title>Title</styles.Title>
                    <styles.Description>{fee.title}</styles.Description>
                </styles.Row>

                <styles.Row>
                    <styles.Title>Total Charges</styles.Title>
                    <styles.Description>{fee.totalCharges}</styles.Description>
                </styles.Row>
            </styles.Wrapper>
        );
    };

    return render();
};
