import { ICustomer, IParser, ISaveCustomerReq } from '../interfaces/customer';

import { Action } from '../../shared/interfaces/shared';
import { IAttachRoleReq } from '../../users/interfaces/roles';
import { IFilterObjectData } from '../../order-list/interfaces/order-list';

export const GET_CUSTOMERS_BY_COMPANY_NAME_REQ = 'GET_CUSTOMERS_BY_COMPANY_NAME_REQ';
export const getCustomersByCompanyNameReq = (companyName: string): Action<string> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_REQ,
    payload: companyName
});

export const GET_CUSTOMERS_BY_COMPANY_NAME_OK = 'GET_CUSTOMERS_BY_COMPANY_NAME_OK';
export const getCustomersByCompanyNameOk = (customers: ICustomer[]): Action<ICustomer[]> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_OK,
    payload: customers
});

export const GET_CUSTOMERS_BY_COMPANY_NAME_FAIL = 'GET_CUSTOMERS_BY_COMPANY_NAME_FAIL';
export const getCustomersByCompanyNameFail = (error: string): Action<string> => ({
    type: GET_CUSTOMERS_BY_COMPANY_NAME_FAIL,
    payload: error
});

export const GET_CUSTOMERS_BY_COMPANY_ID_REQ = 'GET_CUSTOMERS_BY_COMPANY_ID_REQ';
export const getCustomersByCompanyIdReq = (id: string | number) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_REQ,
    payload: id,
});

export const GET_CUSTOMERS_BY_COMPANY_ID_OK = 'GET_CUSTOMERS_BY_COMPANY_ID_OK';
export const getCustomersByCompanyIdOk = (customers: ICustomer[]) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_OK,
    payload: customers,
});

export const GET_CUSTOMERS_BY_COMPANY_ID_FAIL = 'GET_CUSTOMERS_BY_COMPANY_ID_FAIL';
export const getCustomersByCompanyIdFail = (error: string) => ({
    type: GET_CUSTOMERS_BY_COMPANY_ID_FAIL,
    payload: error,
});

export const GET_CUSTOMER_BY_ID_REQ = 'GET_CUSTOMER_BY_ID_REQ';
export const getCustomerByIdReq = (id: number) => ({
    type: GET_CUSTOMER_BY_ID_REQ,
    payload: id,
});

export const GET_CUSTOMER_BY_ID_OK = 'GET_CUSTOMER_BY_ID_OK';
export const getCustomerByIdOk = (customer: ICustomer) => ({
    type: GET_CUSTOMER_BY_ID_OK,
    payload: customer,
});

export const GET_CUSTOMER_BY_ID_FAIL = 'GET_CUSTOMER_BY_ID_FAIL';
export const getCustomerByIdFail = (error: string) => ({
    type: GET_CUSTOMER_BY_ID_FAIL,
    payload: error,
});

export const GET_BREADCRUMB_CUSTOMER_REQ = 'GET_BREADCRUMB_CUSTOMER_REQ';
export const getBreadcrumbCustomerReq = (id: string) => ({
    type: GET_BREADCRUMB_CUSTOMER_REQ,
    payload: id,
});

export const GET_BREADCRUMB_CUSTOMER_OK = 'GET_BREADCRUMB_CUSTOMER_OK';
export const getBreadcrumbCustomerOk = (customer: ICustomer) => ({
    type: GET_BREADCRUMB_CUSTOMER_OK,
    payload: customer,
});

export const GET_BREADCRUMB_CUSTOMER_FAIL = 'GET_BREADCRUMB_CUSTOMER_FAIL';
export const getBreadcrumbCustomerFail = (error: string) => ({
    type: GET_BREADCRUMB_CUSTOMER_FAIL,
    payload: error,
});

export const SET_BREADCRUMB_CUSTOMER = 'SET_BREADCRUMB_CUSTOMER';
export const setBreadcrumbCustomer = (customer: ICustomer) => ({
    type: SET_BREADCRUMB_CUSTOMER,
    payload: customer,
});

export const RESET_CUSTOMERS = 'RESET_CUSTOMERS';
export const resetCustomers = () => ({
    type: RESET_CUSTOMERS,
    payload: null
});

export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const resetCustomer = () => ({
    type: RESET_CUSTOMER,
    payload: null,
});

export const TOGGLE_CUSTOMERS_LOADING = 'TOGGLE_CUSTOMERS_LOADING';
export const toggleCustomersLoading = (flag: boolean) => ({
    type: TOGGLE_CUSTOMERS_LOADING,
    payload: flag
});

export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const setCurrentCustomer = (customer: ICustomer) => ({
    type: SET_CURRENT_CUSTOMER,
    payload: customer,
});

export const TOGGLE_DELETE_CUSTOMER_MODAL = 'TOGGLE_DELETE_CUSTOMER_MODAL';
export const toggleDeleteCustomerModal = (flag: boolean) => ({
    type: TOGGLE_DELETE_CUSTOMER_MODAL,
    payload: flag,
});

export const TOGGLE_SAVE_CUSTOMER_MODAL = 'TOGGLE_SAVE_CUSTOMER_MODAL';
export const toggleSaveCustomerModal = (flag: boolean) => ({
    type: TOGGLE_SAVE_CUSTOMER_MODAL,
    payload: flag,
});

export const DELETE_CUSTOMER_REQ = 'DELETE_CUSTOMER_REQ';
export const deleteCustomerReq = (customer: ICustomer) => ({
    type: DELETE_CUSTOMER_REQ,
    payload: customer,
});

export const DELETE_CUSTOMER_OK = 'DELETE_CUSTOMER_OK';
export const deleteCustomerOk = (customer: ICustomer) => ({
    type: DELETE_CUSTOMER_OK,
    payload: customer,
});

export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL';
export const deleteCustomerFail = (error: string) => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error,
});

export const SAVE_CUSTOMER_REQ = 'SAVE_CUSTOMER_REQ';
export const saveCustomerReq = (request: ISaveCustomerReq) => ({
    type: SAVE_CUSTOMER_REQ,
    payload: request,
});

export const SAVE_CUSTOMER_OK = 'SAVE_CUSTOMER_OK';
export const saveCustomerOk = (customer: ICustomer) => ({
    type: SAVE_CUSTOMER_OK,
    payload: customer,
});

export const SAVE_CUSTOMER_FAIL = 'SAVE_CUSTOMER_FAIL';
export const saveCustomerFail = (error: string) => ({
    type: SAVE_CUSTOMER_FAIL,
    payload: error,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ';
export const getFilterExternalCustomerIdsReq = () => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ,
    payload: null,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK';
export const getFilterExternalCustomerIdsOk = (arr: IFilterObjectData[]) => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK,
    payload: arr,
});

export const GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL = 'GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL';
export const getFilterExternalCustomerIdsFail = (error: string) => ({
    type: GET_FILTER_EXTERNAL_CUSTOMER_IDS_FAIL,
    payload: error,
});

export const GET_PARSERS_REQ = 'GET_CUSTOMER_PARSERS_REQ';
export const getParsersReq = () => ({
    type: GET_PARSERS_REQ,
    payload: null,
});

export const GET_PARSERS_OK = 'GET_PARSERS_OK';
export const getParsersOk = (parsers: IParser[]) => ({
    type: GET_PARSERS_OK,
    payload: parsers,
});

export const GET_PARSERS_FAIL = 'GET_PARSERS_FAIL';
export const getParsersFail = (error: string) => ({
    type: GET_PARSERS_FAIL,
    payload: error,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_REQ = 'GET_CUSTOMERS_FROM_ERP_COMPANY_REQ';
export const getCustomersFromErpCompanyReq = (company: string) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_REQ,
    payload: company,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_OK = 'GET_CUSTOMERS_FROM_ERP_COMPANY_OK';
export const getCustomersFromErpCompanyOk = (customers: string[]) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_OK,
    payload: customers,
});

export const GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL = 'GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL';
export const getCustomersFromErpCompanyFail = (error: string) => ({
    type: GET_CUSTOMERS_FROM_ERP_COMPANY_FAIL,
    payload: error,
});

export const GET_ERP_CUSTOMER_IDS_REQ = 'GET_ERP_CUSTOMER_IDS_REQ';
export const getErpCustomerIdsReq = () => ({
    type: GET_ERP_CUSTOMER_IDS_REQ,
    payload: null,
});

export const GET_ERP_CUSTOMER_IDS_OK = 'GET_ERP_CUSTOMER_IDS_OK';
export const getErpCustomerIdsOk = (ids: string[]) => ({
    type: GET_ERP_CUSTOMER_IDS_OK,
    payload: ids,
});

export const GET_ERP_CUSTOMER_IDS_FAIL = 'GET_ERP_CUSTOMER_IDS_FAIL';
export const getErpCustomerIdsFail = (error: string) => ({
    type: GET_ERP_CUSTOMER_IDS_FAIL,
    payload: error,
});

export const TOGGLE_ERP_CUSTOMER_IDS_LOADING = 'TOGGLE_ERP_CUSTOMER_IDS_LOADING';
export const toggleErpCustomerIdsLoading = (flag: boolean) => ({
    type: TOGGLE_ERP_CUSTOMER_IDS_LOADING,
    payload: flag,
});

export const SET_ERP_CUSTOMER_IDS = 'SET_ERP_CUSTOMER_IDS';
export const setErpCustomerIds = (ids: string[]) => ({
    type: SET_ERP_CUSTOMER_IDS,
    payload: ids,
});
