import React, { useEffect, useState } from 'react';

import { CURRENCIES } from '../../../shared/services/utils.service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ICurrency } from '../../../shared/interfaces/currency';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const CurrencyFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const getInitialCurrency = (currCurrency: string) => {
        if (!currCurrency) {
            return null;
        }

        let currentCurrency = null;

        for (let currency of CURRENCIES) {
            if (currency.name === currCurrency) {
                currentCurrency = currency;
                break;
            }
        }

        return currentCurrency;
    };

    // State
    const [currency, setCurrency] = useState(getInitialCurrency(initValue));

    const render = () => {
        return (
            <Dropdown label={'Currency'}
                keyToShow={'name'}
                allowClear={true}
                options={CURRENCIES}
                onChange={currency=> onChange(currency)}
                value={currency} />
        );
    };

    useEffect(() => {
        if (!initValue) {
            setCurrency({} as ICurrency);
        }
    }, [initValue]);

    const onChange = (currency: ICurrency) => {
        setCurrency(currency);
        onInputChange('currency', currency.name);
    };

    return render();
};
