import 'jspdf-autotable';

import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfMake from "pdfmake/build/pdfmake";

import { ICompleteOrder, IErpShipper } from '../../interfaces/order';

import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { ErpShippingInfoLineTable } from '../erp-shipping-info-line-table/erp-shipping-info-line-table';
import { ErpShippingInfoTableUtils } from './erp-shipping-info-table.utils';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { GeckoTable } from '../../../shared/components/gecko-table/gecko-table';
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { useSelector } from 'react-redux'

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// tslint-disabled
interface Props {
    showFilters?: boolean;
    showExport?: boolean;
    showExpandedView?: boolean;
    erpShippers: IErpShipper[];
}

export const ErpShippingInfoTable: React.FC<Props> = (props: Props) => {
    const { showFilters, showExport, erpShippers, showExpandedView } = props;

    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);
    const order = useSelector<AppState, ICompleteOrder>(state => state.orders.currentCompleteOrder);

    // console.log('++ order', order);

    // console.log('++ erpShippers', erpShippers);

    const render = () => {
        return (
            <>
                <Theme.SpaceBetween>
                    {/* {
                        showExport &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Download'}
                            onClick={() => onDownloadClick()} />
                    } */}

                    {
                        showFilters &&
                        <Button type={ButtonTypes.PRIMARY}
                            label={'Filters'}
                            onClick={() => { }}
                            disabled={true} />
                    }
                </Theme.SpaceBetween>

                <GeckoTable dataSource={{ data: erpShippers }}
                    columns={ErpShippingInfoTableUtils.shipperColumns(tokenUserRole)}
                    expandable={showExpandedView ? expandable() : undefined}
                    filters={[]}
                    rowKey={(shipper: IErpShipper) => shipper.id + shipper.erpOrderHeaderId}
                    tableId={'erpShippingInfo'} />
            </>
        );
    };

    const expandable = (): ExpandableConfig<IErpShipper> => {
        return {
            expandedRowRender: (erpShipper: IErpShipper) => <ErpShippingInfoLineTable lines={erpShipper.erpShipperLines} />,
            expandedRowClassName: () => 'table-expanded-row',
        };
    };

    // const onDownloadClick = () => {
    //     const mainKeys = ['packlistId', 'shippedDate', 'shippedFrom', 'shipVia', 'wayBill', 'trackingUrl', 'invoiceId'];
    //     const mainBody = erpShippers.map((shipper: any) => {
    //         let arr: any[] = [];

    //         mainKeys.forEach(key => {
    //             const obj = {
    //                 text: shipper[key],
    //                 style: 'tableCell',
    //             };

    //             arr = [ ...arr, obj ];
    //         });

    //         return arr;
    //     });

    //     const mainHead = [
    //         { text: 'Packlist', style: 'tableHeader' },
    //         { text: 'Ship Date', style: 'tableHeader' },
    //         { text: 'Ship From', style: 'tableHeader' },
    //         { text: 'VIA', style: 'tableHeader' },
    //         { text: 'Waybill', style: 'tableHeader' },
    //         { text: 'Tracking URL', style: 'tableHeader' },
    //         { text: 'Invoice', style: 'tableHeader' },
    //     ];

    //     let erpShipperDetails: IErpShipperLine[] = [];
    //     erpShippers.forEach(e => {
    //         erpShipperDetails = [
    //             ...erpShipperDetails,
    //             ...e.erpShipperLines,
    //         ];
    //     });

    //     const detailsKeys = ['packlistId', 'partId', 'orderId', 'shippedQty', 'unitPrice'];
    //     const detailsBody = erpShipperDetails.map((detail: any) => {
    //         let arr: any[] = [];

    //         detailsKeys.forEach(key => {
    //             arr = [
    //                 ...arr,
    //                 { text: detail[key], style: 'tableCell' },
    //             ];
    //         });

    //         return arr;
    //     });

    //     const detailsHead = [
    //         { text: 'Packlist', style: 'tableHeader'  },
    //         { text: 'Part ID', style: 'tableHeader'  },
    //         { text: 'Order', style: 'tableHeader'  },
    //         { text: 'Qty Ship', style: 'tableHeader'  },
    //         { text: 'Unit Price', style: 'tableHeader'  },
    //     ];

    //     const docDefinition = {
    //         pageOrientation: 'landscape',
    //         content: [
    //             { text: `Shipping information - Order number ${order.orderId}`, style: 'header' },
    //             {
    //                 style: 'tableExample',
    //                 table: {
    //                     widths: ['auto', 'auto', 'auto', 'auto', 'auto', 100, 'auto'],
    //                     headerRows: 1,
    //                     body: [
    //                         mainHead,
    //                         ...mainBody,
    //                     ],
    //                 },
    //                 pageBreak: 'after',
    //                 layout: 'lightHorizontalLines',
    //             },
    //             { text: `Shipping information - Packlist details`, style: 'header' },
    //             {
    //                 style: 'tableExample',
    //                 table: {
    //                     widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
    //                     headerRows: 1,
    //                     body: [
    //                         detailsHead,
    //                         ...detailsBody,
    //                     ],
    //                 },
    //                 layout: 'lightHorizontalLines',
    //             },
    //         ],
    //         styles: {
    //             header: {
    //                 fontSize: 18,
    //                 bold: true,
    //                 margin: [0, 0, 0, 10],
    //             },
    //             subheader: {
    //                 fontSize: 16,
    //                 bold: true,
    //                 margin: [0, 10, 0, 5]
    //             },
    //             tableExample: {
    //                 margin: [0, 5, 0, 15],
    //                 border: 'none',
    //             },
    //             tableOpacityExample: {
    //                 margin: [0, 5, 0, 15],
    //                 fillColor: 'blue',
    //                 fillOpacity: 0.3
    //             },
    //             tableHeader: {
    //                 bold: true,
    //                 fontSize: 10,
    //                 color: colors.$blue,
    //             },
    //             tableCell: {
    //                 bold: false,
    //                 fontSize: 14,
    //                 color: colors.$blue,
    //             },
    //         },
    //         defaultStyle: {
    //             // alignment: 'justify'
    //         }
    //     };

    //     pdfMake.createPdf(docDefinition as any).download();
    // };

    // const onDownloadClick = () => {
    //     const doc = new jsPDF('l') as any;

    //     const mainKeys = ['packlistId', 'shippedDate', 'shippedFrom', 'shipVia', 'wayBill', 'trackingUrl', 'invoiceId'];
    //     const mainBody = erpShippers.map((shipper: any) => {
    //         let arr: string[] = [];

    //         mainKeys.forEach(key => {
    //             arr = [
    //                 ...arr,
    //                 shipper[key],
    //             ];
    //         });

    //         return arr;
    //     });
    //     const mainHead = [[
    //         { content: 'Packlist' },
    //         { content: 'Ship Date' },
    //         { content: 'Ship From' },
    //         { content: 'VIA' },
    //         { content: 'Waybill' },
    //         { content: 'Tracking URL', styles: { cellWidth: 100 } },
    //         { content: 'Invoice' },
    //     ]];

    //     doc.text(`Shipping information - Order number ${order.orderId}`, 10, 10);

    //     doc.autoTable({
    //         head: mainHead,
    //         body: mainBody,
    //     });

    //     /** Calculate Packlist Details */
    //     let finalY = doc.previousAutoTable.finalY;
    //     doc.text(`Shipping information - Packlist details`, 10, finalY + 100);

    //     let erpShipperDetails: IErpShipperLine[] = [];
    //     erpShippers.forEach(e => {
    //         erpShipperDetails = [
    //             ...erpShipperDetails,
    //             ...e.erpShipperLines,
    //         ];
    //     });

    //     const detailsKeys = ['packlistId', 'partId', 'orderId', 'shippedQty', 'unitPrice'];
    //     const detailsBody = erpShipperDetails.map((detail: any) => {
    //         let arr: string[] = [];

    //         detailsKeys.forEach(key => {
    //             arr = [
    //                 ...arr,
    //                 detail[key],
    //             ];
    //         });

    //         return arr;
    //     });

    //     const detailsHead = [[
    //         { content: 'Packlist' },
    //         { content: 'Part ID' },
    //         { content: 'Order' },
    //         { content: 'Qty Ship' },
    //         { content: 'Unit Price' },
    //     ]];

    //     doc.autoTable({
    //         startY: finalY + 150,
    //         head: detailsHead,
    //         body: detailsBody,
    //     });

    //     doc.save('init.pdf');
    // };

    return render();
};
