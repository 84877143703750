import React from "react";
import { ICardPost } from "../../interfaces/card.interface";
import { ReactComponent as BackIcon } from "../../../shared/assets/icons/Icon_Back.svg";

import {
    HeaderCard,
    BodyCard,
    HeaderCardIcon,
    Link,
    BodyCardIcon,
    CardTitle,
    CardDescription,
    BackIconWrapper
} from "./Card.style";
import { useHistory } from "react-router";

export const Card: React.FC<ICardPost> = (props: ICardPost) => {
    const { icon, title, description, url, isHeaderCard } = props;

    // use history for internal navigation

    const history = useHistory();

    const handleCardClik = () => history.push(url);

    return isHeaderCard ? (
        <HeaderCard onClick={handleCardClik}>
            <HeaderCardIcon src={icon} />
            <div>
                <CardTitle marginBottom="14px">{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
            </div>
        </HeaderCard>
    ) : (
        <BodyCard>
            {/* link component for external links */}
            <Link target="_blank" href={url}>
                <BodyCardIcon src={icon} />
                <CardTitle textAlign="center" marginBottom="16px">
                    {title}
                </CardTitle>
                <CardDescription textAlign="center" height="86px">
                    {description}
                </CardDescription>
                <BackIconWrapper>
                    <BackIcon />
                </BackIconWrapper>
            </Link>
        </BodyCard>
    );
};
