import * as React from "react";
import * as div from "./login.page.style";

import { ActivityIndicator } from "../../shared/components/activity-indicator/activity-indicator";
import { AppState } from "../../shared/interfaces/app.state";
import { Button } from "../../shared/components/button/button";
import { GeckoLogo } from "../../shared/components/gecko-logo/gecko-logo";
import { IAzureADFunctionProps } from "react-aad-msal";
import { MSAL } from "../../shared/services/msal";
import { Theme } from "../../shared/styles/theme";
import { config } from "../components/auth-provider/config";
import { loginPageTexts } from "../constants/login.page.constants";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

interface Props extends IAzureADFunctionProps { }

export const InternalLoginPage: React.FC<Props> = (_props: Props) => {
    // State
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    // Store
    const token = useSelector<AppState, string>(state => state.users.currentUser.token);

    const history = useHistory();

    const isAC = config.isAC;

    const render = () => {
        if (isLoading) {
            return <ActivityIndicator />
        }

        return (
            <div.Wrapper>
                {/** Left Container */}
                <div.Left />

                {/** Right Content */}
                <div.Right>

                    {/** Logo */}
                    <GeckoLogo width={123} height={72} isAc={isAC} />

                    {/** Texts */}
                    <div.Texts>
                        <div.TitleText>{loginPageTexts.header}</div.TitleText>

                        <div.DescriptionText>
                            {loginPageTexts.description}
                        </div.DescriptionText>
                    </div.Texts>

                    <Theme.Section>
                        <Theme.Center>
                            <Button label={loginPageTexts.signInButton}
                                disabled={!!isLoading}
                                onClick={() => onLoginClick()}
                            />

                            {/* <Button label={'Login as a customer'}
                                overrides={{ root: 'margin-left: 20px;' }}
                                onClick={() => onCustomerLoginClick()} /> */}
                        </Theme.Center>
                    </Theme.Section>

                    {isLoading !== null && isLoading && <ActivityIndicator />}
                </div.Right>
            </div.Wrapper>
        );
    };

    React.useEffect(() => {
        const appLoading = localStorage.getItem('appLoading');

        if (appLoading === 'true') {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (token) {
            history.push('/');
        }
    }, []);

    const onLoginClick = () => {
        // const { login, accountInfo } = this.props;
        // this.setState({ isLoading: true });
        // setIsLoading(true);

        try {
            // authProvider.login();
            // await login();
            localStorage.setItem('appLoading', JSON.stringify(true));

            // login();
            MSAL.loginRedirect({ scopes: config.scopes });
            // authProvider.loginRedirect();
            // setIsLoading(false);

            localStorage.setItem('provider', 'b2b');

            // throw new Error('')
            // let token = await MSAL.acquireTokenSilent({ scopes: config.scopes });
        } catch (err) {
            console.error(err);
            // setIsLoading(false);
            // this.setState({ isLoading: false });
        }
    };

    // const onCustomerLoginClick = () => {
    //     try {
    //         // authProviderB2C.login();
    //         MSAL.loginRedirect({ scopes: ['https://geckob2cdev.onmicrosoft.com/api/General.Scope' ]});
    //         localStorage.setItem('provider', 'b2c');
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    return render();
};
