import * as React from 'react';
import * as styles from './switch.style';

import ReactSwitch from 'react-ios-switch';
import { colors } from '../../styles/colors';

interface Props {
    value: boolean;
    onChange: (value: boolean) => void;
    label?: string;
}

export const Switch: React.FC<Props> = (props: Props) => {
    const { value, label, onChange } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.Label>
                    {label}
                </styles.Label>

                <ReactSwitch checked={value}
                    onColor={colors.$darkBlue}
                    onChange={() => onChange(!value)} />
            </styles.Wrapper>
        );
    };

    return render();
};
