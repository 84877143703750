import { IRole, IUserRole } from '../interfaces/roles';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ColumnsType } from 'antd/lib/table';
import { DateService } from '../../shared/services/date.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UsersStateService } from '../services/users-state.service';
import { colors } from '../../shared/styles/colors';

export class UserRolesPageUtils {
    public static columns(roles: IRole[]): ColumnsType<IUserRole> {
        return [{
            title: 'Email',
            key: 'userEmail',
            sorter: true,
            render: (_text: string, userRole: IUserRole) =>
            <>
                {userRole.userEmail || '-'}
            </>
        }, {
            title: 'Customer IDs',
            key: 'externalCustomerIdsList',
            sorter: true,
            render: (_text: string, userRole: IUserRole) =>
            <>
                {userRole.externalCustomerIdsList || '-'}
            </>
        }, {
            title: 'Role',
            key: 'userRole',
            sorter: true,
            render: (_text: string, userRole: IUserRole) =>
                this.getRole(roles, userRole)
        }, {
            title: 'Rejected Date',
            key: 'rejectedDate',
            sorter: true,
            render: (_text: string, userRole: IUserRole) => DateService.formatDate(userRole.rejectedDate),
        }, {
            title: 'Requested Date',
            key: 'requestDate',
            sorter: true,
            render: (_text: string, userRole: IUserRole) => DateService.formatDate(userRole.requestDate),
        }, {
            title: 'Has Access',
            key: 'rejected',
            sorter: true,
            render: (_text: string, userRole: IUserRole) => this.getIcon(userRole),
        }];
    }

    private static getIcon(userRole: IUserRole) {
        if (userRole.userRoleId === null) {
            return;
        }

        if (!userRole.rejected) {
            return (
                <FontAwesomeIcon icon={faCheck}
                    color={colors.$success}
                    size={'lg'} />
            );
        }

        return (
            <FontAwesomeIcon icon={faBan}
                color={colors.$alert}
                size={'lg'} />
        );
    };

    private static getRole(roles: IRole[], userRole: IUserRole) {
        let foundRole = roles.filter(r => r.id === userRole.userRoleId)[0];
        let roleString = '';

        if (foundRole) {
            roleString = foundRole.name;
        } else {
            roleString = '-';
        }

        return roleString;
    }

    public static onEditClick(userRole: IUserRole) {
        // UsersStateService.setCurrentUserRole(userRole);
        // GET User Role by ID
        UsersStateService.getUserRoleById(userRole.id);
        UsersStateService.toggleChangeUserRoleModal(true);
    }

}
