import { colors } from "../../styles/colors";
import { fontSizes } from "../../styles/font-sizes";
import { fonts } from "../../styles/fonts";
import styled from "styled-components";

interface TextProps {
    override?: string;
}

export const Text = styled.div<TextProps>`
    font-family: ${fonts.proxima};
    font-size: ${fontSizes.pageTitle};
    line-height: 1.2;
    margin-bottom: 20px;
    color: ${colors.pageTitle.color};
    ${props => props.override};
`;
