import * as React from "react";
import * as div from "./user-header-actions.style";

import { Dropdown, Menu } from "antd";
import { MSAL, getAccountName } from "../../../shared/services/msal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { colors } from "../../../shared/styles/colors";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";

interface Props {}

export const UserHeaderActions: React.FunctionComponent<Props> = (props: Props) => {
    const account = getAccountName();

    return (
        <div.Wrapper>

            {/** User */}
            {
                !isEmpty(account) &&
                <Dropdown overlay={dropdownMenu}>
                    <div.DropdownContent>
                        <div>Hello {account.name}</div>
                        <FontAwesomeIcon icon={faUser} color={colors.$darkBlue} />
                    </div.DropdownContent>
                </Dropdown>
            }

        </div.Wrapper>
    );
};

const dropdownMenu = () => {
    return (
        <Menu>
            <Menu.Item>
                <Link to='/admin/soa'>
                    My Profile
                </Link>
            </Menu.Item>

            <Menu.Item>
                <span onClick={() => logoutClick()}>
                    Logout
                </span>
            </Menu.Item>
        </Menu>
    );
};

const logoutClick = () => {
    MSAL.logout();
    localStorage.clear();
    localStorage.setItem('appLoading', JSON.stringify(false));
};
