import { Selector, createSelector } from 'reselect';

import { AppState } from '../../shared/interfaces/app.state';
import { ISOAState } from '../interfaces/soa-state';
import { ISoa } from '../interfaces/soa';

const SOA_MODULE: Selector<AppState, ISOAState> = state => state.soa;

export const SOAS = createSelector<AppState, ISOAState, ISoa[]>(
    SOA_MODULE,
    state => state.soas
);

export const SOAS_LOADING = createSelector<AppState, ISOAState, boolean>(
    SOA_MODULE,
    state => state.getSoasLoading
);

export const DELETED_SOA_RESPONSE = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.deleteSoa.response
);

export const DELETED_SOA_ERROR = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.deleteSoa.error
);


export const DELETE_SOA_LOADING = createSelector<AppState, ISOAState, boolean>(
    SOA_MODULE,
    state => state.deleteSoa.loading
);


export const CREATE_SOA_RESPONSE = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.createSoa.response
);

export const CREATE_SOA_ERROR = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.createSoa.error
);

export const CREATE_SOA_LOADING = createSelector<AppState, ISOAState, boolean>(
    SOA_MODULE,
    state => state.createSoa.loading
);


export const EDIT_SOA_RESPONSE = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.editSoa.response
);

export const EDIT_SOA_ERROR = createSelector<AppState, ISOAState, string>(
    SOA_MODULE,
    state => state.editSoa.error
);

export const EDIT_SOA_LOADING = createSelector<AppState, ISOAState, boolean>(
    SOA_MODULE,
    state => state.editSoa.loading
);


