import React, { useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const PartIdFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const partIds = useSelector<AppState, string[]>(state => state.shared.allPartIds);

    const [partId, setPartId] = useState(initValue || '');

    const render = () => {
        return (
            <Dropdown options={partIds}
                keyToShow={''}
                value={partId}
                allowClear={true}
                onChange={value => onChange('partId', value)}
                label={'Part ID'} />
        );
    };

    const onChange = (keyValue: string, value: string) => {
        setPartId(value);
        onInputChange(keyValue, value);
    };

    return render();
};
