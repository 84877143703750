import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { CustomersStateService } from '../../../customers/services/customers.state-service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { FiltersStateService } from '../../services/filters.state-service';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const ErpCustomerFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const customers = useSelector<AppState, string[]>(state => state.filters.erpCustomerIds);
    const selectedCompany = useSelector<AppState, string>(state => state.filters.orders.company);

    // State
    const [customer, setCustomer] = useState(initValue);

    const render = () => {
        return (
            <Dropdown label={'Customer ID'}
                keyToShow={''}
                allowClear={true}
                options={customers || []}
                onChange={customers => onChange(customers)}
                value={customer} />
        );
    };

    useEffect(() => {
        if (selectedCompany) {
            CustomersStateService.getCustomerIdsFromErpCompany(selectedCompany);
        } else {
            FiltersStateService.setErpCustomerIds([]);
            setCustomer('');
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (!initValue) {
            setCustomer('');
        }
    }, [initValue]);

    const onChange = (customer: string) => {
        setCustomer(customer);
        onInputChange('customerId', customer);
    };

    return render();
};
