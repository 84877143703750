import { IOrderStatus } from '../../interfaces/order-list';
import React from 'react';
import { StatusText } from './integration-status.style';

interface Props {
    status: IOrderStatus;
    children?: React.ReactNode;
}

export const IntegrationStatus: React.FC<Props> = (props: Props) => {
    const { status } = props;

    // console.log('++ status', status);

    const render = () => {
        return (
            <StatusText type={status}>
                {props.children}
            </StatusText>
        );
    };

    return render();
};
