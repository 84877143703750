import { IBreadcrumb } from "../../shared/interfaces/shared";

export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const addBreadcrumb = (breadcrumb: IBreadcrumb) => ({
    type: ADD_BREADCRUMB,
    payload: breadcrumb,
});

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const setBreadcrumbs = (breadcrumbs: IBreadcrumb[]) => ({
    type: SET_BREADCRUMBS,
    payload: breadcrumbs,
});

export const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';
export const resetBreadcrumbs = () => ({
    type: RESET_BREADCRUMBS,
    payload: null,
});

export const REFINE_BREADCRUMBS = 'REFINE_BREADCRUMBS';
export const refineBreadcrumbs = (breadcrumb: IBreadcrumb) => ({
    type: REFINE_BREADCRUMBS,
    payload: breadcrumb,
});

export const ADD_BREADCRUMB_HISTORY = 'ADD_BREADCRUMB_HISTORY';
export const addBreadcrumbHistory = (breadcrumb: IBreadcrumb) => ({
    type: ADD_BREADCRUMB_HISTORY,
    payload: breadcrumb,
});
