import * as styles from './erp-order-invoice-table.style';

import { ColumnsType } from "antd/lib/table";
import { DateService } from "../../../shared/services/date.service";
import { IErpOrderInvoice } from "../../interfaces/order";
import { ITokenUserRole } from '../../../users/interfaces/token-user-role';
import { OrdersStateService } from '../../services/orders-state.service';
import { ROLES } from '../../../users/interfaces/roles';
import React from 'react';
import { Theme } from '../../../shared/styles/theme';
import { isEmpty } from 'lodash';
import { store } from '../../../shared/services/state.service';

export class ErpOrderInvoiceTableUtils {
    public static readonly columns = (tokenUserRole: ITokenUserRole | null): ColumnsType<IErpOrderInvoice> => ([{
        title: <styles.HeaderTitle>Invoice</styles.HeaderTitle>,
        render: (_text: string, invoice: IErpOrderInvoice) =>
        <>
            {
                (tokenUserRole && tokenUserRole.role) === ROLES.Client &&
                invoice.invoiceId
            }

            {
                (tokenUserRole && tokenUserRole.role) !== ROLES.Client &&
                <Theme.Clickable onClick={() => ErpOrderInvoiceTableUtils.onDownloadInvoiceClick(invoice)}>
                    {invoice.invoiceId}
                </Theme.Clickable>
            }
        </>,
    }, {
        title: <styles.HeaderTitle>Packlist</styles.HeaderTitle>,
        dataIndex: 'packlistId',
    }, {
        title: <styles.HeaderTitle>Invoice Date</styles.HeaderTitle>,
        dataIndex: 'invoiceDate',
        render: (_text: string, invoice: IErpOrderInvoice) => DateService.formatDate(invoice.invoiceDate),
    }, {
        title: <styles.HeaderTitle>Total</styles.HeaderTitle>,
        dataIndex: 'totalAmount',
    }, {
        title: <styles.HeaderTitle>Paid</styles.HeaderTitle>,
        dataIndex: 'paidAmount',
    }, {
        title: <styles.HeaderTitle>Terms(d)</styles.HeaderTitle>,
        dataIndex: 'termsDays',
    }, {
        title: <styles.HeaderTitle>Currency</styles.HeaderTitle>,
        dataIndex: 'currency',
    }]);

    private static onDownloadInvoiceClick(invoice: IErpOrderInvoice) {
        const order = store.getState().orders.currentCompleteOrder;

        if (!isEmpty(order)) {
            OrdersStateService.downloadInvoice({
                invoiceId: invoice.invoiceId,
                customerId: order.customerId,
            });
        }

    }

}
