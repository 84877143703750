import { IAzureADFunctionProps } from 'react-aad-msal';
import { NotificationPreferencesPage } from '../pages/notification-preferences.page';
import React from 'react';
import { Route } from 'react-router';

export const notificationsRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route exact={true}
            key={'notifications'}
            path='/notifications/preferences'
            render={props => <NotificationPreferencesPage {...props} {...azureAd} />} />,
    ];
};
