import { catchError, concatMap, map } from "rxjs/operators";

import { Action } from "../../shared/interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { IAttachRoleReq } from "../interfaces/roles";
import { ISetting } from "../interfaces/settings";
import { SnackbarTypes } from "../../shared/components/snackbar/snackback.utils";
import { UsersActions } from "./users.actions";
import { UsersStateService } from "../services/users-state.service";
import { UsersWebApi } from "../webapi/users.webapi";
import { _setSnackbar } from "../../shared/services/shared.state-service";
import { of } from "rxjs";

export const getRoles$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(UsersActions.GET_ROLES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => UsersWebApi.getRoles().pipe(
            map(roles => UsersActions.getRolesOk(roles)),
            catchError(err => of(UsersActions.getRolesFail(err.response && err.response.data))),
        )),
    );

export const attachRole$ = (action$: ActionsObservable<Action<IAttachRoleReq>>) =>
    action$.ofType(UsersActions.ATTACH_ROLE_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => UsersWebApi.attachRole(req).pipe(
            map(() => {
                UsersStateService.toggleChangeUserRoleModal(false);
                // UsersStateService.getUserRoles();

                _setSnackbar({
                    isOpen: true,
                    message: 'Role successfully updated',
                    type: SnackbarTypes.SUCCESS,
                });

                return UsersActions.attachRoleOk();
            }),
            catchError(err => {
                _setSnackbar({
                    isOpen: true,
                    message: 'Could not update role',
                    type: SnackbarTypes.ERROR,
                });

                return of(UsersActions.getRolesFail(err.response && err.response.data));
            }),
        )),
    );

export const getUserRoles$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(UsersActions.GET_USER_PERMISSION_REQ).pipe(
        map(action => action.payload!),
        concatMap(params => UsersWebApi.getUserRoles(params).pipe(
            map(userRoles => UsersActions.getUserPermissionOk(userRoles)),
            catchError(err => of(UsersActions.getUserPermissionFail(err.response && err.response.data))),
        )),
    );

export const getUserRole$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(UsersActions.GET_USER_ROLE_REQ).pipe(
        map(action => action.payload),
        concatMap(() => UsersWebApi.getUserRole().pipe(
            map(role => UsersActions.getUserRoleOk(role)),
            catchError(err => of(UsersActions.getUserRoleFail(err.response && err.response.data))),
        )),
    );

export const getUserRoleById$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(UsersActions.GET_USER_ROLE_BY_ID_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => UsersWebApi.getUserRoleById(id).pipe(
            map(role => UsersActions.getUserRoleByIdOk(role)),
            catchError(err => of(UsersActions.getUserRoleByIdFail(err.response && err.response.data))),
        )),
    );

export const getAdminSettings$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(UsersActions.GET_ADMIN_SETTINGS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => UsersWebApi.getAdminSettings().pipe(
            map(settings => UsersActions.getAdminSettingsOk(settings)),
            catchError(err => of(UsersActions.getAdminSettingsFail(err.response && err.response.data))),
        )),
    );

export const saveAdminSettings$ = (action$: ActionsObservable<Action<ISetting[]>>) =>
    action$.ofType(UsersActions.SAVE_ADMIN_SETTINGS_REQ).pipe(
        map(action => action.payload!),
        concatMap(settings => UsersWebApi.saveAdminSettings(settings).pipe(
            map(() => {
                _setSnackbar({
                    type: SnackbarTypes.SUCCESS,
                    message: 'Settings successfully updated',
                    isOpen: true,
                });

                return UsersActions.saveAdminSettingsOk();
            }),
            catchError(err => {
                _setSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Could not update settings',
                    isOpen: true,
                });

                return of(UsersActions.saveAdminSettingsFail(err.response && err.response.data));
            }),
        )),
    );
