import { CURRENCIES } from "./utils.service";

export class SharedService {
    public static getCurrency(currencyName: string | null) {
        if (currencyName) {
            return CURRENCIES.filter(currency => currency.name === currencyName)[0];
        }

        return CURRENCIES[0];
    }
}