import * as React from "react";

import { IAzureADFunctionProps } from "react-aad-msal";
import { InternalLoginPage } from "../pages/internal-login.page";
import { LoginPage } from "../pages/login.page";
import { LogoutPage } from "../pages/logout.page";
import { Route } from "react-router";

export const authRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route
            exact={true}
            key="login"
            path="/login"
            render={props => <LoginPage {...props} {...azureAd} />} />,

        <Route exact={true}
            key={'logout'}
            path='/logout'
            render={props => <LogoutPage {...props} /> }/>,

        <Route exact={true}
            key={'internal-login'}
            path={'/internal-login'}
            render={props => <InternalLoginPage {...props} {...azureAd} /> } />,
    ];
};
