import * as styles from './additional-info.style';

import { ICompleteOrder } from '../../interfaces/order';
import React from 'react';
import { SectionTitle } from '../../../shared/components/section-title/section-title';

interface Props {
    order: ICompleteOrder;
}

export const OrderAdditionalInfo: React.FC<Props> = (props: Props) => {
    const { order } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <SectionTitle text={'Additional Information'} />

                <styles.Section>
                    <styles.Row>
                        <styles.Label>FOB</styles.Label>

                        <styles.Value color={undefined}>
                            {order.fob || '-'}
                        </styles.Value>
                    </styles.Row>

                    <styles.Row>
                        <styles.Label>Carrier ID</styles.Label>

                        <styles.Value color={undefined}>
                            {order.carrierId || '-'}
                        </styles.Value>
                    </styles.Row>

                    <styles.Row>
                        <styles.Label>PO Number</styles.Label>

                        <styles.Value color={undefined}>
                            {order.poNumber}
                        </styles.Value>
                    </styles.Row>

                    <styles.Row>
                        <styles.Label>
                            Shipment Type
                        </styles.Label>

                        <styles.Value color={undefined}>
                            {order.shipmentType || '-'}
                        </styles.Value>
                    </styles.Row>
                </styles.Section>

            </styles.Wrapper>
        );
    };

    return render();
};
