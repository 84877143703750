import * as soaActions from './soa.actions';
import * as soaWebApi from '../webapi/soa.webapi';

import {
    _getSoas,
    _resetCurrentSoa,
    _toggleCreateSoaLoading,
    _toggleCreatetSoaModal,
    _toggleDeleteSoaLoading,
    _toggleDeleteSoaModal,
    _toggleEditSoaLoading,
    _toggleGetSoasLoading
} from '../services/soa-state.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { Action } from '../../shared/interfaces/shared';
import { ActionsObservable } from 'redux-observable';
import { AppState } from '../../shared/interfaces/app.state';
import { ISoa } from '../interfaces/soa'
import { ISoaFormValues } from '../interfaces/soa-form-values'
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { Store } from 'redux';
import { _setSnackbar } from '../../shared/services/shared.state-service'
import { of } from 'rxjs';

export const getSoas$ = (action$: ActionsObservable<Action<null>>, _store: Store<AppState>) =>
    action$.ofType(soaActions.GET_SOAS_REQ).pipe(
        tap(() => _toggleGetSoasLoading(true)),
        map(action => action.payload),
        concatMap(() =>
            soaWebApi.getSoas().pipe(
                tap(() => _toggleGetSoasLoading(false)),
                map(soas => soaActions.getSoasOk(soas)),
                catchError(error => of(soaActions.getSoasFail(error)))
            )
        )
    );

export const deleteSoaById$ = (action$: ActionsObservable<Action<number>>, _store: Store<AppState>) =>
    action$.ofType(soaActions.DELETE_SOA_BY_ID_REQ).pipe(
        tap(() => _toggleDeleteSoaLoading(true)),
        map(action => action.payload!),
        concatMap(id =>
            soaWebApi.deleteSoa(id).pipe(
                tap(() => _toggleDeleteSoaLoading(false)),
                tap(() => _toggleDeleteSoaModal(false)),
                map(response => {
                    _getSoas();
                    _setSnackbar({
                        message: 'Successfully deleted',
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });
                    return soaActions.deleteSoaByIdOK(response);
                }),
                catchError(error => {

                    _setSnackbar({
                        message: 'There has been an error',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(soaActions.deleteSoaByIdFail(error.response.data))
                })
            )
        )
    );


export const createSoa$ = (action$: ActionsObservable<Action<ISoaFormValues>>, _store: Store<AppState>) =>
    action$.ofType(soaActions.CREATE_SOA_REQ).pipe(
        tap(() => _toggleCreateSoaLoading(true)),
        map(action => action.payload!),
        concatMap(soa =>
            soaWebApi.createSoa(soa).pipe(
                tap(() => _toggleCreateSoaLoading(false)),
                tap(() => _toggleCreatetSoaModal(false)),
                map(response => {
                    _getSoas();
                    _setSnackbar({
                        message: `SOA with the name "${soa.name}" was created.`,
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });
                    return soaActions.createSoaOk(response);
                }),
                catchError(error => {
                    let err = error.response.data;
                    _setSnackbar({
                        message: err.description,
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(soaActions.createSoaFail(error.response.data))
                })
            )
        )
    );


export const editSoa$ = (action$: ActionsObservable<Action<ISoa>>, _store: Store<AppState>) =>
    action$.ofType(soaActions.EDIT_SOA_REQ).pipe(
        tap(() => _toggleEditSoaLoading(true)),
        map(action => action.payload!),
        concatMap(soa =>
            soaWebApi.editSoa(soa).pipe(
                tap(() => _toggleEditSoaLoading(false)),
                tap(() => _toggleCreatetSoaModal(false)),
                tap(() => _resetCurrentSoa()),
                map(response => {
                    _getSoas();
                    _setSnackbar({
                        message: `SOA with the name "${soa.name}" was updated.`,
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });
                    return soaActions.editSoaOk(response);
                }),
                catchError(error => {
                    let err = error.response.data;
                    _setSnackbar({
                        message: err.description,
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(soaActions.editSoaFail(error.response.data))
                })
            )
        )
    );

export const getFilterSoas$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(soaActions.GET_FILTER_SOAS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => soaWebApi.getFilterSoas().pipe(
            map(soas => soaActions.getFilterSoasOk(soas)),
            catchError(err => of(soaActions.getFilterSoasFail(err.response.data))),
        )),
    );
