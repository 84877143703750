import { colors } from '../../../shared/styles/colors'
import { fonts } from "../../../shared/styles/fonts";
import styled from 'styled-components'

export const DownloadButton = styled.a`
    padding: 10px;
    background-color: ${colors.$darkBlue};
    color: ${colors.$white};
    border-radius: 5px;
    font-family: ${fonts.proxima};
    text-decoration: none;
`;
