import * as React from 'react';
import * as styled from './dropdown.style';

import { Select } from 'antd';

interface Props {
    options: any[];

    value?: any;

    onChange: (option: any) => void;

    label?: string;

    placeholder?: string;

    error?: string;

    disabled?: boolean;

    required?: boolean;

    allowClear?: boolean;
}

export const MultipleDropdown: React.FC<Props> = (props: Props) => {
    const { label, options, error, onChange, value, disabled, required, allowClear } = props;

    // console.log('++ value', value);

    const render = () => {
        return (
            <styled.Wrapper hasError={!!error}>

                {/** Label */}
                {
                    label &&
                    <styled.Label>
                        {label}
                        {
                            required && <span>*</span>
                        }
                    </styled.Label>
                }

                <Select showSearch={true}
                    onChange={value => onSelectChange(value)}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    defaultValue={value}
                    allowClear={allowClear}
                    className={'multiple-select'}
                    mode={'multiple'}
                    value={value}
                    disabled={disabled ? disabled : false}>
                    {
                        options.map(option =>
                            <Select.Option value={option}
                                key={option}>
                                {option}
                            </Select.Option>
                        )
                    }
                </Select>

                {/** Error */}
                {
                    error &&
                    <styled.Error>
                        {error}
                    </styled.Error>
                }

            </styled.Wrapper>
        );
    };

    const onSelectChange = (value: any) => {
        if (value) {
            onChange(value);
        } else {
            onChange('');
        }
    }

    return render();
};
