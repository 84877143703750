import { colors } from '../../styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    color: ${colors.$darkBlue};
`;

export const Icon = styled.div`
    display: inline-block;
`;
