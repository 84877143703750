import * as React from 'react';
import * as styles from './save-company-modal.style';

import { ICompany, ISaveCompanyReq } from '../../interfaces/company';
import { _resetCompany, _saveCompany, _toggleSaveCompanyModal } from '../../services/companies.state-service';
import { isDate, isEmpty } from 'lodash';

import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IServerError } from '../../../shared/interfaces/shared';
import { ISoa } from '../../../soa/interfaces/soa';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import { ModalHeader } from '../../../shared/components/modal-header/modal-header';
import { Switch } from '../../../shared/components/switch/switch';
import { _getSoas } from '../../../soa/services/soa-state.service';
import { colors } from '../../../shared/styles/colors';
import { useSelector } from 'react-redux';

interface Props {
    company: ICompany;
    error: IServerError | null;
}

export const SaveCompanyModal: React.FC<Props> = (props: Props) => {
    // props
    const { company } = props;
    const isEditMode = !isEmpty(company);

    // store
    const soas = useSelector<AppState, ISoa[]>(state => state.soa.soas);

    // utils
    const getInitialSoa = React.useCallback((isEditMode: boolean, soas: ISoa[]) => {
        if (isEditMode) {
            let soaFound = soas.find(soa => soa.id.toString() === company.soaId.toString());

            return soaFound;
        }

        return {} as ISoa;
    }, [company.soaId]);

    // state
    const [inputs, setInputs] = React.useState({
        name: isEditMode ? company.name : '',
        email: isEditMode ? company.email : '',
        emailDomainSearch: isEditMode ? company.emailDomainSearch : false,
        // emailNotifications: isEditMode ? company.emailNotifications : false,
        // automaticApproval: isEditMode ? company.automaticApproval : false,
    });
    const [soa, setSoa] = React.useState(
        getInitialSoa(isEditMode, soas)
    );
    const [errors, setErrors] = React.useState({
        name: '',
        email: '',
        soa: '',
    });

    const emailPattern = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    const render = () => {
        return (
            <Modal onClose={() => _toggleSaveCompanyModal(false)}>
                <styles.Wrapper>
                    {/** Header */}
                    <ModalHeader text={getModalHeader()} />

                    {/** Name */}
                    <Input label={'Name'}
                        value={inputs.name}
                        error={errors.name}
                        onChange={(name: string) => onInputChange(name, 'name', 'string')} />

                    {/** Email */}
                    {/* <Input label={'Email'}
                        value={inputs.email}
                        type={'email'}
                        error={errors.email}
                        onChange={(email: string) => onInputChange(email, 'email', 'email')} /> */}

                    {/** SOA */}
                    <Dropdown options={soas}
                        keyToShow={'name'}
                        label={'SOA'}
                        value={soa}
                        error={errors.soa}
                        onChange={soa => setSoa(soa)} />

                    {/** Email Domain Search */}
                    <Switch value={inputs.emailDomainSearch}
                        label={'Email Domain Search'}
                        onChange={emailDomainSearch => setInputs({ ...inputs, emailDomainSearch })} />

                    {/** Email Notifications */}
                    {/* <Switch value={inputs.emailNotifications}
                        label={'Email Notifications'}
                        onChange={emailNotifications => setInputs({ ...inputs, emailNotifications })} /> */}

                    {/** Automatic Approval */}
                    {/* <Switch value={inputs.automaticApproval}
                        label={'Automatic Approval'}
                        onChange={automaticApproval => setInputs({ ...inputs, automaticApproval })} /> */}

                    {/** Buttons */}
                    <styles.Buttons>
                        <Button onClick={() => onCreatePress()}
                            color={colors.$success}
                            disabled={isSaveButtonDisabled()}
                            label={'Save'} />

                        <Button onClick={() => onCancelPress()}
                            color={colors.$alert}
                            label={'Cancel'} />
                    </styles.Buttons>

                    {/* {
                        <div>{error && error.description}</div>
                    } */}
                </styles.Wrapper>
            </Modal>
        );
    };

    React.useEffect(() => {
        _getSoas();

        setSoa(getInitialSoa(isEditMode, soas));
    }, []);

    React.useEffect(() => {
        setSoa(
            getInitialSoa(isEditMode, soas)
        );
    }, [soas, getInitialSoa, isEditMode]);

    const onInputChange = (text: string | Date, key: string, type: string) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type);
    };

    const onCreatePress = () => {
        const { name, /* automaticApproval, emailNotifications, */ emailDomainSearch } = inputs;
        const soaId = isEmpty(soa) ? '' : soa!.id.toString();

        let saveReq: ISaveCompanyReq = {
            name,
            soaId,
            // automaticApproval,
            emailDomainSearch,
            // emailNotifications,
        };

        if (isEditMode) {
            saveReq.isEditMode = isEditMode;
            saveReq.id = company.id;
        }

        _saveCompany(saveReq);
    };

    const onCancelPress = () => {
        _toggleSaveCompanyModal(false);
        _resetCompany();
    };

    const isSaveButtonDisabled = () => {
        const { name } = inputs;

        if (!name || isEmpty(soa)) {
            return true;
        }

        return false;
    };

    const getModalHeader = () => {
        if (isEditMode) {
            return `Editing "${company.name}"`;
        }

        return 'Create New Company';
    };

    const handleValidation = (text: string | Date | number, key: string, type: string) => {
        switch (type) {
            case 'date': {
                if (!isDate(text)) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                break;
            }

            case 'email': {
                if (isEmpty(text)) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else if (!emailPattern.test(text.toString())) {
                    setErrors({ ...errors, [key]: 'Incorrect email' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }

                break;
            }

            case 'string': {
                if (isEmpty(text)) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                break;
            }

            default: {
                setErrors({ ...errors, [key]: '' })
            }
        }

    }

    return render();
};
