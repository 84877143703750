import { colors } from '../../../shared/styles/colors';
import { fonts } from '../../../shared/styles/fonts';
import styled from 'styled-components';

export const HeaderTitle = styled.div`
    font-size: 12px;
    font-family: ${fonts.proxima};
    font-weight: 600;
    color: ${colors.$darkBlue};
`;

export const Bold = styled.div`
    font-weight: 600;
`;
