import { IDownloadInvoiceReq, IEditErpOrderLineReq, IEditOrderReq, IGetOrderRequest } from "../interfaces/order";

import { FiltersService } from "../../shared/services/filters.service";
import { IApiTableRequest } from "../../shared/interfaces/shared";
import { IEditErpOrderHeaderRequest } from "../interfaces/edit-erp-order-header-request";
import { IUpdateErpOrderLineRequest } from "../interfaces/update-erp-order-line-request";
import { axios } from "../../shared/services/axios.service";
import download from 'downloadjs';
import { from } from "rxjs";

export class OrdersWebApi {
    public static getOrders(params: IApiTableRequest) {
        const qs = FiltersService.getApiQuerySearch(params);

        return from(
            axios.get(`/ErpOrderHeader?${qs}`)
                .then(res => res.data),
        );
    }

    public static getOrderDetails(id: number) {
        return from(
            axios.get(`ErpOrderHeader/${id}`)
                .then(res => res.data),
        );
    }

    public static getErpOrderInvoices(id: string) {
        return from(
            axios.get(`/ErpInvoice/${id}`)
                .then(res => res.data),
        );
    }

    public static downloadInvoice(req: IDownloadInvoiceReq): any {
        const { customerId, invoiceId } = req;

        return from(
            axios.get(`/ErpInvoice/invoice?customerId=${customerId}&invoiceId=${invoiceId}`, { responseType: 'blob' })
                .then(res => {
                    return download(res.data, `${customerId}_invoice${invoiceId}`);
                }),
        );
    }

    public static getErpPoNumbers(req: string) {
        return from(
            axios.get(`/ErpOrderHeader/poNumber/${req}`)
                .then(res => res.data),
        );
    }

    public static getErpOrderNumbers(req: string) {
        return from(
            axios.get(`ErpOrderHeader/orderNumber/${req}`)
                .then(res => res.data),
        );
    }

    public static editOrder(_req: IEditOrderReq) {
        return from(
            axios.put(`ErpOrderHeader/edit`)
                .then(res => res.data),
        );
    }

    public static editErpOrderLine(req: IEditErpOrderLineReq) {
        return from(
            axios.put(`/ErpOrderLine/updateInDataHub/${req.orderLineId}`, { ...req })
                .then(res => res.data),
        );
    }

    public static editErpOrderHeader(req: IEditErpOrderHeaderRequest) {
        return from(
            axios.put(`/ErpOrderHeader/updateInDataHub/${req.id}`, { ...req })
                .then(res => res.data),
        );
    }

    public static getErpOrderLines(params: IApiTableRequest) {
        const qs = FiltersService.getApiQuerySearch(params);

        return from(
            axios.get(`/ErpOrderLine?${qs}`)
                .then(res => res.data),
        );
    }

    public static updateErpOrderLines(req: IUpdateErpOrderLineRequest) {
        return from(
            axios.put(`/ErpOrderLine/bulkEdition`, { ...req })
                .then(res => res.data),
        );
    }

}
