import * as styles from './gecko-table.style';

import { ExpandableConfig, FilterValue, GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import { IApplyClick, ITableActions } from './gecko-table.interface';
import React, { useCallback, useEffect, useState } from 'react';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router';

import { AppState } from '../../interfaces/app.state';
import { Button } from '../button/button';
import { FILTERS } from '../../../filters/interfaces/filters';
import { FilterMappersService } from '../../../filters/services/filter-mappers.service';
import { GeckoFilters } from '../../../filters/components/gecko-filters/gecko-filters';
import { GeckoTableService } from './gecko-table-service';
import { IApiTableFilter } from '../../interfaces/filters';
import { IApiTableRequest } from '../../interfaces/shared';
import { IPagination } from '../../interfaces/pagination';
import { IRowMenu } from '../../interfaces/row-menu';
import { RowMenu } from '../row-menu/row-menu';
import { SharedStateService } from '../../services/shared.state-service';
import { SlimButton } from '../button/slim-button';
import { SortingOrder } from '../../interfaces/sorting';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';

interface Props {
    columns: any;
    dataSource: IPagination<any>;
    initConfig?: IApiTableRequest;

    /** Filters to be displayed inside the Filters Component */
    filters: FILTERS[];

    /** Unique identifier that is used for URL Matching */
    tableId: string;

    isLoading?: boolean;

    showSorting?: boolean;
    showPagination?: boolean;
    showRowMenu?: boolean;
    showFilters?: boolean;

    actions?: ITableActions;

    rowKey?: string | GetRowKey<any>;
    expandable?: ExpandableConfig<any>;

    rowSelection?: TableRowSelection<any>;

    defaultSortColumn?: string;

    onAddClick?: () => void;
    onExportClick?: () => void;
    onShowDocClick?: () => void;
    rowMenu?: (row: any) => IRowMenu[];
    onRow?: (record: any, index: number | undefined) => any;
    apiCall?: (params: IApiTableRequest) => void;
}

export const GeckoTable: React.FC<Props> = (props: Props) => {
    const { showPagination, dataSource, columns, isLoading, defaultSortColumn, apiCall, filters, onRow, tableId, initConfig, showRowMenu, rowMenu, showSorting, showFilters, actions, onAddClick, expandable, rowKey, onExportClick, rowSelection, onShowDocClick } = props;

    const history = useHistory();
    const location = useLocation();

    const querySearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    const tableQuerySearch = GeckoTableService.getTableRequestFromUrlParamsByTableId(querySearch as any, tableId);

    const [currentPagination, setCurrentPagination] = useState<TablePaginationConfig>({});
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [filterValues, setFilterValues] = useState<IApplyClick>({});

    const [rowHovered, setRowHovered] = useState<any>({});

    const [apiTableRequest, setApiTableRequest] = useState<IApiTableRequest>({} as IApiTableRequest);

    const shouldTableUpdate = useSelector<AppState, boolean>(state => state.shared.shouldTableUpdate);

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.ButtonWrapper>
                    {
                        actions && actions.showDoc &&
                        <SlimButton onClick={() => onShowDocClick && onShowDocClick()}
                            label={'Show Document'} />
                    }

                    {
                        actions && actions.showDoc === false &&
                        <SlimButton onClick={() => onShowDocClick && onShowDocClick()}
                            label={'Hide Document'} />
                    }

                    {
                        actions && actions.refresh &&
                        <SlimButton onClick={() => onRefreshClick()}
                            overrides={{ root: 'margin-left: 5px' }}
                            label={'Refresh'} />
                    }

                    {
                        actions && actions.filter &&
                        <SlimButton overrides={{ root: 'margin-left: 5px' }}
                            label={'Filters'}
                            onClick={() => setShowFiltersModal(true)} />
                    }

                    {
                        actions && actions.add &&
                        <SlimButton overrides={{ root: 'margin-left: 5px' }}
                            label={'Add new'}
                            onClick={() => onAddClick && onAddClick()} />
                    }

                    {
                        actions && actions.export &&
                        <Button overrides={{ root: 'margin-left: 5px' }}
                            label={'Export'}
                            onClick={() => onExportClick && onExportClick()} />
                    }
                </styles.ButtonWrapper>

                <Table dataSource={dataSource.data}
                    // locale={{ emptyText: 'Something went wrong. Please try again later' }}
                    rowClassName={'blue'}
                    loading={isLoading}
                    rowKey={rowKey || 'id'}
                    onChange={handleTableChange}
                    pagination={showPagination ? currentPagination : false}
                    onRow={onGeckoTableRow}
                    expandable={expandable}
                    rowSelection={rowSelection ? ({ ...rowSelection, type: 'checkbox' }) : undefined}
                    columns={[ ...columns, actionsColumn ]} />

                {
                    showFiltersModal &&
                    <GeckoFilters filters={filters}
                        onCloseClick={() => setShowFiltersModal(false)}
                        filterValues={filterValues}
                        onClearClick={onClearClick}
                        onFilterValuesChange={filterValuesChange}
                        onApplyClick={() => onFiltersApplyClick()} />
                }
            </styles.Wrapper>
        );
    };

    // ===== EFFECTS =====

    const initPagination = useCallback(() => {
        /** Load dynamic pagination on table render */
        let pagination: TablePaginationConfig = {
            pageSize: apiTableRequest.pageSize || 20,
            current: apiTableRequest.pageNumber || 1,
            showSizeChanger: false,
            total: dataSource.totalRecords,
        };

        if (showPagination) {
            setCurrentPagination(pagination);
        }
    }, [apiTableRequest, showPagination, dataSource.totalRecords]);

    const actionsColumn = {
        title: '',
        width: 30,
        render: (_text: string, row: any) =>
        <>
            {
                showRowMenu &&
                <styles.RowMenuActions onClick={event => event.stopPropagation()} className='row-menu-actions'>
                    <RowMenu menuItems={(rowMenu && rowMenu(row)) || []} />
                </styles.RowMenuActions>
            }
        </>
    };

    useEffect(() => {
        if (shouldTableUpdate) {
            apiCall && apiCall(apiTableRequest);

            SharedStateService.setShouldTableUpdate(false);
        }
    }, [shouldTableUpdate]);

    useEffect(() => {
        initPagination();
    }, [initPagination]);

    useEffect(() => {
        /** Set table request to keep state updated */
        setApiTableRequest(tableQuerySearch);

        let currentFilterValues: IApplyClick = {};

        currentFilterValues = GeckoTableService.getCurrentFiltersFromArray(tableQuerySearch.filters);

        /** Set Current Filters to set default values on filter modal if there are any */
        setFilterValues(currentFilterValues);

        if (!isEmpty(tableQuerySearch)) {
            apiCall && apiCall(tableQuerySearch);
        } else if (!isEmpty(initConfig)) {
            apiCall && apiCall(initConfig!);
        }

    }, [JSON.stringify(tableQuerySearch)]);

    useEffect(() => {
        if (!isEmpty(apiTableRequest)) {
            SharedStateService.setApiTableRequest(apiTableRequest);
        }
    }, [apiTableRequest]);

    // ===== Handlers =====

    const onGeckoTableRow = (record: any, index: number | undefined) => {
        // create instance of onRow give through props
        const onRowInstance = onRow && onRow(record, index);

        const innerMouseEnter = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (onRowInstance) {
                onRowInstance.onMouseEnter && onRowInstance.onMouseEnter();
            }

            // set currentHoveredRow
            setRowHovered(record);
        };

        const innerMouseLeave = (event: MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (onRowInstance) {
                onRowInstance.onMouseLeave && onRowInstance.onMouseLeave();
            }

            setRowHovered({});
        };

        return {
            ...onRowInstance,
            onMouseEnter: (event: MouseEvent) => innerMouseEnter(event),
            onMouseLeave: (event: MouseEvent) => innerMouseLeave(event),
        };
    };

    const onRefreshClick = () => {
        if (apiCall && apiTableRequest) {
            apiCall(apiTableRequest);
        }
    };

    const filterValuesChange = (key: string, value: string) => {
        const newValues = { ...filterValues, [key]: value };

        if (!value) {
            delete newValues[key];
        }

        setFilterValues(newValues);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        _filters: Record<string, FilterValue | null>,
        sorter: any,
    ) => {
        let sortOrder = GeckoTableService.getSortingOrder(sorter.order);

        let sorting = {
            sortColumn: sortOrder === SortingOrder.None ? (initConfig && initConfig.sortColumn ? initConfig!.sortColumn : 'OrderDate') : sorter.columnKey,
            sortOrder: sortOrder === SortingOrder.None ? 2 : sortOrder,
        };

        const obj: IApiTableRequest = {
            ...sorting,
            pageNumber: pagination.current!,
            pageSize: pagination.pageSize,
            filters: apiTableRequest.filters,
        };

        setApiTableRequest(obj);
        setCurrentPagination(pagination);

        let qs = GeckoTableService.getUrlParamsFromTableRequestByTableId(obj, tableId);

        history.push({
            pathname: location.pathname,
            search: qs,
        });
    };

    const onClearClick = () => {
        setFilterValues({});
    };

    const onFiltersApplyClick = () => {
        /** Transform values to IApiFilterTable */
        let arr: IApiTableFilter[] = [];

        Object.keys(filterValues).forEach(key => {
            if (filterValues[key]) {
                let obj: IApiTableFilter = {
                    key,
                    value: filterValues[key],
                    option: FilterMappersService.optionsMapper[key],
                };

                arr = [...arr, obj];
            }
        });

        let params: IApiTableRequest = {
            sortColumn: apiTableRequest.sortColumn || defaultSortColumn,
            sortOrder: apiTableRequest.sortOrder || SortingOrder.Desc,
            pageNumber: 1,
            pageSize: 20,
            filters: arr,
        };

        setApiTableRequest(params);
        setShowFiltersModal(false);

        let urlParams = GeckoTableService.getUrlParamsFromTableRequestByTableId(params, tableId);

        history.push({
            pathname: location.pathname,
            search: urlParams,
        });
    };

    return render();
};
