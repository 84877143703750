import * as React from 'react';

import { ICurrency } from '../interfaces/currency';
import { ILateCode } from '../interfaces/late-code';

/**
 *
 * @param props Props of the component
 * @param shouldBeAuth if the user should be auth to see the page, shouldBeAuth = true
 * @param pathToRedirect redirect to Path doesn't pass shouldBeAuth condition
 * @param Component component itself ex: LoginPage
 * @param loginResponse object received after logging in
 * Checks on Route mounting if the user is logged in or not and if the user should be allowed to visit the page or not
 */
export function renderPage(
    props: any,
    _shouldBeAuth: boolean,
    _pathToRedirect: string,
    Component: React.FunctionComponent | React.ComponentClass,
    _token: string,
) {
    // let isAuth = token ? true : false;

    return <Component {...props} />;

    // return isAuth === shouldBeAuth ?
    //     <Component {...props} /> : <Redirect to={pathToRedirect} />;
}

export const CURRENCIES: ICurrency[] = [
    { id: 1, name: 'CDN' },
    { id: 2, name: 'USD' },
    { id: 3, name: 'EUR' },
];

export const SITE_IDS: string[] = [
    'GA-CA1',
    'GA-CN1',
    'GA-EU1',
    'GA-MX1',
    'GA-MX4',
    'GA-US1',
];

export const LATE_CODES: ILateCode[] = [{
    key: 'P',
    description: 'PRODUCTION',
}, {
    key: 'M',
    description: 'B/O OR MISSING PARTS',
}, {
    key: 'I',
    description: 'INVENTORY DISCREPANCY',
}, {
    key: 'F',
    description: 'LATE DELIVERY BY SUPPLIER',
}, {
    key: 'S',
    description: 'SHIPPING ERROR OR LATE',
}, {
    key: 'C',
    description: 'CONSOLIDATION',
}, {
    key: 'Q',
    description: 'QUALITY ISSUE',
}, {
    key: 'A',
    description: `CUSTOMER HASN'T PAID`,
}];

export const WEEKDAYS: string[] = ['Any', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const COUNTRY_CODES: string[] = ['USA', 'JP', 'GB', 'ES', 'CA', 'DE', 'IT', 'FR', 'AU', 'TW', 'NL', 'BR', 'TR', 'BE', 'GR', 'IN', 'MX', 'DK', 'AR', 'CH', 'CL', 'AT', 'KR', 'IE', 'CO', 'PL', 'PT', 'PK'];
