import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from 'antd';
import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ICompleteOrder } from '../../interfaces/order';
import { IEditErpOrderHeaderRequest } from '../../interfaces/edit-erp-order-header-request';
import { IShipVia } from '../../../shared/interfaces/ship-via';
import { Input } from '../../../shared/components/input/input';
import Modal from 'antd/lib/modal/Modal';
import { OrdersStateService } from '../../services/orders-state.service';
import { SharedStateService } from '../../../shared/services/shared.state-service';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface Props {
    order: ICompleteOrder;
}

export const EditErpOrderHeaderModal: React.FC<Props> = (props: Props) => {
    const { order } = props;

    const getInitShipVia = () => {
        if (!isEmpty(order)) {
            if (order.shipmentType) {
                let shipViaFound = shipVias.filter(sv => sv.code === order.shipmentType)[0];

                return shipViaFound;
            }
        }

        return {} as IShipVia;
    };

    const fobs = useSelector<AppState, string[]>(state => state.shared.fobs);
    const shipVias = useSelector<AppState, IShipVia[]>(state => state.shared.shipVias);

    const [inputs, setInputs] = useState({
        carrierId: '',
        fob: '',
        shipmentType: '',
    });

    const [poNumber, setPoNumber] = useState('');
    const [orderDate, setOrderDate] = useState(null);
    const [customerRequestedDate, setCustomerRequestedDate] = useState(null);
    const [confirmedDate, setConfirmedDate] = useState(null);
    const [shipVia, setShipVia] = useState(getInitShipVia())

    const render = () => {
        return (
            <Modal visible={true}
                onCancel={() => OrdersStateService.toggleEditErpOrderHeaderModal(false)}
                onOk={onOk}
                footer={[
                    <Button key='cancel'
                        onClick={() => OrdersStateService.toggleEditErpOrderHeaderModal(false)}>
                        Cancel
                    </Button>,
                    <Button key='submit'
                        type='primary'
                        onClick={onOk}
                        disabled={isButtonDisabled()}>
                        Submit
                    </Button>
                ]}
                okButtonProps={{ disabled: isButtonDisabled() }}>

                <Input label={'PO Number'}
                    value={poNumber}
                    onChange={poNumber => setPoNumber(poNumber)} />

                <DateSelector value={orderDate}
                    label={'Order Date'}
                    onChange={date => setOrderDate(date)} />

                <DateSelector value={customerRequestedDate}
                    label={'Customer Requested Date'}
                    onChange={date => setCustomerRequestedDate(date)} />

                <DateSelector value={confirmedDate}
                    label={'Confirmed Date'}
                    onChange={date => setConfirmedDate(date)} />

                <Dropdown options={fobs}
                    keyToShow={''}
                    value={inputs.fob}
                    allowClear={true}
                    label={"FOB"}
                    onChange={(text: string) => setInputs({ ...inputs, fob: text })} />

                <Input label={'Carrier ID'}
                    value={inputs.carrierId}
                    onChange={carrierId => setInputs({ ...inputs, carrierId })} />

                <Dropdown
                    allowClear={true}
                    keyToShow={'code'}
                    value={shipVia}
                    label={'Shipment Type'}
                    options={shipVias}
                    onChange={(sp: IShipVia) => setShipVia(sp)}
                />

            </Modal>
        );
    };

    useEffect(() => {
        SharedStateService.getFobs();
        SharedStateService.getShipVias();
    }, []);

    const onOk = () => {
        let req: IEditErpOrderHeaderRequest = {
            carrierId: inputs.carrierId || null,
            customerId: order.customerId || null,
            poNumber: poNumber || null,
            orderDate: orderDate ? moment(orderDate).format('MM-DD-YYYY') : null,
            customerRequestedDate: customerRequestedDate ? moment(customerRequestedDate).format('MM-DD-YYYY') : null,
            confirmedDate: confirmedDate ? moment(confirmedDate).format('MM-DD-YYYY') : null,
            fob: inputs.fob || null,
            shipmentType: (shipVia && shipVia.code) || null,
            orderId: order.orderId,
            id: order.id,
        };

        OrdersStateService.editErpOrderHeader(req);
    };

    const isButtonDisabled = () => {
        if (!poNumber && !orderDate && !customerRequestedDate && !confirmedDate && !inputs.fob && isEmpty(shipVia)) {
            return true;
        }

        return false;
    };

    return render();
};
