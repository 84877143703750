import { colors } from "../../../shared/styles/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100%;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    align-self: flex-end;
`;

export const DropdownContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    & > div {
        margin-right: 10px;
        color: ${colors.$darkBlue};
    }
`;
