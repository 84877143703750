import * as styles from './order-list-excel-document.style';

import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { IOrderBlob, IOrderList } from '../../interfaces/order-list';
import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';

interface Props {
    blob: IOrderBlob;
    orderList: IOrderList;
}

export const OrderListExcelDocument: React.FC<Props> = (props: Props) => {
    const { blob } = props;

    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const render = () => {
        return (
            <styles.Wrapper>
                {
                    isLoading &&
                    <ActivityIndicator />
                }

                {
                    !isLoading &&
                    <OutTable data={rows}
                        columns={cols}
                        tableClassName='ExcelTable2007'
                        tableHeaderRowClass='heading' />
                }
            </styles.Wrapper>
        );
    };

    useEffect(() => {
        ExcelRenderer(blob.blob, (err: any, resp: any) => {
            if (err) {
                console.log(err);
            } else {
                let formattedRows: any = [];

                resp.rows.forEach((row: any) => {
                    if (row.length) {
                        formattedRows = [...formattedRows, row];
                    }
                });

                setRows(formattedRows);
                setCols(resp.cols);
                setIsLoading(false);
            }
        });
    }, [blob]);

    return render();
};
