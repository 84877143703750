import * as styled from './row-menu.style';

import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRowMenu } from '../../interfaces/row-menu';
import { colors } from '../../styles/colors';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface Props {
    menuItems: IRowMenu[];
}

export const RowMenu: React.FC<Props> = (props: Props) => {
    const { menuItems } = props;

    const [showMenu, setShowMenu] = useState(false);

    const render = () => {
        return (
            <styled.RowMenuWrapper className='row-menu-wrapper' onClick={() => setShowMenu(!showMenu)}>
                {
                    // hover &&
                    <FontAwesomeIcon icon={faEllipsisV}
                        style={{ fontSize: 18, margin: '0 auto' }}
                        color={colors.$blue} />
                }

                {
                    showMenu &&
                    // <styled.Menu onMouseEnter={() => setHover(true)}>
                    <styled.Menu onMouseLeave={() => setShowMenu(false)}>
                        {
                            menuItems.length &&
                            menuItems.map(item =>
                                <styled.MenuItem onClick={event => onClick(item, event)}
                                    hide={item.hide || false}
                                    key={item.label}
                                    disabled={item.disabled ? item.disabled : false}>
                                    {item.label}
                                </styled.MenuItem>
                            )
                        }
                    </styled.Menu>
                }
            </styled.RowMenuWrapper>
        );
    };

    useEffect(() => {
        return () => {
            setShowMenu(false);
        };
    }, []);

    const onClick = (item: IRowMenu, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (item.disabled) {
            return;
        }

        item.onClick && item.onClick();
    };

    return render();
};
