import React, { useEffect, useState } from 'react';

import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IOrderStatus } from '../../../order-list/interfaces/order-list';
import { OrderListService } from '../../../order-list/services/order-list.service';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const StatusFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const getInitialStatus = (currStatus: string) => {
        if (!currStatus) {
            return null;
        }

        let statuses = OrderListService.STATUSES;
        let currentStatus = null;

        for (let status of statuses) {
            if (status.id.toString() === currStatus) {
                currentStatus = status;
                break;
            }
        }

        return currentStatus;
    };

    // State
    const [status, setStatus] = useState(getInitialStatus(initValue));

    const render = () => {
        return (
            <Dropdown label={'Order Status'}
                keyToShow={'name'}
                allowClear={true}
                options={OrderListService.STATUSES}
                onChange={status => onChange(status)}
                value={status} />
        );
    };

    useEffect(() => {
        if (!initValue) {
            setStatus({} as IOrderStatus);
        }
    }, [initValue]);

    const onChange = (status: IOrderStatus) => {
        setStatus(status);
        onInputChange('status', (status.id && status.id.toString()) || '');
    };

    return render();
};
