import * as styles from './erp-shipping-info-line-table.style';

import { ErpShippingInfoLineTableUtils } from './erp-shipping-info-line-table.utils';
import { IErpShipperLine } from '../../interfaces/order';
import React from 'react';
import { Table } from 'antd';

interface Props {
    lines: IErpShipperLine[];
}

export const ErpShippingInfoLineTable: React.FC<Props> = (props: Props) => {
    const { lines } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.Title>
                    Packlist details
                </styles.Title>

                <Table columns={ErpShippingInfoLineTableUtils.columns}
                    dataSource={lines}
                    pagination={false}
                    rowClassName={'blue'}
                    rowKey={'id'} />
            </styles.Wrapper>
        );
    };

    return render();
};
