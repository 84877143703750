import React, { useState } from 'react';

import { Input } from '../../../shared/components/input/input';

interface Props {
    initErpOrderLinesStatus: string;
    onInputChange: (key: string, value: string) => void;
}

export const ErpOrderLinesStatusFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initErpOrderLinesStatus } = props;

    const [status, setStatus] = useState(initErpOrderLinesStatus || '');

    const render = () => {
        return (
            <Input value={status}
                onChange={desc => onChange(desc, 'erpOrderLinesStatus')}
                label={'Status'} />
        );
    };

    const onChange = (status: string, key: string) => {
        setStatus(status);

        onInputChange(key, status);
    };

    return render();
};
