import * as React from 'react';
import * as styled from './modal.style';

import { CLOSE } from '../../assets/icons';
import { Icon } from '../../icon/icon';
import { colors } from '../../styles/colors';

interface Props {
    onClose: () => void;
    children?: React.ReactNode;
    // optional min height for modal content
    minHeight?: string;
}

export const Modal: React.FC<Props> = (props: Props) => {
    const { onClose, children, minHeight } = props;

    return (
        <styled.Wrapper>

            <styled.Content minHeight={minHeight}>
                <styled.Close onClick={() => onClose()}>
                    <Icon d={CLOSE} color={colors.$darkBlue} viewBox={'0 0 26 26'} width={16} height={16} />
                </styled.Close>

                {children}
            </styled.Content>
        </styled.Wrapper>
    );
};
