import * as msal from '@azure/msal-browser';

import { config } from '../../auth/components/auth-provider/config';

// export const config = {
//     appId: '7a948eba-c652-4eae-ac12-83b980c64c41',
//     authority: 'https://login.microsoftonline.com/f193f6f5-62be-492d-897d-776cde125585/',
//     redirectUri: 'http://localhost:3000',
//     // redirectUri: 'https://app-dev.customer.geckoalliance.com',
//     apiUrl: 'https://api-dev.customer.geckoalliance.com',
//     scopes: ['api://e1fa5d4f-3048-4737-a2d2-1c9ab167e129/General.Scope'],
// };
const { b2c } = config;

// const tenant = "geckob2cdev.onmicrosoft.com";
// const signInPolicy = "B2C_1A_SignIn";
// const applicationID = "b3d4b4e5-ca10-4ae8-bafd-68114159b552";
// const reactRedirectUri = "http://localhost:3000";
// const reactRedirectUri = 'https://app-dev.customer.geckoalliance.com';
// const instance = `https://geckob2cdev.b2clogin.com/`;
const signInAuthority = `${b2c.instance}${b2c.tenant}/${b2c.signInPolicy}`;

const msalConfigB2B = {
    auth: {
        clientId: config.appId,
        authority: config.authority,
        redirectUri: config.redirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
        secureCookies: false,
    },
};

const b2cConfig = {
    msalConfigB2C: {
        auth: {
            clientId: config.b2c.applicationID,
            authority: signInAuthority,
            knownAuthorities: [b2c.instance],
            redirectUri: config.redirectUri,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
            secureCookies: false,
        },
    },
    apiConfig: {
        b2cScopes: [],
        webApiUri: config.redirectUri,
    },
    loginRequest: {
        scopes: ['openid', 'offline_access'],
    },
    tokenRequest: {
        scopes: [],
    }
};

const provider = localStorage.getItem('provider');
const isB2C = provider === 'b2c';
const currentProvider = provider === 'b2b' ? msalConfigB2B : b2cConfig.msalConfigB2C;

export const MSAL = new msal.PublicClientApplication(currentProvider);

export const getToken = async () => {
    let account = MSAL.getAllAccounts()[0];

    if (account) {
        const silentRequest = {
            scopes: isB2C ? b2c.scopes : config.scopes,
            account,
            forceRefresh: false,
        };
        const request = {
            scopes: isB2C ? b2c.scopes : config.scopes,
            loginHint: account.username,
        };

        try {
            let tokenResponse = await MSAL.acquireTokenSilent(silentRequest);

            return tokenResponse;
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return MSAL.acquireTokenRedirect(request);
            }
        }
    }

    return null;
};

export const getAccountName = () => MSAL.getAllAccounts()[0];

// MSAL.handleRedirectPromise()
//     .then(tokenResponse => {
//         if (tokenResponse !== null) {
//             // success auth
//         } else {
//             // nope
//         }
//     })
//     .catch(err => {
//         // nope
//     });
