import { Action, IApiTableRequest } from "../interfaces/shared";

import { IPoType } from "../interfaces/po-type";
import { IShipVia } from "../interfaces/ship-via";
import { ISiteId } from "../interfaces/site-id";
import { ISnackbar } from "../interfaces/snackbar";

export const SET_SAMPLE = "SET_SAMPLE";
export const setSample = (sample: string): Action<string> => ({
    type: SET_SAMPLE,
    payload: sample
});

export const GET_PO_TYPES_REQ = 'GET_PO_TYPES_REQ';
export const getPoTypesReq = () => ({
    type: GET_PO_TYPES_REQ,
    payload: null,
});

export const GET_PO_TYPES_OK = 'GET_PO_TYPES_OK';
export const getPoTypesOk = (poTypes: IPoType[]) => ({
    type: GET_PO_TYPES_OK,
    payload: poTypes,
});

export const GET_PO_TYPES_FAIL = 'GET_PO_TYPES_FAIL';
export const getPoTypesFail = (error: string) => ({
    type: GET_PO_TYPES_FAIL,
    payload: error,
});

// ===== SNACKBAR =====

export const SET_SNACKBAR = 'SET_SNACKBAR';
export const setSnackbar = (snack: ISnackbar) => ({
    type: SET_SNACKBAR,
    payload: snack,
});

// ===== SHIP VIA =====

export class SharedActions {
    public static readonly GET_SHIPVIA_REQ = 'GET_SHIPVIA_REQ';
    public static getShipViaReq() {
        return {
            type: this.GET_SHIPVIA_REQ,
            payload: null,
        };
    }

    public static readonly GET_SHIPVIA_OK = 'GET_SHIPVIA_OK';
    public static getShipViaOk(shipVias: IShipVia[]) {
        return {
            type: this.GET_SHIPVIA_OK,
            payload: shipVias,
        };
    }

    public static readonly GET_SHIPVIA_FAIL = 'GET_SHIPVIA_FAIL';
    public static getShipViaFail(error: string) {
        return {
            type: this.GET_SHIPVIA_FAIL,
            payload: error,
        };
    }

    public static readonly SET_SNACKBAR = 'SET_SNACKBAR';
    public static setSnackbar(snack: ISnackbar) {
        return {
            type: SET_SNACKBAR,
            payload: snack,
        };
    }

    public static readonly GET_PO_TYPES_REQ = 'GET_PO_TYPES_REQ';
    public static getPoTypesReq = () => ({
        type: GET_PO_TYPES_REQ,
        payload: null,
    });

    public static readonly GET_PO_TYPES_OK = 'GET_PO_TYPES_OK';
    public static getPoTypesOk = (poTypes: IPoType[]) => ({
        type: GET_PO_TYPES_OK,
        payload: poTypes,
    });

    public static readonly GET_PO_TYPES_FAIL = 'GET_PO_TYPES_FAIL';
    public static getPoTypesFail = (error: string) => ({
        type: GET_PO_TYPES_FAIL,
        payload: error,
    });

    public static readonly SET_FOUND_UNAUTHORIZED_CALL = 'SET_FOUND_UNAUTHORIZED_CALL';
    public static setFoundUnauthorizedCall(flag: boolean) {
        return {
            type: this.SET_FOUND_UNAUTHORIZED_CALL,
            payload: flag,
        };
    }

    public static readonly GET_FOBS_REQ = 'GET_FOBS_REQ';
    public static getFobsReq() {
        return {
            type: this.GET_FOBS_REQ,
            payload: null,
        };
    }

    public static readonly GET_FOBS_OK = 'GET_FOBS_OK';
    public static getFobsOk(fobs: string[]) {
        return {
            type: this.GET_FOBS_OK,
            payload: fobs,
        };
    }

    public static readonly GET_FOBS_FAIL = 'GET_FOBS_FAIL';
    public static getFobsFail(error: string) {
        return {
            type: this.GET_FOBS_FAIL,
            payload: error,
        };
    }

    public static readonly GET_SITE_IDS_REQ = 'GET_SITE_IDS_REQ';
    public static getSiteIdsReq() {
        return {
            type: this.GET_SITE_IDS_REQ,
            payload: null,
        };
    }

    public static readonly GET_SITE_IDS_OK = 'GET_SITE_IDS_OK';
    public static getSiteIdsOk(siteIDs: ISiteId[]) {
        return {
            type: this.GET_SITE_IDS_OK,
            payload: siteIDs,
        };
    }

    public static readonly GET_SITE_IDS_FAIL = "GET_SITE_IDS_FAIL";
    public static getSiteIdsFail(error: string) {
        return {
            type: this.GET_SITE_IDS_FAIL,
            payload: error,
        };
    }

    public static readonly SET_BACKEND_NOT_WORKING = 'SET_BACKEND_NOT_WORKING';
    public static setBackendNotWorking(flag: boolean) {
        return {
            type: this.SET_BACKEND_NOT_WORKING,
            payload: flag,
        };
    }

    public static readonly GET_ALL_PART_IDS_REQ = 'GET_ALL_PART_IDS_REQ';
    public static getAllPartIds() {
        return {
            type: this.GET_ALL_PART_IDS_REQ,
            payload: null,
        };
    }

    public static readonly GET_ALL_PART_IDS_OK = 'GET_ALL_PART_IDS_OK';
    public static getAllPartIdsOk(ids: string[]) {
        return {
            type: this.GET_ALL_PART_IDS_OK,
            payload: ids,
        };
    }

    public static readonly GET_ALL_PART_IDS_FAIL = 'GET_ALL_PART_IDS_FAIL';
    public static getAllPartIdsFail(error: string) {
        return {
            type: this.GET_ALL_PART_IDS_FAIL,
            payload: error,
        };
    }

    public static readonly SET_CURRENT_API_TABLE_REQUEST = 'SET_CURRENT_API_TABLE_REQUEST';
    public static setCurrentApiTableRequest(req: IApiTableRequest) {
        return {
            type: this.SET_CURRENT_API_TABLE_REQUEST,
            payload: req,
        };
    }

    public static readonly SET_SHOULD_TABLE_UPDATE = 'SET_SHOULD_TABLE_UPDATE';
    public static setShouldTableUpdate(flag: boolean) {
        return {
            type: this.SET_SHOULD_TABLE_UPDATE,
            payload: flag,
        };
    }

}
