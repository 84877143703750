import * as React from "react";
import * as div from "./header-tile.style";

import { IHeaderTile } from "./header-tile.interface";
import { useHistory } from "react-router";

interface Props {
    tile: IHeaderTile;
    isActive: boolean;
}

export const HeaderTile: React.FC<Props> = (props: Props) => {
    const { tile, isActive } = props;

    const history = useHistory();

    const render = () => {
        return (
            <div.Wrapper isActive={isActive} onClick={() => onTileClick(tile)}>
                <div.Label disabled={tile.disabled}>{tile.label}</div.Label>
            </div.Wrapper>
        );
    };

    const onTileClick = (tile: IHeaderTile) => {
        if (tile.disabled) {
            return;
        }

        history.push(tile.path);
    };

    return render();
};
