import { Action } from "../../shared/interfaces/shared";
import { IOrderConfirmationState } from "../interfaces/order-confirmation-state";
import { OrdersConfirmationActions } from "./orders-confirmation.actions";
import { ordersConfirmationInitState } from "./orders-confirmation.init-state";

export function ordersConfirmationReducer(state: IOrderConfirmationState = ordersConfirmationInitState, action: Action<any>): IOrderConfirmationState {
    switch (action.type) {
        case OrdersConfirmationActions.GET_ORDER_CONFIRMATION_REQ : {
            return {
                ...state,
                isLoading: true,
            }
        }

        case OrdersConfirmationActions.GET_ORDER_CONFIRMATION_OK: {
            return {
                ...state,
                order: action.payload,
                isLoading: false,
                error: '',
            };
        }

        case OrdersConfirmationActions.GET_ORDER_CONFIRMATION_FAIL: {
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        }

        case OrdersConfirmationActions.TOGGLE_DISPLAY_ORDER_CONFIRMATION_PAGE: {
            return {
                ...state,
                displayPage: action.payload,
            };
        }

        default:
            return state;
    }
}
