import { ISetOrderConfirmation } from "../interfaces/order-confirmation";
import { axios } from "../../shared/services/axios.service";
import { from } from "rxjs";

export class OrdersConfirmationWebApi {
    public static getOrderConfirmation(id: string) {
        return from(
            axios.get(`/CustomerAllocationForecast/${id}`)
                .then(res => res.data),
        );
    }

    public static setOrderConfirmation(request: ISetOrderConfirmation) {
        return from(
            axios.post(`/CustomerAllocationChoice/${request.id}`, JSON.stringify(request.choice))
                .then(res => res.data),
        );
    }

}
