import { AdminPageUtils } from './admin.page.utils';
import { AppState } from '../../shared/interfaces/app.state';
import { Breadcrumbs } from '../../shared/components/breadcrumbs/breadcrumbs';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { IBreadcrumb } from '../../shared/interfaces/shared';
import React from 'react';
import { Subheader } from '../../shared/components/subheader/subheader';
import { adminRoutes } from '../routes/admin.routes';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps {}

export const AdminPage: React.FC<Props> = (azureAd: Props) => {
    const breadcrumbs = useSelector<AppState, IBreadcrumb[]>(state => state.aoe.breadcrumbs);

    const render = () => {
        return (
            <>
                <Subheader tiles={AdminPageUtils.SUBHEADER_TILES} />

                {/** Breadcrumbs */}
                <Breadcrumbs breadcrumbs={breadcrumbs} />

                {adminRoutes(azureAd)}
            </>
        );
    };

    return render();
};
