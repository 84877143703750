import { OrderLineStateService, _setCurrentOrderLine, _toggleOrderLineDeleteModal, _toggleOrderLineSaveModal } from "../services/order-line.state-service";

import { ColumnsType } from "antd/lib/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOrderLine } from "../interfaces/order-line";
import { IOrderList } from "../../order-list/interfaces/order-list";
import React from 'react';
import { colors } from "../../shared/styles/colors";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export class OrderLinePageUtils {
    public static columns = (orderList: IOrderList): ColumnsType<IOrderLine> => [
        {
            title: '',
            render: (_text: string, orderLine: IOrderLine) =>
                <>
                    {
                        orderList?.orderStatus !== 4 &&
                        <>
                            {
                                !!(orderList && orderList.orderStatus === 2 && orderLine.errorsList && orderLine.errorsList.length) &&
                                <FontAwesomeIcon icon={faBell}
                                    color={colors.$warning}
                                    size={'lg'} />
                            }
                        </>
                    }
                </>,
        },
        {
            title: 'No',
            render: (_text: string, _orderLine: IOrderLine, index: number) => index + 1,
        }, {
            title: 'Part ID',
            dataIndex: 'partId',
        }, {
            title: 'Customer Part ID',
            dataIndex: 'customerPartId',
        }, {
            title: 'Part Description',
            dataIndex: 'partDescription',
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
        }, {
            title: 'Revised Quantity',
            dataIndex: 'revisedQuantity',
        }, {
            title: 'Unit Price',
            render: (_text: string, orderLine: IOrderLine) =>
                orderLine.unitPrice ? orderLine.unitPrice : '-',
        }, {
            title: 'Revised Unit Price',
            render: (_text: string, orderLine: IOrderLine) =>
                orderLine.revisedUnitPrice !== null ? orderLine.revisedUnitPrice.toFixed(2) : '-',
        }, {
            title: 'Site ID',
            dataIndex: 'siteId',
        }
    ];

    public static onDeleteClick = (orderLine: IOrderLine) => {
        _toggleOrderLineDeleteModal(true);
        _setCurrentOrderLine(orderLine);
    };

    public static onEditClick = (orderLine: IOrderLine) => {
        _toggleOrderLineSaveModal(true)
        _setCurrentOrderLine(orderLine);
    };

    public static handleView = (orderLine: IOrderLine) => {
        _toggleOrderLineSaveModal(true);
        _setCurrentOrderLine(orderLine);
        OrderLineStateService.toggleViewMode(true);
    };

}
