import styled from 'styled-components';

export const OrderListPageTitleOverrides = `margin-bottom: 0px;`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 60px;
`;

export const Buttons = styled.div`
    width: 200px;
    display: flex;
    margin-right: 10px;
    flex-direction: row;
    justify-content: flex-end;
`;
