import * as React from "react";

import { FAQPage } from "../pages/faq/faq.page";
import { IAzureADFunctionProps } from "react-aad-msal";
import { OrderLinePage } from "../../order-line/pages/order-line.page";
import { OrderListPage } from "../../order-list/pages/order-list.page";
import { Route } from "react-router";

export const aoeRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        // FAQ
        <Route exact={true}
            path='/automated-order-entry/faq'
            key={'faq'}
            render={props => <FAQPage {...props} {...azureAd} />} />,

        // AOE : Purchase Order List
        <Route
            exact={true}
            path="/automated-order-entry/purchaseorder-list"
            key={"orders-list"}
            render={props => <OrderListPage {...props} {...azureAd} isAdmin={false} />}
        />,

        // Order Line from Independent Order List
        <Route exact={true}
            key={'order-lines'}
            path={'/automated-order-entry/purchaseorder-list/:orderListId/purchaseorder-lines'}
            render={props => <OrderLinePage {...props} {...azureAd} />} />,
    ];
};
