import * as styles from './average-allocation.style';

import React from 'react';

interface Props {
    average: number;
}

export const AverageAllocation: React.FC<Props> = (props: Props) => {
    const { average } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.Description>Currently weekly allocation</styles.Description>

                <styles.Value>{average} per week</styles.Value>
            </styles.Wrapper>
        );
    };

    return render();
};
