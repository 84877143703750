import * as React from 'react';

import { Button, Modal } from 'antd';

interface Props {
    // function for confirm button
    onConfirm: () => void;

    // function for deny button
    onClose: () => void;

    // text for confirm button
    confirmationButtonText?: string;

    // text for deny button
    denyButtonText?: string;

    // title for confirmation dialog
    title?: string;

    // loading state
    loading?: boolean;

    // short description of the action
    description?: string;
}

/**
 * @TODO refactor to confirmationModal
 * -- add title, description, onConfirm, onCancel, buttonTexts
 */
export const ConfirmationModal: React.FC<Props> = (props: Props) => {
    const { title, onConfirm, onClose, confirmationButtonText, denyButtonText, description } = props;

    const render = () => {
        return (
            <Modal visible={true} title={title || 'Are you sure you want to delete?'}
                onOk={onConfirm}
                onCancel={onClose}
                centered={true}
                bodyStyle={{ display: description ? 'block' : 'none' }}
                footer={[
                    <Button onClick={() => onClose()}
                        type='primary'
                        style={{ marginRight: 10 }}
                        key='cancel'>
                        {denyButtonText || 'Cancel'}
                    </Button>,

                    <Button onClick={() => onConfirm()}
                        danger={true}
                        key='confirm'>
                        {confirmationButtonText || 'Delete'}
                    </Button>
                ]}>

                {
                    description
                }
            </Modal>
        );
    };

    return render();
};
