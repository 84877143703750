import * as React from "react";
import * as styled from "./input.style";

import TextArea from "antd/lib/input/TextArea";

interface Props {
    value: string | number | null;

    onChange?: (text: string) => void;

    onBlur?: () => void;

    placeholder?: string;

    label?: string;

    type?: string;

    error?: string;
    /** min value for input type number */
    minValue?: string;

    disabled?: boolean;

    required?: boolean;

    isTextArea?: boolean;

    maxLength?: number;

    inputId?: string;

    onInternalError?: (hasError: boolean, key?: string) => void;

    overrides?: {
        root?: string;
    };
}

export const Input: React.FC<Props> = (props: Props) => {
    const { value, label, onChange, placeholder, onBlur, type, error, minValue, disabled, required, isTextArea, overrides, maxLength, onInternalError, inputId } = props;

    const [hasError, setHasError] = React.useState(error ? true : false);
    const [errorDescription, setErrorDescription] = React.useState(error ? error : '');

    const render = () => {
        return (
            <styled.Wrapper overrides={overrides && overrides.root}>
                {/** Label */}
                {
                    label &&
                    <styled.Label>
                        {label}

                        {
                            required && <span>*</span>
                        }
                    </styled.Label>
                }

                {/** Input */}
                {
                    !isTextArea &&
                    <styled.InputValue
                        type={type}
                        value={value !== null ? value : ''}
                        disabled={disabled}
                        min={minValue}
                        hasError={hasError}
                        placeholder={placeholder}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(event.target.value)
                        }
                        onBlur={() => onBlur && onBlur()}
                    />
                }

                {
                    isTextArea &&
                    <TextArea value={value !== null ? value : ''}
                        disabled={disabled}
                        style={{ resize: 'none', minHeight: '46px' }}
                        placeholder={placeholder}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onInputChange(event.target.value)}
                        onBlur={() => onBlur && onBlur()} />
                }

                {/** Error */}
                {
                    hasError &&
                    <styled.Error>
                        {errorDescription}
                    </styled.Error>
                }
            </styled.Wrapper>
        );
    };

    React.useEffect(() => {
        if (error) {
            setErrorDescription(error);
            setHasError(true);
        }
    }, [error]);

    const onInputChange = (value: string) => {
        if (maxLength) {
            if (value.length > maxLength) {
                setHasError(true);
                setErrorDescription(`Max length is ${maxLength} characters`);

                onInternalError && onInternalError(true, inputId);
            } else {
                setHasError(false);
                setErrorDescription('');

                onInternalError && onInternalError(false, inputId);
            }
        }

        onChange && onChange(value);
    };

    return render();
};
