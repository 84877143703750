import * as React from "react";
import * as div from "./subheader-tile.style";

import { IHeaderTile } from "../header-tiles/header-tile.interface";

interface Props {
    tile: IHeaderTile;
}

export const SubheaderTile: React.FunctionComponent<Props> = (props: Props) => {
    const { tile } = props;

    return (
        <div.Wrapper to={tile.path}>
            <div.Text>{tile.label}</div.Text>
        </div.Wrapper>
    );
};
