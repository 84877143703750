import * as React from "react";

import { Text } from "./page-title.style";

interface Props {
    text: string;
    overrides?: string;
}

export const PageTitle: React.FC<Props> = (props: Props) => {
    const { text, overrides } = props;

    return <Text override={overrides && overrides}>{text}</Text>;
};
