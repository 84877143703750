import { colors } from '../../styles/colors';
import { fontSizes } from '../../styles/font-sizes';
import { fonts } from '../../styles/fonts';
import styled from 'styled-components';

export const Wrapper = styled.div`
    font-size: ${fontSizes.medium};
    font-family: ${fonts.proxima};
    line-height: 1.21;
    color: ${colors.$darkBlue};
    font-weight: 600;
    margin-bottom: 20px;
`;

export const BottomBar = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 5px;
    background-color: ${colors.$lightBlue};
`;
