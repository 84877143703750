import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1460px;
`;

export const ButtonWrapper = styled.div`
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 10px;
    margin-right: 10px;
`;

export const RowMenuActions = styled.div`
    width: 30px;
    height: 100%;
    text-align: center;
`;
