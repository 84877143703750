import { ICustomer } from "../interfaces/customer";
import { ICustomersState } from "../interfaces/customers-state";

export const customersInitState: ICustomersState = {
    customer: {} as ICustomer,
    customers: [],

    customersLoading: false,
    customersError: '',

    currentCustomer: {} as ICustomer,

    erpCustomerIds: [],
    erpCustomerIdsLoading: false,

    parsers: [],

    deleteCustomer: {
        response: {} as ICustomer,
        error: '',
    },

    saveCustomer: {
        response: {} as ICustomer,
        error: '',
    },

    modals: {
        save: false,
        delete: false,
    },

    filters: {
        externalCustomerIds: [],
    },
};
