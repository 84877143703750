import { colors } from '../../shared/styles/colors';
import { fonts } from '../../shared/styles/fonts';
import styled from 'styled-components';

export const Section = styled.div`
    margin-top: 100px;
    padding-bottom: 50px;
`;

export const Header = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

export const CustomerName = styled.div`
    font-family: ${fonts.proxima};
    font-size: 14px;
    color: ${colors.$blue};
    line-height: 1.43;
    font-weight: 900;
`;

export const Info = styled.div`
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
`;
