import * as React from 'react';
import * as div from './section-title.style'

import { Theme } from '../../styles/theme';

interface Props {
    text: string;
    description?: string;
}

export const SectionTitle: React.FC<Props> = (props: Props) => {
    const { text, description } = props;

    return (
        <div.Wrapper>
            <div.Text>{text}</div.Text>
            <Theme.Label>{description}</Theme.Label>
        </div.Wrapper>
    );
};
