import { Action } from "../../shared/interfaces/shared";
import { ICompleteOrder } from "../interfaces/order";
import { IOrdersState } from "../interfaces/orders-state";
import { OrdersActions } from "./orders.actions";
import { ordersInitState } from "./orders.init-state";

export function ordersReducer(state: IOrdersState = ordersInitState, action: Action<any>): IOrdersState {
    switch (action.type) {
        case OrdersActions.GET_ORDERS_REQ: {
            return {
                ...state,
                loading: true,
            };
        }

        case OrdersActions.GET_ORDERS_OK: {
            return {
                ...state,
                orders: action.payload.data,
                pagination: action.payload,
                loading: false,
            };
        }

        case OrdersActions.TOGGLE_ORDERS_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }

        case OrdersActions.GET_ORDER_DETAILS_REQ: {
            return {
                ...state,
                loading: true,
            };
        }

        case OrdersActions.GET_ORDER_DETAILS_OK: {
            return {
                ...state,
                currentCompleteOrder: action.payload,
                loading: false,
            };
        }

        case OrdersActions.GET_ORDER_DETAILS_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        case OrdersActions.RESET_CURRENT_COMPLETE_ORDER: {
            return {
                ...state,
                currentCompleteOrder: {} as ICompleteOrder,
            };
        }

        case OrdersActions.GET_ORDER_INVOICES_INFO_OK: {
            return {
                ...state,
                invoices: action.payload,
            };
        }

        case OrdersActions.TOGGLE_EDIT_ORDER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    editOrder: action.payload,
                },
            };
        }

        case OrdersActions.SET_CURRENT_ORDER: {
            return {
                ...state,
                order: action.payload,
            };
        }

        case OrdersActions.TOGGLE_EDIT_ERP_ORDER_LINE: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    editErpOrderLine: action.payload,
                },
            };
        }

        case OrdersActions.SET_CURRENT_ERP_ORDER_LINE: {
            return {
                ...state,
                currentErpOrderLine: action.payload,
            };
        }

        case OrdersActions.TOGGLE_EDIT_ERP_ORDER_HEADER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    editOrderHeader: action.payload,
                },
            };
        }

        case OrdersActions.TOGGLE_ERP_ORDER_LINES_LOADING: {
            return {
                ...state,
                orderLinesLoading: action.payload,
            };
        }

        case OrdersActions.GET_ERP_ORDER_LINES_OK: {
            return {
                ...state,
                orderLines: action.payload,
            };
        }

        case OrdersActions.TOGGLE_BULK_EDIT: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    bulkEdit: action.payload,
                },
            };
        }

        case OrdersActions.TOGGLE_BULK_EDIT_BUTTON: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    bulkEditButton: action.payload,
                },
            };
        }

        case OrdersActions.TOGGLE_BULK_EDIT_MESSAGE: {
            return {
                ...state,
                bulkEditMessage: action.payload,
            };
        }

        case OrdersActions.TOGGLE_CONFIRM_BULK_EDIT_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    confirmBulkEditModal: action.payload,
                },
            };
        }

        default:
            return state;
    }
}
