import styled from 'styled-components';

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    flex: 1;
`;

