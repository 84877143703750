import styled from "styled-components";
import { colors } from "../../../shared/styles/colors";

interface IcolumnProps {
    paddingLeft?: string;
    paddingRight?: string;
}

export const BodyWrapper = styled.div`
    max-width: 100%;
    height: 100%;
    opacity: 0.9;
    padding: 47px 72px 76px;
    background-color: ${colors.$backgroundPage};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
    background-color: ${colors.$white};
    padding: 24px 72px 32px 72px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.$lightBlue};
`;

export const Column = styled.div<IcolumnProps>`
    :not(:first-child) {
        margin-left: 24px;
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: ${props => props.paddingLeft};
    padding-right: ${props => props.paddingRight};
`;
