import { colors } from '../../styles/colors';
import styled from 'styled-components';

interface IMenuItemProps {
    disabled: boolean;
    hide: boolean;
}

export const RowMenuWrapper = styled.div`
    width: 100%;
    z-index: 100;
`;

export const Menu = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    min-width: 170px;
    min-height: 30px;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid ${colors.$blue};
    background-color: ${colors.$white};
`;

export const MenuItem = styled.div<IMenuItemProps>`
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: ${props => props.disabled ? 0.5 : 1};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    ${props => props.hide ? `display: none;` : ''};
`;
