import { colors } from "../../styles/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 40px;
    background-color: ${colors.$white};
    border-bottom: 1px solid ${colors.$lightBlue};
`;
