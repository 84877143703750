import * as React from "react";
import * as div from "./subheader.style";

import { IHeaderTile } from "../header-tiles/header-tile.interface";
import { SubheaderTile } from "./subheader-tile";

interface Props {
    tiles: IHeaderTile[];
}

export const Subheader: React.FunctionComponent<Props> = (props: Props) => {
    const { tiles } = props;

    const [currentTiles, setCurrentTiles] = React.useState(tiles);

    React.useEffect(() => {
        let newTiles = tiles.filter(t => !t.disabled);

        setCurrentTiles(newTiles);
    }, [tiles]);

    const render = () => {
        return (
            <div.Wrapper>
                {currentTiles.length &&
                    currentTiles.map(tile => (
                        <SubheaderTile key={tile.path} tile={tile} />
                    ))}
            </div.Wrapper>
        );
    };

    return render();
};
