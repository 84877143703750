import { ICompany, ISaveCompanyReq } from "../interfaces/company";
import { Observable, from } from "rxjs";

import { FiltersService } from "../../shared/services/filters.service";
import { IApiTableRequest } from "../../shared/interfaces/shared";
import { IFilterObjectData } from "../../order-list/interfaces/order-list";
import { axios } from "../../shared/services/axios.service";

// import axiosClient from 'axios';

export const createCompany = (req: ISaveCompanyReq): Observable<ICompany> =>
    from<Promise<ICompany>>(
        axios.post(`/Company`, { ...req })
            .then(res => res.data),
    );

export const getCompanyById = (id: string): Observable<ICompany> =>
    from<Promise<ICompany>>(
        axios.get(`/Company/${id}`)
            .then(res => res.data),
    );

export const editCompany = (req: ISaveCompanyReq, id: number): Observable<ICompany> => {
    return from<Promise<ICompany>>(
        axios.put(`/Company/${id}`, { ...req })
            .then(res => res.data),
    );
}

export const getCompanies = (req: IApiTableRequest): Observable<ICompany[]> => {
    let qs = FiltersService.getApiQuerySearch(req);

    return from<Promise<ICompany[]>>(
        axios.get(`/Company?${qs}`)
            .then(res => res.data),
    );
}

export const deleteCompany = (company: ICompany): Observable<ICompany> =>
    from<Promise<ICompany>>(
        axios.delete(`/Company/${company.id}`)
            .then(res => res.data),
    );

export const getFilterCompanies = (): Observable<IFilterObjectData[]> =>
    from<Promise<IFilterObjectData[]>>(
        axios.get(`/company/name`)
            .then(res => res.data),
    );

export const getErpCompanies = () =>
    from(
        axios.get('/ErpCustomer/CompanyName')
            .then(res => res.data),
    );
