import { IHeaderTile } from "../../shared/components/header-tiles/header-tile.interface";

export class AdminPageUtils {
    public static readonly SUBHEADER_TILES: IHeaderTile[] = [{
        label: 'SOA/CSR',
        path: '/admin/soa',
        urlPath: 'admin/soa',
    }, {
        label: 'Companies',
        path: '/admin/companies',
        urlPath: 'admin/companies'
    }, {
        label: 'Access',
        path: '/admin/userRoles',
        urlPath: 'admin/userRoles',
    }, {
        label: 'Settings',
        path: '/admin/settings',
        urlPath: 'admin/settings',
    }];
}
