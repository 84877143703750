import * as React from 'react';
import * as styles from './save-customer-modal.style';

import { COUNTRY_CODES, CURRENCIES, WEEKDAYS } from '../../../shared/services/utils.service';
import { ICustomer, IParser, ISaveCustomerReq } from '../../interfaces/customer';
import { _resetCustomer, _saveCustomer, _toggleSaveCustomerModal } from '../../services/customers.state-service';
import { isDate, isEmpty } from 'lodash';

import { Button } from '../../../shared/components/button/button';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { ICurrency } from '../../../shared/interfaces/currency';
import { IPoType } from '../../../shared/interfaces/po-type';
import { ISiteId } from '../../../shared/interfaces/site-id';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import { ModalHeader } from '../../../shared/components/modal-header/modal-header';
import { MultipleDropdown } from '../../../shared/components/dropdown/multiple-dropdown';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { Switch } from '../../../shared/components/switch/switch';
import { Theme } from '../../../shared/styles/theme';
import { colors } from '../../../shared/styles/colors';

interface Props {
    customer: ICustomer;
    error: string;
    poTypes: IPoType[];
    companyId: string;
    parsers: IParser[];
    siteIDs: ISiteId[];
}

export const SaveCustomerModal: React.FC<Props> = (props: Props) => {
    // props
    const { customer, poTypes, parsers, siteIDs } = props;

    // utils
    const isEditMode = !isEmpty(customer);
    const getInitialPoType = React.useCallback((isEditMode: boolean) => {
        if (isEditMode) {
            let poTypeFound = poTypes.find(poType => poType.id.toString() === customer.poTypeId.toString());

            return poTypeFound ? poTypeFound : {} as IPoType;
        }

        // return poTypes[0];
        return {} as IPoType;
    }, [customer.poTypeId]);

    const getInitialCurrency = () => {
        if (!isEmpty(customer) && customer.currency) {
            let foundCurrency = CURRENCIES.find(curr => curr.name === customer.currency);

            return foundCurrency;
        }

        // return CURRENCIES[0];
        return {} as ICurrency;
    };

    const getInitialParser = () => {
        if (!isEmpty(customer) && customer.parserId) {
            let foundParser = parsers.find(p => p.id === customer.parserId);

            return foundParser;
        }

        return {} as IParser;
    };

    const getInitialConsolidationSiteId = () => {
        if (!isEmpty(customer) && customer.consolidationSiteId) {
            let foundConsolidation = siteIDs.find(s => s.siteId === customer.consolidationSiteId);

            return foundConsolidation;
        }

        return {} as ISiteId;
    };

    const getInitialPreferredShipmentDays = () => {
        if (isEditMode && customer.preferredShipmentDays) {
            return customer.preferredShipmentDays.split(',');
        }

        return [ WEEKDAYS[0] ];
    };

    // eslint-disable-next-line
    const emailPattern = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    // state
    const [inputs, setInputs] = React.useState({
        externalCustomerId: !isEmpty(customer) ? customer.externalCustomerId : '',
        siteId: !isEmpty(customer) ? customer.siteId : '',
        email: !isEmpty(customer) ? customer.email : '',
        emailNotifications: isEditMode ? !!customer.emailNotifications : false,
        automaticApproval: isEditMode ? !!customer.automaticApproval : false,
        quebecAverageShippingWorkingDays: isEditMode ? customer.quebecAverageShippingWorkingDays : '',
        mexicoAverageShippingWorkingDays: isEditMode ? customer.mexicoAverageShippingWorkingDays : '',
        shipToCountryCode: isEditMode ? customer.shipToCountryCode : '',
    });
    const [errors, setErrors] = React.useState({
        externalCustomerId: '',
        siteId: '',
        email: '',
    });
    const [poType, setPoType] = React.useState(getInitialPoType(isEditMode));
    const [currency, setCurrency] = React.useState(getInitialCurrency());
    const [parser, setParser] = React.useState(getInitialParser());
    const [consolidationSiteId, setConsolidationSiteId] = React.useState(getInitialConsolidationSiteId());
    const [preferredShipmentDays, setPreferredShipmentDays] = React.useState(getInitialPreferredShipmentDays());

    const isPDF = (poType && poType.name === 'PDF') || false;

    const render = () => {
        return (
            <Modal onClose={() => _toggleSaveCustomerModal(false)}>
                <styles.Wrapper>

                    {/** Header */}
                    <ModalHeader text={'Save customer'} />

                    {/** External Customer ID */}
                    <Input label={'External Customer ID'}
                        value={inputs.externalCustomerId}
                        error={errors.externalCustomerId}
                        onChange={(externalCustomerId: string) => onInputChange(externalCustomerId, 'externalCustomerId', 'string')} />

                    <Dropdown options={COUNTRY_CODES}
                        keyToShow={''}
                        label={'Ship To Country (multiple Customer IDs shipping to different countries)'}
                        value={inputs.shipToCountryCode}
                        allowClear={true}
                        onChange={country => setInputs({ ...inputs, shipToCountryCode: country })} />

                    {/** Site ID */}
                    <Input label={'Site ID'}
                        type={'number'}
                        minValue={'1'}
                        error={errors.siteId}
                        value={inputs.siteId}
                        onChange={(siteId: string) => onInputChange(siteId, 'siteId', 'string')} />

                    {/** Email */}
                    <Input label={'Email'}
                        type={'email'}
                        error={errors.email}
                        value={inputs.email}
                        onChange={(email: string) => onInputChange(email, 'email', 'email')} />

                    {/** PO Types */}
                    <Dropdown options={poTypes}
                        keyToShow={'name'}
                        label={'PO Type'}
                        value={poType}
                        allowClear={true}
                        onChange={poType => setPoType(poType)} />

                    {
                        isPDF &&
                        <Dropdown options={parsers}
                            keyToShow={'label'}
                            label={'Parser'}
                            value={parser}
                            allowClear={true}
                            onChange={parser => setParser(parser)} />
                    }

                    {/** Currency */}
                    <Dropdown options={CURRENCIES}
                        keyToShow={'name'}
                        label={'Currency'}
                        value={currency}
                        allowClear={true}
                        onChange={currency => setCurrency(currency)} />

                    {/** Consolidation Site ID */}
                    <Dropdown options={siteIDs}
                        keyToShow={'siteId'}
                        label={'Consolidation Site ID'}
                        value={consolidationSiteId}
                        allowClear={true}
                        onChange={siteId => setConsolidationSiteId(siteId)} />

                    <Theme.Section>
                        <MultipleDropdown options={WEEKDAYS}
                            allowClear={false}
                            value={preferredShipmentDays}
                            label={'Shipping schedule (best day to ship)'}
                            onChange={days => onChangePreferredShipmentDays(days)} />
                    </Theme.Section>

                    {/** Average shipping working days */}
                    <Theme.Section style={{ marginTop: 20 }}>
                        <SectionTitle text={'Average shipping working days'}
                            description={'For POs with due date instead of ship date'} />

                        <Input type='number'
                            value={inputs.mexicoAverageShippingWorkingDays}
                            label={'Mexico'}
                            onChange={text => setInputs({ ...inputs, mexicoAverageShippingWorkingDays: text })} />

                        <Input type='number'
                            value={inputs.quebecAverageShippingWorkingDays}
                            label={'Quebec'}
                            onChange={text => setInputs({ ...inputs, quebecAverageShippingWorkingDays: text })} />
                    </Theme.Section>

                    {/** Email Notifications */}
                    <Switch value={inputs.emailNotifications}
                        label={'Email Notifications'}
                        onChange={emailNotifications => setInputs({ ...inputs, emailNotifications })} />

                    {/** Automatic Approval */}
                    <Switch value={inputs.automaticApproval}
                        label={'Automatic Approval'}
                        onChange={automaticApproval => setInputs({ ...inputs, automaticApproval })} />

                    {/** Buttons */}
                    <styles.Buttons>
                        <Button onClick={() => onSavePress()}
                            color={colors.$success}
                            disabled={isSaveButtonDisabled()}
                            label={'Save'} />

                        <Button onClick={() => onCancelPress()}
                            color={colors.$alert}
                            label={'Cancel'} />
                    </styles.Buttons>
                </styles.Wrapper>
            </Modal>
        );
    };

    React.useEffect(() => {
        setPoType(getInitialPoType(isEditMode));
    }, [isEditMode, getInitialPoType]);

    /** Private */

    const onInputChange = (text: string | Date, key: string, type: string) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type);
    };

    const handleValidation = (text: string | Date | number, key: string, type: string) => {
        switch (type) {
            case 'date': {
                if (!isDate(text)) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                break;
            }

            case 'string': {
                if (isEmpty(text)) {
                    setErrors({ ...errors, [key]: 'Required' })
                } else {
                    setErrors({ ...errors, [key]: '' })
                }
                break;
            }

            case 'email': {
                if (!emailPattern.test(text.toString())) {
                    setErrors({ ...errors, [key]: 'Invalid email' });
                } else {
                    setErrors({ ...errors, [key]: '' });
                }

                break;
            }

            default: {
                setErrors({ ...errors, [key]: '' })
            }
        }

    }

    const onChangePreferredShipmentDays = (days: string[]) => {
        let latestChoice = days[days.length - 1];

        if (latestChoice === WEEKDAYS[0] || !days.length) {
            setPreferredShipmentDays([ WEEKDAYS[0] ]);
            return;
        }

        if (latestChoice !== WEEKDAYS[0] && days.length !== 5) {
            const index = days.indexOf(WEEKDAYS[0]);
            const copyDays = [...days];

            if (index > -1) {
                copyDays.splice(index, 1);
            }

            setPreferredShipmentDays(copyDays);
            return;
        }

        if (days.length === 5) {
            setPreferredShipmentDays([ WEEKDAYS[0] ]);
            return;
        }

        setPreferredShipmentDays(days);
    };

    const onSavePress = () => {
        const { externalCustomerId, siteId, email, automaticApproval, emailNotifications, quebecAverageShippingWorkingDays, mexicoAverageShippingWorkingDays, shipToCountryCode } = inputs;
        const { companyId } = props;

        const shipmentDays = () => {
            if (!preferredShipmentDays.length || (preferredShipmentDays.length === 1 && preferredShipmentDays[0] === 'Any')) {
                return '';
            }

            return preferredShipmentDays.join(',');
        };

        let saveReq: ISaveCustomerReq = {
            externalCustomerId,
            siteId,
            poTypeId: !isEmpty(poType) ? poType.id : '',
            currency: !isEmpty(currency) ? currency!.name : '',
            companyId,
            automaticApproval,
            emailNotifications,
            email,
            parserId: !isEmpty(parser) ? parser!.id : '',
            consolidationSiteId: !isEmpty(consolidationSiteId) ? consolidationSiteId!.siteId : null,
            quebecAverageShippingWorkingDays,
            mexicoAverageShippingWorkingDays,
            preferredShipmentDays: shipmentDays(),
            shipToCountryCode,
        };

        if (isEditMode) {
            saveReq.isEditMode = isEditMode;
            saveReq.id = customer.id;
        }

        _saveCustomer(saveReq);
    };

    const isSaveButtonDisabled = () => {
        const { siteId, externalCustomerId, email } = inputs;

        if (!siteId || !externalCustomerId || isEmpty(poType) || isEmpty(currency) || !email || errors.email) {
            return true;
        }

        return false;
    };

    const onCancelPress = () => {
        _toggleSaveCustomerModal(false);
        _resetCustomer();
    };

    return render();
};
