import { Button, Modal } from 'antd';
import { IEditOrderReq, IOrder } from '../../interfaces/order';
import React, { useEffect, useState } from 'react';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { OrdersStateService } from '../../services/orders-state.service';

interface Props {
    order: IOrder;
    handleSubmit: (req: IEditOrderReq) => void;
}

export const EditOrderModal: React.FC<Props> = (props: Props) => {
    const { order, handleSubmit } = props;

    const [confirmedDate, setConfirmedDate] = useState(null);
    const [lastShippedDate, setLastShippedDate] = useState(null);
    const [orderDate, setOrderDate] = useState(null);

    const render = () => {
        return (
            <Modal visible={true}
                title={`Updating Order ${order.id}`}
                footer={[
                    <Button key='cancel'
                        onClick={() => OrdersStateService.toggleEditOrderModal(false)}>
                        Cancel
                    </Button>,
                    <Button key='submit'
                        type='primary'
                        onClick={() => onSubmitClick()}>
                        Submit
                    </Button>
                ]}>

                <DateSelector value={confirmedDate}
                    label={'Confirmed Date'}
                    onChange={date => setConfirmedDate(date)} />

                <DateSelector value={lastShippedDate}
                    label={'Last Shipped Date'}
                    onChange={date => setLastShippedDate(date)} />

                <DateSelector value={orderDate}
                    label={'Order Date'}
                    onChange={date => setOrderDate(date)} />

            </Modal>
        );
    };

    useEffect(() => {
        return () => {
            OrdersStateService.setCurrentOrder({} as IOrder);
        };
    }, []);

    const onSubmitClick = () => {
        const req: IEditOrderReq = {
            confirmedDate,
            lastShippedDate,
            orderDate,
        };

        handleSubmit(req);
    };

    return render();
};
