import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-bottom: 50px;
`;

export const Wrapper = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    width: 700px;
    margin: 50px auto;
`;

export const Description = styled.div`
    width: 225px;
    margin-top: 10px;
    text-align: center;
`;
