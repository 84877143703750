import * as axiosLib from 'axios';

import { ICustomer, ISaveCustomerReq } from '../interfaces/customer';
import { Observable, from } from 'rxjs';

import { Config } from '../../config';
import { IFilterObjectData } from '../../order-list/interfaces/order-list';
import { axios } from '../../shared/services/axios.service';

// import axiosClient from 'axios';

export const getCustomersByCompanyName = (name: string): Observable<ICustomer[]> =>
    from<Promise<ICustomer[]>>(
        axios.get<ICustomer[]>(`/Company/${name}/customers`)
            .then(res => res.data),
    );

export const getCustomerById = (id: number | string): Observable<ICustomer> =>
    from<Promise<ICustomer>>(
        axios.get<ICustomer>(`/Customer/${id}`)
            .then(res => res.data),
    );

export const getCustomersByCompanyId = (id: string | number): Observable<ICustomer[]> =>
    from<Promise<ICustomer[]>>(
        axios.get<ICustomer[]>(`/Company/${id}/allCustomers`)
            .then(res => res.data),
    );

export const deleteCustomerById = (customer: ICustomer): Observable<ICustomer> =>
    from<Promise<ICustomer>>(
        axios.delete<ICustomer>(`/Customer/${customer.id}`)
            .then(res => res.data),
    );

export const createCustomer = (req: ISaveCustomerReq): Observable<ICustomer> =>
    from<Promise<ICustomer>>(
        axios.post<ICustomer>(`/Customer`, { ...req })
            .then(res => res.data),
    );

export const editCustomer = (req: ISaveCustomerReq): Observable<ICustomer> =>
    from<Promise<ICustomer>>(
        axios.put<ICustomer>(`/Customer/${req.id}`, { ...req })
            .then(res => res.data),
    );

export const getFilterExternalCustomerIds = (): Observable<IFilterObjectData[]> =>
    from<Promise<IFilterObjectData[]>>(
        axios.get(`/Customer/externalCustomerId`)
            .then(res => res.data),
    );

export const getParsers = () =>
    from(
        axiosLib.default.get(`https://api.docparser.com/v1/parsers?api_key=${Config.API_KEY_PARSER}`)
            .then(res => res.data),
    );

export const getCustomerIdsFromErpCompany = (companyName: string) =>
    from(
        axios.get(`/ErpCustomer/${encodeURIComponent(companyName)}/CustomerIds`)
            .then(res => res.data),
    );

export const getErpCustomerIds = () =>
    from(
        axios.get(`/ErpCustomer/CustomerIds`)
            .then(res => res.data),
    );
