import { INotificationPreference, INotificationPreferenceReq } from "../interfaces/notification-preference";

import { NotificationsActions } from "../state/notifications.actions";
import { store } from "../../shared/services/state.service";

export class NotificationsStateService {
    public static getNotificationChannels() {
        store.dispatch(
            NotificationsActions.getNotificationsChannelsReq(),
        );
    }

    public static getNotificationEvents() {
        store.dispatch(
            NotificationsActions.getNotificationEventsReq(),
        );
    }

    public static getNotificationPreferenceByCustomerId(id: string) {
        store.dispatch(
            NotificationsActions.getPreferencesByCustomerIdReq(id),
        );
    }

    public static setCurrentPreference(preference: INotificationPreference) {
        store.dispatch(
            NotificationsActions.setCurrentPreference(preference),
        );
    }

    public static toggleCurrentPreferenceLoading(flag: boolean) {
        store.dispatch(
            NotificationsActions.toggleCurrentPreferenceLoading(flag),
        );
    }

    public static saveNotificationPreference(req: INotificationPreferenceReq) {
        store.dispatch(
            NotificationsActions.saveNotificationPreferenceReq(req),
        );
    }

}
