import * as actions from '../state/order-line.actions';

import { IOrderLine, ISaveOrderLineReq } from '../interfaces/order-line';

import { store } from "../../shared/services/state.service";

export class OrderLineStateService {
    public static setBreadcrumbOrderLine(orderLine: IOrderLine): void {
        store.dispatch(
            actions.setBreadcrumbOrderLine(orderLine),
        );
    }

    public static toggleViewMode(flag: boolean): void {
        store.dispatch(
            actions.toggleViewMode(flag),
        );
    }
}

export const _toggleOrderLineLoading = (flag: boolean): void => {
    store.dispatch(
        actions.toggleOrderLinesLoading(flag),
    );
};

export const _toggleOrderLineDeleteModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleDeleteOrderLineModal(flag),
    );
};

export const _toggleOrderLineSaveModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleSaveOrderLineModal(flag),
    );
};

export const _getOrderLines = (orderListId: string): void => {
    store.dispatch(
        actions.getOrderLinesReq(orderListId),
    );
};

export const _getBreadcrumbOrderLine = (id: string): void => {
    store.dispatch(
        actions.getBreadcrumbOrderLineReq(id),
    );
};

export const _setCurrentOrderLine = (orderLine: IOrderLine): void => {
    store.dispatch(
        actions.setOrderLine(orderLine),
    );
};

export const _resetCurrentOrderLine = (): void => {
    store.dispatch(
        actions.resetOrderLine(),
    );
};

export const _deleteOrderLine = (orderLine: IOrderLine): void => {
    store.dispatch(
        actions.deleteOrderLineReq(orderLine),
    );
};

export const _saveOrderLine = (orderLine: ISaveOrderLineReq): void => {
    store.dispatch(
        actions.saveOrderLineReq(orderLine),
    );
};

export const _toggleSaveOrderLineLoading = (flag: boolean): void => {
    store.dispatch(
        actions.toggleSaveOrderLineLoading(flag)
    );
};

export const _editOrderLine = (orderLine: IOrderLine): void => {
    store.dispatch(
        actions.editOrderLineReq(orderLine),
    );
};

export const _toggleEditOrderLineLoading = (flag: boolean): void => {
    store.dispatch(
        actions.toggleEditOrderLineLoading(flag)
    );
};

export const _getPartDescriptions = (externalCustomerId: string) => {
    store.dispatch(
        actions.getPartDescriptionsReq(externalCustomerId),
    );
};

export const _getPartIds = (externalCustomerId: string) => {
    store.dispatch(
        actions.getPartIdsReq(externalCustomerId),
    );
};

export const _toggleArchiveOrderModal = (flag: boolean) => {
    store.dispatch(
        actions.toggleArchiveOrderModal(flag),
    );
};
