import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initExpectedDateFrom: string;
    initExpectedDateTo: string;
}

const { Filters } = Theme;

export const ExpectedDateFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initExpectedDateFrom, initExpectedDateTo } = props;

    const getInitFrom = () => {
        if (initExpectedDateFrom) {
            return moment(initExpectedDateFrom);
        }

        return null;
    };

    const getInitTo = () => {
        if (initExpectedDateTo) {
            return moment(initExpectedDateTo);
        }

        return null;
    };

    const [expectedDateFrom, setExpectedDateFrom] = useState(getInitFrom());
    const [expectedDateTo, setExpectedDateTo] = useState(getInitTo());

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Expected Date</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px'>
                        <DateSelector value={expectedDateFrom}
                            label={'From'}
                            onChange={date => onChange(date, 'expectedDateFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <DateSelector value={expectedDateTo}
                            label={'To'}
                            onChange={date => onChange(date, 'expectedDateTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initExpectedDateFrom && !initExpectedDateTo) {
            setExpectedDateFrom(null);
            setExpectedDateTo(null);
        }
    }, [initExpectedDateTo, initExpectedDateFrom]);

    const onChange = (date: Moment, keyValue: string) => {
        if (keyValue === 'expectedDateFrom') {
            setExpectedDateFrom(date);
        }

        if (keyValue === 'expectedDateTo') {
            setExpectedDateTo(date);
        }

        if (date) {
            onInputChange(keyValue, moment(date).format('MM/DD/YYYY'));
        } else {
            onInputChange(keyValue, '');
        }

    };

    return render();
};