import React, { useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { OrdersStateService } from '../../../orders/services/orders-state.service';
import { Search } from '../../../shared/components/search/search';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initOrderId: string;
}

// const { Filters } = Theme;

export const OrderIdFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initOrderId } = props;

    const orderNumbers = useSelector<AppState, string[]>(state => state.filters.erpOrderNumbers);

    const [orderId, setOrderId] = useState(initOrderId || '');

    const render = () => {
        return (
            <Search value={orderId || ''}
                onChange={orderNumber => onChange(orderNumber, 'orderId')}
                onSearch={value => OrdersStateService.getErpOrderNumbers(value)}
                data={orderNumbers}
                showKey={''}
                searchAfter={3}
                label={'Order Number'} />
        );
    };

    const onChange = (orderId: string, keyValue: string) => {
        setOrderId(orderId);
        onInputChange(keyValue, orderId);
    };

    return render();
};
