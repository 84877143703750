import styled from 'styled-components'

interface IFormColumnProps {
    paddingLeft?: string;
    paddingRight?: string;
}
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items : flex-start;
    width: 100%;
    margin-bottom: 5px;
`

export const FormColumn = styled.div<IFormColumnProps>`
    display: flex;
    align-items : center;
    padding-left: ${props => props.paddingLeft};
    padding-right: ${props => props.paddingRight};
    width: 350px;
`;

export const FormSection = styled.div``;

export const OpenFeeModalSection = styled.div`
    position: absolute;
    top: 285px;
    width: 20px;
    right: 0px;
`;
