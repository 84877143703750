import { IOrderLine, ISaveOrderLineReq } from "../interfaces/order-line";
import { Observable, from } from "rxjs";

import { axios } from "../../shared/services/axios.service";

export const getOrderLines = (orderListId: string): Observable<IOrderLine[]> =>
    from<Promise<IOrderLine[]>>(
        axios.get<IOrderLine[]>(`/CustomerPoLine/${orderListId}/poLines`)
            .then(res => res.data),
    );

export const getOrderLineById = (id: string): Observable<IOrderLine> =>
    from<Promise<IOrderLine>>(
        axios.get<IOrderLine>(`/CustomerPoLine/${id}`)
            .then(res => res.data),
    );

export const deleteOrderLine = (orderLine: IOrderLine): Observable<IOrderLine> =>
    from<Promise<IOrderLine>>(
        axios.delete<IOrderLine>(`/CustomerPoLine/${orderLine.id}`)
            .then(res => res.data),
    );

export const createOrderLine = (orderLine: ISaveOrderLineReq): Observable<IOrderLine> =>
    from<Promise<IOrderLine>>(
        axios.post<IOrderLine>(`/CustomerPoLine`, { ...orderLine })
            .then(res => res.data),
    );

export const editOrderLine = (orderLine: IOrderLine): Observable<IOrderLine> =>
    from<Promise<IOrderLine>>(
        axios.put<IOrderLine>(`/CustomerPoLine/${orderLine.id}`, { ...orderLine })
            .then(res => res.data),
    );

export const getPartIds = (externalCustomerId: string): Observable<string[]> =>
    from<Promise<string[]>>(
        axios.get<string[]>(`/Customer/${externalCustomerId}/partId`)
            .then(res => res.data),
    );

export const getPartDescriptions = (externalCustomerId: string): Observable<string[]> =>
    from<Promise<string[]>>(
        axios.get<string[]>(`/Customer/${externalCustomerId}/partDescription`)
            .then(res => res.data),
    );
