import { colors } from '../../styles/colors';
import styled from 'styled-components';

interface IContentProps {
    minHeight?: string;
}

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div<IContentProps>`
    min-width: 600px;
    /* max-width: 500px; */
    min-height: ${props => props.minHeight ? props.minHeight : '300px'};
    max-height: 100%;
    background-color: ${colors.$white};
    padding: 20px;
    border-radius: 4px;
    position: relative;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Close = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
        cursor: pointer;
    }
`;
