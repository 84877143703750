import * as actions from './customers.actions';

import { Action } from "../../shared/interfaces/shared";
import { ICustomer } from '../interfaces/customer';
import { ICustomersState } from "../interfaces/customers-state";
import { customersInitState } from "./customers.init-state";

export function customersReducer(state: ICustomersState = customersInitState, action: Action<any>): ICustomersState {
    switch (action.type) {
        case actions.GET_CUSTOMERS_BY_COMPANY_NAME_OK: {
            return {
                ...state,
                customers: action.payload,
                customersError: '',
            };
        }

        case actions.SET_ERP_CUSTOMER_IDS: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_NAME_FAIL: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: action.payload.title,
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_ID_OK: {
            return {
                ...state,
                customers: action.payload,
                customersLoading: false,
                customersError: '',
            };
        }

        case actions.GET_CUSTOMERS_BY_COMPANY_ID_FAIL: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: action.payload,
            };
        }

        case actions.GET_CUSTOMER_BY_ID_OK: {
            return {
                ...state,
                customer: action.payload,
                customersLoading: false,
                customersError: '',
            };
        }

        case actions.GET_CUSTOMER_BY_ID_FAIL: {
            return {
                ...state,
                customer: {} as ICustomer,
                customersLoading: false,
                customersError: action.payload,
            };
        }

        case actions.RESET_CUSTOMERS: {
            return {
                ...state,
                customers: [],
                customersLoading: false,
                customersError: '',
                currentCustomer: {} as ICustomer,
            };
        }

        case actions.RESET_CUSTOMER: {
            return {
                ...state,
                currentCustomer: customersInitState.currentCustomer,
            };
        }

        case actions.TOGGLE_CUSTOMERS_LOADING: {
            return {
                ...state,
                customersLoading: action.payload,
            };
        }

        case actions.SET_CURRENT_CUSTOMER: {
            return {
                ...state,
                currentCustomer: action.payload,
            };
        }

        case actions.TOGGLE_DELETE_CUSTOMER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    delete: action.payload,
                },
            };
        }

        case actions.DELETE_CUSTOMER_OK: {
            return {
                ...state,
                deleteCustomer: {
                    ...state.deleteCustomer,
                    response: action.payload,
                    error: '',
                },
            };
        }

        case actions.DELETE_CUSTOMER_FAIL: {
            return {
                ...state,
                deleteCustomer: {
                    ...state.deleteCustomer,
                    response: {} as ICustomer,
                    error: action.payload,
                },
            };
        }

        case actions.TOGGLE_SAVE_CUSTOMER_MODAL: {
            return {
                ...state,
                modals: {
                    ...state.modals,
                    save: action.payload,
                },
            };
        }

        case actions.GET_FILTER_EXTERNAL_CUSTOMER_IDS_OK: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    externalCustomerIds: action.payload,
                },
            };
        }

        case actions.GET_PARSERS_OK: {
            return {
                ...state,
                parsers: action.payload,
            };
        }

        case actions.GET_CUSTOMERS_FROM_ERP_COMPANY_OK: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.GET_ERP_CUSTOMER_IDS_OK: {
            return {
                ...state,
                erpCustomerIds: action.payload,
            };
        }

        case actions.TOGGLE_ERP_CUSTOMER_IDS_LOADING: {
            return {
                ...state,
                erpCustomerIdsLoading: action.payload,
            };
        }

        default:
            return state;
    }

}
