import { boxShadows } from '../../../shared/styles/box-shadows';
import { colors } from '../../../shared/styles/colors';
import { fonts } from '../../../shared/styles/fonts';
import styled from 'styled-components';

interface RowColumnProps {
    paddingLeft?: string;
    paddingRight?: string;
}

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    height: 100vh;
    overflow: scroll;
    padding: 70px;
    background-color: ${colors.$white};
    box-shadow: ${boxShadows.snackbar};
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    };
`;

export const FormWrapper = styled.div``;

export const Row = styled.div`
    margin-bottom: 20px;
`;

export const RowTitle = styled.div`
    font-family: ${fonts.proxima};
    font-weight: 500;
    font-size: 16px;
    color: ${colors.$darkBlue};
`;

export const RowBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const RowColumn = styled.div<RowColumnProps>`
    width: 100%;
    padding-right: ${props => props.paddingRight || 0};
    padding-left: ${props => props.paddingLeft || 0};
`;
