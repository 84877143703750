import * as CompaniesService from '../services/companies.state-service';
import * as React from 'react';

import { IApiTableRequest, IBreadcrumb, IServerError } from '../../shared/interfaces/shared';
import { _resetAoeBreadcrumbs, addBreadcrumb } from '../../aoe/services/aoe-state.service';
import { useHistory, useLocation } from 'react-router';

import { AppState } from '../../shared/interfaces/app.state';
import { AuthService } from '../../auth/services/auth.service';
import { BackendError } from '../../shared/components/backend-error/backend-error';
import { ColumnsType } from 'antd/lib/table';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ICompany } from '../interfaces/company';
import { IPagination } from '../../shared/interfaces/pagination';
import { IRowMenu } from '../../shared/interfaces/row-menu';
import { ISoa } from '../../soa/interfaces/soa';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { PageWrapper } from '../../shared/styles/page-wrapper';
import { ROLES } from '../../users/interfaces/roles';
import { SaveCompanyModal } from '../components/save-company-modal/save-company-modal';
import { UsersService } from '../../users/services/users.service';
import { _getSoas } from '../../soa/services/soa-state.service';
import { colors } from '../../shared/styles/colors';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import qs from 'qs';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps { }

export const CompaniesPage: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const location = useLocation();

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    // store
    const companiesLoading = useSelector<AppState, boolean>(state => state.companies.companiesLoading);
    const deleteCompanyModal = useSelector<AppState, boolean>(state => state.companies.modals.delete);
    // const companies = useSelector<AppState, ICompany[]>(state => state.companies.companies);
    const company = useSelector<AppState, ICompany>(state => state.companies.company);
    const saveCompanyModal = useSelector<AppState, boolean>(state => state.companies.modals.save);
    const saveCompanyError = useSelector<AppState, IServerError | null>(state => state.companies.saveCompany.error);
    const soas = useSelector<AppState, ISoa[]>(state => state.soa.soas);
    const soasLoading = useSelector<AppState, boolean>(state => state.soa.getSoasLoading);
    const pagination = useSelector<AppState, IPagination<ICompany>>(state => state.companies.pagination);

    const render = () => {
        return (
            <PageWrapper>
                <PageTitle text={'Companies'} />

                <GeckoTable dataSource={{ ...pagination }}
                    columns={columns(soas)}
                    showRowMenu={true}
                    onRow={onRow}
                    showPagination={true}
                    rowMenu={company => rowMenu(company)}
                    tableId={'companies'}
                    filters={[]}
                    isLoading={companiesLoading || soasLoading}
                    initConfig={initCompaniesTableReq}
                    actions={{ add: true }}
                    onAddClick={() => CompaniesService._toggleSaveCompanyModal(true)}
                    apiCall={params => apiCall(params)} />

                {
                    deleteCompanyModal &&
                    <ConfirmationModal onClose={() => onDeleteModalClose()}
                        onConfirm={() => CompaniesService._deleteCompany(company)} />
                }

                {
                    saveCompanyModal &&
                    <SaveCompanyModal company={company} error={saveCompanyError} />
                }

                <BackendError />
            </PageWrapper>
        );
    };

    const onPageInit = React.useCallback(() => {
        AuthService.checkAuth(history);

        _getSoas();
        _resetAoeBreadcrumbs();
    }, []);

    React.useEffect(() => {
        onPageInit();

        return () => {
            CompaniesService._resetCompaniesModule();
        };
    }, [onPageInit]);

    const initCompaniesTableReq: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        filters: [],
    };

    const apiCall = (params: IApiTableRequest) => {
        CompaniesService._getCompanies(params);
    };

    const onDeleteModalClose = () => {
        CompaniesService._resetCompany();
        CompaniesService._toggleDeleteCompanyModal(false);
    };

    const onRow = (company: ICompany, _rowIndex?: number) => {
        return {
            onClick: () => onTableRowClick(company),
        };
    };

    const onTableRowClick = (company: ICompany) => {
        const path = `/admin/companies/${company.id}/customerIds`;
        const breadcrumb: IBreadcrumb = {
            path,
            label: `Company: ${company.name}`,
        };

        addBreadcrumb(breadcrumb);
        CompaniesService.CompaniesStateService.setBreacrumbCompany(company);

        history.push(path);
    };

    const columns = (soas: ISoa[]): ColumnsType<ICompany> => {
        return [{
            title: 'Name',
            dataIndex: 'name',
            key: 'companies_name',
        }, {
            title: 'SOA',
            // dataIndex: 'soaId',
            render: (_text: string, company: ICompany) =>
                <>
                    {getSoaName(soas, company.soaId)}
                </>,
            key: 'companies_soa',
        }, {
            title: 'Email Domain Search',
            render: (_text: string, company: ICompany) => getIcon(company.emailDomainSearch),
            key: 'companies_emailDomainSearch',
        }];
    };

    const getIcon = (flag: boolean) => {
        if (flag) {
            return (
                <FontAwesomeIcon icon={faCheck}
                    color={colors.$success}
                    size={'lg'} />
            );
        }
    };

    const getSoaName = (soas: ISoa[], soaId: string) => {
        if (soas && soaId) {
            let foundSoa = soas.filter(soa => soa.id.toString() === soaId.toString())[0];

            if (foundSoa) {
                return foundSoa.name;
            }
        }

        return '';
    };

    const rowMenu = (company: ICompany): IRowMenu[] => {
        return [{
            label: 'Update Company',
            onClick: () => onEditPress(company),
        }, {
            label: 'Delete Company',
            onClick: () => onDeletePress(company),
            disabled: !UsersService.authorizeDisplay([ROLES.Admin]),
        }, /**{
            label: 'Configure Prepaid Conditions',
            onClick: () => onConfigurePrepaidConditionsClick(company),
        } */];
    }

    const onDeletePress = (company: ICompany) => {
        CompaniesService._toggleDeleteCompanyModal(true);
        CompaniesService._setCompany(company);
    };

    const onEditPress = (company: ICompany) => {
        CompaniesService._toggleSaveCompanyModal(true);
        CompaniesService._setCompany(company);
    };

    const onConfigurePrepaidConditionsClick = (company: ICompany) => {
        CompaniesService._setCompany(company);

        history.push(`/admin/shipment/${company.id}`);
    };

    return render();
};
