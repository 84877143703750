import { ITokenUserRole } from "../interfaces/token-user-role";
import { ROLES } from "../interfaces/roles";
import { store } from "../../shared/services/state.service";

export class UsersService {
    public static authorizeDisplay(roles: ROLES[]) {
        const currentRole = store.getState().users.tokenUserRole;
        let flag = false;

        roles.forEach(role => {
            if (currentRole && role === currentRole.role) {
                flag = true;
            }
        });

        return flag;
    }

    /**
     * 0 = Active
     * 1 - ValidationInProgress
     * 2 - Rejected
     */
    public static isExternalAccountActive(currentRole: ITokenUserRole | null) {
        if (currentRole) {
            let { role, status } = currentRole;

            if (!role) {
                return false;
            }

            if (status === 1 || status === 2) {
                return false;
            }

            return true;
        } else {
            return false;
        }
    }
}
