import { colors } from '../../shared/styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const OrdersTableIcons = styled.div`
    width: 20px;
    height: 100%;
    text-align: right;
`;

export const RowMenu = styled.div`
    width: 100px;
    height: 100px;
    background-color: red;
`;

export const TotalPrice = styled.div`
    color: ${colors.$success};
`;
