import styled from 'styled-components';

interface ITableContainerWrapper {
    showDoc: boolean;
}

export const OrderLineContainer = styled.div`
    margin-bottom: 50px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
`;

export const PoLinesContainer = styled.div``;

export const TableContainer = styled.div<ITableContainerWrapper>``;
