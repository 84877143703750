import { BodyWrapper, Column, HeaderWrapper } from "./Cards.style";

import { Card } from "./Card";
import { ICardPost } from "../../interfaces/card.interface";
import React from "react";

interface ICardsProps {
    data: ICardPost[];
    isHeader?: boolean;
}

export const Cards: React.FC<ICardsProps> = (props: ICardsProps) => {
    const { data, isHeader } = props;

    if (isHeader) {
        return (
            <HeaderWrapper>
                {(data && data.length) &&
                    data.map((card, index) => (
                        !card.hide &&
                        <Column key={index}>
                            <Card
                                icon={card.icon}
                                description={card.description}
                                title={card.title}
                                url={card.url}
                                isHeaderCard={card.isHeaderCard}
                            />
                        </Column>
                    ))}
            </HeaderWrapper>
        );
    } else {
        return (
            <BodyWrapper>
                {(data && data.length) &&
                    data.map((card, index) => (
                        <Column key={index}>
                            <Card
                                icon={card.icon}
                                description={card.description}
                                title={card.title}
                                url={card.url}
                                isHeaderCard={card.isHeaderCard}
                            />
                        </Column>
                    ))}
            </BodyWrapper>
        );
    }
};
