import { ICompleteOrder, IErpOrderLine } from '../interfaces/order';
import { IErpOrderLinesUpdatePair, IUpdateErpOrderLineRequest } from '../interfaces/update-erp-order-line-request';
import React, { useEffect, useState } from 'react';

import { AppState } from '../../shared/interfaces/app.state';
import { BulkEditButtonModal } from '../components/bulk-edit-button-modal/bulk-edit-button-modal';
import { ConfirmationModal } from '../../shared/components/confirmation-modal/confirmation-modal';
import { ErpOrderLineTableUtils } from '../components/erp-order-line-table/erp-order-line-table.utils';
import { FILTERS } from '../../filters/interfaces/filters';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IApiTableRequest } from '../../shared/interfaces/shared';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { IPagination } from '../../shared/interfaces/pagination';
import { ITokenUserRole } from '../../users/interfaces/token-user-role';
import { OrdersStateService } from '../services/orders-state.service';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { SharedStateService } from '../../shared/services/shared.state-service';
import { SortingOrder } from '../../shared/interfaces/sorting';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Theme } from '../../shared/styles/theme';
import { UpdateErpOrderLinesModal } from '../components/update-erp-order-lines-modal/update-erp-order-lines-modal';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps {}

export const ErpOrderLinesPage: React.FC<Props> = (props: Props) => {
    const order = useSelector<AppState, ICompleteOrder>(state => state.orders.currentCompleteOrder);

    const lines = useSelector<AppState, IPagination<IErpOrderLine>>(state => state.orders.orderLines);
    const bulkEditButton = useSelector<AppState, boolean>(state => state.orders.modals.bulkEditButton);
    const bulkEditModal = useSelector<AppState, boolean>(state => state.orders.modals.bulkEdit);
    const isLoading = useSelector<AppState, boolean>(state => state.orders.orderLinesLoading);
    const allPartIds = useSelector<AppState, string[]>(state => state.shared.allPartIds);
    const confirmBulkEditModal = useSelector<AppState, boolean>(state => state.orders.modals.confirmBulkEditModal);
    const tokenRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const [selectedLines, setSelectedLines] = useState<IErpOrderLine[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [bulkReq, setBulkReq] = useState<IUpdateErpOrderLineRequest>({} as IUpdateErpOrderLineRequest);

    const filters = [FILTERS.PART_ID, FILTERS.PART_DESCRIPTION, FILTERS.ORDER_ID, FILTERS.ERP_COMPANY, FILTERS.ERP_CUSTOMER_ID, FILTERS.EXPECTED_DATE, FILTERS.CONFIRMED_DATE, FILTERS.ERP_ORDER_LINES_STATUS, FILTERS.ERP_SHIPPED_DATE, FILTERS.QTY, FILTERS.SHIPPED_QTY, FILTERS.UNIT_PRICE];
    const initLinesTableConfig: IApiTableRequest = {
        pageSize: 20,
        pageNumber: 1,
        sortColumn: 'ShippedDate',
        sortOrder: SortingOrder.Desc,
        filters: [],
    };

    const render = () => {
        return (
            <Theme.PageWrapper style={{ paddingBottom: 150 }}>
                <SectionTitle text={'ERP Order Lines'} />

                <GeckoTable dataSource={lines}
                    tableId='erpOrderLines'
                    filters={filters}
                    rowSelection={rowSelection()}
                    isLoading={isLoading}
                    actions={{ filter: true, refresh: true }}
                    showPagination={true}
                    initConfig={initLinesTableConfig}
                    apiCall={apiCall}
                    defaultSortColumn={'ShippedDate'}
                    columns={ErpOrderLineTableUtils.extendedOrderLineColumns} />

                {
                    // bulkEditButton &&
                    !!selectedLines.length &&
                    <BulkEditButtonModal />
                }

                {
                    bulkEditModal &&
                    <UpdateErpOrderLinesModal partIds={allPartIds} onUpdate={onUpdateLinesClick} />
                }

                {
                    confirmBulkEditModal &&
                    <ConfirmationModal onClose={() => OrdersStateService.toggleConfirmBulkEditModal(false)}
                        confirmationButtonText={'Update'}
                        onConfirm={() => onSubmitLinesClick(bulkReq)} title={'You are about to update more than 100 lines. Are you sure?'} />
                }
            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        // OrdersStateService.getOrderDetails(190237);
        SharedStateService.getAllPartIds();
    }, []);

    useEffect(() => {
        if (selectedLines.length > 99) {
            OrdersStateService.toggleBulkEditMessage(true);
        } else {
            OrdersStateService.toggleBulkEditMessage(false);
        }
    }, [selectedLines]);

    const apiCall = (params: IApiTableRequest) => {
        OrdersStateService.getErpOrderLines(params);
    };

    const rowSelection = (): TableRowSelection<any> => {
        return {
            onChange: (rowKeys: React.Key[], selectedRows: IErpOrderLine[]) => {
                setSelectedLines(selectedRows);
                setSelectedRowKeys(rowKeys);
            },
            selectedRowKeys,
            preserveSelectedRowKeys: true,
        };
    };

    const onUpdateLinesClick = (req: IUpdateErpOrderLineRequest) => {
        if (selectedLines.length > 99) {
            OrdersStateService.toggleConfirmBulkEditModal(true);
            setBulkReq(req);

            return;
        } else {
            onSubmitLinesClick(req);
        }
    };

    const onSubmitLinesClick = (req: IUpdateErpOrderLineRequest) => {
        let erpOrderLines = selectedLines.map(line => ({ orderId: line.orderId, lineNo: line.lineNo }));

        req = { ...req, erpOrderLines };

        OrdersStateService.updateErpOrderLines(req);
        OrdersStateService.toggleConfirmBulkEditModal(false);
        OrdersStateService.toggleBulkEditModal(false);

        setSelectedLines([]);
        setSelectedRowKeys([]);
    };

    return render();
};
