import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IUpdateErpOrderLineRequest } from '../../interfaces/update-erp-order-line-request';
import { Input } from '../../../shared/components/input/input';
import { OrdersStateService } from '../../services/orders-state.service';
import { Theme } from '../../../shared/styles/theme';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface Props {
    partIds: string[];
    onUpdate: (req: IUpdateErpOrderLineRequest) => void;
}

export const UpdateErpOrderLinesModal: React.FC<Props> = (props: Props) => {
    const { partIds, onUpdate } = props;

    const bulkEditMessage = useSelector<AppState, boolean>(state => state.orders.bulkEditMessage);

    const [inputs, setInputs] = useState({
        partId: '',
        qty: '',
        // status: '',
        confirmedDate: null,
        customerRequestedDate: null,
    });

    const render = () => {
        return (
            <Modal visible={true}
                title={'Bulk Edition - Order Lines'}
                onCancel={() => OrdersStateService.toggleBulkEditModal(false)}
                footer={[
                    <Button key='cancel'
                        onClick={() => OrdersStateService.toggleBulkEditModal(false)}>
                        Cancel
                    </Button>,
                    <Button key='submit'
                        type='primary'
                        onClick={() => onOk()}
                        disabled={isButtonDisabled()}>
                        Update
                    </Button>
                ]}>

                {/** Part ID */}
                <Dropdown options={partIds}
                    label={'Part ID'}
                    keyToShow={''}
                    value={inputs.partId}
                    allowClear={true}
                    onChange={partId => setInputs({ ...inputs, partId })} />

                {/** Status */}
                {/* <Dropdown options={[]}
                    label={'Status'}
                    keyToShow={''}
                    value={inputs.status}
                    allowClear={true}
                    onChange={status => setInputs({ ...inputs, status })} /> */}

                {/** Confirmed Date */}
                <DateSelector value={inputs.confirmedDate}
                    label={'Confirmed Date'}
                    onChange={confirmedDate => setInputs({ ...inputs, confirmedDate })} />

                {/** Customer Requested Date */}
                <DateSelector value={inputs.customerRequestedDate}
                    label={'Expected Date'}
                    onChange={customerRequestedDate => setInputs({ ...inputs, customerRequestedDate })} />

                {/** Quantity */}
                <Input value={inputs.qty}
                    label={'Quantity'}
                    onChange={qty => setInputs({ ...inputs, qty })}
                    type={'number'} />

                {
                    bulkEditMessage &&
                    <Theme.ErrorMessage>You're about to edit over 100 lines</Theme.ErrorMessage>
                }
            </Modal>
        );
    };

    const onOk = () => {
        let req: IUpdateErpOrderLineRequest = {
            erpOrderLines: [],
            partId: inputs.partId,
            qty: inputs.qty ? parseInt(inputs.qty) : null,
            confirmedDate: inputs.confirmedDate ? moment(inputs.confirmedDate).format('MM/DD/YYYY') : '',
            customerRequestedDate: inputs.customerRequestedDate ? moment(inputs.customerRequestedDate).format('MM/DD/YYYY') : '',
        };

        onUpdate(req);
    };

    const isButtonDisabled = () => {
        if (!inputs.partId && !inputs.qty && !inputs.confirmedDate && !inputs.customerRequestedDate) {
            return true;
        }

        return false;
    };

    return render();
};

