import styled from "styled-components";
import { colors } from "../../../shared/styles/colors";
import { fonts } from "../../../shared/styles/fonts";
import { fontSizes } from "../../../shared/styles/font-sizes";

interface CardTitleProps {
    marginBottom: string;
    textAlign?: string;
}

interface CardDescriptionProps {
    height?: string;
    textAlign?: string;
}

export const BodyCard = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 12px;
    background-color: ${colors.$white};
    align-items: center;
`;

export const BackIconWrapper = styled.div`
    width: 100%;
    margin-top: 105px;
    display: flex;
    justify-content: flex-end;
`;

export const BodyCardIcon = styled.img`
    width: 44px;
    height: 44px;
    margin-bottom: 53px;
`;

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeaderCard = styled.div`
    cursor: pointer;
    flex: 1;
    box-sizing: border-box;
    padding: 27px 40px 24px 28px;
    border-radius: 12px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.16);
    background-color: ${colors.$white};
    display: flex;
    align-items: center;
    :hover {
        background-color: ${colors.$backgroundPage};
        box-shadow: none;
    }
`;

export const CardTitle = styled.h1<CardTitleProps>`
    font-family: ${fonts.proxima};

    font-size: ${fontSizes.medium};

    font-weight: bold;
    line-height: 1.21;
    text-align: ${props => props.textAlign};
    color: ${colors.$darkBlue};
    margin-bottom: ${props => props.marginBottom};
    margin-top: 0;
`;

export const CardDescription = styled.p<CardDescriptionProps>`
    font-family: ${fonts.proxima};
    height: ${props => props.height};
    font-size: ${fontSizes.paragraph};
    line-height: 1.43;
    text-align: ${props => props.textAlign};
    color: ${colors.$darkBlue};
    margin: 0;
`;

export const HeaderCardIcon = styled.img`
    width: 28px;
    height: 32px;
    margin-right: 30px;
`;
