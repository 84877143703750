import * as styles from './erp-shipping-info-line-table.style';

import { ColumnsType } from "antd/lib/table";
import { IErpShipperLine } from "../../interfaces/order";
import React from 'react';

export class ErpShippingInfoLineTableUtils {
    public static readonly columns: ColumnsType<IErpShipperLine> = [{
        title: <styles.HeaderTitle>Line</styles.HeaderTitle>,
        dataIndex: 'lineNo',
    }, {
        title: <styles.HeaderTitle>Packlist</styles.HeaderTitle>,
        dataIndex: 'packlistId',
    }, {
        title: <styles.HeaderTitle>Part ID</styles.HeaderTitle>,
        dataIndex: 'partId',
    }, {
        title: <styles.HeaderTitle>Order</styles.HeaderTitle>,
        dataIndex: 'orderId',
    }, {
        title: <styles.HeaderTitle>Qty Ship</styles.HeaderTitle>,
        dataIndex: 'shippedQty',
    }, {
        title: <styles.HeaderTitle>Unit Price</styles.HeaderTitle>,
        dataIndex: 'unitPrice',
    }];
}
