import styled from 'styled-components';

export const EventWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ChannelsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 300px;
`;

export const ChannelWrapper = styled.div``;
