import { colors } from '../../../shared/styles/colors';
import { fonts } from '../../../shared/styles/fonts';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 20px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-family: ${fonts.proxima};
    color: ${colors.$black};
`;

export const HeaderTitle = styled.div`
    font-size: 12px;
    font-family: ${fonts.proxima};
    font-weight: 600;
    color: ${colors.$darkBlue};
`;
