import * as styles from './settings.page.style';

import React, { useEffect, useState } from 'react';

import { AppState } from '../../shared/interfaces/app.state';
import { Button } from '../../shared/components/button/button';
import { ButtonTypes } from '../../shared/components/button/button.utils';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ISetting } from '../interfaces/settings';
import { Input } from '../../shared/components/input/input';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { Theme } from '../../shared/styles/theme';
import { UsersStateService } from '../services/users-state.service';
import { useSelector } from 'react-redux';

interface Props extends IAzureADFunctionProps {}

export const SettingsPage: React.FC<Props> = (_props: Props) => {
    const settings = useSelector<AppState, ISetting[]>(state => state.users.settings);

    const [leadTime, setLeadTime] = useState('10');
    const [consolidation, setConsolidation] = useState('20');

    const render = () => {
        return (
            <Theme.PageWrapper>
                <PageTitle text={'Settings'} />

                <styles.Form>
                    <Input label={'Fix Lead-Time (in working days)'}
                        value={leadTime}
                        type={'number'}
                        onChange={text => setLeadTime(text)} />

                    <Input label={'Transfer Time between QC/MX for consolidation (in working days)'}
                        value={consolidation}
                        type={'number'}
                        onChange={text => setConsolidation(text)} />

                    <Button type={ButtonTypes.PRIMARY}
                        label={'Save'}
                        disabled={isButtonDisabled()}
                        onClick={() => onSaveClick()} />
                </styles.Form>
            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        UsersStateService.getAdminSettings();
    }, []);

    useEffect(() => {
        if (settings.length) {
            settings.forEach(s => {
                if (s.key === 'FixLeadTime') {
                    setLeadTime(s.value);
                }

                if (s.key === 'ExtraDaysForConsolidation') {
                    setConsolidation(s.value);
                }
            });
        }
    }, [settings]);

    const isButtonDisabled = () => {
        if (!leadTime || !consolidation) {
            return true;
        }

        return false;
    };

    const onSaveClick = () => {
        const arr = [{
            id: 1,
            key: 'FixLeadTime',
            value: leadTime,
        }, {
            id: 2,
            key: 'ExtraDaysForConsolidation',
            value: consolidation,
        }];

        UsersStateService.saveAdminSettings(arr);
    };

    return render();
};
