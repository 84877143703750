import { ISoa } from "../../soa/interfaces/soa";

export enum FilterOptions {
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains',
    DoesNotContain = 'DoesNotContain',
    IsEmpty = 'IsEmpty',
    IsNotEmpty = 'IsNotEmpty',
    IsGreaterThan = 'IsGreaterThan',
    IsGreaterThanOrEqualTo = 'IsGreaterThanOrEqualTo',
    IsLessThan = 'IsLessThan',
    IsLessThanOrEqualTo = 'IsLessThanOrEqualTo',
    IsEqualTo = 'IsEqualTo',
    IsNotEqualTo = 'IsNotEqualTo'
}

export interface IApiTableFilter {
    key: string;
    value: string;
    option: FilterOptions;
}

export interface IFilters {
    soas: ISoa[];
}
