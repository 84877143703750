import './index.css';

import { persistor, store } from './shared/services/state.service';

import App from './App';
import { MSAL } from './shared/services/msal';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import { authProvider } from './auth/services/auth-provider.service';
import reportWebVitals from './reportWebVitals';

// import 'antd/dist/antd.css';
// import 'antd/lib/style/themes/default.less';
// import 'antd/dist/antd.less';
// import './antd.less';

/**
 * After the user logs in from microsoft interface,
 * this promise handler will check if the token was created
 * and redirect the user to homepage
 */
MSAL.handleRedirectPromise()
    .then(tokenResponse => {
        if (tokenResponse) {
            window.location.href = '/';
        }

        localStorage.setItem('appLoading', JSON.stringify(false));

        // throw new Error('Hello');
    })
    .catch(err => {
        console.log('++ err from promise', err );
        localStorage.setItem('appLoading', JSON.stringify(false));
    });

ReactDOM.render(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
                <React.StrictMode>
                    <Router>
                        <App />
                    </Router>
                </React.StrictMode>
            {/* </PersistGate> */}
        </Provider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
