import { CURRENCIES } from "../../../shared/services/utils.service";
import { ICompany } from "../../../companies/interfaces/company";
import { ICustomer } from "../../../customers/interfaces/customer";
import { IFilterObjectData } from "../../interfaces/order-list";
import { IFilterSoa } from "../../../soa/interfaces/soa";
import { OrderListService } from "../../services/order-list.service";

export class OrderListFiltersUtils {
    public static getStatusInitValue(paramStatus: string | undefined) {
        if (!paramStatus) {
            return null;
        }

        let statuses = OrderListService.STATUSES;
        let currentStatus = null;

        for (let status of statuses) {
            if (status.id.toString() === paramStatus) {
                currentStatus = status;
                break;
            }
        }

        return currentStatus;
    }

    public static getPoNumberInitValue(paramPoNumber: string | undefined) {
        if (paramPoNumber) {
            return paramPoNumber;
        }

        return '';
    }

    public static getCurrencyInitValue(paramCurrency: string | undefined) {
        if (!paramCurrency) {
            return null;
        }

        let currencies = CURRENCIES;
        let currentCurrency = null;

        for (let currency of currencies) {
            if (currency.name === paramCurrency) {
                currentCurrency = currency;
                break;
            }
        }

        return currentCurrency;
    }

    public static getFilterSoaInitValue(soas: IFilterSoa[], soaId: string | undefined) {
        if (!soas || !soaId) {
            return null;
        }

        let currentSoa = null;

        for (let soa of soas) {
            if (soa.id.toString() === soaId) {
                currentSoa = soa;
                break;
            }
        }

        return currentSoa;
    }

    public static getCompanyInitValue(
        filterCompanies: IFilterObjectData[],
        queryparamsCompany: string | undefined,
        breadcrumbCompany: ICompany,
        isAdmin: boolean | undefined,
    ) {
        let currentCompany = null;

        for (let company of filterCompanies) {
            if (company.id.toString() === queryparamsCompany) {
                currentCompany = company;
                break;
            }

            if (isAdmin && breadcrumbCompany.name === company.value) {
                currentCompany = company;
                break;
            }
        }

        return currentCompany !== null ? currentCompany : {} as IFilterObjectData;
    }

    public static getExternalCustomerIdInitValue(
        filterCustomerIds: IFilterObjectData[],
        queryParamsCustomerId: string | undefined,
        breadcrumbCustomer: ICustomer,
        isAdmin: boolean | undefined,
    ) {
        let currentExternalCustomerId = null;

        for (let externalCustomerId of filterCustomerIds) {
            if (externalCustomerId.id.toString() === queryParamsCustomerId) {
                currentExternalCustomerId = externalCustomerId;
                break;
            }

            if (isAdmin && breadcrumbCustomer.externalCustomerId === externalCustomerId.value) {
                currentExternalCustomerId = externalCustomerId;
                break;
            }
        }

        return currentExternalCustomerId !== null ? currentExternalCustomerId : {} as IFilterObjectData;
    }

}
