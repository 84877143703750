import * as styles from './order-list-html-document.style';

import { IOrderList } from '../../interfaces/order-list';
import React from 'react';

interface Props {
    blob: any;
    orderList: IOrderList;
}

export const OrderListHTMLDocument: React.FC<Props> = (props: Props) => {
    const { blob } = props;

    const newBlob = new Blob([blob.blob], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(newBlob);

    const render = () => {
        return (
            <styles.Wrapper>
                <styles.IFrame src={blobUrl} width={'1920px'} height='600px' />
            </styles.Wrapper>
        );
    };

    return render();
};
