import { IFilterObjectData } from "../../order-list/interfaces/order-list";

export class FiltersActions {
    public static readonly GET_COMPANIES_REQ = 'GET_COMPANIES_REQ';
    public static getCompaniesReq() {
        return {
            type: this.GET_COMPANIES_REQ,
            payload: null,
        };
    }

    public static readonly GET_COMPANIES_OK = 'GET_COMPANIES_OK';
    public static getCompaniesOk(companies: IFilterObjectData[]) {
        return {
            type: this.GET_COMPANIES_OK,
            payload: companies,
        };
    }

    public static readonly GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
    public static getCompaniesFail(error: string) {
        return {
            type: this.GET_COMPANIES_FAIL,
            payload: error,
        };
    }

    public static SET_ORDER_COMPANY = 'SET_ORDER_COMPANY';
    public static setOrderCompany(company: string) {
        return {
            type: this.SET_ORDER_COMPANY,
            payload: company,
        };
    }

    public static SET_ERP_CUSTOMER_IDS = 'SET_ERP_CUSTOMER_IDS';
    public static setErpCustomerIds(ids: string[]) {
        return {
            type: this.SET_ERP_CUSTOMER_IDS,
            payload: ids,
        };
    }

}
