import * as actions from './order-line.actions';
import * as webapi from '../webapi/order-line.webapi';

import { IOrderLine, ISaveOrderLineReq } from '../interfaces/order-line';
import { _getOrderLines, _resetCurrentOrderLine, _toggleEditOrderLineLoading, _toggleOrderLineDeleteModal, _toggleOrderLineLoading, _toggleOrderLineSaveModal, _toggleSaveOrderLineLoading } from "../services/order-line.state-service";
import { catchError, concatMap, map, tap } from "rxjs/operators";

import { Action } from "../../shared/interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { OrderListStateService } from '../../order-list/services/order-list-state.service';
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { _setSnackbar } from '../../shared/services/shared.state-service';
import { of } from "rxjs";
import { store } from '../../shared/services/state.service';

export const getOrderLines$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(actions.GET_ORDER_LINES_REQ).pipe(
        map(action => action.payload!),
        tap(() => _toggleOrderLineLoading(true)),
        concatMap(poHeaderId => webapi.getOrderLines(poHeaderId).pipe(
            tap(() => _toggleOrderLineLoading(false)),
            map(orderLines => actions.getOrderLinesOk(orderLines)),
            catchError(err => of(actions.getOrderLinesFail(err.response && err.response.data))),
        )),
    );

export const deleteOrderLine$ = (action$: ActionsObservable<Action<IOrderLine>>) =>
    action$.ofType(actions.DELETE_ORDER_LINE_REQ).pipe(
        map(action => action.payload!),
        concatMap(orderLine => webapi.deleteOrderLine(orderLine).pipe(
            map(() => {
                _toggleOrderLineDeleteModal(false);
                _resetCurrentOrderLine();
                _getOrderLines(orderLine.headerId.toString());

                const orderListId = store.getState().shared.breadcrumbs.orderList.id;
                OrderListStateService.getBreadcrumbOrderList(orderListId.toString());

                _setSnackbar({
                    type: SnackbarTypes.SUCCESS,
                    message: 'Order Line successfully deleted',
                    isOpen: true,
                });

                return actions.deleteOrderLineOk(orderLine);
            }),
            catchError(err => {
                _resetCurrentOrderLine();
                const error = err.response.data;
                _setSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: error.description || 'There was a problem while deleting Order Line',
                    isOpen: true,
                });

                return of(actions.deleteOrderLineFail(error));
            }),
        )),
    );

export const saveOrderLine$ = (action$: ActionsObservable<Action<ISaveOrderLineReq>>) =>
    action$.ofType(actions.SAVE_ORDER_LINE_REQ).pipe(
        tap(() => _toggleSaveOrderLineLoading(true)),
        map(action => action.payload!),
        concatMap(orderLine =>
            webapi.createOrderLine(orderLine).pipe(
                tap(() => _toggleSaveOrderLineLoading(false)),
                tap(() => _toggleOrderLineSaveModal(false)),
                map(response => {
                    _getOrderLines(orderLine.headerId.toString());

                    _setSnackbar({
                        isOpen: true,
                        message: 'Order Line successfully created',
                        type: SnackbarTypes.SUCCESS,
                    });
                    return actions.saveOrderLineOk(response);
                }),
                catchError(err => {
                    let error = '';

                    if (err && err.response) {
                        error = err.response.data;
                    }

                    _setSnackbar({
                        message: err.description || 'There was a problem while creating Order Line',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(actions.saveOrderLineFail(error))
                })
            )
        )
    );

export const editOrderLine$ = (action$: ActionsObservable<Action<IOrderLine>>) =>
    action$.ofType(actions.EDIT_ORDER_LINE_REQ).pipe(
        tap(() => _toggleEditOrderLineLoading(true)),
        map(action => action.payload!),
        concatMap(orderLine =>
            webapi.editOrderLine(orderLine).pipe(
                tap(() => _toggleEditOrderLineLoading(false)),
                tap(() => _toggleOrderLineSaveModal(false)),
                map(response => {
                    _getOrderLines(orderLine.headerId.toString());
                    _resetCurrentOrderLine();

                    const orderListId = store.getState().shared.breadcrumbs.orderList.id;
                    OrderListStateService.getBreadcrumbOrderList(orderListId.toString());

                    _setSnackbar({
                        isOpen: true,
                        message: 'Order Line successfully edited',
                        type: SnackbarTypes.SUCCESS,
                    });
                    return actions.editOrderLineOk(response);
                }),
                catchError(err => {
                    _resetCurrentOrderLine();
                    let error = err.response.data;
                    _setSnackbar({
                        message: err.description || 'There was a problem while editing Order Line',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(actions.editOrderLineFail(error))
                }),
            )
        )
    );

export const getBreadcrumbOrderLine$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(actions.GET_BREADCRUMB_ORDER_LINE_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => webapi.getOrderLineById(id).pipe(
            map(orderLine => actions.getBreadcrumbOrderLineOk(orderLine)),
            catchError(error => {
                return of(actions.getBreadcrumbOrderLineFail(error.response && error.response.data));
            }),
        )),
    );

export const getPartIds$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(actions.GET_PART_IDS_REQ).pipe(
        map(action => action.payload!),
        concatMap(externalCustomerId => webapi.getPartIds(externalCustomerId).pipe(
            map(partIds => actions.getPartIdsOk(partIds)),
            catchError(error => of(actions.getPartIdsFail(error.response && error.response.data))),
        )),
    );

export const getPartDescriptions$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(actions.GET_PART_DESCRIPTIONS_REQ).pipe(
        map(action => action.payload!),
        concatMap(externalCustomerId => webapi.getPartDescriptions(externalCustomerId).pipe(
            map(partDescriptions => actions.getPartDescriptionsOk(partDescriptions)),
            catchError(err => of(actions.getPartDescriptionsFail(err.response && err.response.data))),
        )),
    );
