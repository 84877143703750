import { colors } from "../../styles/colors";
import { fontSizes } from "../../../shared/styles/font-sizes";
import { fonts } from "../../../shared/styles/fonts";
import styled from "styled-components";

interface WrapperProps {
    isActive: boolean;
}

interface LabelProps {
    disabled: boolean | undefined;
}

export const Wrapper = styled.div<WrapperProps | any>`
    text-decoration: none;
    cursor: pointer;
    position: relative;

    ${props => props.isActive && `
        &:before {
            content: "";
            height: 4px;
            width: 100%;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: ${colors.$darkBlue};
            position: absolute;
            top: 0;
            left: 0;
        }
    `}
`;

export const Label = styled.span<LabelProps>`
    display: block;
    padding: 22px 16px;
    font-size: ${fontSizes.paragraph};
    font-weight: 500;
    color: ${props => !props.disabled ? colors.$darkBlue : colors.$grey};
    text-transform: uppercase;
    transition: 0.4s;

    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    font-family: ${fonts.brandonGrotesque};

    ${Wrapper}:hover & {
        color: ${colors.$tileTextHover};
    }
`;

export const Link = styled.a`
    text-decoration: none;
`;

// ===== UTILS =====
