import * as orderListActions from './order-list.actions'
import * as orderListWebApi from '../webapi/order-list.webapi';

import { Action, IApiTableRequest } from '../../shared/interfaces/shared';
import { IOrderList, IOrderListSaveReq } from '../interfaces/order-list'
import {
    OrderListStateService,
    _resetCurrentOrderList,
    _toggleCreateOrderListLoading,
    _toggleCreateOrderListModal,
    _toggleDeleteOrderListLoading,
    _toggleDeleteOrderListModal,
    _toggleEditOrderListLoading,
    _toggleGetOrderListsLoading,
    _toggleGetOriginalOrderLoading
} from '../services/order-list-state.service';
import { SharedStateService, _setSnackbar } from '../../shared/services/shared.state-service';
import { _getOrderLines, _toggleArchiveOrderModal, _toggleOrderLineSaveModal } from '../../order-line/services/order-line.state-service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { ActionsObservable } from 'redux-observable';
import { AppState } from '../../shared/interfaces/app.state';
import { OrderListWebApi } from '../webapi/order-list.webapi';
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { Store } from 'redux';
import { isObject } from 'lodash';
import { of } from 'rxjs';

export const getOriginalOrder$ = (action$: ActionsObservable<Action<IOrderList>>, _store: Store<AppState>) =>
    action$.ofType(orderListActions.GET_ORIGINAL_ORDER_REQ).pipe(
        tap(() => _toggleGetOriginalOrderLoading(true)),
        map(action => action.payload!),
        concatMap((orderList) =>
            orderListWebApi.getOriginalOrder(orderList).pipe(
                tap(() => _toggleGetOriginalOrderLoading(false)),
                map(response => {
                    _setSnackbar({
                        isOpen: true,
                        message: 'Document successfully downloaded',
                        type: SnackbarTypes.SUCCESS,
                    });
                    return orderListActions.createOrderListOk(response);
                }),
                catchError(error => {
                    _toggleGetOriginalOrderLoading(false);
                    let err = error.response && error.response.data;

                    _setSnackbar({
                        message: err ? err.description : 'An error has occured',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });
                    return of(orderListActions.getOriginalOrderFail(error));
                }),
            )
        )
    );

export const getAllOrderLists$ = (action$: ActionsObservable<Action<IApiTableRequest>>, _store: Store<AppState>) =>
    action$.ofType(orderListActions.GET_ALL_ORDER_LISTS_REQ).pipe(
        tap(() => _toggleGetOrderListsLoading(true)),
        map(action => action.payload!),
        concatMap(req =>
            orderListWebApi.getAllOrderLists(req).pipe(
                tap(() => _toggleGetOrderListsLoading(false)),
                map(orderLists => orderListActions.getAllOrderListsOk(orderLists)),
                catchError(error => {
                    _toggleGetOrderListsLoading(false);
                    return of(orderListActions.getAllOrderListsFail(error));
                }),
            )
        )
    );

// export const getOrderLists$ = (action$: ActionsObservable<Action<IGetOrderListByCustomerId>>, _store: Store<AppState>) =>
//     action$.ofType(orderListActions.GET_ORDER_LISTS_BY_CUSTOMER_ID_REQ).pipe(
//         tap(() => _toggleGetOrderListsLoading(true)),
//         map(action => action.payload!),
//         concatMap(request => {
//             return orderListWebApi.getOrderListsByCustomerId(request).pipe(
//                 tap(() => _toggleGetOrderListsLoading(false)),
//                 map(orderLists => orderListActions.getOrderListsByCustomerIdOk(orderLists)),
//                 catchError(error => {
//                     _toggleGetOrderListsLoading(false);
//                     return of(orderListActions.getOrderListsByCustomerIdFail(error));
//                 }),
//             )
//         }),
//     );

export const createOrderList$ = (action$: ActionsObservable<Action<IOrderListSaveReq>>, _store: Store<AppState>) =>
    action$.ofType(orderListActions.CREATE_ORDER_LIST_REQ).pipe(
        tap(() => _toggleCreateOrderListLoading(true)),
        map(action => action.payload!),
        concatMap(orderList =>
            orderListWebApi.createOrderList(orderList).pipe(
                tap(() => _toggleCreateOrderListLoading(false)),
                tap(() => _toggleCreateOrderListModal(false)),
                map(response => {
                    // @TODO EDIT WITH DYNAMIC pagination
                    OrderListStateService.getAllOrderLists({
                        pageNumber: 1,
                        pageSize: 20,
                        filters: [],
                    });
                    _setSnackbar({
                        isOpen: true,
                        message: 'Order List successfully created',
                        type: SnackbarTypes.SUCCESS,
                    });

                    return orderListActions.createOrderListOk(response);
                }),
                catchError(error => {
                    let err = error.response && error.response.data;

                    _setSnackbar({
                        message: err ? err.description : 'An error has occured. Try again later',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });

                    return of(orderListActions.createOrderListFail(err));
                })
            )
        )
    );

export const editOrderList$ = (action$: ActionsObservable<Action<IOrderListSaveReq>>, _store: Store<AppState>) =>
    action$.ofType(orderListActions.EDIT_ORDER_LIST_REQ).pipe(
        tap(() => _toggleEditOrderListLoading(true)),
        map(action => action.payload!),
        concatMap(orderList =>
            orderListWebApi.editOrderList(orderList).pipe(
                tap(() => _toggleEditOrderListLoading(false)),
                tap(() => _toggleCreateOrderListModal(false)),
                tap(() => _resetCurrentOrderList()),
                map(response => {
                    // @TODO EDIT WITH DYNAMIC PAGINATION
                    // orderList.getAllOrderList ? OrderListStateService.getAllOrderLists({
                    //     pageNumber: 1,
                    //     pageSize: 20,
                    // }) : OrderListStateService.getAllOrderLists({
                    //     customerId: orderList.customerId,
                    //     pageNumber: 1,
                    //     pageSize: 20,
                    // });

                    OrderListStateService.setBreadcrumbOrderList(response);

                    _setSnackbar({
                        isOpen: true,
                        message: 'Order List successfully edited',
                        type: SnackbarTypes.SUCCESS,
                    });

                    return orderListActions.editOrderListOk(response);
                }),
                catchError(error => {
                    let err = error.response && error.response.data;

                    _setSnackbar({
                        message: err ? err.description : 'An error has occured. Try again later',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });

                    return of(orderListActions.editOrderListFail(err));
                })
            )
        )
    );

export const deleteOrderListById$ = (action$: ActionsObservable<Action<IOrderList>>, _store: Store<AppState>) =>
    action$.ofType(orderListActions.DELETE_ORDER_LIST_BY_ID_REQ).pipe(
        tap(() => _toggleDeleteOrderListLoading(true)),
        map(action => action.payload!),
        concatMap(orderList =>
            orderListWebApi.deleteOrderList(orderList).pipe(
                tap(() => _toggleDeleteOrderListLoading(false)),
                tap(() => _toggleDeleteOrderListModal(false)),
                map(response => {
                    // @TODO EDIT WITH DYNAMIC PAGINATION
                    OrderListStateService.getAllOrderLists({
                        pageNumber: 1,
                        pageSize: 20,
                        filters: [],
                    });

                    _setSnackbar({
                        isOpen: true,
                        message: 'Order List successfully deleted',
                        type: SnackbarTypes.SUCCESS,
                    });

                    return orderListActions.deleteOrderListByIdOK(response);
                }),
                catchError(error => {
                    let err = error.response && error.response.data;

                    _setSnackbar({
                        message: err ? err.description : 'An error has occured. Try again later',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });

                    return of(orderListActions.deleteOrderListByIdFail(err));
                })
            )
        )
    );

export const getBreadcrumbOrderList$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(orderListActions.GET_BREADCRUMB_ORDER_LIST_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrderListWebApi.getOrderListById(id).pipe(
            map(orderList => orderListActions.getBreadcrumbOrderListOk(orderList)),
            catchError(err => of(orderListActions.getBreadcrumbOrderListFail(err.response && err.response.data))),
        )),
    );

export const confirmCustomerPurchaseOrder$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(orderListActions.OrderListActions.CONFIRM_CUSTOMER_ORDER_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrderListWebApi.confirmCustomerOrder(id).pipe(
            map(() => {
                _setSnackbar({
                    isOpen: true,
                    type: SnackbarTypes.SUCCESS,
                    message: 'PO sent successfully to DataHub',
                });

                OrderListStateService.getBreadcrumbOrderList(id.toString());

                return orderListActions.OrderListActions.confirmCustomerOrderOk();
            }),
            catchError(err => {
                let error = '';

                if (err && err.response && isObject(err.response.data)) {
                    error = err.response.data.description;
                } else {
                    error = err.response.data;
                }

                _setSnackbar({
                    isOpen: true,
                    type: SnackbarTypes.ERROR,
                    message: error || 'Please try again later or contact your administrator',
                });

                OrderListStateService.getBreadcrumbOrderList(id.toString());
                _getOrderLines(id.toString());

                return of(orderListActions.OrderListActions.confirmCustomerOrderFail(err.response && err.response.data));
            }),
        )),
    );

export const validateOrderList$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(orderListActions.OrderListActions.VALIDATE_ORDER_LIST_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrderListWebApi.validateOrderList(id).pipe(
            map(response => {
                OrderListStateService.getBreadcrumbOrderList(id);
                OrderListStateService.toggleValidationLoading(false);
                _toggleCreateOrderListModal(false);
                _toggleOrderLineSaveModal(false);
                _getOrderLines(id);

                return orderListActions.OrderListActions.validateOrderListOk(response);
            }),
            catchError(err => {
                _setSnackbar({
                    isOpen: true,
                    type: SnackbarTypes.ERROR,
                    message: 'Could not validate PO Header',
                });

                return of(orderListActions.OrderListActions.validateOrderListError(err.response && err.response.data))
            }),
        )),
    );

export const getFilterPoNumbers$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(orderListActions.OrderListActions.GET_FILTER_PO_NUMBERS_REQ).pipe(
        map(action => action.payload!),
        concatMap(text => OrderListWebApi.getFilterPoNumbers(text).pipe(
            map(poNumbers => orderListActions.OrderListActions.getFilterPoNumbersOk(poNumbers)),
            catchError(err => of(orderListActions.OrderListActions.getFilterPoNumbersFail(err.response && err.response.data))),
        )),
    );

export const getOrderBlob$ = (action$: ActionsObservable<Action<IOrderList>>) =>
    action$.ofType(orderListActions.OrderListActions.GET_ORDER_BLOB_REQ).pipe(
        map(action => action.payload!),
        concatMap(orderList => OrderListWebApi.getOrderBlob(orderList).pipe(
            map(blob => orderListActions.OrderListActions.getOrderBlobOk(blob)),
            catchError(err => of(orderListActions.OrderListActions.getOrderBlobFail(err.response && err.response.data))),
        )),
    );

export const archivePo$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(orderListActions.OrderListActions.ARCHIVE_PO_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrderListWebApi.archivePo(id).pipe(
            map(() => {
                OrderListStateService.getBreadcrumbOrderList(id.toString());

                _toggleArchiveOrderModal(false);
                SharedStateService.setShouldTableUpdate(true);

                _setSnackbar({
                    isOpen: true,
                    message: 'PO was successfully archived',
                    type: SnackbarTypes.SUCCESS,
                });

                return orderListActions.OrderListActions.archivePoOk();
            }),
            catchError(err => {
                _setSnackbar({
                    isOpen: true,
                    message: 'Could not archive PO',
                    type: SnackbarTypes.ERROR,
                });

                return of(orderListActions.OrderListActions.archivePoFail(err.response && err.response.data))
            }),
        )),
    );

export const getShippingFees$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(orderListActions.OrderListActions.GET_SHIPPING_FEES_BY_ORDER_ID_REQ).pipe(
        map(action => action.payload!),
        tap(() => OrderListStateService.toggleLoadingShippingFees(true)),
        concatMap(id => OrderListWebApi.getShippingFees(id).pipe(
            tap(() => OrderListStateService.toggleLoadingShippingFees(false)),
            map(fees => orderListActions.OrderListActions.getShippingFeedByOrderIdOk(fees)),
            catchError(err => of(orderListActions.OrderListActions.getShippingFeedByOrderIdFail(err.response && err.response.data))),
        )),
    );
