import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { OrderListStateService } from '../../../order-list/services/order-list-state.service';
import { Search } from '../../../shared/components/search/search';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const PoNumberFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const poNumbers = useSelector<AppState, string[]>(state => state.filters.poNumbers);

    const getInitialPoNumber = (currPoNumber: string) => {
        if (!currPoNumber) {
            return null;
        }

        return currPoNumber;
    };

    // State
    const [poNumber, setPoNumber] = useState(getInitialPoNumber(initValue));

    const render = () => {
        return (
            <Search value={poNumber || ''}
                onChange={poNumber => onChange(poNumber)}
                onSearch={value => OrderListStateService.getFilterPoNumber(value)}
                data={poNumbers}
                showKey={''}
                searchAfter={3}
                label={'PO Number'} />
        );
    };

    useEffect(() => {
        if (!initValue) {
            setPoNumber('');
        }
    }, [initValue]);

    const onChange = (poNumber: string) => {
        setPoNumber(poNumber);
        onInputChange('poNumber', poNumber);
    };

    return render();
};
