import { ISOAState } from '../interfaces/soa-state';
import { ISoa } from '../interfaces/soa';

export const soaInitState: ISOAState = {
    deleteSoaModal: false,
    createSoaModal: false,
    getSoasLoading: false,
    soas: [] as ISoa[],
    createSoa: {
        loading: false,
        response: '',
        error: '',
    },
    deleteSoa: {
        deletedSoa: {} as ISoa,
        loading: false,
        response: '',
        error: ''
    },
    editSoa: {
        currentSoa: {} as ISoa,
        loading: false,
        response: '',
        error: ''
    }
};
