import * as React from "react";
import * as div from "./header-tiles.style";

import { RouteComponentProps, withRouter } from "react-router";

import { HeaderTile } from "./header-tile";
import { IHeaderTile } from "./header-tile.interface";
import { isEmpty } from "lodash";

interface Props extends RouteComponentProps {
    tiles: IHeaderTile[];
    activeTile: IHeaderTile;
}

const _HeaderTiles: React.FunctionComponent<Props> = (props: Props) => {
    const { tiles, activeTile } = props;

    const getActiveStatus = (tile: IHeaderTile) => {
        if (isEmpty(activeTile)) {
            return false;
        }

        if (activeTile.urlPath === tile.urlPath) {
            return true;
        }

        return false;
    };

    return (
        <div.Wrapper>
            {
                tiles.length &&
                tiles.map(tile => <HeaderTile tile={tile} key={tile.label}
                    isActive={getActiveStatus(tile)} />)
            }
        </div.Wrapper>
    );
};

export const HeaderTiles = withRouter<Props, React.ComponentType<Props>>(
    _HeaderTiles
);
