import { ButtonWrapper, FormWrapper } from './create-soa-modal.style';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { Button } from '../../../shared/components/button/button';
import { ISoa } from '../../interfaces/soa';
import { ISoaFormValues } from '../../interfaces/soa-form-values';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import React from 'react';
import { createSoaFormConstants as consts } from './create-soa-modal.constants';
import { isEmpty } from 'lodash'

interface Props {
    onSubmit: (values: ISoaFormValues) => void;

    onSubmitEdit?: (values: ISoa) => void;

    onClose: () => void;
    /** initial values for edit mode */
    currentSOA?: ISoa;
    /** modal loading */
    loading?: boolean;

    isEditMode?: boolean;
}

/** Create SOA Modal */
export const CreateSoaModal: React.FC<Props> = (props: Props) => {
    const { onSubmit, onSubmitEdit, onClose, currentSOA, loading, isEditMode } = props;

    // eslint-disable-next-line
    const emailPattern = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    /** fields values */
    const [inputs, setInputs] = React.useState({
        name: (currentSOA && currentSOA.name) || '',
        soaEmail: (currentSOA && currentSOA.soaEmail) || '',
        groupId: (currentSOA && currentSOA.groupId) || 1,
        phoneNumber: (currentSOA && currentSOA.phoneNumber) || '',
    });

    const [errors, setErrors] = React.useState({
        name: '',
        soaEmail: '',
        groupId: '',
        phoneNumber: '',
    });

    const handleValidation = (text: string, key: string) => {
        if (isEmpty(text)) {
            setErrors({ ...errors, [key]: 'Required' })
        } else if (key === 'soaEmail' && !emailPattern.test(text)) {
            setErrors({ ...errors, [key]: 'Invalid email' })
        } else {
            setErrors({ ...errors, [key]: '' })
        }
    }

    const onInputChange = (text: string, key: string) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key)
    };

    const isSaveButtonDisabled = () => {
        const { name, soaEmail, groupId } = inputs;

        if (!name || !soaEmail || !groupId) {
            return true;
        }

        return false;
    };

    const handleSubmit = () => {
        if (isEditMode && currentSOA && onSubmitEdit) {
            const editValues = {
                ...inputs,
                id: currentSOA && currentSOA.id,
                companies: currentSOA && [...currentSOA.companies]
            }
            onSubmitEdit(editValues)
        } else {
            onSubmit(inputs)
        }

    }

    if (loading) {
        return (
            <Modal onClose={() => onClose()} >
                <ActivityIndicator />
            </Modal>
        )
    } else {
        return (
            <Modal onClose={() => onClose()}>
                <FormWrapper>
                    <Input
                        value={inputs.name}
                        label={consts.name}
                        error={errors.name}
                        onChange={(text: string) => onInputChange(text, 'name')}
                    />

                    <Input
                        value={inputs.soaEmail}
                        label={consts.soa_email}
                        error={errors.soaEmail}
                        onChange={(text: string) => onInputChange(text, 'soaEmail')}
                    />

                    <Input value={inputs.phoneNumber}
                        label={'Phone Number'}
                        error={errors.phoneNumber}
                        onChange={(text: string) => onInputChange(text, 'phoneNumber')}
                    />

                    {/* <Input
                        value={inputs.groupId}
                        type="number"
                        minValue="0"
                        label={consts.group_id}
                        error={errors.groupId}
                        onChange={(text: string) => onInputChange(text, 'groupId')}
                    /> */}
                    <ButtonWrapper>
                        <Button disabled={isSaveButtonDisabled()} label={'Submit'} onClick={handleSubmit} />
                    </ButtonWrapper>
                </FormWrapper>
            </Modal>
        );
    }
};
