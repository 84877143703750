export interface IOrderConfirmation {
    customerName: string;
    allocationInfo: IAllocationInfo[];
}

export interface IAllocationInfo {
    id: number;
    categoryName: string;
    allocation: number;
    allocationAverage: number;
    weeklyAllocation: IWeeklyAllocation[];
}

export interface IWeeklyAllocation {
    id: number;
    year: number;
    weekNo: number;
    allocation: number;
    expirationDate: string;
}

export enum OrderConfirmationChoice {
    CONFIRMED = 'confirm',
    DECREASE = 'decrease',
}

export interface ISetOrderConfirmation {
    id: string;
    choice: OrderConfirmationChoice;
}
