import * as styles from './erp-order-lines-table.style';

import { ColumnsType } from "antd/lib/table";
import { DateService } from '../../../shared/services/date.service';
import { IErpOrderLine } from "../../interfaces/order";
import React from 'react';

export class ErpOrderLineTableUtils {
    public static orderLineColumns: ColumnsType<IErpOrderLine> = [{
        title: <styles.HeaderTitle>Part ID</styles.HeaderTitle>,
        key: 'orderDetails_partId',
        dataIndex: 'partId',
    }, {
        title: <styles.HeaderTitle>Part Description</styles.HeaderTitle>,
        key: 'orderDetails_partDescription',
        dataIndex: 'partDescription',
    }, {
        title: <styles.HeaderTitle>Expected Date</styles.HeaderTitle>,
        key: 'orderDetails_expectedDate',
        dataIndex: 'customerRequestedDate',
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.customerRequestedDate)
    }, {
        title: <styles.HeaderTitle>Confirmed Date</styles.HeaderTitle>,
        key: 'confirmedDate',
        dataIndex: 'confirmedDate',
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.confirmedDate),
    }, {
        title: <styles.HeaderTitle>Ln Clsd Short</styles.HeaderTitle>,
        key: 'orderDetails_statusCode',
        dataIndex: 'statusCode',
    }, {
        title: <styles.HeaderTitle>Shipped Date</styles.HeaderTitle>,
        key: 'orderDetails_shippedDate',
        dataIndex: 'shippedDate',
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.shippedDate),
    }, {
        title: <styles.HeaderTitle>QTY</styles.HeaderTitle>,
        key: 'orderDetails_qty',
        dataIndex: 'qty',
        render: (_text: string, orderLine: IErpOrderLine) =>
            orderLine.id === 0 ? <styles.Bold>{orderLine.qty}</styles.Bold> : orderLine.qty
    }, {
        title: <styles.HeaderTitle>Shipped QTY</styles.HeaderTitle>,
        key: 'orderDetails_shippedQty',
        dataIndex: 'shippedQty',
    }, {
        title: <styles.HeaderTitle>BO</styles.HeaderTitle>,
        key: 'orderDetails_bo',
        dataIndex: 'bo',
        render: (_text: string, orderLine: IErpOrderLine) =>
            orderLine.id !== 0 && !orderLine.bo ? '-' : orderLine.bo,
    }, {
        title: <styles.HeaderTitle>Unit Price</styles.HeaderTitle>,
        key: 'orderDetails_unitPrice',
        dataIndex: 'unitPrice',
    }];

    public static extendedOrderLineColumns: ColumnsType<IErpOrderLine> = [{
        title: <styles.HeaderTitle>Part ID</styles.HeaderTitle>,
        key: 'partId',
        dataIndex: 'partId',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Part Description</styles.HeaderTitle>,
        key: 'partDescription',
        dataIndex: 'partDescription',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Order Number</styles.HeaderTitle>,
        key: 'orderId',
        dataIndex: 'orderId',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Company Name</styles.HeaderTitle>,
        key: 'customerName',
        dataIndex: 'customerName',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Customer ID</styles.HeaderTitle>,
        key: 'customerId',
        dataIndex: 'customerId',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Expected Date</styles.HeaderTitle>,
        key: 'customerRequestedDate',
        dataIndex: 'customerRequestedDate',
        sorter: true,
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.customerRequestedDate)
    }, {
        title: <styles.HeaderTitle>Confirmed Date</styles.HeaderTitle>,
        key: 'confirmedDate',
        dataIndex: 'confirmedDate',
        sorter: true,
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.confirmedDate),
    }, {
        title: <styles.HeaderTitle>Ln Clsd Short</styles.HeaderTitle>,
        key: 'statusCode',
        dataIndex: 'statusCode',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>Shipped Date</styles.HeaderTitle>,
        key: 'shippedDate',
        dataIndex: 'shippedDate',
        sorter: true,
        render: (_text: string, order: IErpOrderLine) =>
            DateService.formatDate(order.shippedDate),
    }, {
        title: <styles.HeaderTitle>QTY</styles.HeaderTitle>,
        key: 'qty',
        dataIndex: 'qty',
        sorter: true,
        render: (_text: string, orderLine: IErpOrderLine) =>
            orderLine.id === 0 ? <styles.Bold>{orderLine.qty}</styles.Bold> : orderLine.qty
    }, {
        title: <styles.HeaderTitle>Shipped QTY</styles.HeaderTitle>,
        key: 'shippedQty',
        dataIndex: 'shippedQty',
        sorter: true,
    }, {
        title: <styles.HeaderTitle>BO</styles.HeaderTitle>,
        key: 'bo',
        dataIndex: 'bo',
        sorter: true,
        render: (_text: string, orderLine: IErpOrderLine) =>
            orderLine.id !== 0 && !orderLine.bo ? '-' : orderLine.bo,
    }, {
        title: <styles.HeaderTitle>Unit Price</styles.HeaderTitle>,
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        sorter: true,
    }];
}
