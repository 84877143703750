import { Reducer, combineReducers } from 'redux';

import { AppState } from '../interfaces/app.state';
import { aoeReducer } from '../../aoe/state/aoe.reducer';
import { companiesReducer } from '../../companies/state/companies.reducer';
import { customersReducer } from '../../customers/state/customers.reducer';
import { filtersReducer } from '../../filters/state/filters.reducer';
import { notificationsReducer } from '../../notifications/state/notifications.reducer';
import { orderLineReducer } from '../../order-line/state/order-line.reducer';
import { orderListReducer } from '../../order-list/state/order-list.reducer'
import { ordersConfirmationReducer } from '../../orders-confirmation/state/orders-confirmation.reducer';
import { ordersReducer } from '../../orders/state/orders.reducer';
import { sharedReducer } from './shared.reducer';
import { soaReducer } from '../../soa/state/soa.reducer';
import { usersReducer } from '../../users/state/users.reducer';

export const appReducer: Reducer<AppState> = combineReducers({
    aoe: aoeReducer,
    companies: companiesReducer,
    customers: customersReducer,
    filters: filtersReducer,
    notifications: notificationsReducer,
    orderList: orderListReducer,
    orderLine: orderLineReducer,
    orders: ordersReducer,
    ordersConfirmation: ordersConfirmationReducer,
    shared: sharedReducer,
    users: usersReducer,
    soa: soaReducer,
});
