import { colors } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import styled from 'styled-components';

interface InputProps {
    hasError: boolean;
    disabled: boolean;
}

interface WrapperProps {
    hasError: boolean;
    overrides?: string;
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    position: relative;
    ${props => props.hasError && `
            .ant-select-selector {
                border-color: ${colors.$alert} !important;
            }
        `
    }
    ${props => props.overrides};
`;

export const InputValue = styled.div<InputProps>`
    height: 52px;
    border-radius: 4px;
    font-family: ${fonts.arial};
    font-size: 14px;
    color: ${colors.$darkBlue};
    background-color: ${props => getBackgroundColor(props)};
    outline: none;
    padding-left: 16px;
    line-height: 1.14;
    transition: 0.4s;
    border-color: ${props => getInputBorderColor(props)};
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${colors.$blue};

    &:hover, &:focus {
        background-color: ${colors.$white};
        box-shadow: 0 3px 20px 0 rgba(101, 152, 184, 0.3);
    }
`;
    /* border: 1px solid ${props => getInputBorderColor(props)}; */

export const Options = styled.div`
    position: absolute;
    top: 70px;
    z-index: 1000;
    width: 100%;
    cursor: pointer;
    border: 1px solid ${colors.$blue};
    background-color: ${colors.$white};
    max-height: 300px;
    overflow: scroll;
`;

export const Option = styled.div`
    height: 52px;
    font-family: ${fonts.arial};
    font-size: 14px;
    color: ${colors.$darkBlue};
    outline: none;
    line-height: 1.14;
    transition: 0.4s;
    border-bottom: 1px solid ${colors.$blue};
    padding-left: 16px;
    display: flex;
    align-items: center;
`;


export const Label = styled.div`
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$darkBlue};
    line-height: 1.17;
    margin-bottom: 5px;
`;

export const Error = styled.div`
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    font-family: ${fonts.proxima};
    color: ${colors.$alert};
    line-height: 1.17;
`;

// ===== UTILS =====

function getInputBorderColor(props: InputProps) {
    if (props.hasError) {
        return colors.$alert;
    }

    return colors.$blue;
}

function getBackgroundColor(props: InputProps) {
    if (props.disabled) {
        return colors.$disabled;
    }

    return 'inherit';
}
