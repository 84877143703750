import * as customersActions from './customers.actions';
import * as customersWebApi from '../webapi/customers.webapi';

import { CustomersStateService, _getCustomersByCompanyId, _resetCustomer, _toggleCustomersLoading, _toggleDeleteCustomerModal, _toggleSaveCustomerModal } from '../services/customers.state-service';
import { ICustomer, ISaveCustomerReq } from '../interfaces/customer';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { Action } from '../../shared/interfaces/shared';
import { ActionsObservable } from 'redux-observable';
import { AppState } from '../../shared/interfaces/app.state';
import { SnackbarTypes } from '../../shared/components/snackbar/snackback.utils';
import { Store } from 'redux';
import { _setSnackbar } from '../../shared/services/shared.state-service';
import { of } from 'rxjs';

export const getCustomersByCompanyNameName$ = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(customersActions.GET_CUSTOMERS_BY_COMPANY_NAME_REQ).pipe(
        tap(() => _toggleCustomersLoading(true)),
        map(action => action.payload!),
        concatMap(companyName => customersWebApi.getCustomersByCompanyName(companyName).pipe(
            tap(() => _toggleCustomersLoading(false)),
            map(customers => customersActions.getCustomersByCompanyNameOk(customers)),
            catchError(error => of(customersActions.getCustomersByCompanyNameFail(error.response.data))),
        )),
    );

export const getCustomersByCompanyId$ = (action$: ActionsObservable<Action<string | number>>) =>
    action$.ofType(customersActions.GET_CUSTOMERS_BY_COMPANY_ID_REQ).pipe(
        tap(() => _toggleCustomersLoading(true)),
        map(action => action.payload!),
        concatMap(companyId => customersWebApi.getCustomersByCompanyId(companyId).pipe(
            tap(() => _toggleCustomersLoading(false)),
            map(customers => customersActions.getCustomersByCompanyIdOk(customers)),
            catchError(error => of(customersActions.getCustomersByCompanyIdFail(error))),
        )),
    );

export const getCustomerById$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(customersActions.GET_CUSTOMER_BY_ID_REQ).pipe(
        map(action => action.payload!),
        concatMap(customerId => customersWebApi.getCustomerById(customerId).pipe(
            tap(() => _toggleCustomersLoading(false)),
            map(customers => customersActions.getCustomerByIdOk(customers)),
            catchError(error => of(customersActions.getCustomerByIdFail(error))),
        )),
    );

export const deleteCustomer$ = (action$: ActionsObservable<Action<ICustomer>>) =>
    action$.ofType(customersActions.DELETE_CUSTOMER_REQ).pipe(
        map(action => action.payload!),
        concatMap(customer => customersWebApi.deleteCustomerById(customer).pipe(
            map(() => {
                _toggleDeleteCustomerModal(false);
                _resetCustomer();
                _getCustomersByCompanyId(customer.companyId);
                _setSnackbar({
                    isOpen: true,
                    message: `You successfully deleted the customer`,
                    type: SnackbarTypes.SUCCESS,
                });

                return customersActions.deleteCustomerOk(customer);
            }),
            catchError(err => {
                const error = err.response.data;

                _setSnackbar({
                    isOpen: true,
                    message: error.description,
                    type: SnackbarTypes.ERROR,
                });

                return of(customersActions.deleteCustomerFail(error));
            }),
        )),
    );

export const saveCustomer$ = (action$: ActionsObservable<Action<ISaveCustomerReq>>) =>
    action$.ofType(customersActions.SAVE_CUSTOMER_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => {
            if (req.isEditMode) {
                delete req.isEditMode;

                return customersWebApi.editCustomer(req).pipe(
                    map(customer => {
                        _toggleSaveCustomerModal(false);
                        _resetCustomer();
                        _getCustomersByCompanyId(req.companyId);
                        _setSnackbar({
                            message: `Customer was successfully edited.`,
                            isOpen: true,
                            type: SnackbarTypes.SUCCESS,
                        });

                        return customersActions.saveCustomerOk(customer);
                    }),
                    catchError(error => {
                        const err = error.response.data;

                        _setSnackbar({
                            message: 'There was an error while updating customer.',
                            type: SnackbarTypes.ERROR,
                            isOpen: true,
                        });

                        return of(customersActions.saveCustomerFail(err));
                    }),
                );
            }

            return customersWebApi.createCustomer(req).pipe(
                map(customer => {
                    _toggleSaveCustomerModal(false);
                    _getCustomersByCompanyId(customer.companyId);
                    _setSnackbar({
                        message: 'A new customer was successfully created.',
                        type: SnackbarTypes.SUCCESS,
                        isOpen: true,
                    });

                    return customersActions.saveCustomerOk(customer);
                }),
                catchError(error => {
                    const err = error.response.data;

                    _setSnackbar({
                        message: err.description || 'An error has occured.',
                        type: SnackbarTypes.ERROR,
                        isOpen: true,
                    });

                    return of(customersActions.saveCustomerFail(error.response.data));
                }),
            );
        }),
    );

export const getBreadcrumbCustomer$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(customersActions.GET_BREADCRUMB_CUSTOMER_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => customersWebApi.getCustomerById(id).pipe(
            map(customer => customersActions.getBreadcrumbCustomerOk(customer)),
            catchError(error => of(customersActions.getBreadcrumbCustomerFail(error.response.data))),
        )),
    );

export const getFilterExternalCustomerIds$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(customersActions.GET_FILTER_EXTERNAL_CUSTOMER_IDS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => customersWebApi.getFilterExternalCustomerIds().pipe(
            map(eci => customersActions.getFilterExternalCustomerIdsOk(eci)),
            catchError(err => of(customersActions.getFilterExternalCustomerIdsFail(err.response && err.response.data))),
        )),
    );

export const getParsers$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(customersActions.GET_PARSERS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => customersWebApi.getParsers().pipe(
            map(parsers => customersActions.getParsersOk(parsers)),
            catchError(err => of(customersActions.getParsersFail(err.response && err.response.data))),
        )),
    );

export const getCustomersByErpCompany$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(customersActions.GET_CUSTOMERS_FROM_ERP_COMPANY_REQ).pipe(
        map(action => action.payload!),
        concatMap(companyName => customersWebApi.getCustomerIdsFromErpCompany(companyName).pipe(
            map(customers => customersActions.getCustomersFromErpCompanyOk(customers)),
            catchError(err => of(customersActions.getCustomersFromErpCompanyFail(err.response && err.response.data))),
        )),
    );

export const getErpCustomerIds$ = (action$: ActionsObservable<Action<null>>) =>
    action$.ofType(customersActions.GET_ERP_CUSTOMER_IDS_REQ).pipe(
        map(action => action.payload),
        tap(() => CustomersStateService.toggleErpCustomerIdsLoading(true)),
        concatMap(() => customersWebApi.getErpCustomerIds().pipe(
            map(ids => customersActions.getErpCustomerIdsOk(ids)),
            catchError(err => of(customersActions.getErpCustomerIdsFail(err.response && err.response.data))),
            tap(() => CustomersStateService.toggleErpCustomerIdsLoading(false)),
        )),
    );
