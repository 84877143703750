import * as actions from '../state/companies.actions';
import * as selectors from '../state/companies.selectors';

import { IApiTableRequest, IServerError } from '../../shared/interfaces/shared';
import { ICompany, ISaveCompanyReq } from "../interfaces/company";
import { distinctUntilChanged, map } from "rxjs/operators";
import { store, store$ } from "../../shared/services/state.service";

import { Observable } from "rxjs";

/**
 * @TODO add everything inside class
 */
export class CompaniesStateService {
    public static getBreadcrumbCompany(id: string): void {
        store.dispatch(
            actions.getBreadcrumbCompanyReq(id),
        );
    }

    public static setBreacrumbCompany(company: ICompany): void {
        store.dispatch(
            actions.setBreadcrumbCompany(company),
        );
    }

    public static getFilterCompanies(): void {
        store.dispatch(
            actions.getFilterCompaniesReq(),
        );
    }

    public static getErpCompanies(): void {
        store.dispatch(
            actions.getErpCompaniesReq(),
        );
    }

    public static getCompanyById(id: string): void {
        store.dispatch(
            actions.getCompanyByIdReq(id),
        );
    }

}

// ===== CREATE COMPANY =====

export const _saveCompany = (companyReq: ISaveCompanyReq): void => {
    store.dispatch(
        actions.saveCompanyReq(companyReq),
    );
}

export const createCompanyResponse$ = (): Observable<ICompany> => store$.pipe(
    map(state => selectors.SAVE_COMPANY_RESPONSE(state)),
    distinctUntilChanged(),
);

export const createCompanyError$ = (): Observable<IServerError | null> => store$.pipe(
    map(state => selectors.SAVE_COMPANY_ERROR(state)),
    distinctUntilChanged(),
);

// ===== RESET COMPANY =====

export const _resetCompany = (): void => {
    store.dispatch(
        actions.resetCompany(),
    );
};

export const _resetCompaniesModule = (): void => {
    store.dispatch(
        actions.resetCompaniesModule(),
    );
};

// ===== GET COMPANIES =====

export const _getCompanies = (req: IApiTableRequest): void => {
    store.dispatch(
        actions.getCompaniesReq(req),
    );
};

export const companies$ = (): Observable<ICompany[]> => store$.pipe(
    map(state => selectors.COMPANIES(state)),
    distinctUntilChanged(),
);

export const company$ = (): Observable<ICompany> => store$.pipe(
    map(state => selectors.COMPANY(state)),
    distinctUntilChanged(),
);

export const companiesLoading$ = (): Observable<boolean> => store$.pipe(
    map(state => selectors.COMPANIES_LOADING(state)),
    distinctUntilChanged(),
);

export const _toggleCompaniesLoading = (flag: boolean): void => {
    store.dispatch(
        actions.toggleCompaniesLoading(flag),
    );
};

// ===== DELETE COMPANY =====

export const _deleteCompany = (company: ICompany): void => {
    store.dispatch(
        actions.deleteCompanyReq(company),
    );
};

export const _toggleDeleteCompanyModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleDeleteCompanyModal(flag),
    );
};

// ===== UTILS =====

export const _setCompany = (company: ICompany): void => {
    store.dispatch(
        actions.setCompany(company),
    );
};

export const _toggleSaveCompanyModal = (flag: boolean): void => {
    store.dispatch(
        actions.toggleSaveCompanyModal(flag),
    );
};
