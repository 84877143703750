export const SEARCH = 'M21.7 20.29l-3.71-3.68A8.992 8.992 0 1 0 16.6 18l3.678 3.68a.993.993 0 1 0 1.422-1.39zM10.995 18a7 7 0 1 1 7-7 7 7 0 0 1-7 7z';

export const USER = 'M12.019 2a10.009 10.009 0 0 0-7.364 16.777 10.025 10.025 0 0 0 14.728 0A10.009 10.009 0 0 0 12.019 2zm0 18.019a8.019 8.019 0 0 1-5.56-2.252 6.013 6.013 0 0 1 11.121 0 8.019 8.019 0 0 1-5.561 2.252zm-2-10.01a2 2 0 1 1 2 2 2 2 0 0 1-2.004-2.001zm8.927 6.006a8.013 8.013 0 0 0-3.917-3.383 4.006 4.006 0 1 0-6.011 0A8.013 8.013 0 0 0 5.1 16.014 7.923 7.923 0 0 1 4 12.01a8.015 8.015 0 0 1 16.03 0 7.923 7.923 0 0 1-1.088 4.004z';

export const SETTINGS = '';

export const CHECK_CIRCLE = 'M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 1.41 0l5-5a1 1 0 1 0-1.41-1.42zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z';

export const WARNING = 'M10.938 14.55a.9.9 0 1 0 .9.9.9.9 0 0 0-.9-.9zm9.643 1.318L13.306 3.311a2.722 2.722 0 0 0-4.736 0L1.34 15.868a2.673 2.673 0 0 0-.028 2.7 2.715 2.715 0 0 0 2.342 1.367h14.568a2.715 2.715 0 0 0 2.378-1.351 2.673 2.673 0 0 0-.017-2.716zm-1.564 1.794a.905.905 0 0 1-.8.457H3.653a.905.905 0 0 1-.8-.457.891.891 0 0 1 0-.9l7.23-12.557a.907.907 0 0 1 1.609 0l7.275 12.557a.891.891 0 0 1 .045.915zM10.938 7.374a.9.9 0 0 0-.9.9v3.588a.9.9 0 0 0 1.808 0V8.271a.9.9 0 0 0-.908-.897z';

export const BELL = 'M18 13.207V10.02a6.01 6.01 0 0 0-5-5.92V3a1 1 0 1 0-2 0v1.1a6.01 6.01 0 0 0-5 5.92v3.188a3.007 3.007 0 0 0-2 2.827v2a1 1 0 0 0 1 1h3.14a4 4 0 0 0 7.72 0H19a1 1 0 0 0 1-1v-2a3.007 3.007 0 0 0-2-2.827zM8 10.02a4 4 0 1 1 8 0v3.007H8zm4 10.025a2 2 0 0 1-1.72-1h3.44a2 2 0 0 1-1.72.999zm6-3.007H6v-1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z';

export const CLOSE = 'M18.342 15.993L25.5 8.85a1.672 1.672 0 1 0-2.364-2.364l-7.143 7.16-7.142-7.16A1.672 1.672 0 0 0 6.487 8.85l7.16 7.143-7.16 7.143A1.672 1.672 0 1 0 8.851 25.5l7.143-7.16 7.143 7.16a1.672 1.672 0 1 0 2.363-2.364z';

export const ADD = 'M22.75 12.75h-7.5v-7.5a1.25 1.25 0 0 0-2.5 0v7.5h-7.5a1.25 1.25 0 0 0 0 2.5h7.5v7.5a1.25 1.25 0 0 0 2.5 0v-7.5h7.5a1.25 1.25 0 0 0 0-2.5z';
