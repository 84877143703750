import * as React from "react";

import { IAzureADFunctionProps } from "react-aad-msal";
import { SUBHEADER_TILES_AOE } from "../constants/aoe.constants";
import { Subheader } from "../../shared/components/subheader/subheader";
import { aoeRoutes } from "../routes/aoe.routes";

interface Props extends IAzureADFunctionProps { }

export const AoePage: React.FC<Props> = (azureAd: Props) => {
    const render = () => {
        return (
            <React.Fragment>
                {/* Subheader */}
                <Subheader tiles={SUBHEADER_TILES_AOE} />

                {/* Automated orders entry routes */}
                {aoeRoutes(azureAd)}
            </React.Fragment>
        );
    }

    return render();
};
