import { Action, IApiTableRequest } from "../../shared/interfaces/shared";
import { IDownloadInvoiceReq, IEditErpOrderLineReq, IEditOrderReq, IGetOrderRequest } from "../interfaces/order";
import { catchError, concatMap, map, tap } from "rxjs/operators";

import { ActionsObservable } from "redux-observable";
import { IEditErpOrderHeaderRequest } from "../interfaces/edit-erp-order-header-request";
import { IUpdateErpOrderLineRequest } from "../interfaces/update-erp-order-line-request";
import { OrdersActions } from "./orders.actions";
import { OrdersStateService } from "../services/orders-state.service";
import { OrdersWebApi } from "../webapi/orders.webapi";
import { SnackbarTypes } from "../../shared/components/snackbar/snackback.utils";
import { _setSnackbar } from "../../shared/services/shared.state-service";
import { of } from "rxjs";

export const getOrders$ = (action$: ActionsObservable<Action<IApiTableRequest>>) =>
    action$.ofType(OrdersActions.GET_ORDERS_REQ).pipe(
        map(action => action.payload!),
        concatMap(request => OrdersWebApi.getOrders(request).pipe(
            map(orders => OrdersActions.getOrdersOk(orders)),
            catchError(err => of(OrdersActions.getOrdersFail(err.response && err.response.data))),
        )),
        tap(() => OrdersStateService.toggleOrdersLoading(false)),
    );

export const getOrderDetails$ = (action$: ActionsObservable<Action<number>>) =>
    action$.ofType(OrdersActions.GET_ORDER_DETAILS_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrdersWebApi.getOrderDetails(id).pipe(
            map(order => OrdersActions.getOrderDetailsOk(order)),
            catchError(err => of(OrdersActions.getOrderDetailsFail(err.response && err.response.data))),
        )),
    );

export const getErpOrderInvoices$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(OrdersActions.GET_ORDER_INVOICES_INFO_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrdersWebApi.getErpOrderInvoices(id).pipe(
            map(invoices => OrdersActions.getOrderInvoicesInfoOk(invoices)),
            catchError(err => of(OrdersActions.getOrderInvoicesInfoFail(err.response && err.response.data))),
        )),
    );

export const downloadInvoice$ = (action$: ActionsObservable<Action<IDownloadInvoiceReq>>) =>
    action$.ofType(OrdersActions.DOWNLOAD_INVOICE_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.downloadInvoice(req).pipe(
            map(() => OrdersActions.downloadInvoiceOk('Ok')),
            catchError(err => {
                _setSnackbar({
                    isOpen: true,
                    message: 'Could not download invoice. Please contact administrator',
                    type: SnackbarTypes.ERROR,
                });

                return of(OrdersActions.downloadInvoiceFail(err.response && err.response.data));
            }),
        )),
    );

export const getErpPoNumber$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(OrdersActions.GET_ERP_PONUMBERS_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.getErpPoNumbers(req).pipe(
            map(poNumbers => OrdersActions.getErpPoNumbersOk(poNumbers)),
            catchError(err => of(OrdersActions.getErpPoNumbersFail(err.response && err.response.data))),
        )),
    );

export const getErpOrderNumbers4 = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(OrdersActions.GET_ERP_ORDER_NUMBERS_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.getErpOrderNumbers(req).pipe(
            map(orderNumbers => OrdersActions.getErpOrderNumbersOk(orderNumbers)),
            catchError(err => of(OrdersActions.getErpOrderNumbersFail(err.response && err.response.data))),
        )),
    );

export const editOrder$ = (action$: ActionsObservable<Action<IEditOrderReq>>) =>
    action$.ofType(OrdersActions.EDIT_ORDER_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.editOrder(req).pipe(
            map(() => {
                const message = 'Order successfully edited';

                _setSnackbar({
                    message,
                    isOpen: true,
                    type: SnackbarTypes.SUCCESS,
                });
                OrdersStateService.toggleEditOrderModal(false);

                return OrdersActions.editOrderOk(message);
            }),
            catchError(err => {
                _setSnackbar({
                    message: 'Could not edit order',
                    isOpen: true,
                    type: SnackbarTypes.ERROR,
                });
                OrdersStateService.toggleEditOrderModal(false);

                return of(OrdersActions.editOrderFail(err.response && err.response.data));
            }),
        )),
    );

export const editErpOrderLine$ = (action$: ActionsObservable<Action<IEditErpOrderLineReq>>) =>
    action$.ofType(OrdersActions.EDIT_ERP_ORDER_LINE_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.editErpOrderLine(req).pipe(
            map(() => {
                const message = 'The data will be up to date when validated into the ERP.';

                _setSnackbar({
                    message,
                    isOpen: true,
                    type: SnackbarTypes.SUCCESS,
                });
                OrdersStateService.toggleEditErpOrderLine(false);

                return OrdersActions.editErpOrderLineOk(message);
            }),
            catchError(err => {
                _setSnackbar({
                    message: 'Could not edit order line',
                    isOpen: true,
                    type: SnackbarTypes.ERROR,
                });
                OrdersStateService.toggleEditErpOrderLine(false);

                return of(OrdersActions.editErpOrderLineFail(err.response && err.response.data));
            }),
        )),
    );

export const editErpOrderHeader$ = (action$: ActionsObservable<Action<IEditErpOrderHeaderRequest>>) =>
    action$.ofType(OrdersActions.EDIT_ERP_ORDER_HEADER_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.editErpOrderHeader(req).pipe(
            map(() => {
                const message = 'The data will be up to date when validated into the ERP.';

                _setSnackbar({
                    message,
                    isOpen: true,
                    type: SnackbarTypes.SUCCESS,
                });
                OrdersStateService.toggleEditErpOrderHeaderModal(false);

                return OrdersActions.editErpOrderHeaderOk();
            }),
            catchError(err => {
                _setSnackbar({
                    message: 'Could not edit order header',
                    isOpen: true,
                    type: SnackbarTypes.ERROR,
                });
                OrdersStateService.toggleEditErpOrderHeaderModal(false);

                return of(OrdersActions.editErpOrderHeaderFail(err.response && err.response.data));
            }),
        ))
    );

export const getErpOrderLines$ = (action$: ActionsObservable<Action<IApiTableRequest>>) =>
    action$.ofType(OrdersActions.GET_ERP_ORDER_LINES_REQ).pipe(
        map(action => action.payload!),
        tap(() => OrdersStateService.toggleErpOrderLinesLoading(true)),
        concatMap(req => OrdersWebApi.getErpOrderLines(req).pipe(
            map(lines => {
                return OrdersActions.getErpOrderLinesOk(lines);
            }),
            catchError(err => {
                _setSnackbar({
                    type: SnackbarTypes.ERROR,
                    isOpen: true,
                    message: 'Could not get order lines',
                });

                return of(OrdersActions.getErpOrderLinesFail(err.response && err.response.data));
            })
        )),
        tap(() => OrdersStateService.toggleErpOrderLinesLoading(false)),
    );

export const updateErpOrderLines$ = (action$: ActionsObservable<Action<IUpdateErpOrderLineRequest>>) =>
    action$.ofType(OrdersActions.UPDATE_ERP_ORDER_LINES_REQ).pipe(
        map(action => action.payload!),
        concatMap(req => OrdersWebApi.updateErpOrderLines(req).pipe(
            map(() => {
                const message = 'The data will be up to date when validated into the ERP.';

                OrdersStateService.toggleBulkEditModal(false);

                _setSnackbar({
                    type: SnackbarTypes.SUCCESS,
                    isOpen: true,
                    message: message,
                });

                return OrdersActions.updateErpOrderLinesOk();
            }),
            catchError(err => {
                _setSnackbar({
                    type: SnackbarTypes.ERROR,
                    isOpen: true,
                    message: 'Could not bulk edit. Please contact administrator.',
                });

                OrdersStateService.toggleBulkEditModal(false);
                return of(OrdersActions.updateErpOrderLinesFail(err.response && err.response.data));
            }),
        )),
    );
