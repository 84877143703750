import { colors } from "../../styles/colors";
import styled from "styled-components";


interface ILogoProps {
    width?: string;
    height?: string;
}
export const Wrapper = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.$white};
    padding-left: 72px;
    padding-right: 72px;
`;

export const Logo = styled.img<ILogoProps>`
    width: ${props => props.width ? props.width : '55px'};
    height: ${ props => props.height ? props.height : '32px'};
`;
