import { colors } from "../../styles/colors";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const activeClassName = "activeTile";

export const Wrapper = styled(NavLink).attrs({ activeClassName })`
    text-decoration: none;
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 14px;
    position: relative;
    cursor: pointer;

    &.${activeClassName} {
        &:before {
            content: "";
            height: 4px;
            width: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: ${colors.$darkBlue};
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
`;

export const ActiveBar = styled.div`
    height: 4px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: ${colors.$darkBlue};
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const Text = styled.span`
    font-family: "proxima-nova", sans-serif;
    display: block;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.$darkBlue};
    transition: 0.4s;

    ${Wrapper}:hover & {
        color: ${colors.$tileTextHover};
    }
`;
