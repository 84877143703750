import { IPagination } from "../../shared/interfaces/pagination";
import { ISetting } from "../interfaces/settings";
import { ITokenUserRole } from "../interfaces/token-user-role";
import { IUserRole } from "../interfaces/roles";
import { IUsersState } from "../interfaces/users-state";

export const usersInitState: IUsersState = {
    currentUser: {
        token: '',
        name: '',
        username: '',
    },
    isLoading: false,
    roles: [],
    userRoles: {} as IPagination<IUserRole>,
    currentUserRole: {} as IUserRole,
    tokenUserRole: {} as ITokenUserRole,
    settings: [] as ISetting[],
    modals: {
        changeRole: false,
    },
};
