import { ICompleteOrder, IDownloadInvoiceReq, IEditErpOrderLineReq, IEditOrderReq, IErpOrderInvoice, IErpOrderLine, IGetOrderRequest, IOrder } from "../interfaces/order";

import { IApiTableRequest } from "../../shared/interfaces/shared";
import { IEditErpOrderHeaderRequest } from "../interfaces/edit-erp-order-header-request";
import { IPagination } from "../../shared/interfaces/pagination";
import { IUpdateErpOrderLineRequest } from "../interfaces/update-erp-order-line-request";

export class OrdersActions {
    public static readonly GET_ORDERS_REQ = 'GET_ORDERS_REQ';
    public static getOrdersReq(request: IApiTableRequest) {
        return {
            type: this.GET_ORDERS_REQ,
            payload: request,
        };
    }

    public static readonly GET_ORDERS_OK = 'GET_ORDERS_OK';
    public static getOrdersOk(orders: IOrder[]) {
        return {
            type: this.GET_ORDERS_OK,
            payload: orders,
        };
    }

    public static readonly GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';
    public static getOrdersFail(error: string) {
        return {
            type: this.GET_ORDERS_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_ORDERS_LOADING = 'TOGGLE_ORDERS_LOADING';
    public static toggleOrdersLoading(flag: boolean) {
        return {
            type: this.TOGGLE_ORDERS_LOADING,
            payload: flag,
        };
    }

    public static readonly GET_ORDER_DETAILS_REQ = 'GET_ORDER_DETAILS_REQ';
    public static getOrderDetailsReq(id: number) {
        return {
            type: this.GET_ORDER_DETAILS_REQ,
            payload: id,
        };
    }

    public static readonly GET_ORDER_DETAILS_OK = 'GET_ORDER_DETAILS_OK';
    public static getOrderDetailsOk(order: ICompleteOrder) {
        return {
            type: this.GET_ORDER_DETAILS_OK,
            payload: order,
        };
    }

    public static readonly GET_ORDER_DETAILS_FAIL = 'GET_ORDER_DETAILS_FAIL';
    public static getOrderDetailsFail(error: string) {
        return {
            type: this.GET_ORDER_DETAILS_FAIL,
            payload: error,
        };
    }

    public static readonly RESET_CURRENT_COMPLETE_ORDER = 'RESET_CURRENT_COMPLETE_ORDER';
    public static resetCurrentCompleteOrder() {
        return {
            type: this.RESET_CURRENT_COMPLETE_ORDER,
            payload: null,
        };
    }

    public static readonly GET_ORDER_INVOICES_INFO_REQ = 'GET_ORDER_INVOICES_INFO_REQ';
    public static getOrderInvoicesInfoReq(id: string) {
        return {
            type: this.GET_ORDER_INVOICES_INFO_REQ,
            payload: id,
        };
    }

    public static readonly GET_ORDER_INVOICES_INFO_OK = 'GET_ORDER_INVOICES_INFO_OK';
    public static getOrderInvoicesInfoOk(invoices: IErpOrderInvoice[]) {
        return {
            type: this.GET_ORDER_INVOICES_INFO_OK,
            payload: invoices,
        };
    }

    public static readonly GET_ORDER_INVOICES_INFO_FAIL = 'GET_ORDER_INVOICES_INFO_FAIL';
    public static getOrderInvoicesInfoFail(error: string) {
        return {
            type: this.GET_ORDER_INVOICES_INFO_FAIL,
            payload: error,
        };
    }

    public static readonly DOWNLOAD_INVOICE_REQ = 'DOWNLOAD_INVOICE_REQ';
    public static downloadInvoiceReq(req: IDownloadInvoiceReq) {
        return {
            type: this.DOWNLOAD_INVOICE_REQ,
            payload: req,
        };
    }

    public static readonly DOWNLOAD_INVOICE_OK = 'DOWNLOAD_INVOICE_OK';
    public static downloadInvoiceOk(response: string) {
        return {
            type: this.DOWNLOAD_INVOICE_OK,
            payload: response,
        };
    }

    public static readonly DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';
    public static downloadInvoiceFail(error: string) {
        return {
            type: this.DOWNLOAD_INVOICE_FAIL,
            payload: error,
        };
    }

    public static readonly GET_ERP_PONUMBERS_REQ = 'GET_ERP_PONUMBERS_REQ';
    public static getErpPoNumbersReq(req: string) {
        return {
            type: this.GET_ERP_PONUMBERS_REQ,
            payload: req,
        };
    }

    public static readonly GET_ERP_PONUMBERS_OK = 'GET_ERP_PONUMBERS_OK';
    public static getErpPoNumbersOk(poNumbers: string[]) {
        return {
            type: this.GET_ERP_PONUMBERS_OK,
            payload: poNumbers,
        };
    }

    public static readonly GET_ERP_PONUMBERS_FAIL = 'GET_ERP_PONUMBERS_FAIL';
    public static getErpPoNumbersFail(error: string) {
        return {
            type: this.GET_ERP_PONUMBERS_FAIL,
            payload: error,
        };
    }

    public static readonly GET_ERP_ORDER_NUMBERS_REQ = 'GET_ERP_ORDER_NUMBERS_REQ';
    public static getErpOrderNumbersReq(req: string) {
        return {
            type: this.GET_ERP_ORDER_NUMBERS_REQ,
            payload: req,
        };
    }

    public static readonly GET_ERP_ORDER_NUMBERS_OK = 'GET_ERP_ORDER_NUMBERS_OK';
    public static getErpOrderNumbersOk(numbers: string[]) {
        return {
            type: this.GET_ERP_ORDER_NUMBERS_OK,
            payload: numbers,
        };
    }

    public static readonly GET_ERP_ORDER_NUMBERS_FAIL = 'GET_ERP_ORDER_NUMBERS_FAIL';
    public static getErpOrderNumbersFail(error: string) {
        return {
            type: this.GET_ERP_ORDER_NUMBERS_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_EDIT_ORDER_MODAL = 'TOGGLE_EDIT_ORDER_MODAL';
    public static toggleEditOrderModal(flag: boolean) {
        return {
            type: this.TOGGLE_EDIT_ORDER_MODAL,
            payload: flag,
        };
    }

    public static readonly SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
    public static setCurrentOrder(order: IOrder) {
        return {
            type: this.SET_CURRENT_ORDER,
            payload: order,
        };
    }

    public static readonly EDIT_ORDER_REQ = 'EDIT_ORDER_REQ';
    public static editOrderReq(req: IEditOrderReq) {
        return {
            type: this.EDIT_ORDER_REQ,
            payload: req,
        };
    }

    public static readonly EDIT_ORDER_OK = 'EDIT_ORDER_OK';
    public static editOrderOk(message: string) {
        return {
            type: this.EDIT_ORDER_OK,
            payload: message,
        };
    }

    public static readonly EDIT_ORDER_FAIL = 'EDIT_ORDER_FAIL';
    public static editOrderFail(error: string) {
        return {
            type: this.EDIT_ORDER_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_EDIT_ERP_ORDER_LINE = 'TOGGLE_EDIT_ERP_ORDER_LINE';
    public static toggleEditErpOrderLine(flag: boolean) {
        return {
            type: this.TOGGLE_EDIT_ERP_ORDER_LINE,
            payload: flag,
        };
    }

    public static readonly SET_CURRENT_ERP_ORDER_LINE = 'SET_CURRENT_ERP_ORDER_LINE';
    public static setCurrentErpOrderLine(erpOrderLine: IErpOrderLine) {
        return {
            type: this.SET_CURRENT_ERP_ORDER_LINE,
            payload: erpOrderLine,
        };
    }

    public static readonly EDIT_ERP_ORDER_LINE_REQ = 'EDIT_ERP_ORDER_LINE_REQ';
    public static editErpOrderLineReq(req: IEditErpOrderLineReq) {
        return {
            type: this.EDIT_ERP_ORDER_LINE_REQ,
            payload: req,
        };
    }

    public static readonly EDIT_ERP_ORDER_LINE_OK = 'EDIT_ERP_ORDER_LINE_OK';
    public static editErpOrderLineOk(message: string) {
        return {
            type: this.EDIT_ERP_ORDER_LINE_OK,
            payload: message,
        };
    }

    public static readonly EDIT_ERP_ORDER_LINE_FAIL = 'EDIT_ERP_ORDER_LINE_FAIL';
    public static editErpOrderLineFail(error: string) {
        return {
            type: this.EDIT_ERP_ORDER_LINE_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_EDIT_ERP_ORDER_HEADER_MODAL = 'TOGGLE_EDIT_ERP_ORDER_HEADER_MODAL';
    public static toggleEditErpOrderHeaderModal(flag: boolean) {
        return {
            type: this.TOGGLE_EDIT_ERP_ORDER_HEADER_MODAL,
            payload: flag,
        };
    }

    public static readonly EDIT_ERP_ORDER_HEADER_REQ = 'EDIT_ERP_ORDER_HEADER_REQ';
    public static editErpOrderHeaderReq(req: IEditErpOrderHeaderRequest) {
        return {
            type: this.EDIT_ERP_ORDER_HEADER_REQ,
            payload: req,
        };
    }

    public static readonly EDIT_ERP_ORDER_HEADER_OK = 'EDIT_ERP_ORDER_HEADER_OK';
    public static editErpOrderHeaderOk() {
        return {
            type: this.EDIT_ERP_ORDER_HEADER_OK,
            payload: null,
        };
    }

    public static readonly EDIT_ERP_ORDER_HEADER_FAIL = 'EDIT_ERP_ORDER_HEADER_FAIL';
    public static editErpOrderHeaderFail(error: string) {
        return {
            type: this.EDIT_ERP_ORDER_HEADER_FAIL,
            payload: error,
        };
    }

    public static readonly GET_ERP_ORDER_LINES_REQ = 'GET_ERP_ORDER_LINES_REQ';
    public static getErpOrderLinesReq(request: IApiTableRequest) {
        return {
            type: this.GET_ERP_ORDER_LINES_REQ,
            payload: request,
        };
    }

    public static readonly GET_ERP_ORDER_LINES_OK = 'GET_ERP_ORDER_LINES_OK';
    public static getErpOrderLinesOk(lines: IPagination<IErpOrderLine>) {
        return {
            type: this.GET_ERP_ORDER_LINES_OK,
            payload: lines,
        };
    }

    public static readonly GET_ERP_ORDER_LINES_FAIL = 'GET_ERP_ORDER_LINES_FAIL';
    public static getErpOrderLinesFail(err: string) {
        return {
            type: this.GET_ERP_ORDER_LINES_FAIL,
            payload: err,
        };
    }

    public static readonly TOGGLE_ERP_ORDER_LINES_LOADING = 'TOGGLE_ERP_ORDER_LINES_LOADING';
    public static toggleErpOrderLinesLoading(flag: boolean) {
        return {
            type: this.TOGGLE_ERP_ORDER_LINES_LOADING,
            payload: flag,
        };
    }

    public static readonly UPDATE_ERP_ORDER_LINES_REQ = 'UPDATE_ERP_ORDER_LINES_REQ';
    public static updateErpOrderLinesReq(req: IUpdateErpOrderLineRequest) {
        return {
            type: this.UPDATE_ERP_ORDER_LINES_REQ,
            payload: req,
        };
    }

    public static readonly UPDATE_ERP_ORDER_LINES_OK = 'UPDATE_ERP_ORDER_LINES_OK';
    public static updateErpOrderLinesOk() {
        return {
            type: this.UPDATE_ERP_ORDER_LINES_OK,
            payload: null,
        };
    }

    public static readonly UPDATE_ERP_ORDER_LINES_FAIL = 'UPDATE_ERP_ORDER_LINES_FAIL';
    public static updateErpOrderLinesFail(err: string) {
        return {
            type: this.UPDATE_ERP_ORDER_LINES_FAIL,
            payload: err,
        };
    }

    public static readonly TOGGLE_BULK_EDIT_BUTTON = 'TOGGLE_BULK_EDIT_BUTTON';
    public static toggleBulkEditButton(flag: boolean) {
        return {
            type: this.TOGGLE_BULK_EDIT_BUTTON,
            payload: flag,
        };
    }

    public static readonly TOGGLE_BULK_EDIT = 'TOGGLE_BULK_EDIT';
    public static toggleBulkEdit(flag: boolean) {
        return {
            type: this.TOGGLE_BULK_EDIT,
            payload: flag,
        };
    }

    public static readonly TOGGLE_BULK_EDIT_MESSAGE = 'TOGGLE_BULK_EDIT_MESSAGE';
    public static toggleBulkEditMessage(flag: boolean) {
        return {
            type: this.TOGGLE_BULK_EDIT_MESSAGE,
            payload: flag,
        };
    }

    public static readonly TOGGLE_CONFIRM_BULK_EDIT_MODAL = 'TOGGLE_CONFIRM_BULK_EDIT_MODAL';
    public static toggleConfirmBulkEditModal(flag: boolean) {
        return {
            type: this.TOGGLE_CONFIRM_BULK_EDIT_MODAL,
            payload: flag,
        };
    }

}
