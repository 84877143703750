import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 400px;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
`;
