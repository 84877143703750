import React, { useEffect, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { CompaniesStateService } from '../../../companies/services/companies.state-service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IFilterObjectData } from '../../../order-list/interfaces/order-list';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const CompaniesFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const companies = useSelector<AppState, IFilterObjectData[]>(state => state.filters.companies);

    const getInitialCompany = (currCompany: string) => {
        if (!currCompany) {
            return null;
        }

        let currentCompany = null;

        for (let company of companies) {
            if (company.id.toString() === currCompany) {
                currentCompany = company;
                break;
            }
        }

        return currentCompany;
    };

    // State
    const [company, setCompany] = useState(getInitialCompany(initValue));

    const render = () => {
        return (
            <Dropdown label={'Company Name'}
                keyToShow={'value'}
                allowClear={true}
                options={companies}
                onChange={company=> onChange(company)}
                value={company} />
        );
    };

    useEffect(() => {
        CompaniesStateService.getFilterCompanies();
    }, []);

    useEffect(() => {
        if (!initValue) {
            setCompany({} as IFilterObjectData);
        }
    }, [initValue]);

    const onChange = (company: IFilterObjectData) => {
        setCompany(company);

        if (company.id) {
            onInputChange('company', company.id.toString());
        } else {
            onInputChange('company', '');
        }
    };

    return render();
};
