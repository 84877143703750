import * as React from 'react';
import * as div from './button.style';

import { ButtonTypes } from './button.utils';
import { colors } from '../../styles/colors';

interface Props {
    /** Text to display */
    label?: string;

    /** Callback executed when button clicked */
    onClick: () => void;

    /**
     * Depending on the need, a button might take different forms
     * For the moment, only PRIMARY and SECONDARY buttons are available
     */
    type?: ButtonTypes;

    disabled?: boolean;

    icon?: React.ReactNode;

    color?: string;

    /** Override default styling values */
    overrides?: {

        /** Entire Wrapper */
        root?: string;

        /** For text only */
        label?: string;
    };
}

export const Button: React.FunctionComponent<Props> = (props: Props) => {
    const { label, onClick, type, overrides, disabled, icon, color } = props;

    if (type === ButtonTypes.SECONDARY) {
        return (
            <div.SecondaryWrapper onClick={() => onClick()}
                disabled={disabled}
                color={color ? color : colors.$white}
                override={overrides && overrides.root}>

                {/** Icon */}
                {
                    icon &&
                    <div.IconWrapper hasLabel={!!label}>
                        {icon}
                    </div.IconWrapper>
                }

                {/** Label */}
                <div.SecondaryLabel override={overrides && overrides.label}
                    hasIcon={!!icon}>
                    {label}
                </div.SecondaryLabel>
            </div.SecondaryWrapper>
        );
    }

    return (
        <div.PrimaryWrapper onClick={() => onClick()}
            disabled={disabled}
            color={color ? color : colors.$darkBlue}
            override={overrides && overrides.root}>

            {/** Icon */}
            {
                icon &&
                <div.IconWrapper hasLabel={!!label}>
                    {icon}
                </div.IconWrapper>
            }

            {/** Label */}
            <div.PrimaryLabel override={overrides && overrides.label}
                hasIcon={!!icon}>
                {label}
            </div.PrimaryLabel>

        </div.PrimaryWrapper>
    );
}
