import * as styles from './gecko-filters.style';

import { Button } from '../../../shared/components/button/button';
import { ButtonTypes } from '../../../shared/components/button/button.utils';
import { CompaniesFilter } from '../companies-filter/companies-filter';
import { ConfirmedDateFilter } from '../confimed-date-filter/confirmed-date-filter';
import { CurrencyFilter } from '../currency-filter/currency-filter';
import { CustomerFilter } from '../customer-filter/customer-filter';
import { ErpCompaniesFilter } from '../erp-companies-filter/erp-companies-filter';
import { ErpCustomerFilter } from '../erp-customer-filter/erp-customer-filter';
import { ErpOrderLinesStatusFilter } from '../erp-order-lines-status-filter/erp-order-lines-status-filter';
import { ErpPoNumberFilter } from '../erp-ponumber-filter/erp-ponumber-filter';
import { ErpShippedDateFilter } from '../erp-shipped-date-filter/erp-shipped-date-filter';
import { ErpStatusFilter } from '../erp-status-filter/erp-status-filter';
import { ErpTotalCostFilter } from '../erp-total-cost-filter/erp-total-cost-filter';
import { ExpectedDateFilter } from '../expected-date-filter/expected-date-filter';
import { FILTERS } from '../../interfaces/filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IApplyClick } from '../../../shared/components/gecko-table/gecko-table.interface';
import { OrderDateFilter } from '../order-date-filter/order-date-filter';
import { OrderIdFilter } from '../order-id-filter/order-id-filter';
import { PartDescriptionFilter } from '../part-description-filter/part-description-filter';
import { PartIdFilter } from '../part-id-filter/part-id-filter';
import { PoNumberFilter } from '../ponumber-filter/ponumber-filter';
import { PriceFilter } from '../price-filter/price-filter';
import { QtyFilter } from '../qty-filter/qty-filter';
import React from 'react';
import { RevisedDateFilter } from '../../../orders/components/revised-date-filter/revised-date-filter';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { ShippedDateFilter } from '../shipped-date-filter/shipped-date-filter';
import { ShippedQtyFilter } from '../shipped-qty-filter/shipped-qty-filter';
import { SoaFilter } from '../soa-filter/soa-filter';
import { StatusFilter } from '../status-filter/status-filter';
import { UnitPriceFilter } from '../unit-price-filter/unit-price-filter';
import { colors } from '../../../shared/styles/colors';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    filters: FILTERS[];
    onCloseClick: () => void;
    onApplyClick: () => void;
    onClearClick: () => void;
    filterValues: IApplyClick;
    onFilterValuesChange: (key: string, value: string) => void;
}

export const GeckoFilters: React.FC<Props> = (props: Props) => {
    const { filters, onCloseClick, onApplyClick, filterValues, onFilterValuesChange, onClearClick } = props;

    const render = () => {
        return (
            <styles.Wrapper>

                <styles.Row>
                    <FontAwesomeIcon icon={faTimes}
                        cursor='pointer'
                        color={colors.$darkBlue}
                        size='2x'
                        onClick={() => onCloseClick()} />
                </styles.Row>

                <SectionTitle text={'Filters'} />

                <styles.FormWrapper>
                    {
                        !!filters.length &&
                        filters.map((filter) =>
                            <styles.Row key={filter}>
                                <styles.RowBody>
                                    {geckoFiltersMapper[filter]()}
                                </styles.RowBody>
                            </styles.Row>
                        )
                    }

                    <styles.Row>
                        <styles.RowBody>
                            <Button type={ButtonTypes.PRIMARY}
                                onClick={() => onApplyClick()}
                                label={'Apply Filters'} />

                            <Button type={ButtonTypes.SECONDARY}
                                onClick={() => onClearClick()}
                                label={'Clear filters'} />
                        </styles.RowBody>
                    </styles.Row>

                </styles.FormWrapper>

            </styles.Wrapper>
        );
    };

    const geckoFiltersMapper = {
        [FILTERS.COMPANY]: () => <CompaniesFilter key={'company'}
            initValue={filterValues['company']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.ERP_COMPANY]: () => <ErpCompaniesFilter key={'erpCompany'}
            initValue={filterValues['erpCompany']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.CURRENCY]: () => <CurrencyFilter key={'currency'}
            initValue={filterValues['currency']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.CUSTOMER_ID]: () => <CustomerFilter key={'customerId'}
            initValue={filterValues['customerId']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.ERP_CUSTOMER_ID]: () => <ErpCustomerFilter key={'erpCustomerId'}
            initValue={filterValues['customerId']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.PRICE]: (additionalKey?: string) => <PriceFilter key={'price'}
            initPriceFrom={filterValues['priceFrom']}
            initPriceTo={filterValues['priceTo']}
            additionalKey={additionalKey}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.PO_NUMBER]: () => <PoNumberFilter key={'poNumber'}
            initValue={filterValues['poNumber']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.SOA]: () => <SoaFilter key={'soa'}
            initValue={filterValues['soaId']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.STATUS]: () => <StatusFilter key={'status'}
            initValue={filterValues['status']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.ERP_STATUS]: () => <ErpStatusFilter key={'erp-status'}
            initValue={filterValues['erpStatus']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.SHIPPED_DATE]: () => <ShippedDateFilter key={'shipped-date'}
            initShippedDateFrom={filterValues['shippedDateFrom']}
            initShippedDateTo={filterValues['shippedDateTo']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.EXPECTED_DATE]: () => <ExpectedDateFilter key={'expected-date'}
            initExpectedDateFrom={filterValues['expectedDateFrom']}
            initExpectedDateTo={filterValues['expectedDateTo']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.REVISED_DATE]: () => <RevisedDateFilter key={'revised-date'}
            initRevisedDateFrom={filterValues['revisedDateFrom']}
            initRevisedDateTo={filterValues['revisedDateTo']}
            onInputChange={(key: string, value: string) => onFilterValuesChange(key, value)} />,

        [FILTERS.ORDER_DATE]: () => <OrderDateFilter key={'order-date'}
            initOrderDateFrom={filterValues['orderDateFrom']}
            initOrderDateTo={filterValues['orderDateTo']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.CONFIRMED_DATE]: () => <ConfirmedDateFilter key={'confirmed-date'}
            initConfirmedDateFrom={filterValues['confirmedDateFrom']}
            initConfirmedDateTo={filterValues['confirmedDateTo']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.ORDER_ID]: () => <OrderIdFilter key={'order-id'}
            initOrderId={filterValues['orderId']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.ERP_PONUMBER]: () => <ErpPoNumberFilter key={'erp-ponumber'}
            initValue={filterValues['erpPoNumber']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.PART_ID]: () => <PartIdFilter key={'part-id'}
            initValue={filterValues['partId']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.QTY]: () => <QtyFilter key={'qty'}
            initQuantityMax={filterValues['qtyTo']}
            initQuantityMin={filterValues['qtyFrom']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.SHIPPED_QTY]: () => <ShippedQtyFilter key={'shipped-qty'}
            initQuantityMax={filterValues['shippedQtyTo']}
            initQuantityMin={filterValues['shippedQtyFrom']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.UNIT_PRICE]: () => <UnitPriceFilter key={'unit-price'}
            initPriceFrom={filterValues['unitPriceFrom']}
            initPriceTo={filterValues['unitPriceTo']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.PART_DESCRIPTION]: () => <PartDescriptionFilter key={'part-description'}
            initPartDescription={filterValues['partDescription']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.ERP_TOTAL_COST]: () => <ErpTotalCostFilter key={'erp-total-cost'}
            initPriceFrom={filterValues['priceFromErp']}
            initPriceTo={filterValues['priceToErp']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.ERP_SHIPPED_DATE]: () => <ErpShippedDateFilter key={'erp-shipped-date'}
            initShippedDateFrom={filterValues['erpShippedDateFrom']}
            initShippedDateTo={filterValues['erpShippedDateTo']}
            onInputChange={onFilterValuesChange} />,

        [FILTERS.ERP_ORDER_LINES_STATUS]: () => <ErpOrderLinesStatusFilter key={'erp-order-lines-status'}
            initErpOrderLinesStatus={filterValues['erpOrderLinesStatus']}
            onInputChange={onFilterValuesChange} />,
    };

    return render();
};
