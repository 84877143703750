import { OrdersConfirmationPage } from "../pages/orders-confirmation.page";
import React from 'react';
import { Route } from "react-router";

export const ordersConfirmationRoutes = () => {
    return [
        <Route exact={true}
            path='/orders-confirmation/customer'
            key='orders-confirmation-customer'
            render={props => <OrdersConfirmationPage {...props} />} />,
    ];
};