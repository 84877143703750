import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Theme } from '../../../shared/styles/theme';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initConfirmedDateFrom: string;
    initConfirmedDateTo: string;
}

const { Filters } = Theme;

export const ConfirmedDateFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initConfirmedDateFrom, initConfirmedDateTo } = props;

    const getInitFrom = () => {
        if (initConfirmedDateFrom) {
            return moment(initConfirmedDateFrom);
        }

        return null;
    };

    const getInitTo = () => {
        if (initConfirmedDateTo) {
            return moment(initConfirmedDateTo);
        }

        return null;
    };

    const [confirmedDateFrom, setConfirmedDateFrom] = useState(getInitFrom());
    const [confirmedDateTo, setConfirmedDateTo] = useState(getInitTo());

    const render = () => {
        return (
            <Filters.Wrapper>
                <Filters.Title>Confirmed Date</Filters.Title>

                <Filters.Body>
                    <Filters.Column paddingRight='20px' className='column'>
                        <DateSelector value={confirmedDateFrom}
                            label={'From'}
                            onChange={date => onChange(date, 'confirmedDateFrom')} />
                    </Filters.Column>

                    <Filters.Column paddingLeft='10px'>
                        <DateSelector value={confirmedDateTo}
                            label={'To'}
                            onChange={date => onChange(date, 'confirmedDateTo')} />
                    </Filters.Column>
                </Filters.Body>
            </Filters.Wrapper>
        );
    };

    useEffect(() => {
        if (!initConfirmedDateFrom && !initConfirmedDateTo) {
            setConfirmedDateFrom(null);
            setConfirmedDateTo(null);
        }
    }, [initConfirmedDateTo, initConfirmedDateFrom]);

    const onChange = (date: Moment, keyValue: string) => {
        if (keyValue === 'confirmedDateFrom') {
            setConfirmedDateFrom(date);
        }

        if (keyValue === 'confirmedDateTo') {
            setConfirmedDateTo(date);
        }

        if (date) {
            onInputChange(keyValue, moment(date).format('MM/DD/YYYY'));
        } else {
            onInputChange(keyValue, '');
        }

    };

    return render();
};