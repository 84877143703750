import * as React from "react";

import { HomePage } from "../pages/home.page";
import { Route } from "react-router";
import { IAzureADFunctionProps } from "react-aad-msal";

export const homepageRoutes = (azureAd: IAzureADFunctionProps) => {
    return [
        <Route
            exact={true}
            strict={true}
            path="/"
            key={"homepage"}
            render={props => <HomePage {...props} {...azureAd} />}
        />
    ];
};
