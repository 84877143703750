import { ButtonWrapper, FormColumn, FormRow, FormWrapper, OpenFeeModalSection } from './save-order-list-modal.style';
import { IOrderList, IOrderListError, IOrderListSaveReq } from '../../interfaces/order-list';
import React, { useEffect, useState } from 'react';
import { isDate, isEmpty } from 'lodash';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../../shared/interfaces/app.state';
import { Button } from '../../../shared/components/button/button';
import { CURRENCIES } from '../../../shared/services/utils.service';
import { DateSelector } from '../../../shared/components/date-selector/date-selector'
import { Dropdown } from '../../../shared/components/dropdown/dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IShipVia } from '../../../shared/interfaces/ship-via';
import { IShippingFee } from '../../interfaces/shipping-fee';
import { ISiteId } from '../../../shared/interfaces/site-id';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import { OrderListService } from '../../services/order-list.service';
import { OrderListStateService } from '../../services/order-list-state.service';
import { SharedService } from '../../../shared/services/shared.service';
import { colors } from '../../../shared/styles/colors';
import { config } from '../../../auth/components/auth-provider/config';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface Props {
    /**function for submit */
    onSubmit: (values: IOrderListSaveReq) => void;
    /** function for edit submit */
    onSubmitEdit?: (values: IOrderListSaveReq) => void;

    onValidateClick: () => void;

    /** function to close the modal */
    onClose: () => void;
    /** initial values for edit mode */
    currentOrderList: IOrderList;
    /** modal loading */
    loading?: boolean;
    /** boolean to check is edit mode */
    isEditMode?: boolean;

    externalCustomerId: string;

    errors?: IOrderListError[];

    orderListValidationLoading: boolean;

    viewMode?: boolean;

    shipVias: IShipVia[];

    siteIds: ISiteId[];
}

/** Save HTML Order List Modal */
export const SaveHTMLOrderListModal: React.FC<Props> = (props: Props) => {
    const { onClose, currentOrderList, loading, onSubmit, isEditMode, onSubmitEdit, externalCustomerId, errors, orderListValidationLoading, viewMode, siteIds, shipVias } = props;

    const isAC = config.isAC;

    const selectedShippingFee = useSelector<AppState, IShippingFee>(state => state.orderList.selectedShippingFee);

    const getConfirmedDate = () => {
        if (!isEmpty(currentOrderList)) {
            // return 'INvalid Date';
            if (currentOrderList!.confirmedDate && isDate(new Date(currentOrderList!.confirmedDate))) {
                return moment(currentOrderList!.confirmedDate);
            }

            if (currentOrderList!.poDate) {
                return moment(currentOrderList!.poDate);
            }
        }

        return null;
    };

    const getSiteId = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList.siteId) {
                let siteIdFound = siteIds.filter(si => si.siteId === currentOrderList.siteId)[0];

                return siteIdFound;
            }
        }

        return {} as ISiteId;
    };

    const getRevisedShipVia = () => {
        if (!isEmpty(currentOrderList)) {
            if (currentOrderList.revisedShipViaText) {
                let shipViaFound = shipVias.filter(sv => sv.code === currentOrderList.revisedShipViaText)[0];

                return shipViaFound;
            }
        }

        return {} as IShipVia;
    };

    /** fields values */
    const [inputs, setInputs] = React.useState({
        externalCustomerId: externalCustomerId,
        revisedPoDate: (currentOrderList && currentOrderList.revisedPoDate && moment(currentOrderList.revisedPoDate)) || null,
        confirmedDate: getConfirmedDate(),
        orderDate: (currentOrderList && currentOrderList.orderDate) || '',
        orderStatus: (currentOrderList && OrderListService.getCurrentStatus(currentOrderList.orderStatus)) || OrderListService.STATUSES[0],
        shipToAddress: (currentOrderList && currentOrderList.shipToAddress) || '',
        billTo: (currentOrderList && currentOrderList.billTo) || '',
        shipToFullName: (currentOrderList && currentOrderList.shipToFullName) || '',
        revisedShipToFullName: (currentOrderList && currentOrderList.revisedShipToFullName) || '',
        shipToCompanyName: (currentOrderList && currentOrderList.shipToCompanyName) || '',
        shipViaText: (currentOrderList && currentOrderList.shipViaText) || '',
        shipToEmail: (currentOrderList && currentOrderList.shipToEmail) || '',
        revisedShipToEmail: (currentOrderList && currentOrderList.revisedShipToEmail) || '',
        revisedShipToCompanyName: (currentOrderList && currentOrderList.revisedShipToCompanyName) || '',
        revisedShipToCountry: (currentOrderList && currentOrderList.revisedShipToCountry) || '',
        revisedShipToAddressLine1: (currentOrderList && currentOrderList.revisedShipToAddressLine1) || '',
        revisedShipToAddressLine2: (currentOrderList && currentOrderList.revisedShipToAddressLine2) || '',
        revisedShipToCity: (currentOrderList && currentOrderList.revisedShipToCity) || '',
        revisedShipToState: (currentOrderList && currentOrderList.revisedShipToState) || '',
        revisedShipToZipCode: (currentOrderList && currentOrderList.revisedShipToZipCode) || '',
        shippingFees: (currentOrderList && currentOrderList.shippingFees) || '',
    });

    const [currency, setCurrency] = React.useState(
        SharedService.getCurrency(!isEmpty(currentOrderList) ? currentOrderList!.currency : null)
    );

    const [siteId, setSiteId] = React.useState(
        getSiteId(),
    );

    const [revisedShipViaTextObj, setRevisedShipViaTextObj] = useState(getRevisedShipVia());
    const [acRevisedShipViaText, setAcRevisedShipViaText] = useState(currentOrderList && currentOrderList.revisedShipViaText || '');

    const [customErrors, setCustomErrors] = React.useState({
        externalCustomerId: '',
        shipToAddress: '',
        orderDate: '',

        revisedPoDate: '',
        confirmedDate: '',

        siteId: '',
        currency: '',
        orderStatus: '',
        billTo: '',

        shipToFullName: '',
        revisedShipToFullName: '',
        shipToCompanyName: '',
        shipViaText: '',
        shipToEmail: '',
        revisedShipToEmail: '',
        revisedShipViaText: '',
        revisedShipToCompanyName: '',
        revisedShipToCountry: '',
        revisedShipToAddressLine1: '',
        revisedShipToAddressLine2: '',
        revisedShipToCity: '',
        revisedShipToState: '',
        revisedShipToZipCode: '',
        shippingFees: '',
    });

    const [internalErrors, setInternalErrors] = React.useState({});

    const render = () => {
        if (loading || orderListValidationLoading) {
            return (
                <Modal onClose={() => onClose()} >
                    <ActivityIndicator />
                </Modal>
            );
        } else {
            return (
                <Modal onClose={() => onClose()}>
                    <FormWrapper style={{ position: 'relative' }}>
                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Input
                                    error={customErrors.externalCustomerId}
                                    value={inputs.externalCustomerId}
                                    disabled={true}
                                    label={"External Customer ID"}
                                    onChange={(text: string) => onInputChange(text, 'externalCustomerId', 'string')}
                                />

                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <DateSelector
                                    error={customErrors.revisedPoDate}
                                    disabled={viewMode}
                                    value={inputs.revisedPoDate}
                                    label={"Revised PO Date"}
                                    onChange={(text: string) => onInputChange(text, 'revisedPoDate', 'date')}
                                />
                            </FormColumn>

                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <DateSelector
                                    error={customErrors.orderDate}
                                    value={moment(inputs.orderDate)}
                                    format={'MM/DD/YYYY HH:mm'}
                                    label={"PO Received Date"}
                                    disabled={true}
                                    onChange={(text: Date) => onInputChange(text, 'orderDate', 'date')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                <DateSelector
                                    error={customErrors.confirmedDate}
                                    value={inputs.confirmedDate}
                                    label={"Confirmed Date (Desired Ship Date)"}
                                    disabled={viewMode}
                                    required={true}
                                    onChange={(text: Date) => onInputChange(text, 'confirmedDate', 'date')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Dropdown error={customErrors.currency}
                                    keyToShow={'name'}
                                    value={currency}
                                    options={CURRENCIES}
                                    label={'Currency'}
                                    disabled={true}
                                    onChange={currency => setCurrency(currency)} />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Dropdown
                                    error={customErrors.orderStatus}
                                    keyToShow={"name"}
                                    value={inputs.orderStatus}
                                    label={"Order Status"}
                                    disabled={true}
                                    options={OrderListService.STATUSES}
                                    onChange={(text: string) => onInputChange(text, 'orderStatus', 'string')} />
                            </FormColumn>

                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                {
                                    !isAC &&
                                    <Input
                                        error={customErrors.shipViaText}
                                        value={inputs.shipViaText}
                                        label={'Ship Via'}
                                        disabled={true}
                                        maxLength={100}
                                        onInternalError={onInternalInputErrors}
                                        inputId={'shipViaText'}
                                        onChange={(text: string) => onInputChange(text, 'shipViaText', 'string')}
                                    />
                                }

                                {
                                    isAC &&
                                    <Input error={customErrors.shippingFees}
                                        value={inputs.shippingFees}
                                        label={'Shipping Fees'}
                                        disabled={true}
                                        onChange={() => {}} />
                                }
                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                {
                                    !isAC &&
                                    <Dropdown
                                        allowClear={true}
                                        error={customErrors.revisedShipViaText}
                                        keyToShow={'code'}
                                        value={revisedShipViaTextObj}
                                        label={'Revised Ship Via'}
                                        disabled={viewMode}
                                        options={shipVias}
                                        onChange={(sp: IShipVia) => setRevisedShipViaTextObj(sp)}
                                    />
                                }

                                {
                                    isAC &&
                                    <Input value={acRevisedShipViaText}
                                        error={customErrors.revisedShipViaText}
                                        label={'Revised Ship Via'}
                                        disabled={true}
                                        onChange={() => {}} />
                                }
                            </FormColumn>
                        </FormRow>

                        {
                            isAC &&
                            <OpenFeeModalSection>
                                <FontAwesomeIcon icon={faSync}
                                    color={colors.$darkBlue}
                                    onClick={() => OrderListStateService.toggleShippingFeesModal(true)} />
                            </OpenFeeModalSection>
                        }

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.shipToEmail}
                                    value={inputs.shipToEmail}
                                    label={'Ship To Email'}
                                    disabled={true}
                                    onChange={(text: string) => onInputChange(text, 'shipToEmail', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft="10px">
                                <Input
                                    error={customErrors.revisedShipToEmail}
                                    value={inputs.revisedShipToEmail}
                                    maxLength={50}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToEmail'}
                                    label={'Revised Ship To Email'}
                                    onChange={(text: string) => onInputChange(text, 'revisedShipToEmail', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            {/* <FormColumn paddingRight='10px'> */}
                                <Dropdown
                                    error={customErrors.siteId}
                                    keyToShow={"siteId"}
                                    value={siteId}
                                    label={"Site ID"}
                                    options={siteIds}
                                    required={true}
                                    disabled={viewMode}
                                    onChange={siteId => setSiteId(siteId)} />
                            {/* </FormColumn> */}
                        </FormRow>

                        <FormRow>
                            <Input
                                error={customErrors.billTo}
                                value={inputs.billTo}
                                isTextArea={true}
                                disabled={true}
                                maxLength={255}
                                onInternalError={onInternalInputErrors}
                                inputId={'billTo'}
                                label={"Bill To"}
                                onChange={(text: string) => onInputChange(text, 'billTo', 'string')}
                            />
                        </FormRow>

                        <FormRow>
                            <Input error={customErrors.shipToAddress}
                                isTextArea={true}
                                value={inputs.shipToAddress}
                                label='Ship To Address'
                                disabled={viewMode || true}
                                required={true}
                                onChange={(text: string) => onInputChange(text, 'shipToAddress', 'string')} />
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight="10px">
                                <Input
                                    error={customErrors.shipToFullName}
                                    value={inputs.shipToFullName}
                                    disabled={true}
                                    label={"Ship To Full Name"}
                                    onChange={(text: string) => onInputChange(text, 'shipToFullName', 'string')}
                                />

                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToFullName}
                                    value={inputs.revisedShipToFullName}
                                    disabled={viewMode}
                                    maxLength={100}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToFullName'}
                                    label={"Revised Ship To Full Name"}
                                    onChange={(text: string) => onInputChange(text, 'revisedShipToFullName', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        {/* <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.shipToCompanyName}
                                    value={inputs.shipToCompanyName}
                                    disabled={true}
                                    label={"Ship To Company Name"}
                                    onChange={(text: string) => onInputChange(text, 'shipToCompanyName', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToCompanyName}
                                    value={inputs.revisedShipToCompanyName}
                                    disabled={viewMode}
                                    label={"Revised Ship To Company Name"}
                                    onChange={(text: string) => onInputChange(text, 'revisedShipToCompanyName', 'string')}
                                />
                            </FormColumn>
                        </FormRow> */}

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToAddressLine1}
                                    value={inputs.revisedShipToAddressLine1}
                                    disabled={viewMode}
                                    maxLength={50}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToAddressLine1'}
                                    label={"Revised Ship To Address Line 1"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine1', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToAddressLine2}
                                    value={inputs.revisedShipToAddressLine2}
                                    maxLength={50}
                                    disabled={viewMode}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToAddressLine2'}
                                    label={"Revised Ship To Address Line 2"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToAddressLine2', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToCity}
                                    value={inputs.revisedShipToCity}
                                    disabled={viewMode}
                                    label={"Revised Ship To City"}
                                    maxLength={30}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToCity'}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCity', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft='10px'>
                                <Input
                                    error={customErrors.revisedShipToState}
                                    value={inputs.revisedShipToState}
                                    maxLength={30}
                                    disabled={viewMode}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToState'}
                                    label={"Revised Ship To State"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToState', 'string')}
                                />
                            </FormColumn>
                        </FormRow>

                        <FormRow>
                            <FormColumn paddingRight='10px'>
                                <Input
                                    error={customErrors.revisedShipToZipCode}
                                    value={inputs.revisedShipToZipCode}
                                    maxLength={10}
                                    disabled={viewMode}
                                    label={"Revised Ship To Zipcode"}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToZipcode'}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToZipCode', 'string')}
                                />
                            </FormColumn>

                            <FormColumn paddingLeft="10px">

                                <Input
                                    error={customErrors.revisedShipToCountry}
                                    value={inputs.revisedShipToCountry}
                                    maxLength={50}
                                    disabled={viewMode}
                                    onInternalError={onInternalInputErrors}
                                    inputId={'revisedShipToCountry'}
                                    label={"Revised Ship To Country"}
                                    onChange={(text: string) => onInputChange(text.toUpperCase(), 'revisedShipToCountry', 'string')}
                                />
                                {/* <Input
                                    error={customErrors.siteId}
                                    value={inputs.siteId}
                                    label={"Site ID"}
                                    disabled={viewMode}
                                    required={true}
                                    onChange={(text: string) => onInputChange(text, 'siteId', 'string')}
                                /> */}
                            </FormColumn>
                        </FormRow>

                        <ButtonWrapper>
                            <Button disabled={isSaveButtonDisabled()} label={'Submit'} onClick={handleSubmit} />
                        </ButtonWrapper>
                    </FormWrapper>
                </Modal>
            );
        }
    };

    useEffect(() => {
        // it has ValidationError status
        if (currentOrderList && currentOrderList.orderStatus === 2) {
            let errorObj: any = {};

            if (errors && errors.length) {
                errors.forEach(err => {
                    Object.keys(customErrors).forEach(key => {
                        if (key.toLowerCase() === err.fieldName.toLowerCase()) {
                            errorObj[key] = err.errorMessage;
                        }
                    });
                });
            }

            setCustomErrors({ ...customErrors, ...errorObj });
        }
    }, [errors]);

    useEffect(() => {
        if (!isEmpty(selectedShippingFee)) {
            setAcRevisedShipViaText(selectedShippingFee.code);
            setInputs({
                ...inputs,
                shippingFees: selectedShippingFee.totalCharges,
            });
        }
    }, [selectedShippingFee]);

    // useEffect(() => {
    //     OrderListStateService.getShippingFees(currentOrderList.id);
    // }, [currentOrderList, currentOrderList.id]);

    const onInternalInputErrors = (flag: boolean, key?: string) => {
        console.log('++ flag, key', flag, key);
        if (key) {
            if (flag) {
                setInternalErrors({ ...internalErrors, [key]: flag });
            } else {
                let copy: any = { ...internalErrors };
                delete copy[key];

                setInternalErrors(copy);
            }
        }
    };

    const isSaveButtonDisabled = () => {
        if (viewMode) {
            return true;
        }

        if (!isEmpty(internalErrors)) {
            return true;
        }

        if (!inputs.confirmedDate || !inputs.revisedPoDate) {
            return true;
        }

        return false;
    };

    const handleValidation = (text: string | Date | number, key: string, type: string) => {
        switch (type) {
            case 'date': {
                if (!text) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            case 'string': {
                if (isEmpty(text)) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            default: {
                setCustomErrors({ ...customErrors, [key]: '' })
            }
        }

    }

    const onInputChange = (text: string | Date | number, key: string, type: string) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type);
    };

    const handleSubmit = () => {
        const editValues = {
            ...currentOrderList,
            ...inputs,
            revisedPoDate: inputs.revisedPoDate ? moment(inputs.revisedPoDate).format('MM/DD/YYYY') : null,
            confirmedDate: moment(inputs.confirmedDate).format('MM/DD/YYYY'),
            siteId: !isEmpty(siteId) ? siteId.siteId : '',
            orderStatus: inputs.orderStatus.id,
            revisedShipViaText: isAC ? acRevisedShipViaText : ((!isEmpty(revisedShipViaTextObj) && revisedShipViaTextObj.code) || ''),
            // getAllOrderList: getAllOrderList,
        };

        if (isEditMode && currentOrderList) {
            onSubmitEdit && onSubmitEdit(editValues);
        } else {
            onSubmit && onSubmit(editValues);
        }
    }

    return render();
};
