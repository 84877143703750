export const config = {
    appId: 'f2591786-0e8c-4a49-bfc8-b38d825a3671',
    authority: 'https://login.microsoftonline.com/f193f6f5-62be-492d-897d-776cde125585/',
    // redirectUri: 'http://localhost:3000',
    redirectUri: 'https://app-qa.customer.geckoalliance.com',
    apiUrl: 'https://api-qa.customer.geckoalliance.com',
    scopes: ['api://3824fab7-c04f-454a-8ef7-5bf35496d2bf/General.Scope'],
    isAC: false,
    b2c: {
        tenant: "geckob2cqa.onmicrosoft.com",
        scopes: ['https://geckob2cqa.onmicrosoft.com/api/General.Scope'],
        signInPolicy: "B2C_1A_SignIn",
        applicationID: "1ec027c5-9ef3-4675-a288-e56c64fa82a3",
        instance: `https://geckob2cqa.b2clogin.com/`,
    }
};
