import * as React from 'react';
import * as div from './activity-indicator.style';

import { Levels } from 'react-activity';

interface Props {}

export const ActivityIndicator: React.FunctionComponent<Props> = (_props: Props) => {
    return (
        <div.Wrapper>
            <Levels />
        </div.Wrapper>
    );
};
