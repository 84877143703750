import { colors } from '../../../shared/styles/colors';
import { fonts } from '../../../shared/styles/fonts';
import styled from 'styled-components';

interface ValueProps {
    color: string | undefined;
}

export const Wrapper = styled.div`
    background-color: ${colors.$white};
    padding: 20px;
    border-radius: 3px;
    margin-right: 10px;
    width: 420px;
    height: 293px;
`;

export const Row = styled.div`
    margin-bottom: 30px;
`;

export const Label = styled.div`
    font-size: 16px;
    font-weight: 600;
    font-family: ${fonts.proxima};
    color: ${colors.$blue};
    display: inline-block;
    width: 120px;
    margin-right: 30px;
`;

export const Value = styled.div<ValueProps>`
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.color ? props.color : colors.$darkBlue};
    display: inline-block;
`;

export const Section = styled.div``;
