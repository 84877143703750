import React, { useEffect, useState } from 'react';

import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { OrdersService } from '../../../orders/services/orders.service';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const ErpStatusFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    // State
    const [status, setStatus] = useState(initValue);

    const render = () => {
        return (
            <Dropdown label={'Status Code'}
                keyToShow={''}
                allowClear={true}
                options={OrdersService.ERP_STATUSES}
                onChange={status => onChange(status)}
                value={status} />
        );
    };

    useEffect(() => {
        if (!initValue) {
            setStatus('');
        }
    }, [initValue]);

    const onChange = (status: string) => {
        setStatus(status);
        onInputChange('erpStatus', status || '');
    };

    return render();
};
