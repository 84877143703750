import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../../shared/interfaces/app.state';
import { CompaniesStateService } from '../../../companies/services/companies.state-service';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { FiltersStateService } from '../../services/filters.state-service';
import { useSelector } from 'react-redux';

interface Props {
    onInputChange: (key: string, value: string) => void;
    initValue: string;
}

export const ErpCompaniesFilter: React.FC<Props> = (props: Props) => {
    const { onInputChange, initValue } = props;

    const companies = useSelector<AppState, string[]>(state => state.filters.erpCompanies);

    // State
    const [company, setCompany] = useState(initValue);

    const render = () => {
        return (
            <Dropdown label={'Company Name'}
                keyToShow={''}
                allowClear={true}
                options={companies || []}
                onChange={company=> onChange(company)}
                value={company} />
        );
    };

    useEffect(() => {
        CompaniesStateService.getErpCompanies();
    }, []);

    useEffect(() => {
        if (initValue) {
            FiltersStateService.setOrderCompany(initValue);
        } else {
            setCompany('');
        }
    }, [initValue]);

    const onChange = (company: string) => {
        setCompany(company);
        FiltersStateService.setOrderCompany(company);
        onInputChange('erpCompany', company);

        if (!company) {
            FiltersStateService.setErpCustomerIds([]);
        }
    };

    return render();
};
