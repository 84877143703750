import styled from 'styled-components';

interface WrapperProps {
    width: number;
    height: number;
}

export const Wrapper = styled.img<WrapperProps>`
    width: ${props => props.width};
    height: ${props => props.height};
`;
