import { _setCurrentSoa, _setDeletedSoa, _toggleCreatetSoaModal, _toggleDeleteSoaModal } from "../services/soa-state.service";

import { ColumnsType } from "antd/lib/table";
import { IRowMenu } from "../../shared/interfaces/row-menu";
import { ISoa } from "../interfaces/soa";

export class SoaPageUtils {
    public static tableColumns: ColumnsType<ISoa> = [{
        title: 'Name',
        dataIndex: 'name'
    }, {
        title: 'SOA Email',
        dataIndex: 'soaEmail'
    }, {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
    }];

    public static rowMenu(soa: ISoa): IRowMenu[] {
        return [{
            label: 'Update SOA',
            onClick: () => this.handleEdit(soa),
        }, {
            label: 'Delete SOA',
            onClick: () => this.handleDelete(soa),
        }];
    }

    private static handleEdit(soa: ISoa) {
        _setCurrentSoa(soa);
        _toggleCreatetSoaModal(true);
    }

    private static handleDelete(soa: ISoa) {
        _setDeletedSoa(soa);
        _toggleDeleteSoaModal(true);
    }
}