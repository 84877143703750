import * as styles from './shipping-fees-modal.style';

import React, { useEffect, useState } from 'react';

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../../shared/interfaces/app.state';
import { IShippingFee } from '../../interfaces/shipping-fee';
import { Modal } from 'antd';
import { OrderListStateService } from '../../services/order-list-state.service';
import { ShippingFeeRadio } from '../shipping-fee-radio/shipping-fee-radio';
import { config } from '../../../auth/components/auth-provider/config';
import { useSelector } from 'react-redux';

interface Props {
    orderId: string;
}

export const ShippingFeesModal: React.FC<Props> = (props: Props) => {
    const { orderId } = props;

    const [selected, setSelected] = useState('');

    const fees = useSelector<AppState, IShippingFee[]>(state => state.orderList.shippingFees);
    const loading = useSelector<AppState, boolean>(state => state.orderList.loadingShippingFees);

    const render = () => {
        return (
            <Modal visible={true}
                onCancel={() => OrderListStateService.toggleShippingFeesModal(false)}
                onOk={onOk}
                okButtonProps={{ disabled: !selected }}>

                {
                    loading &&
                    <ActivityIndicator />
                }

                {
                    !loading &&
                    <styles.ContentWrapper>
                        {
                            !fees.length &&
                            <div>No fee found</div>
                        }

                        {
                            !!fees.length && fees.map(fee =>
                                <ShippingFeeRadio fee={fee}
                                    isSelected={selected === JSON.stringify(fee)}
                                    onSelect={(fee: IShippingFee) => setSelected(JSON.stringify(fee))} />
                            )
                        }
                    </styles.ContentWrapper>
                }

            </Modal>
        );
    };

    useEffect(() => {
        if (config.isAC) {
            OrderListStateService.getShippingFees(parseInt(orderId));
        }
    }, [orderId]);

    const onOk = () => {
        OrderListStateService.setSelectedShippingFee(JSON.parse(selected));
        OrderListStateService.toggleShippingFeesModal(false);
    };

    return render();
};
