import { Button, Checkbox, Modal } from 'antd';
import { CustomersStateService, _setCurrentCustomer } from '../../../customers/services/customers.state-service';
import { IAttachRoleReq, IRole, IUserRole } from '../../interfaces/roles';
import React, { useEffect, useRef, useState } from 'react';

import { AppState } from '../../../shared/interfaces/app.state';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { Input } from '../../../shared/components/input/input';
import { MultipleDropdown } from '../../../shared/components/dropdown/multiple-dropdown';
import { UsersStateService } from '../../services/users-state.service';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface Props {
    handleSubmit: (req: IAttachRoleReq) => void;
    handleCancel: () => void;
    roles: IRole[];
    currentUserRole: IUserRole;
    companies: string[];
}

export const AttachRoleModal: React.FC<Props> = (props: Props) => {
    const { handleSubmit, handleCancel, roles, currentUserRole, companies } = props;

    const erpCustomerIds = useSelector<AppState, string[]>(state => state.customers.erpCustomerIds);

    const getInitRole = () => {
        let foundRole = {} as IRole;

        for (let r of roles) {
            if (r.id === currentUserRole.userRoleId) {
                foundRole = r;
                break;
            }
        }

        return foundRole;
    };

    const getInitCustomersList = () => {
        if (currentUserRole.externalCustomerIdsList && currentUserRole.externalCustomerIdsList.length) {
            return currentUserRole.externalCustomerIdsList.split(',');
        }

        return [];
    };

    const [role, setRole] = useState<IRole>(getInitRole());
    const [company, setCompany] = useState<string>(currentUserRole.companyName || '');
    const [customerIds, setCustomerIds] = useState<string[]>(getInitCustomersList());
    const [rejected, setRejected] = useState<boolean>(currentUserRole.rejected);
    const [isInitValue, setIsInitValue] = useState<boolean>(false);

    const prevCompany = useRef<string>('');

    const render = () => {
        return (
            <Modal visible={true}
                title={`Attach Role for ${currentUserRole.userEmail}`}
                onOk={() => onSubmitClick()}
                onCancel={handleCancel}
                footer={[
                    <Button key='cancel'
                        onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit'
                        disabled={isEmpty(role)}
                        type='primary'
                        onClick={() => onSubmitClick()}>
                        Attach
                    </Button>
                ]}>

                <Input label={'Company Selected by Customer'}
                    value={currentUserRole.companyNameClientInput}
                    disabled={true} />

                <Dropdown label={'Company'}
                    options={companies}
                    keyToShow={''}
                    allowClear={true}
                    value={company}
                    onChange={company => setCompany(company)} />

                <MultipleDropdown label={'Customers'}
                    options={erpCustomerIds}
                    allowClear={true}
                    value={customerIds}
                    onChange={customerIds => setCustomerIds(customerIds)} />

                <Dropdown label={'Select Role'}
                    options={roles}
                    keyToShow='name'
                    allowClear={true}
                    value={role}
                    onChange={role => setRole(role)} />

                <Checkbox checked={rejected} onChange={event => setRejected(event.target.checked)}>
                    Reject Access
                </Checkbox>

            </Modal>
        );
    };

    useEffect(() => {
        if (company) {
            setIsInitValue(true);
        }

        return () => {
            UsersStateService.setCurrentUserRole({} as IUserRole);
            CustomersStateService.setErpCustomerIds([]);
        };
    }, []);

    useEffect(() => {
        if (company) {
            CustomersStateService.getCustomerIdsFromErpCompany(company);
        }

        if (isInitValue) {
            setIsInitValue(false);
        }
    }, [company]);

    useEffect(() => {
        if (erpCustomerIds.length) {
            if (erpCustomerIds.length === 1) {
                setCustomerIds(erpCustomerIds);
            } else if (!isInitValue) {
                setCustomerIds([]);
            }
        }
    }, [erpCustomerIds]);

    const onSubmitClick = () => {
        // let submitCustomerId = (customerId && customerId.value) || '';
        let submitRoleId = (role && role.id) || 0;
        let serializedCustomerIds = '';

        customerIds.forEach((id, index) => {
            // console.log('++ id', JSON.parse(id));
            // serializedCustomerIds += JSON.parse(id);
            serializedCustomerIds += id;

            if (index !== customerIds.length - 1) {
                serializedCustomerIds += ',';
            }
        });

        let req: IAttachRoleReq = {
            id: currentUserRole.id,
            userRoleId: submitRoleId,
            userEmail: currentUserRole.userEmail,
            companyName: company,
            externalCustomerIdsList: serializedCustomerIds,
            rejected,
        };

        handleSubmit(req);
    };

    return render();
};
