import React, { useEffect } from 'react';

import { Spin } from 'antd';
import { UsersStateService } from '../../users/services/users-state.service';
import { useHistory } from 'react-router';

interface Props {}

export const LogoutPage: React.FC<Props> = (props: Props) => {
    const history = useHistory();

    const render = () => {
        return (
            <Spin />
        );
    }

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        UsersStateService.setToken('');

        history.push('/login');
    }, []);

    return render();
};
