import { FilterOptions } from "../../shared/interfaces/filters";

export class FilterMappersService {
    public static readonly optionsMapper: any = {
        'soaId': FilterOptions.IsEqualTo,
        'priceFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'priceTo': FilterOptions.IsLessThanOrEqualTo,
        'priceFromErp': FilterOptions.IsGreaterThanOrEqualTo,
        'priceToErp': FilterOptions.IsLessThanOrEqualTo,
        'status': FilterOptions.IsEqualTo,
        'erpStatus': FilterOptions.IsEqualTo,
        'company': FilterOptions.IsEqualTo,
        'erpCompany': FilterOptions.IsEqualTo,
        'currency': FilterOptions.IsEqualTo,
        'customerId': FilterOptions.IsEqualTo,
        'erpCustomerId': FilterOptions.IsEqualTo,
        'poNumber': FilterOptions.IsEqualTo,
        'shippedDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'shippedDateTo': FilterOptions.IsLessThanOrEqualTo,
        'erpShippedDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'erpShippedDateTo': FilterOptions.IsLessThanOrEqualTo,
        'revisedDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'revisedDateTo': FilterOptions.IsLessThanOrEqualTo,
        'expectedDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'expectedDateTo': FilterOptions.IsLessThanOrEqualTo,
        'orderDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'orderDateTo': FilterOptions.IsLessThanOrEqualTo,
        'confirmedDateFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'confirmedDateTo': FilterOptions.IsLessThanOrEqualTo,
        'orderId': FilterOptions.IsEqualTo,
        'erpPoNumber': FilterOptions.IsEqualTo,
        'partId': FilterOptions.IsEqualTo,
        'qtyFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'qtyTo': FilterOptions.IsLessThanOrEqualTo,
        'shippedQtyFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'shippedQtyTo': FilterOptions.IsLessThanOrEqualTo,
        'unitPriceFrom': FilterOptions.IsGreaterThanOrEqualTo,
        'unitPriceTo': FilterOptions.IsLessThanOrEqualTo,
        'partDescription': FilterOptions.Contains,
        'erpOrderLinesStatus': FilterOptions.Contains,
    };

    public static readonly nameMapper: any = {
        'soaId': 'SoaId',
        'priceFrom': 'TotalAmount',
        'priceTo': 'TotalAmount',
        'priceFromErp': 'TotalCost',
        'priceToErp': 'TotalCost',
        'status': 'OrderStatus',
        'erpStatus': 'StatusCode',
        'company': 'CompanyId',
        'erpCompany': 'CustomerName',
        'currency': 'Currency',
        'customerId': 'CustomerId',
        'erpCustomerId': 'CustomerId',
        'poNumber': 'PoNumber',
        'shippedDateFrom': 'LastShippedDate',
        'shippedDateTo': 'LastShippedDate',
        'erpShippedDateFrom': 'ShippedDate',
        'erpShippedDateTo': 'ShippedDate',
        'expectedDateFrom': 'CustomerRequestedDate',
        'expectedDateTo': 'CustomerRequestedDate',
        'revisedDateFrom': 'RevisedDate',
        'revisedDateTo': 'RevisedDate',
        'orderDateFrom': 'OrderDate',
        'orderDateTo': 'OrderDate',
        'confirmedDateFrom': 'ConfirmedDate',
        'confirmedDateTo': 'ConfirmedDate',
        'orderId': 'OrderId',
        'erpPoNumber': 'PoNumber',
        'partId': 'PartId',
        'qtyFrom': 'Qty',
        'qtyTo': 'Qty',
        'shippedQtyFrom': 'ShippedQty',
        'shippedQtyTo': 'ShippedQty',
        'unitPriceTo': 'UnitPrice',
        'unitPriceFrom': 'UnitPrice',
        'partDescription': 'PartDescription',
        'erpOrderLinesStatus': 'StatusCode',
    };
}
