import { ICompleteOrder, IErpOrderLine, IOrder } from "../interfaces/order";

import { IOrdersState } from "../interfaces/orders-state";
import { IPagination } from "../../shared/interfaces/pagination";

export const ordersInitState: IOrdersState = {
    order: {} as IOrder,
    orders: [],
    currentErpOrderLine: {} as IErpOrderLine,
    currentCompleteOrder: {} as ICompleteOrder,
    pagination: {} as IPagination<IOrder>,
    loading: false,
    orderLines: {} as IPagination<IErpOrderLine>,
    orderLinesLoading: false,
    bulkEditMessage: false,
    invoices: [],
    modals: {
        editOrder: false,
        editErpOrderLine: false,
        editOrderHeader: false,
        confirmBulkEditModal: false,
        bulkEditButton: false,
        bulkEdit: false,
    },
};
