import * as actions from '../state/aoe.actions';

import { IBreadcrumb } from "../../shared/interfaces/shared";
import { store } from "../../shared/services/state.service";

export const addBreadcrumb = (breadcrumb: IBreadcrumb): void => {
    store.dispatch(
        actions.addBreadcrumb(breadcrumb),
    );
};

export const _resetAoeBreadcrumbs = (): void => {
    store.dispatch(
        actions.resetBreadcrumbs(),
    );
};

export const _setBreadcrumbs = (breadcrumbs: IBreadcrumb[]): void => {
    store.dispatch(
        actions.setBreadcrumbs(breadcrumbs),
    );
}

export const refineBreadcrumbs = (breadcrumb: IBreadcrumb): void => {
    store.dispatch(
        actions.refineBreadcrumbs(breadcrumb),
    );
};
