import { Selector, createSelector } from "reselect";

import { AppState } from "../../shared/interfaces/app.state";
import { ICustomer } from "../interfaces/customer";
import { ICustomersState } from "../interfaces/customers-state";

const CUSTOMERS_MODULE: Selector<AppState, ICustomersState> = (state: AppState) => state.customers;

export const CUSTOMERS = createSelector<AppState, ICustomersState, ICustomer[]>(
    CUSTOMERS_MODULE,
    state => state.customers,
);

export const CUSTOMERS_LOADING = createSelector<AppState, ICustomersState, boolean>(
    CUSTOMERS_MODULE,
    state => state.customersLoading,
);

export const CUSTOMERS_ERROR = createSelector<AppState, ICustomersState, string>(
    CUSTOMERS_MODULE,
    state => state.customersError,
);

export const CURRENT_CUSTOMER = createSelector<AppState, ICustomersState, ICustomer>(
    CUSTOMERS_MODULE,
    state => state.currentCustomer,
);
