export const colors = {
    // primary
    $darkBlue: "#4a6187",
    $darkBlueRGBA: (opacity: number) => `rgba(74, 97, 135, ${opacity})`,
    $blue: "#6598b8",
    $lightBlue: "#bbdaed",
    $blueRGBA: (opacity: number) => `rgba(101, 152, 184, ${opacity})`,

    //alert
    $alert: "#ea4b4b",

    //warning

    $warning: "#eab34b",

    //success
    $success: "#1ea863",

    // Common
    $white: "#ffffff",

    //background
    $backgroundPage: "#f7f9ff",
    $tableHeaderBackground: "#f8f8f8",

    // black
    $black: "#1c1c1c",
    $lightBlack: "#363636",
    $grey: "#b5b5b5",

    // Button specific colors
    $buttonSecondaryBorder: "#3c567f",

    // Header specific colors
    $tileTextHover: "#6598b8",

    // ===== COMPONENTS =====

    // input disabled

    $disabled: "#EBEBE4",

    // ======================

    // ===== PAGE TITLE =====

    pageTitle: {
        color: "#1c1c1c"
    },

    table: {
        $rowHover: '#e4f0f7',
    },

    $excel: '#1D6F42',
};
