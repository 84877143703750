import background from "../../shared/assets/login-background.png";
import { colors } from "../../shared/styles/colors";
import { fontSizes } from "../../shared/styles/font-sizes";
import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100vw;
    flex: 1;
    height: 100vh;
    justify-content: center;
`;

export const Left = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    background: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const Right = styled.div`
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Texts = styled.div`
    display: flex;
    max-width: 400px;
    padding: 10px;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
`;

export const TitleText = styled.div`
    font-size: ${fontSizes.paragraph};
    font-weight: 600;
    line-height: 20px;
    color: ${colors.$darkBlue};
`;

export const DescriptionText = styled(TitleText)`
    font-weight: 400;
`;
