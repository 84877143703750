export interface ISortingRequest {
    sortColumn?: string | null;
    sortOrder?: SortingOrder | null;
}

export enum SortingOrder {
    None = 0,
    Asc = 1,
    Desc = 2,
}
