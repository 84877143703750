import { FiltersActions } from "../state/filters.actions";
import { store } from "../../shared/services/state.service";

export class FiltersStateService {
    public static setOrderCompany(company: string) {
        store.dispatch(
            FiltersActions.setOrderCompany(company),
        );
    }

    public static setErpCustomerIds(ids: string[]) {
        store.dispatch(
            FiltersActions.setErpCustomerIds(ids),
        );
    }

}
