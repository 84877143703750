import * as styles from './utility-button.style';

import React from 'react';

interface Props {
    label: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    iconOnLeft?: boolean;
}

export const UtilityButton: React.FC<Props> = (props: Props) => {
    const { onClick, label, icon, iconOnLeft } = props;

    const render = () => {
        return (
            <styles.Wrapper onClick={onClick}>

                {
                    (iconOnLeft && icon) &&
                    <styles.Icon>
                        {icon}
                    </styles.Icon>
                }

                <styles.Label>
                    {label}
                </styles.Label>

                {
                    (!iconOnLeft && icon) &&
                    <styles.Icon>
                        {icon}
                    </styles.Icon>
                }
            </styles.Wrapper>
        );
    };

    return render();
};
