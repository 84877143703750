import * as styles from './allocation-info-table.style';

import { IAllocationInfo, IWeeklyAllocation } from '../../interfaces/order-confirmation';

import { AverageAllocation } from '../average-allocation/average-allocation';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Table } from 'antd';

interface Props {
    allocationInfo: IAllocationInfo;
}

export const AllocationInfoTable: React.FC<Props> = (props: Props) => {
    const { allocationInfo } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <AverageAllocation average={allocationInfo.allocationAverage} />

                <Table dataSource={allocationInfo.weeklyAllocation}
                    rowKey={'id'}
                    columns={columns}
                    style={{ width: 200 }}
                    pagination={false} />
            </styles.Wrapper>
        );
    };

    const columns: ColumnsType<IWeeklyAllocation> = [{
        title: 'Week',
        dataIndex: 'weekNo',
        key: 'weeklyAllocation_weekNo',
    }, {
        title: 'Allocation',
        dataIndex: 'allocation',
        key: 'weeklyAllocation_allocation',
    }];

    return render();
};
