import { IOrderConfirmation, ISetOrderConfirmation } from "../interfaces/order-confirmation";

export class OrdersConfirmationActions {
    public static readonly GET_ORDER_CONFIRMATION_REQ = 'GET_ORDER_CONFIRMATION_REQ';
    public static getOrderConfirmationReq(id: string) {
        return {
            type: this.GET_ORDER_CONFIRMATION_REQ,
            payload: id,
        };
    }

    public static readonly GET_ORDER_CONFIRMATION_OK = 'GET_ORDER_CONFIRMATION_OK';
    public static getOrderConfirmationOk(order: IOrderConfirmation) {
        return {
            type: this.GET_ORDER_CONFIRMATION_OK,
            payload: order,
        };
    }

    public static readonly GET_ORDER_CONFIRMATION_FAIL = 'GET_ORDER_CONFIRMATION_FAIL';
    public static getOrderConfirmationFail(error: string) {
        return {
            type: this.GET_ORDER_CONFIRMATION_FAIL,
            payload: error,
        };
    }

    public static readonly SET_ORDER_CONFIRMATION_REQ = 'SET_ORDER_CONFIRMATION_REQ';
    public static setOrderConfirmationReq(confirm: ISetOrderConfirmation) {
        return {
            type: this.SET_ORDER_CONFIRMATION_REQ,
            payload: confirm,
        };
    }

    public static readonly SET_ORDER_CONFIRMATION_OK = 'SET_ORDER_CONFIRMATION_OK';
    public static setOrderConfirmationOk(response: string) {
        return {
            type: this.SET_ORDER_CONFIRMATION_OK,
            payload: response,
        };
    }

    public static readonly SET_ORDER_CONFIRMATION_FAIL = 'SET_ORDER_CONFIRMATION_FAIL';
    public static setOrderConfirmationFail(error: string) {
        return {
            type: this.SET_ORDER_CONFIRMATION_FAIL,
            payload: error,
        };
    }

    public static readonly TOGGLE_DISPLAY_ORDER_CONFIRMATION_PAGE = 'TOGGLE_DISPLAY_ORDER_CONFIRMATION_PAGE';
    public static toggleDisplayOrderConfirmationPage(flag: boolean | null) {
        return {
            type: this.TOGGLE_DISPLAY_ORDER_CONFIRMATION_PAGE,
            payload: flag,
        };
    }

}
