import { ICompany, ISaveCompanyReq } from "../interfaces/company";

import { IFilterObjectData } from "../../order-list/interfaces/order-list";
import { IPaginationRequest } from "../../shared/interfaces/pagination";
import { IServerError } from "../../shared/interfaces/shared";

export const SAVE_COMPANY_REQ = 'SAVE_COMPANY_REQ';
export const saveCompanyReq = (request: ISaveCompanyReq) => ({
    type: SAVE_COMPANY_REQ,
    payload: request,
});

export const SAVE_COMPANY_OK = 'SAVE_COMPANY_OK';
export const saveCompanyOk = (company: ICompany) => ({
    type: SAVE_COMPANY_OK,
    payload: company,
});

export const SAVE_COMPANY_FAIL = 'SAVE_COMPANY_FAIL';
export const saveCompanyFail = (error: IServerError | null) => ({
    type: SAVE_COMPANY_FAIL,
    payload: error,
});

export const RESET_COMPANY = 'RESET_COMPANY';
export const resetCompany = () => ({
    type: RESET_COMPANY,
    payload: null,
});

export const GET_COMPANIES_REQ = 'GET_COMPANIES_REQ';
export const getCompaniesReq = (req: IPaginationRequest) => ({
    type: GET_COMPANIES_REQ,
    payload: req,
});

export const GET_COMPANIES_OK = 'GET_COMPANIES_OK';
export const getCompaniesOk = (companies: ICompany[]) => ({
    type: GET_COMPANIES_OK,
    payload: companies,
});

export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export const getCompaniesFail = (error: string) => ({
    type: GET_COMPANIES_FAIL,
    payload: error,
});

export const GET_BREADCRUMB_COMPANY_REQ = 'GET_BREADCRUMB_COMPANY_REQ';
export const getBreadcrumbCompanyReq = (id: string) => ({
    type: GET_BREADCRUMB_COMPANY_REQ,
    payload: id,
});

export const GET_BREADCRUMB_COMPANY_OK = 'GET_BREADCRUMB_COMPANY_OK';
export const getBreadcrumbCompanyOk = (company: ICompany) => ({
    type: GET_BREADCRUMB_COMPANY_OK,
    payload: company,
});

export const GET_BREADCRUMB_COMPANY_FAIL = 'GET_BREADCRUMB_COMPANY_FAIL';
export const getBreadcrumbCompanyFail = (error: string) => ({
    type: GET_BREADCRUMB_COMPANY_FAIL,
    payload: error,
});

export const SET_BREADCRUMB_COMPANY = 'SET_BREADCRUMB_COMPANY';
export const setBreadcrumbCompany = (company: ICompany) => ({
    type: SET_BREADCRUMB_COMPANY,
    payload: company,
});

export const TOGGLE_COMPANIES_LOADING = 'TOGGLE_COMPANIES_LOADING';
export const toggleCompaniesLoading = (flag: boolean) => ({
    type: TOGGLE_COMPANIES_LOADING,
    payload: flag,
});

export const DELETE_COMPANY_REQ = 'DELETE_COMPANY_REQ';
export const deleteCompanyReq = (company: ICompany) => ({
    type: DELETE_COMPANY_REQ,
    payload: company,
});

export const DELETE_COMPANY_OK = 'DELETE_COMPANY_OK';
export const deleteCompanyOk = (company: ICompany) => ({
    type: DELETE_COMPANY_OK,
    payload: company,
});

export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const deleteCompanyFail = (error: IServerError) => ({
    type: DELETE_COMPANY_FAIL,
    payload: error,
});

export const TOGGLE_DELETE_COMPANY_MODAL = 'TOGGLE_DELETE_COMPANY_MODAL';
export const toggleDeleteCompanyModal = (flag: boolean) => ({
    type: TOGGLE_DELETE_COMPANY_MODAL,
    payload: flag,
});

export const SET_COMPANY = 'SET_COMPANY';
export const setCompany = (company: ICompany) => ({
    type: SET_COMPANY,
    payload: company,
});

export const TOGGLE_SAVE_COMPANY_MODAL = 'TOGGLE_SAVE_COMPANY_MODAL';
export const toggleSaveCompanyModal = (flag: boolean) => ({
    type: TOGGLE_SAVE_COMPANY_MODAL,
    payload: flag,
});

export const RESET_COMPANIES_MODULE = 'RESET_COMPANIES_MODULE';
export const resetCompaniesModule = () => ({
    type: RESET_COMPANIES_MODULE,
    payload: null,
});

export const GET_FILTER_COMPANIES_REQ = 'GET_FILTER_COMPANIES_REQ';
export const getFilterCompaniesReq = () => ({
    type: GET_FILTER_COMPANIES_REQ,
    payload: null,
});

export const GET_FILTER_COMPANIES_OK = 'GET_FILTER_COMPANIES_OK';
export const getFilterCompaniesOk = (arr: IFilterObjectData[]) => ({
    type: GET_FILTER_COMPANIES_OK,
    payload: arr,
});

export const GET_FILTER_COMPANIES_FAIL = 'GET_FILTER_COMPANIES_FAIL';
export const getFilterCompaniesFail = (error: string) => ({
    type: GET_FILTER_COMPANIES_FAIL,
    payload: error,
});

export const GET_ERP_COMPANIES_REQ = 'GET_ERP_COMPANIES_REQ';
export const getErpCompaniesReq = () => ({
    type: GET_ERP_COMPANIES_REQ,
    payload: null,
});

export const GET_ERP_COMPANIES_OK = 'GET_ERP_COMPANIES_OK';
export const getErpCompaniesOk = (companies: string[]) => ({
    type: GET_ERP_COMPANIES_OK,
    payload: companies,
});

export const GET_ERP_COMPANIES_FAIL = 'GET_ERP_COMPANIES_FAIL';
export const getErpCompaniesFail = (error: string) => ({
    type: GET_ERP_COMPANIES_FAIL,
    payload: error,
});

export const GET_COMPANY_BY_ID_REQ = 'GET_COMPANY_BY_ID_REQ';
export const getCompanyByIdReq = (id: string) => ({
    type: GET_COMPANY_BY_ID_REQ,
    payload: id,
});

export const GET_COMPANY_BY_ID_OK = 'GET_COMPANY_BY_ID_OK';
export const getCompanyByIdOk = (company: ICompany) => ({
    type: GET_COMPANY_BY_ID_OK,
    payload: company,
});

export const GET_COMPANY_BY_ID_FAIL = 'GET_COMPANY_BY_ID_FAIL';
export const getCompanyByIdFail = (error: string) => ({
    type: GET_COMPANY_BY_ID_FAIL,
    payload: error,
});
