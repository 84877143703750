import { IAttachRoleReq, IUserRole } from "../interfaces/roles";

import { IApiTableRequest } from "../../shared/interfaces/shared";
import { ICurrentUser } from "../interfaces/current-user";
import { ISetting } from "../interfaces/settings";
import { UsersActions } from "../state/users.actions";
import { store } from "../../shared/services/state.service";

export class UsersStateService {
    public static setCurrentUser(user: ICurrentUser) {
        store.dispatch(
            UsersActions.setCurrentUser(user),
        );
    }

    public static setToken(token: string) {
        store.dispatch(
            UsersActions.setToken(token),
        );
    }

    public static getRoles() {
        store.dispatch(
            UsersActions.getRolesReq(),
        );
    }

    public static attachRole(req: IAttachRoleReq) {
        store.dispatch(
            UsersActions.attachRoleReq(req),
        );
    }

    public static getUserRoles(params: IApiTableRequest) {
        store.dispatch(
            UsersActions.getUserPermissionReq(params),
        );
    }

    public static setCurrentUserRole(role: IUserRole) {
        store.dispatch(
            UsersActions.setCurrentUserRole(role),
        );
    }

    public static toggleChangeUserRoleModal(flag: boolean) {
        store.dispatch(
            UsersActions.toggleChangeUserRoleModal(flag),
        );
    }

    public static getUserRole() {
        store.dispatch(
            UsersActions.getUserRoleReq(),
        );
    }

    public static getUserRoleById(id: number) {
        store.dispatch(
            UsersActions.getUserRoleByIdReq(id),
        );
    }

    public static getAdminSettings() {
        store.dispatch(
            UsersActions.getAdminSettingsReq(),
        );
    }

    public static saveAdminSettings(settings: ISetting[]) {
        store.dispatch(
            UsersActions.saveAdminSettingsReq(settings),
        );
    }

}
