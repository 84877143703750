import "./App.less";
import "react-activity/dist/react-activity.css";

import * as React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { ActivityIndicator } from "./shared/components/activity-indicator/activity-indicator";
import { AppRoutes } from "./app.routes";
import { AppState } from "./shared/interfaces/app.state";
import { AuthService } from "./auth/services/auth.service";
import { HEADER_TILES } from "./shared/components/header/header.constants";
import { Header } from "./shared/components/header/header";
import { ISnackbar } from "./shared/interfaces/snackbar";
import { ITokenUserRole } from "./users/interfaces/token-user-role";
import { SharedStateService } from "./shared/services/shared.state-service";
import { Snackbar } from "./shared/components/snackbar/snackbar";
import { SnackbarTypes } from "./shared/components/snackbar/snackback.utils";
import { UsersService } from "./users/services/users.service";
import { UsersStateService } from "./users/services/users-state.service";
import { getToken } from "./shared/services/msal";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

interface Props { }

const __App: React.FC<Props> = (props: Props) => {
    // state
    // const [token, setToken] = React.useState<string>('');
    const version = '0.5';

    // store
    const snackbar = useSelector<AppState, ISnackbar>(state => state.shared.snackbar);
    const foundUnauthorizedCall = useSelector<AppState, boolean>(state => state.shared.foundUnauthorizedCall);
    const token = useSelector<AppState, string>(state => state.users.currentUser.token);
    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const history = useHistory();
    const location = useLocation();

    const [isLoading, setIsLoading] = React.useState(true);
    const [isAuth, setIsAuth] = React.useState(false);

    async function checkIfAuth() {
        let isAuth = await AuthService.isAuth();

        setIsAuth(isAuth);
    }

    const render = () => {
        if (isLoading) {
            return <ActivityIndicator />;
        }

        return (
            <div className="App">
                {/** Header */}
                {
                    isAuth && <Header />
                }

                {/** App Routes */}
                <AppRoutes />

                {
                    (!isEmpty(snackbar) && snackbar.isOpen) &&
                    <Snackbar type={snackbar.type} message={snackbar.message} />
                }

            </div>
        );
    };

    React.useEffect(() => {
        clearCacheIfVersionChanges();
        checkIfAuth();

        return () => {
            UsersStateService.setToken('');
        };
    }, []);

    React.useEffect(() => {
        checkIfAuth();
    }, [location]);

    React.useEffect(() => {
        async function appLoaded() {
            let token = await getToken();

            if (token) {
                if (!isEmpty(tokenUserRole)) {
                    setIsLoading(false);

                    if (!UsersService.isExternalAccountActive(tokenUserRole) && location.pathname !== '/no-access') {
                        history.push('/no-access');
                    }
                } else {
                    UsersStateService.getUserRole();
                }
            } else if (location.pathname !== '/login' && location.pathname !== '/internal-login') {
                setIsLoading(false);
                history.push('/login');
            }

            if (location.pathname === '/login' || location.pathname === '/internal-login') {
                setIsLoading(false);
            }
        }

        appLoaded();

    }, [token, tokenUserRole, location]);

    React.useEffect(() => {
        if (foundUnauthorizedCall) {
            SharedStateService.setFoundUnauthorizedCall(false);
            UsersStateService.setToken('');
        }
    }, [foundUnauthorizedCall]);

    const clearCacheIfVersionChanges = () => {
        const versionApp = localStorage.getItem('version_app');

        if (versionApp && versionApp !== version) {
            localStorage.clear();

            history.push('/login');
        }

        localStorage.setItem('version_app', version);
    };

    return render();
};

export default __App;
