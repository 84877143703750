import { IFilterSoa, ISoa } from '../interfaces/soa';
import { Observable, from } from 'rxjs';

import { ISoaFormValues } from '../interfaces/soa-form-values'
import { axios } from '../../shared/services/axios.service';

export const getSoas = (): Observable<ISoa[]> =>
    from<Promise<ISoa[]>>(axios.get<ISoa[]>('/Soa')
        .then(res => res.data));

export const deleteSoa = (soaId: number): Observable<string> =>
    from<Promise<string>>(axios.delete<string>(`/Soa/${soaId}`)
        .then(res => res.statusText));


export const createSoa = (soa: ISoaFormValues): Observable<string> =>
    from<Promise<string>>(axios.post<string>('/Soa', { ...soa })
        .then(res => res.statusText));


export const editSoa = (soa: ISoa): Observable<string> =>
    from<Promise<string>>(axios.put<string>(`/Soa/${soa.id}`, { ...soa })
        .then(res => res.statusText));

export const getFilterSoas = (): Observable<IFilterSoa[]> =>
    from(
        axios.get(`/soa/forSearch`)
            .then(res => res.data),
    );
