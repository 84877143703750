import React, { useEffect } from 'react';

import { AppState } from '../../shared/interfaces/app.state';
import { ITokenUserRole } from '../interfaces/token-user-role';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { Theme } from '../../shared/styles/theme';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

interface Props {}

export const NoAccessPage: React.FC<Props> = (_props: Props) => {
    const token = useSelector<AppState, string>(state => state.users.currentUser.token);
    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const history = useHistory();

    const render = () => {
        return (
            <Theme.PageWrapper>
                {
                    (tokenUserRole && tokenUserRole.status === 2) &&
                    <SectionTitle text={'Your account was rejected. Please contact your Sales Operation Agent.'} />
                }

                {
                    (tokenUserRole && tokenUserRole.status === 1) &&
                    <SectionTitle text={'Your account is being processed... please wait.'} />
                }
            </Theme.PageWrapper>
        );
    };

    // useEffect(() => {
    //     if (!token) {
    //         history.push('/login');
    //     }
    // }, [token]);

    // useEffect(() => {
    // }, [tokenUserRole]);

    return render();
};
