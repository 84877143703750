import { catchError, concatMap, map } from "rxjs/operators";

import { Action } from "../../shared/interfaces/shared";
import { ActionsObservable } from "redux-observable";
import { ISetOrderConfirmation } from "../interfaces/order-confirmation";
import { OrdersConfirmationActions } from "./orders-confirmation.actions";
import { OrdersConfirmationWebApi } from "../webapi/orders-confirmation.webapi";
import { SnackbarTypes } from "../../shared/components/snackbar/snackback.utils";
import { _setSnackbar } from "../../shared/services/shared.state-service";
import { of } from "rxjs";

export const getOrderConfirmation$ = (action$: ActionsObservable<Action<string>>) =>
    action$.ofType(OrdersConfirmationActions.GET_ORDER_CONFIRMATION_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => OrdersConfirmationWebApi.getOrderConfirmation(id).pipe(
            map(order => OrdersConfirmationActions.getOrderConfirmationOk(order)),
            catchError(() => {
                return of(OrdersConfirmationActions.getOrderConfirmationFail(
                    'Something went wrong. Please try again later.'
                ));
            }),
        )),
    );

export const setOrderConfirmation$ = (action$: ActionsObservable<Action<ISetOrderConfirmation>>) =>
    action$.ofType(OrdersConfirmationActions.SET_ORDER_CONFIRMATION_REQ).pipe(
        map(action => action.payload!),
        concatMap(request => OrdersConfirmationWebApi.setOrderConfirmation(request).pipe(
            map(response => {
                _setSnackbar({
                    type: SnackbarTypes.SUCCESS,
                    message: 'Your choice was saved.',
                    isOpen: true,
                });

                return OrdersConfirmationActions.setOrderConfirmationOk(response);
            }),
            catchError(err => {
                _setSnackbar({
                    type: SnackbarTypes.ERROR,
                    message: 'Something went wrong. Please try again later',
                    isOpen: true,
                });

                return of(OrdersConfirmationActions.setOrderConfirmationFail(err.response && err.response.data));
            }),
        )),
    );
