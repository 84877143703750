import * as styles from './main-info.style';

import { ICompleteOrder } from '../../interfaces/order';
import { IOrderDetailsInfo } from '../../interfaces/order-details';
import React from 'react';
import { SectionTitle } from '../../../shared/components/section-title/section-title';
import { Theme } from '../../../shared/styles/theme';
import { colors } from '../../../shared/styles/colors';
import moment from 'moment';

interface Props {
    order: ICompleteOrder;
}

export const OrderMainInfo: React.FC<Props> = (props: Props) => {
    const { order } = props;

    const render = () => {
        return (
            <styles.Wrapper>
                <SectionTitle text={'Main Information'} />

                <styles.Texts>
                    <styles.Section>
                        <styles.Row>
                            <styles.Label>Customer ID</styles.Label>

                            <styles.Value color={undefined}>
                                {order.customerId}
                            </styles.Value>
                        </styles.Row>

                        <styles.Row>
                            <styles.Label>Currency</styles.Label>

                            <styles.Value color={undefined}>
                                {order.currency}
                            </styles.Value>
                        </styles.Row>

                        <styles.Row>
                            <styles.Label>Total Cost</styles.Label>

                            <styles.Value color={colors.$success}>
                                {order.totalCost}
                            </styles.Value>
                        </styles.Row>

                        <styles.Row>
                            <styles.Label>Status</styles.Label>

                            <styles.Value color={undefined}>
                                {order.statusCode}
                            </styles.Value>
                        </styles.Row>

                    </styles.Section>

                    <styles.Section>
                        <styles.Row>
                            <styles.Label>Order Date</styles.Label>

                            <styles.Value color={undefined}>
                                {moment(order.orderDate).format('MM/DD/YYYY')}
                            </styles.Value>
                        </styles.Row>

                        <styles.Row>
                            <styles.Label>Confirmed Date</styles.Label>

                            <styles.Value color={undefined}>
                                {order.confirmedDate ? moment(order.confirmedDate).format('MM/DD/YYYY') : '-'}
                            </styles.Value>
                        </styles.Row>

                        <styles.Row>
                            <styles.Label>Customer Requested Date</styles.Label>

                            <styles.Value color={undefined}>
                                {order.customerRequestedDate ? moment(order.customerRequestedDate).format('MM/DD/YYYY') : '-'}
                            </styles.Value>
                        </styles.Row>

                    </styles.Section>
                </styles.Texts>
            </styles.Wrapper>
        );
    };

    return render();
};
